// Custom scss classes

@import './components/index';

.btn-link {
  text-decoration: none;
}

.btn.btn-secondary {
  background: #FFF;
  box-shadow: none;

  &:hover {
    background: #FFF;
    box-shadow: none;
  }

  &:focus {
    background: #FFF;
    box-shadow: none;
  }
}

.btn.btn-primary {
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9;

  &:hover {
    background-color: #6FC0F9;
    border: 1px solid #6FC0F9;
  }

  &:focus {
    background-color: #6FC0F9;
    border: 1px solid #6FC0F9;
  }
}

body {
  padding-right: 0 !important;
}

#react-tiny-popover-container {
  z-index: 999,
}

.highlighted-table {
  .rs-table-cell-header {
    .rs-table-cell-content {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 8px;
    }
  }

  .rs-table-column-group-header-content {
    // text-align: center;
  }

  .rs-table-cell-content {
    padding: 0;
  }

  .rs-table-cell-wrap {
    height: 100%;
  }

  &-highlight {
    background-color: #E3F6FF;
    padding: 8px;
    height: 100%;
  }

  &-default {
    background-color: #ffffff;
    padding: 8px;
    height: 100%;
  }
}



.voucher-purchase-step {

  &-1 {
    display: inline-block;
    width: 25%;
    margin-left: 5%;
    margin-right: 6%;
    background-color: #D3E3F2;
    box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
    border-radius: 15px;
    z-index: 10;
    position: relative;
    cursor: pointer;
    z-index: 300;
  }

  &-2 {
    display: inline-block;
    width: 25%;
    margin-right: 7%;
    background-color: #D3E3F2;
    box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
    border-radius: 15px;
    z-index: 10;
    position: relative;
    cursor: pointer;
    z-index: 500;
  }

  &-3 {
    display: inline-block;
    width: 25%;
    background-color: #D3E3F2;
    box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
    border-radius: 15px;
    z-index: 10;
    position: relative;
    cursor: pointer;
    z-index: 300;
  }

  &-active {
    box-shadow: inset 4px 4px 8px #BBCFE2, inset -5px -5px 30px #EEF7FF;
    // box-shadow: -5px -5px 4px rgba(242, 249, 255, 0.8), 5px 5px 4px rgba(173, 189, 204, 0.8);
  }
}



.product-info {
  &-logo {
    padding: 10px;
    border-radius: 2px;
    padding-left: 0;
    //  min-width: 40px;
    //  max-width: 70%;

    &-col {
      display: inline-block;
      min-width: 40px;
      max-width: 20%;
    }
  }

  &-description {
    text-align: justify;
    margin-top: 15px;

    &-col {
      display: inline-block;
      width: max-content;
      padding-top: 10px;
      max-width: 100%;
      text-align: justify;
    }

    &-highlight {
      color: #00aeff;
      font-weight: 600;
      padding-left: 3px;
      padding-right: 4px;
    }
  }

  &-discount {
    &-step {
      font-size: 14px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      min-height: 48px;

      &-next-button {
        margin-top: 30px;
        margin-left: 10px;
        width: 100%;
        height: 67px;
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 600;
        background: #00aeff !important;
        background-color: #00aeff !important;

        &:hover {
          border: 1px solid #00aeff !important;
          background: #FFF !important;
          color: #00aeff !important;
          transition: background 0.5s, border 0.5s, color 0.5s;
        }
      }

      &-disabled {
        color: #FFF;
        border: 1px solid #FFF;
        //  background: #00aeff;
        cursor: default;
        opacity: 0.8;

        & .product-info-discount-step-wrapper {
          background: #DDDDDD !important;
          border: 1px solid transparent;
        }
      }

      & img {
        cursor: pointer;
      }

      &-wrapper {
        color: #00aeff;
        border: 1px solid #9de1ff;
        font-size: 14px;
        text-align: center;
        min-height: 55px;
        width: 90%;
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          color: #FFF;
          border: 1px solid #FFF;
          background: #00aeff;
          transition: background 0.5s, border 0.5s, color 0.5s;
        }

        &-active {
          color: #FFF;
          border: 1px solid #FFF;
          background: #00aeff;
          transition: background 0.5s, border 0.5s, color 0.5s;
        }
      }

      &-title {
        font-size: 22px;
        font-weight: 600;
      }

      &-info {
        font-size: 16px;
        // font-weight: 600;

        & svg {
          width: 24px;
          height: 24px;
        }

        & i {
          width: 24px;
          height: 24px;
        }

        &-locked {
          padding-top: 10px;
          font-size: 12px;
          color: #888;
          float: right;
          width: calc(100% - 30px);
          padding-left: 10px;
          padding-right: 10px;
          text-align: left;
          white-space: nowrap;
          line-height: 16px;
          // font-weight: 600;

          &-icon {
            width: 20px;
            height: 20px;
            filter: invert(51%) sepia(0%) saturate(84%) hue-rotate(215deg) brightness(104%) contrast(96%);
            float: left;
            margin-top: 8px;
            margin-left: 3px;
            position: absolute;
          }
        }

        &-bespoke {
          padding-top: 10px;
          font-size: 12px;
          color: #888;
          float: right;
          width: 100%;
          padding-left: 10px;
          padding-right:10px;
        }

        &-bought {
          padding-top: 10px;
          font-size: 12px;
          color: #888;
          float: right;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      &-active {
        // color: #FFF;
        //  border: 1px solid #FFF;
        //  background: #00aeff;
      }
    }
  }
}



.row-cols-7 > * {
  flex: 0 0 auto;
  width: 14.2857142857%; /* 100/7 */
}

.row-cols-8 > * {
  flex: 0 0 auto;
  width: 12.5%; /* 100/8 */
}

@media (min-width: 768px) {
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%; /* 100/7 */
  }

  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 12.5%; /* 100/8 */
  }
}

@media (min-width: 992px) {
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%; /* 100/7 */
  }

  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 12.5%; /* 100/8 */
  }
}

@media (max-width: 1200px) {
  .product-info {
    &-discount {
      &-step {
        &-info {
          &-locked {
            padding-top: 10px;
            width: calc(100% - 30px);
            font-size: 10px;
            padding-left: 0px;
            padding-right: 10px;
            line-height: 22px;

            &-icon {
              width: 20px;
              height: 20px;
              margin-top: 12px;
              margin-left: -8px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .product-info {
    &-discount {
      &-step {
        &-wrapper {
          min-height: 85px;
        }

        &-info {
          &-gift-text {
          }

          &-locked {
            padding-top: 10px;
            width: calc(100% - 30px);
            font-size: 10px;
            white-space: normal;
            line-height: 22px;
            padding-left: 0;

            &-icon {
              width: 18px;
              height: 18px;
              margin-top: 12px;
              margin-left: -8px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .product-info {
    &-discount {
      &-step {
        &-wrapper {
          min-height: 85px;
        }

        &-info {
          &-gift-text {
            line-height: 48px;
          }

          &-locked {
            padding-top: 10px;
            width: calc(100% - 30px);
            font-size: 8px;
            padding-left: 0px;
            padding-right: 5px;
            white-space: normal;
            line-height: 12px;

            &-icon {
              width: 18px;
              height: 18px;
              margin-top: 12px;
              margin-left: -8px;
            }
          }

          &-bespoke {
            padding-left: 10px;
            padding-right: 0px;
          }

          &-bought {
            padding-top: 10px;
            width: 100%;
            font-size: 8px;
            padding-left: 10px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.stripe-pay {
  &-modal {
    margin-top: 15%;

    & .modal-dialog {
      border-radius: 6px;
    }

    & .modal-content {
      padding: 32px;
    }
  }

  &-button {
    padding: 10px 30px;
    // border: 1px solid #009cde;
    border-radius: 5px;
    // background: #009cde;
    // background-image: linear-gradient(#21bdff, #009cde);
    border: solid 1px rgb(0, 178, 255);
    /* background: #0064ab;
    background: -webkit-linear-gradient(#1ebdfa, #0064ab);
    background: -moz-linear-gradient(#1ebdfa, #0064ab);
    background: -o-linear-gradient(#1ebdfa, #0064ab);
    background: -ms-linear-gradient(#1ebdfa, #0064ab);
    background: linear-gradient(#1ebdfa, #0064ab);*/
    background: rgb(0, 178, 255);
    margin: 0 auto;
    display: block;
    // min-width: 138px;
    position: relative;
    color: #FFFFFF;
    height: 50px;
    margin-top: 15px;
    width: 100%;

    &-title {
      font-size: 14px;
      color: #FFFFFF;
      vertical-align: baseline;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
    }
  }
}

.aspButton.blue {
  color: #fff;
  border: solid 1px #1a528c;
  background: #0064ab;
  background: -webkit-linear-gradient(top,#1ebdfa 0,#0064ab 100%);
  background: -moz-linear-gradient(top,#1ebdfa 0,#0064ab 100%);
  background: -o-linear-gradient(top,#1ebdfa 0,#0064ab 100%);
  background: -ms-linear-gradient(top,#1ebdfa 0,#0064ab 100%);
  background: linear-gradient(top,#1ebdfa 0,#0064ab 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ebdfa',endColorstr='#0064ab',GradientType=0)
}

@import './css/index';