/* ===========================
Index Of css

01. Veriables CSS
02. Normalize CSS
03. Header CSS
04. Hero CSS
05. About CSS
06. Achievement CSS
07. Testimonial CSS
08. Pricing CSS
09. Faq CSS
10. Blog CSS
11. Clients CSS
12. Categories CSS
13. Items Grid CSS
14. Browse Chities CSS
15. Items Tab CSS
16. Newsletter CSS
17. Dashboard CSS
18. Login CSS
19. Why Choose CSS
20. Item Details CSS
21. How Works CSS
22. Call To Action CSS
23. Bookmarked CSS
24. Responsive CSS
25. Contact CSS
26. Error CSS
27. Mail Success CSS
28. Coming Soon CSS
29. Footer CSS
========================== */
/*======================================
    Variables
========================================*/
/*======================================
    Normalize CSS
========================================*/
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");

@keyframes color {
  0% {
    background-color: #ECECEC;
  }

  50% {
    background-color: #CFCFCF;
  }

  100% {
    background-color: #ECECEC;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Jost", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #888;
  overflow-x: hidden;
  font-size: 15px;
}

p {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  // font-family: "Jost", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

.filter_blue {
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
  // transition: filter 0.5s;
}

.filter_white {
  filter: invert(99%) sepia(0%) saturate(7496%) hue-rotate(251deg) brightness(104%) contrast(104%);
  // transition: filter 0.5s;
}

.filter_grey {
  filter: invert(51%) sepia(0%) saturate(84%) hue-rotate(215deg) brightness(104%) contrast(96%);
  // transition: filter 0.5s;
}



.navbar-toggler:focus,
a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff;
}

select {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: #081828;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: -internal-light-dark(white, #3b3b3b);
  cursor: default;
  margin: 0em;
  font: 400 14px;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(#767676, #858585);
  -o-border-image: initial;
  border-image: initial;
}

span,
a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0px;
  color: #081828;
}

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
  }

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

/* Bread Crumbs */
.breadcrumbs {
  position: relative;
  padding-bottom: 40px;
  padding-top: 50px;
  background: #00aeff;
  z-index: 2;
  text-align: left;
}

  .breadcrumbs .breadcrumbs-content {
    position: relative;
    text-align: left;
  }

    .breadcrumbs .breadcrumbs-content .page-title {
      font-size: 20px;
      color: #fff;
      font-weight: 600;
      position: relative;
      line-height: 50px;
    }

    .breadcrumbs .breadcrumbs-content .breadcrumb-nav {
      background: transparent;
      border-radius: 0;
      margin-bottom: 0;
      padding: 0;
      display: inline-block;
    }

  .breadcrumbs .breadcrumb-nav {
    text-align: right;
  }

    .breadcrumbs .breadcrumb-nav li {
      display: inline-block;
      position: relative;
      padding-right: 15px;
      margin-right: 15px;
      text-transform: capitalize;
    }

      .breadcrumbs .breadcrumb-nav li:after {
        content: "\ea5c";
        font-family: lineIcons;
        font-size: 10px;
        position: absolute;
        top: 4px;
        right: -7px;
      }

      .breadcrumbs .breadcrumb-nav li:last-child {
        margin: 0;
        padding: 0;
      }

        .breadcrumbs .breadcrumb-nav li:last-child::after {
          display: none;
        }

      .breadcrumbs .breadcrumb-nav li,
      .breadcrumbs .breadcrumb-nav li a {
        color: #fff;
        font-size: 15px;
        font-weight: 500;
      }

        .breadcrumbs .breadcrumb-nav li a {
          position: relative;
        }

          .breadcrumbs .breadcrumb-nav li a:hover {
            color: #00aeff;
          }

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

/* Section Title */
.section-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 300px;
  position: relative;
  z-index: 5;
}

  .section-title h2 {
    font-size: 35px;
    margin-bottom: 20px;
    line-height: 45px;
    text-transform: capitalize;
    position: relative;
    font-weight: 600;
    padding-bottom: 20px;
  }

    .section-title h2:before {
      position: absolute;
      left: 50%;
      margin-left: -25px;
      bottom: -2px;
      height: 4px;
      width: 50px;
      border-radius: 5px;
      background: #00aeff;
      content: "";
    }

  .section-title p {
    font-size: 16px;
    line-height: 26px;
  }

  .section-title.align-right {
    padding: 0;
    padding-left: 600px;
  }

    .section-title.align-right h2:before {
      display: none;
    }

    .section-title.align-right h2:after {
      position: absolute;
      right: 0;
      bottom: -1px;
      height: 2px;
      width: 50px;
      background: #00aeff;
      content: "";
    }

  .section-title.align-left {
    padding: 0;
    padding-right: 600px;
  }

    .section-title.align-left h2:before {
      left: 0;
      margin-left: 0;
    }

/* One Click Scrool Top Button*/
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #00aeff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  border-radius: 0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  border-radius: 4px;
}

  .scroll-top:hover {
    -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
    box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    background-color: #081828;
  }

/* Overlay */
.overlay {
  position: relative;
}

  .overlay::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    content: "";
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 5;
  }

/* Pagination CSS */
.pagination {
  text-align: left;
  margin: 30px 0 0 0;
  display: block;
}

  .pagination.center {
    text-align: center;
  }

  .pagination.right {
    text-align: right;
  }

  .pagination.left {
    text-align: left;
  }

  .pagination .pagination-list {
    display: inline-block;
    overflow: hidden;
  }

    .pagination .pagination-list li {
      margin-right: 5px;
      display: inline-block;
      margin-top: 10px;
      cursor: pointer;
    }

      .pagination .pagination-list li:last-child {
        margin-right: 0px;
      }

      .pagination .pagination-list li a {
        background: #fff;
        color: #081828;
        font-weight: 500;
        font-size: 15px;
        border-radius: 0;
        line-height: 38px;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #eee;
      }

      .pagination .pagination-list li.active a,
      .pagination .pagination-list li:hover a {
        background: #00aeff;
        color: #fff;
        border-color: transparent;
      }

      .pagination .pagination-list li a i {
        font-size: 13px;
      }

.blog-grids.pagination {
  margin-top: 50px;
  text-align: center;
}

.button .btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 30px;
  background-color: #00aeff;
  color: #fff;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 4px;
}

  .button .btn:hover {
    background-color: #081828;
    color: #fff;
    -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
    box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
    border: 1px solid #081828;
  }

.button .btn-alt {
  background-color: #081828;
  color: #fff;
}

  .button .btn-alt:hover {
    background-color: #00aeff;
    color: #fff;
    border: 1px solid #081828;
  }

.button .btn-white {
  background-color: #FFF;
  color: #888;
}

  .button .btn-white:hover {
    background-color: #081828;
    color: #fff;
    border: 1px solid #081828;
  }

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/* Preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader-icon {
  width: 100px;
  height: 100px;
  display: inline-block;
  padding: 0px;
}

  .preloader-icon span {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #00aeff;
    -webkit-animation: preloader-fx 1.6s linear infinite;
    animation: preloader-fx 1.6s linear infinite;
  }

    .preloader-icon span:last-child {
      animation-delay: -0.8s;
      -webkit-animation-delay: -0.8s;
    }

@keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/*======================================
	01. Start Header CSS
========================================*/
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
}

  .header .login-button {
    margin-left: 40px;
  }

    .header .login-button ul {
      padding: 14px 20px;
      border-radius: 30px;
    }

      .header .login-button ul li {
        display: inline-block;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #eee;
        height: 100%;
      }

        .header .login-button ul li:last-child {
          margin: 0;
          padding: 0;
          border: none;
        }

        .header .login-button ul li a {
          color: #081828;
          font-size: 14px;
          font-weight: 500;
        }

          .header .login-button ul li a:hover {
            color: #00aeff;
          }

          .header .login-button ul li a i {
            display: inline-block;
            color: #00aeff;
            margin-right: 4px;
            font-size: 15px;
          }

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  top: 0;
}

.navbar-expand-lg .navbar-nav {
  margin-left: auto;
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #333;
}

.header .navbar .navbar-nav .nav-item a.active {
  color: #00aeff;
}

.sticky .navbar .navbar-nav .nav-item a.active {
  color: #00aeff;
}

.header .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333;
}

/* Topbar */
.header .toolbar-area {
  padding: 18px 0;
  background: #00aeff;
  overflow: hidden;
}

  .header .toolbar-area .toolbar-social {
    float: right;
  }

    .header .toolbar-area .toolbar-social ul li {
      display: inline-block;
      margin-right: 15px;
    }

      .header .toolbar-area .toolbar-social ul li .title {
        display: inline-block;
        font-weight: 600;
        font-size: 13px;
        color: #fff;
      }

      .header .toolbar-area .toolbar-social ul li:last-child {
        margin: 0;
      }

      .header .toolbar-area .toolbar-social ul li a {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

        .header .toolbar-area .toolbar-social ul li a:hover {
          color: #081828;
        }

.header .toolbar-login {
  float: left;
}

  .header .toolbar-login span {
    display: inline-block;
    color: #fff;
    margin: 0px 12px;
    font-size: 13px;
    font-weight: 600;
  }

  .header .toolbar-login a {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-size: 13px;
  }

    .header .toolbar-login a i {
      display: inline-block;
      margin-right: 3px;
      color: #fff;
      font-size: 11px;
      height: 22px;
      width: 22px;
      text-align: center;
      display: inline-block;
      background: #fff;
      border-radius: 50%;
      color: #00aeff;
      line-height: 22px;
    }

    .header .toolbar-login a:hover {
      color: #081828;
    }

    .header .toolbar-login a:last-child {
      margin: 0;
    }

/*===== NAVBAR =====*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0;
}

  .navbar-area.sticky {
    position: fixed;
    z-index: 99;
    background: #00aeff;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background: #fff;
    padding: 0px 0;
    min-height: 98px;
    max-height: 98px;
  }

    .navbar-area.sticky .toolbar-area {
      display: none;
    }

.navbar {
  padding: 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding: 0;
}

  .navbar-brand img {
    width: 190px;
  }

.mobile-menu-btn {
  padding: 0px;
}

  .mobile-menu-btn:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .mobile-menu-btn .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #222;
    display: block;
    margin: 5px 0;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  .mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
  }

  .mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
  }

  .mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px;
  }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 66px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 165% !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
  }
}

.mobile-navbar {
  display: block;
}

@media (min-width: 992px) {
  .navbar-expand-lg .mobile-navbar {
    display: none !important;
  }
}

.navbar-expand-lg .browser-navbar {
  display: none !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .browser-navbar {
    display: flex !important;
  }
}



.navbar-expand-lg .navbar-nav {
  margin: 0;
  margin-right: auto !important;
}

.navbar-nav .nav-item {
  z-index: 1;
  position: relative;
  margin-right: 40px;
}

  .navbar-nav .nav-item:last-child {
    margin-right: 0 !important;
  }

  .navbar-nav .nav-item:hover a {
    color: #00aeff;
  }

  .navbar-nav .nav-item a {
    font-size: 15px;
    color: #081828;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    padding: 18px 0;
    padding-top: 38px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    transition: all 0.3s ease-out 0s;
    position: relative;
    text-transform: capitalize;
  }

    .navbar-nav .nav-item a::after {
      opacity: 0;
      visibility: hidden;
    }

    .navbar-nav .nav-item a::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 0%;
      background: #00aeff;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .navbar-nav .nav-item a.active:before {
      width: 100%;
    }

  .navbar-nav .nav-item:hover a:before {
    width: 100%;
  }

  .navbar-nav .nav-item a.active {
    color: #00aeff;
  }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu {
    padding-right: 30px;
  }
}

.navbar-nav .nav-item a.dd-menu::after {
  content: "\ea58";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 17px;
  font-size: 10px;
  top: 50%;
  margin-left: 5px;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 10px;
  margin-top: -5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu::after {
    right: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a.dd-menu::after {
    top: 16px;
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a.collapsed::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.navbar-nav .nav-item:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

  .navbar-nav .nav-item:hover > .sub-menu .sub-menu {
    left: 100%;
    top: 0;
  }

.navbar-nav .nav-item .sub-menu {
  border-top: 1px solid #eee;
  padding: 10px;
  min-width: 220px;
  background: #fff;
  -webkit-box-shadow: 0px 10px 20px #1414140e;
  box-shadow: 0px 10px 20px #1414140e;
  position: absolute;
  top: 100% !important;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

  .navbar-nav .nav-item .sub-menu.mega-menu {
    width: 500px;
    white-space: none !important;
    padding-right: 5px;
  }

    .navbar-nav .nav-item .sub-menu.mega-menu .mega-menu-title {
      font-size: 16px;
      display: block;
      font-weight: 500;
      color: #081828;
      margin-bottom: 10px;
      padding-left: 12px;
      padding-top: 12px;
    }

    .navbar-nav .nav-item .sub-menu.mega-menu .single-block {
      width: 49.5% !important;
      display: inline-block !important;
      white-space: none !important;
      border-right: 1px solid #eee;
      height: 100%;
      float: left;
    }

      .navbar-nav .nav-item .sub-menu.mega-menu .single-block:first-child {
        padding-right: 10px;
      }

      .navbar-nav .nav-item .sub-menu.mega-menu .single-block:last-child {
        border: none;
        padding-left: 10px;
      }

      .navbar-nav .nav-item .sub-menu.mega-menu .single-block li a:hover {
        background-color: #00aeff;
        color: #fff;
      }

      .navbar-nav .nav-item .sub-menu.mega-menu .single-block li.active a {
        background-color: #00aeff;
        color: #fff;
      }

  .navbar-nav .nav-item .sub-menu .nav-item {
    width: 100%;
  }

    .navbar-nav .nav-item .sub-menu .nav-item a {
      padding: 12px 15px;
      color: #888;
      display: block;
      width: 100%;
      font-size: 15px;
      font-weight: 400;
      text-transform: capitalize;
      position: relative;
    }

      .navbar-nav .nav-item .sub-menu .nav-item a:before {
        display: none;
      }

  .navbar-nav .nav-item .sub-menu.left-menu {
    left: -100%;
  }

  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: block;
  }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item {
    margin: 0;
  }

    .navbar-nav .nav-item a {
      padding: 12px 16px !important;
    }

      .navbar-nav .nav-item a::before {
        display: none;
      }

    .navbar-nav .nav-item .sub-menu {
      position: static;
      width: 100%;
      opacity: 1;
      visibility: visible;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 0;
      border: none;
      margin-left: 15px;
      margin-right: 15px;
    }

      .navbar-nav .nav-item .sub-menu.mega-menu {
        width: 100% !important;
        white-space: none !important;
        padding-right: 0;
      }

        .navbar-nav .nav-item .sub-menu.mega-menu .single-block {
          width: 100% !important;
          display: block !important;
          white-space: none !important;
          border: none;
          height: 100%;
          float: left;
        }

          .navbar-nav .nav-item .sub-menu.mega-menu .single-block:first-child {
            padding-right: 0;
          }

          .navbar-nav .nav-item .sub-menu.mega-menu .single-block:last-child {
            border: none;
            padding-left: 0;
          }

      .navbar-nav .nav-item .sub-menu .nav-item a {
        padding: 12px 12px;
      }

        .navbar-nav .nav-item .sub-menu .nav-item a:hover {
          background: #00aeff !important;
          color: #fff !important;
        }

      .navbar-nav .nav-item .sub-menu::after {
        display: none;
      }
}

.navbar-nav .nav-item .sub-menu > li {
  display: block;
  margin-left: 0;
}

  .navbar-nav .nav-item .sub-menu > li:last-child {
    border: none;
  }

  .navbar-nav .nav-item .sub-menu > li.active > a,
  .navbar-nav .nav-item .sub-menu > li:hover > a {
    color: #fff !important;
    background-color: #00aeff !important;
  }

  .navbar-nav .nav-item .sub-menu > li > a {
    font-weight: 400;
    display: block;
    padding: 12px 15px;
    font-size: 14px;
    color: #222;
    font-weight: 400;
  }

  .navbar-nav .nav-item .sub-menu > li:first-child a {
    border: none;
  }

  .navbar-nav .nav-item .sub-menu > li > a:hover {
    background: #00aeff;
    color: #fff;
  }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a {
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

    .navbar-nav .nav-item a::after {
      opacity: 1;
      visibility: visible;
    }

  .navbar-nav .nav-item .sub-menu > li.active {
    background: #f9f9f9 !important;
    color: #00aeff !important;
  }

  .header.style3.navbar-area .header-social {
    display: none;
  }

  .navbar-nav .nav-item .sub-menu > li.active > a,
  .navbar-nav .nav-item .sub-menu > li:hover > a {
    color: #fff !important;
    background-color: #00aeff !important;
  }
}

.header-button {
  display: inline-block;
  margin-left: 10px;
}

  .header-button .btn i {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
  }

/*======================================
     End Header CSS
  ========================================*/
/*======================================
    Hero Area CSS
========================================*/
.hero-area {
  background: #00aeff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 2;
}

/* Shimmer Effect */
  .hero-area::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient( 120deg, transparent 30%, rgba(255, 255, 255, 0.5) 50%, transparent 70% );
    animation: shimmer 0.75s linear forwards; /* Runs once */
  }

@keyframes shimmer {
  0% {
    left: -100%;
    width: 200%;
  }

  100% {
    left: 100%;
    width: 0%;
  }
}

  .hero-area.overlay::before {
    z-index: -1;
    opacity: 0.9;
  }

.hero-area.style2 {
  padding-top: 100px;
  padding-bottom: 100px;
  animation: none;
}

  .hero-area.style2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: #00aeff;
    animation: none;
  }

    .hero-area.style2 .business-homepage-start-selling-btn {
      display: inline-block;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
      padding: 15px 30px;
      background-color: #FFF;
      color: #00aeff;
      border: none;
      transition: 0.5s;
      border-radius: 4px;
    }

      .hero-area.style2 .business-homepage-start-selling-btn:hover {
        background-color: #eee;
      }

    .hero-area.style2 .business-homepage-start-selling-image {
      max-width: 125%;
      width: 125%;
      margin-left: -15px;
    }

@media only screen and (max-width: 1200px) {
  .hero-area.style2 .business-homepage-start-selling-image {
    max-width: 115%;
    width: 115%;
    margin-left: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-area.style2 .business-homepage-start-selling-image {
    max-width: 115%;
    width: 115%;
    margin-left: -20px;
  }
}

  .hero-area.style2 .hero-text {
    text-align: left;
  }

    .hero-area.style2 .hero-text h2 {
      font-size: 50px;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

      .hero-area.style2 .hero-text h2::before {
        left: 0;
        margin-left: 0;
      }

  .hero-area.style3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

    .hero-area.style3 .search-form {
      margin-top: 100px;
      background: #fff;
      border-radius: 6px;
      padding: 35px 40px;
      padding-right: 55px;
      width: 100%;
      border: 1px solid #eee;
      position: relative;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
    }

      .hero-area.style3 .search-form .btn:hover {
        background-color: #081828;
        color: #fff;
      }

    .hero-area.style3 .categories {
      padding: 0;
      margin-top: 30px;
    }

      .hero-area.style3 .categories .single-cat {
        border: none;
        background-color: #fff;
        padding: 20px 10px;
      }

  .hero-area .hero-text h2 {
    color: #fff;
    font-size: 45px;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 25px;
    padding-bottom: 15px;
    position: relative;
  }

    .hero-area .hero-text h2::before {
      position: absolute;
      content: "";
      left: 50%;
      bottom: -2px;
      height: 4px;
      width: 60px;
      margin-left: -30px;
      background: #00aeff;
      border-radius: 10px;
    }

  .hero-area .hero-text p {
    color: #ddd;
    font-size: 16px;
    font-weight: 400;
    display: block;
  }

  .hero-area .hero-text .button .btn:hover {
    background-color: #081828;
    color: #fff;
    box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  }

  /* Hero Search Form */
  .search-form {
    margin-top: 60px;
    background: #fff3;
    border-radius: 6px;
    padding: 30px;
    padding-right: 45px;
  }

    .search-form.style2 {
      padding: 0;
      padding: 50px;
      background-color: #fff;
    }

      .search-form.style2 .heading-title {
        font-size: 24px;
        margin-bottom: 8px;
      }

      .search-form.style2 .sub-heding-text {
        margin-bottom: 40px;
        font-size: 16px;
      }

      .search-form.style2 .search-input {
        padding: 0;
        margin-bottom: 18px;
      }

      .search-form.style2 .btn {
        margin: 0;
      }

        .search-form.style2 .btn:hover {
          background-color: #081828;
          color: #fff;
        }

    .search-form .search-input {
      position: relative;
      padding: 0px 8px;
    }

      .search-form .search-input input {
        width: 100%;
        background: #fff;
        border-radius: 6px;
        border: none;
        padding: 0 25px;
        padding-right: 45px;
        height: 55px;
        font-size: 15px;
        border: 1px solid #eee;
      }

        .search-form .search-input input:focus {
          outline: none !important;
        }

      .search-form .search-input label {
        position: absolute;
        right: 25px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      .search-form .search-input select {
        width: 100%;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #eee;
        padding: 0 25px;
        height: 55px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 15px;
      }

        .search-form .search-input select:focus {
          outline: none !important;
        }

    .search-form button {
      width: 100%;
      border-radius: 6px;
      border: none;
      padding: 0 25px;
      height: 55px;
      margin-left: 5px;
    }

      .search-form button.btn:hover {
        background: #fff;
        color: #081828;
      }

      .search-form button i {
        display: inline-block;
        margin-right: 5px;
      }

  /*======================================
    About Us CSS
========================================*/
  .about-us .content-left {
    position: relative;
  }

    .about-us .content-left img {
      width: 100%;
      border-radius: 6px;
    }

    .about-us .content-left .video {
      position: absolute;
      height: 80px;
      width: 80px;
      line-height: 80px;
      text-align: center;
      color: #00aeff;
      background-color: #fff;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      margin-left: -40px;
      margin-top: -40px;
      font-size: 16px;
      padding-left: 3px;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

      .about-us .content-left .video:hover {
        color: #fff;
        background-color: #00aeff;
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }

  .about-us .content-right {
    padding-right: 70px;
    padding-left: 40px;
  }

    .about-us .content-right .sub-heading {
      display: inline-block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 15px;
      color: #00aeff;
      background-color: #f9f9f9;
      padding: 5px 20px;
      border-radius: 4px;
    }

    .about-us .content-right h2 {
      display: block;
      font-size: 32px;
      margin-bottom: 25px;
      line-height: 40px;
      text-transform: capitalize;
      position: relative;
      font-weight: 700;
    }

    .about-us .content-right h3 {
      font-size: 22px;
      margin-top: 40px;
      margin-bottom: 8px;
      line-height: 45px;
      text-transform: capitalize;
      position: relative;
      font-weight: 600;
    }

    .about-us .content-right p {
      font-size: 16px;
      line-height: 26px;
    }

  .about-us .image-placeholder {
    width: 100%;
    aspect-ratio: 1.2884 / 1;
    position: absolute;
    top: 0;
    border-radius: 4px;
    background-color: #ECECEC;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  /*======================================
    Our Achievement CSS
========================================*/
  .our-achievement {
    background-color: #081828;
    padding-top: 70px;
    padding: 90px 0 120px 0;
  }

    .our-achievement .single-achievement {
      margin-top: 30px;
      text-align: center;
      padding: 0px 50px;
    }

      .our-achievement .single-achievement h3 {
        font-size: 40px;
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
        color: #fff;
      }

      .our-achievement .single-achievement p {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        font-family: 'Jost', sans-serif;
      }

  /*======================================
    Testimonial CSS
========================================*/
  /*.testimonials {
    background-color: #081828;
    padding-bottom: 180px;
  }

    .testimonials .section-title h2 {
      color: #fff;
    }

    .testimonials .section-title p {
      color: #fff;
    }

    .testimonials .testimonial-slider {
      margin: 0;
    }

    .testimonials .tns-nav {
      text-align: center;
      position: absolute;
      bottom: 90px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 100%;
      left: 50%;
      z-index: 9;
    }

      .testimonials .tns-nav button {
        height: 5px;
        width: 30px;
        background-color: #fff;
        border-radius: 5px;
        display: inline-block;
        border: none;
        margin: 0px 5px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

        .testimonials .tns-nav button:hover {
          background: #00aeff;
        }

        .testimonials .tns-nav button.tns-nav-active {
          background: #00aeff;
        }

    .testimonials .single-testimonial {
      background: #fff;
      border: 1px solid #ececec;
      padding: 50px 40px;
      margin: 20px 0;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      position: relative;
    }

      .testimonials .single-testimonial .quote-icon {
        position: absolute;
        right: 30px;
        top: 10px;
      }

        .testimonials .single-testimonial .quote-icon i {
          font-size: 70px;
          color: #cccccc3b;
        }

      .testimonials .single-testimonial:hover {
        -webkit-box-shadow: 0px 0px 13px #0000001a;
        box-shadow: 0px 0px 13px #0000001a;
      }

        .testimonials .single-testimonial:hover .author img {
          border-color: #00aeff;
        }

      .testimonials .single-testimonial .author {
        position: relative;
        padding-left: 90px;
        min-height: 70px;
        margin-bottom: 25px;
        padding-top: 10px;
      }

        .testimonials .single-testimonial .author img {
          height: 70px;
          width: 70px;
          border-radius: 50%;
          border: 5px solid #ececec;
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }

        .testimonials .single-testimonial .author .name {
          font-size: 18px;
          font-weight: 600;
          color: #081828;
        }

          .testimonials .single-testimonial .author .name .deg {
            display: block;
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #888;
          }

      .testimonials .single-testimonial .text p {
        font-size: 16px;
      } */

  /*=============================
	Pricing Table CSS
===============================*/
  .pricing-table {
    background: #f9f9f9;
    position: relative;
  }

    .pricing-table .section-title {
      margin-bottom: 50px;
    }

    .pricing-table .single-table {
      margin-top: 30px;
      background: #fff;
      text-align: left;
      position: relative;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      border: 1px solid #ececec;
      overflow: hidden;
      border-radius: 6px;
      padding: 40px 40px;
      z-index: 2;
    }

      .pricing-table .single-table::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 300px;
        width: 300px;
        background: #00aeff;
        border-radius: 50%;
        left: -50px;
        top: -110px;
      }

      .pricing-table .single-table::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 300px;
        width: 300px;
        background: #00aeff;
        border-radius: 50%;
        left: -40px;
        top: -110px;
        opacity: 0.8;
        z-index: -1;
      }

      .pricing-table .single-table .table-head {
        position: relative;
      }

        .pricing-table .single-table .table-head .amount {
          font-size: 35px;
          line-height: 56px;
          font-weight: 700;
          color: #fff;
        }

        .pricing-table .single-table .table-head .duration {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          display: inline-block;
          margin-left: 4px;
          position: relative;
          top: -2px;
        }

        .pricing-table .single-table .table-head .title {
          font-size: 16px;
          line-height: 26px;
          color: #fff;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 5px;
          margin-bottom: 19px;
        }

      .pricing-table .single-table .table-list {
        position: relative;
        display: block;
        margin-top: 90px;
      }

        .pricing-table .single-table .table-list li {
          position: relative;
          display: block;
          font-size: 16px;
          line-height: 26px;
          color: #444;
          font-weight: 400;
          margin-bottom: 10px;
        }

          .pricing-table .single-table .table-list li:last-child {
            margin-bottom: 0;
          }

      .pricing-table .single-table .button {
        margin-top: 40px;
      }

  /*======================================
    Faq CSS
========================================*/
  .faq {
    padding-bottom: 80px;
  }

  .accordion-item .accordion-button {
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    display: block;
    overflow: hidden;
    border: none;
    border: 1px solid #eee;
  }

    .accordion-item .accordion-button span {
      float: left;
    }

    .accordion-item .accordion-button i {
      float: right;
      font-size: 13px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

  .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #00aeff;
    border-color: transparent;
    border-radius: 5px 5px 0 0;
  }

    .accordion-button:not(.collapsed) i::before {
      content: "\eb2c";
      font-family: lineIcons;
    }

  .accordion-button::after {
    display: none;
  }

  .accordion-collapse {
    border: none;
  }

  .accordion-body {
    border: 1px solid #eee;
    border-radius: 0 0 5px 5px;
    padding: 30px 20px;
  }

    .accordion-body p {
      margin: 0;
      margin-bottom: 20px;
      font-size: 16px;
    }

      .accordion-body p:last-child {
        margin: 0;
      }

  .accordion-item {
    margin-bottom: 20px;
  }

  /*======================================
    Blog CSS
========================================*/
  .latest-news-area .single-news {
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
  }

    .latest-news-area .single-news .image {
      overflow: hidden;
      position: relative;
      z-index: 0;
      width: 100%;
    }

      .latest-news-area .single-news .image a {
        width: 100%;
      }

      .latest-news-area .single-news .image:before {
        position: absolute;
        content: "";
        left: 50%;
        top: 0%;
        height: 50px;
        width: 2px;
        background: #fff;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 0;
        visibility: hidden;
      }

      .latest-news-area .single-news .image:after {
        position: absolute;
        content: "";
        left: 0%;
        top: 50%;
        height: 2px;
        width: 50px;
        background: #fff;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 0;
        visibility: hidden;
      }

      .latest-news-area .single-news .image img {
        width: 100%;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        position: relative;
      }

    .latest-news-area .single-news:hover .image::before {
      top: 50%;
      opacity: 1;
      visibility: visible;
    }

    .latest-news-area .single-news:hover .image::after {
      left: 50%;
      opacity: 1;
      visibility: visible;
    }

    .latest-news-area .single-news:hover .image img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    .latest-news-area .single-news .content-body {
      padding: 40px 30px 30px 30px;
      border-radius: 6px;
      border-radius: 0 0 6px 6px;
      border: 1px solid #ececec;
    }

      .latest-news-area .single-news .content-body .title {
        line-height: 30px;
      }

        .latest-news-area .single-news .content-body .title a {
          font-size: 20px;
          font-weight: 700;
          color: #081828;
          margin-bottom: 20px;
        }

          .latest-news-area .single-news .content-body .title a:hover {
            color: #00aeff;
          }

      .latest-news-area .single-news .content-body p {
        font-size: 16px;
      }

      .latest-news-area .single-news .content-body .meta-details {
        padding-top: 15px;
        margin-top: 30px;
        border-top: 1px solid #eee;
      }

        .latest-news-area .single-news .content-body .meta-details ul li {
          display: inline-block;
          margin-right: 17px;
          padding-right: 17px;
          position: relative;
        }

          .latest-news-area .single-news .content-body .meta-details ul li::before {
            position: absolute;
            content: "";
            right: -4px;
            top: 9px;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: #00aeff;
          }

          .latest-news-area .single-news .content-body .meta-details ul li:last-child {
            margin: 0;
            padding: 0;
          }

            .latest-news-area .single-news .content-body .meta-details ul li:last-child::before {
              display: none;
            }

          .latest-news-area .single-news .content-body .meta-details ul li a {
            font-size: 14px;
            color: #081828;
            font-weight: 500;
          }

            .latest-news-area .single-news .content-body .meta-details ul li a:hover {
              color: #00aeff;
            }

  .blog-list {
    background: #f9f9f9;
  }

    .blog-list .single-news {
      margin: 0;
    }

    .blog-list .single-news {
      margin-bottom: 40px;
    }

  /* News Details */
  .blog-single {
    background: #f9f9f9;
  }

    .blog-single .post-thumbnils {
      position: relative;
      overflow: hidden;
      border-radius: 6px 6px 0 0;
    }

      .blog-single .post-thumbnils img {
        width: 100%;
      }

  .post-details .detail-inner {
    background-color: #fff;
    padding: 40px;
    margin-bottom: 40px;
    border: 1px solid #eee;
    border-radius: 0 0 6px 6px;
    border-top-color: transparent;
  }

  .post-details .post-thumbnils {
    margin-bottom: 30px;
  }

  .post-details .post-title {
    font-weight: 700;
    margin-bottom: 25px;
    line-height: 32px;
  }

    .post-details .post-title a {
      font-size: 25px;
      font-weight: 600;
    }

      .post-details .post-title a:hover {
        color: #00aeff;
      }

  ul.custom-flex {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .post-details .post-meta li {
    font-weight: 500;
    font-size: 15px;
    margin-right: 25px;
  }

    .post-details .post-meta li a i {
      font-weight: 400;
      margin-right: 3px;
    }

    .post-details .post-meta li a:hover {
      color: #00aeff;
    }

  .post-details p {
    font-size: 15px;
    margin: 30px 0;
  }

  .post-details h3 {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .post-details p:last-child {
    margin-bottom: 0;
  }

  blockquote {
    position: relative;
    color: #fff;
    font-weight: 400;
    clear: both;
    z-index: 1;
    margin: 40px 0;
    text-align: left;
    padding: 40px;
    background-color: #00aeff;
    border-radius: 5px;
    overflow: hidden;
  }

    blockquote .icon i {
      font-size: 40px;
      color: #fff;
      display: block;
      margin-bottom: 20px;
    }

    blockquote h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
    }

    blockquote span {
      font-size: 13px;
      display: block;
      margin-top: 20px;
    }

    blockquote .shape {
      position: absolute;
      right: -90px;
      top: -91px;
      z-index: -1;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

  .post-details .list {
    margin: 40px;
  }

    .post-details .list li {
      color: #888;
      font-weight: 400;
      position: relative;
      padding-left: 40px;
      display: block;
      margin-bottom: 20px;
      position: relative;
      font-size: 16px;
      font-family: 'Lato', sans-serif;
    }

      .post-details .list li i {
        display: block;
        height: 25px;
        width: 25px;
        line-height: 25px;
        font-size: 10px;
        color: #fff;
        background-color: #00aeff;
        position: absolute;
        left: 0;
        top: -1px;
        text-align: center;
        border-radius: 3px;
      }

      .post-details .list li:last-child {
        margin: 0;
      }

  .post-social-media {
    margin-top: 40px;
  }

    .post-social-media .share-title {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      display: inline-block;
    }

  .post-details .post-social-media ul {
    display: inline-block;
    margin-left: 30px;
  }

    .post-details .post-social-media ul li {
      display: inline-block;
      margin-right: 13px;
    }

      .post-details .post-social-media ul li a {
        font-size: 15px;
        color: #999;
        position: relative;
      }

        .post-details .post-social-media ul li a:hover {
          color: #00aeff;
        }

        .post-details .post-social-media ul li a span {
          background-color: #00aeff;
          color: #fff;
          font-size: 13px;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 3px;
          position: absolute;
          left: 50%;
          top: -30px;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }

          .post-details .post-social-media ul li a span::before {
            position: absolute;
            content: "";
            left: 50%;
            margin-left: -5px;
            bottom: -10px;
            border: 5px solid #00aeff;
            border-bottom-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
          }

        .post-details .post-social-media ul li a:hover span {
          opacity: 1;
          visibility: visible;
          top: -35px;
        }

  .post-details .post-meta li {
    font-weight: 500;
    margin-right: 25px;
  }

    .post-details .post-meta li a {
      font-size: 13px;
      font-weight: 400;
      font-weight: 500;
      color: #081828;
    }

      .post-details .post-meta li a i {
        font-weight: 400;
        margin-right: 5px;
        color: #00aeff;
      }

  .post-details p {
    font-size: 16px;
    line-height: 28px;
  }

  .post-details .post-image {
    margin: 40px 0 20px 0;
    width: 100%;
  }

    .post-details .post-image img {
      width: 100%;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      border-radius: 5px;
    }

  .post-details > ul > li {
    font-weight: 500;
  }

  .detail-post-navigation {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #fff;
    padding: 30px;
    border: 1px solid #eee;
  }

  /*comments*/
  .post-comments {
    background-color: #fff;
    padding: 40px;
    margin-top: 40px;
    border: 1px solid #eee;
    border-radius: 5px;
  }

  .comment-title {
    font-size: 22px !important;
    font-weight: 500 !important;
    margin-bottom: 40px !important;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
  }

    .comment-title span {
      background-color: white;
      padding-right: 15px;
    }

    .comment-title::before {
      position: absolute;
      left: 0;
      top: 50%;
      height: 1px;
      width: 100%;
      background-color: #eee;
      content: '';
      z-index: -1;
    }

  .comment-reply-title {
    font-size: 22px !important;
    font-weight: 500 !important;
    margin-bottom: 40px !important;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
  }

    .comment-reply-title span {
      background-color: white;
      padding-right: 15px;
    }

    .comment-reply-title::before {
      position: absolute;
      left: 0;
      top: 50%;
      height: 1px;
      width: 100%;
      background-color: #eee;
      content: '';
      z-index: -1;
    }

  .post-comments .comments-list li {
    padding-left: 130px;
    position: relative;
    font-size: 14px;
  }

    .post-comments .comments-list li .comment-img {
      position: absolute;
      left: 0;
      width: 100px;
      height: 100px;
    }

      .post-comments .comments-list li .comment-img img {
        max-width: 100px;
        max-height: 100px;
      }

    .post-comments .comments-list li .comment-desc .desc-top {
      margin-bottom: 20px;
      position: relative;
      display: block;
    }

      .post-comments .comments-list li .comment-desc .desc-top h6 {
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: 500;
      }

        .post-comments .comments-list li .comment-desc .desc-top h6 .saved {
          color: #00aeff;
          font-size: 14px;
          margin-left: 10px;
        }

      .post-comments .comments-list li .comment-desc .desc-top span.date {
        font-size: 14px;
        font-weight: 400;
      }

      .post-comments .comments-list li .comment-desc .desc-top .reply-link {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 10px 22px;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        z-index: 2;
        border-radius: 4px;
        border: 1px solid #eee;
        color: #00aeff;
      }

        .post-comments .comments-list li .comment-desc .desc-top .reply-link:hover {
          background-color: #00aeff;
          color: #fff;
          border-color: transparent;
        }

        .post-comments .comments-list li .comment-desc .desc-top .reply-link i {
          margin-right: 5px;
        }

    .post-comments .comments-list li .comment-desc p {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
    }

    .post-comments .comments-list li.children {
      margin-left: 130px;
    }

    .post-comments .comments-list li:not(:first-child) {
      padding-top: 30px;
      margin-top: 30px;
      border-top: 1px solid #eee;
    }

  /*Comment form*/
  .comment-form {
    background-color: #fff;
    padding: 40px;
    margin-top: 40px;
    border: 1px solid #eee;
    border-radius: 5px;
  }

    .comment-form form .form-box {
      position: relative;
    }

      .comment-form form .form-box .icon {
        position: absolute;
        top: 17px;
        right: 25px;
        font-size: 16px;
      }

      .comment-form form .form-box .form-control-custom {
        border: none;
        background: #fff;
        font-size: 14px;
        color: #081828;
        padding: 0 25px;
        font-weight: 500;
        height: 55px;
        border: 1px solid #eee;
        margin-bottom: 25px;
        border-radius: 0;
        font-size: 15px;
        font-weight: 400;
        border-radius: 5px;
      }

      .comment-form form .form-box textarea.form-control-custom {
        height: 200px;
        padding: 25px;
      }

      .comment-form form .form-box .form-control-custom::-webkit-input-placeholder {
        font-size: 15px;
        color: #081828;
        font-weight: 400;
      }

      .comment-form form .form-box .form-control-custom:-ms-input-placeholder {
        font-size: 15px;
        color: #081828;
        font-weight: 400;
      }

      .comment-form form .form-box .form-control-custom::-ms-input-placeholder {
        font-size: 15px;
        color: #081828;
        font-weight: 400;
      }

      .comment-form form .form-box .form-control-custom::placeholder {
        font-size: 15px;
        color: #081828;
        font-weight: 400;
      }

  /* News sidebar */
  .sidebar .widget {
    padding: 40px;
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #eee;
  }

    .sidebar .widget:last-child {
      margin-bottom: 0;
    }

    .sidebar .widget .widget-title {
      font-size: 20px;
      margin-bottom: 40px;
      line-height: 1;
      position: relative;
      font-weight: 600;
      line-height: 28px;
      z-index: 1;
    }

      .sidebar .widget .widget-title span {
        background-color: white;
        padding-right: 18px;
      }

      .sidebar .widget .widget-title::before {
        position: absolute;
        left: 0;
        top: 50%;
        height: 1px;
        width: 100%;
        background-color: #eee;
        content: '';
        z-index: -1;
      }

    .sidebar .widget.search-widget form {
      position: relative;
    }

      .sidebar .widget.search-widget form input {
        width: 100%;
        background-color: transparent;
        height: 55px;
        border: none;
        padding: 0 80px 0 30px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 0;
        border: 1px solid #eee;
        border-radius: 5px;
      }

        .sidebar .widget.search-widget form input::-webkit-input-placeholder {
          color: #333;
        }

        .sidebar .widget.search-widget form input:-ms-input-placeholder {
          color: #333;
        }

        .sidebar .widget.search-widget form input::-ms-input-placeholder {
          color: #333;
        }

        .sidebar .widget.search-widget form input::placeholder {
          color: #333;
        }

      .sidebar .widget.search-widget form button {
        border: none;
        position: absolute;
        right: 7px;
        top: 6px;
        width: 55px;
        height: 42px;
        z-index: 1;
        color: #fff !important;
        font-size: 13px;
        -webkit-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        color: #fff;
        border-radius: 0;
        border-radius: 3px;
        padding: 0 !important;
        border: none;
        background: #00aeff;
      }

        .sidebar .widget.search-widget form button:hover {
          background-color: #081828;
          color: #fff;
        }

    .sidebar .widget.popular-feeds .single-popular-feed {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 20px;
      padding-bottom: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid #eee;
    }

      .sidebar .widget.popular-feeds .single-popular-feed:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .sidebar .widget.popular-feeds .single-popular-feed .feed-img {
        width: 80px;
        margin-right: 20px;
        border-radius: 50%;
        height: 80px;
        border-radius: 50% !important;
        overflow: hidden;
      }

      .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        line-height: 1.5;
      }

        .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a {
          font-size: 16px;
          font-weight: 500;
        }

          .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a:hover {
            color: #00aeff;
          }

      .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
        font-weight: 400;
        font-size: 14px;
      }

        .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time > i {
          margin-right: 4px;
        }

    .sidebar .widget.categories-widget ul li {
      margin-bottom: 10px;
    }

      .sidebar .widget.categories-widget ul li:last-child {
        margin-bottom: 0;
      }

      .sidebar .widget.categories-widget ul li a {
        font-size: 15px;
        height: 35px;
        background-color: transparent;
        display: block;
        position: relative;
        line-height: 35px;
        color: #081828;
        -webkit-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        font-weight: 500;
        padding: 0;
        padding-right: 40px !important;
        display: inline-block;
        line-height: 22px;
        width: 100%;
      }

        .sidebar .widget.categories-widget ul li a span {
          position: absolute;
          right: 0;
          top: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          background-color: transparent;
          color: #333;
          -webkit-transition: all 0.3s ease-out 0s;
          transition: all 0.3s ease-out 0s;
          border-radius: 50%;
          border: 1px solid #eee;
          font-size: 12px;
        }

        .sidebar .widget.categories-widget ul li a:hover {
          color: #00aeff;
        }

          .sidebar .widget.categories-widget ul li a:hover span {
            background: #00aeff;
            color: #fff;
            border-color: transparent;
          }

    .sidebar .widget.popular-tag-widget {
      padding-bottom: 30px;
    }

  .popular-tag-widget .tags > a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 8px 20px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
    background: #00aeff;
    margin-right: 7px;
    margin-bottom: 10px;
    color: #fff;
    background: transparent;
    color: #888;
    border: 1px solid #eee;
    border-radius: 4px;
  }

    .popular-tag-widget .tags > a:hover {
      background-color: #00aeff;
      color: #fff;
      border-color: transparent;
    }

  .sidebar-as img {
    width: 100%;
  }

  /*======================================
   Clients CSS
========================================*/
  .client-logo-section {
    background: #fff;
    padding: 50px 0;
  }

    .client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
      padding: 10px 20px;
      text-align: center;
      margin: 0;
    }

      .client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img {
        max-width: 220px;
        width: 100%;
        opacity: .3;
        -webkit-transition: all .3s ease-out 0s;
        transition: all .3s ease-out 0s;
      }

        .client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img:hover {
          opacity: 1;
        }


  /*======================================
    Dashboard Info Box CSS
========================================*/
.info-box {
  background-color: transparent;
  // height: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  z-index: 10;
}

    .info-box .tns-controls button {
      width: 46px;
      height: 46px;
      cursor: pointer;
      z-index: 2;
      color: #fff;
      font-size: 17px;
      background: #00aeff;
      border: none;
      padding: 2px 5px;
      border-radius: 50%;
      -webkit-transition: all .3s ease-out 0s;
      transition: all .3s ease-out 0s;
      position: absolute;
      z-index: 99;
      top: 50%;
      font-size: 14px;
      color: #fff;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

      .info-box .tns-controls button:hover {
        background-color: #081828;
        color: #fff;
      }

    .info-box:hover .tns-controls button {
      opacity: 1;
      visibility: visible;
    }

    .info-box .tns-controls button:first-child {
      left: -23px;
    }

    .info-box .tns-controls button:last-child {
      right: -23px;
    }

    .info-box .info-box-inner {
      padding: 30px 40px;
      border: 0px;
      border-radius: 6px;
      position: relative;
      z-index: 9;
      background: #fff;
    //  -webkit-transform: translateY(-50%);
    //  transform: translateY(-50%);
    }

  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    .info-box {
      -webkit-transform: translateY(-33%);
      transform: translateY(-33%);
    }

   // .info-box .info-box-inner {
  //    transform: translateY(-33%);
  //  }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .info-box {
      -webkit-transform: translateY(-35%);
      transform: translateY(-35%);
    }

  //  .info-box .info-box-inner {
  //    transform: translateY(-35%);
  //  }
  }

  @media only screen and (max-width: 768px) {
    .info-box {
      -webkit-transform: translateY(-20%);
      transform: translateY(-20%);
    }

   // .info-box .info-box-inner {
   //   transform: translateY(-20%);
   // }
  }

  .info-box.style2 {
    height: auto;
    padding: 100px 0;
  }

    .info-box.style2 .section-title {
      margin-bottom: 50px;
    }

    .info-box.style2 .cat-inner {
      -webkit-transform: none;
      transform: none;
      padding: 0;
      border: none;
    }

    .info-box.style2 .single-cat {
      margin-top: 30px;
      border: 1px solid #eee;
      padding: 25px 10px;
    }

      .info-box.style2 .single-cat:hover {
        -webkit-box-shadow: 0px 10px 15px #00000012;
        box-shadow: 0px 10px 15px #00000012;
        background-color: #fff;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
      }

  .info-box .single-info-box-item {
    text-align: center;
    padding: 15px 10px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    z-index: 2;
    border: none;
    border-radius: 6px;
  }

    .info-box .single-info-box-item:hover {
      // background-color: #f9f9f9;
    }

    .info-box .single-info-box-item .icon img {
      width: 40px;
    }

    .info-box .single-info-box-item h3 {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      line-height: 26px;
      font-weight: 500;
      color: #081828;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

    .info-box .single-info-box-item:hover h3 {
      color: #00aeff;
    }

    .info-box .single-info-box-item .total {
      font-size: 13px;
      padding: 5px 10px;
      font-weight: 500;
      text-align: center;
      background: #f9f9f9;
      border: 1px solid #eee;
      border-radius: 5px;
      color: #081828;
      display: inline-block;
      margin-top: 15px;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

    .info-box .single-info-box-item .total {
      background-color: #00aeff;
      color: #fff;
      border-color: transparent;
    }

  .info-box .single-info-box-item-main-text {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #00aeff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.info-box .single-info-box-item-text {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  cursor: default;
  margin-top: 25px;
}

.info-box .single-info-box-item-icon {
  height: 80px;
  width: 80px;
  background-color: #00aeff;
  color: #fff;
  border-radius: 50%;
  border: 6px solid #00aeff;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.5s;
}

  .info-box .single-info-box-item-icon svg {
    color: #FFF;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 36px;
    margin-top: 16px;
    transition: background 0.5s, border 0.5s, color 0.5s;
  }

//  .info-box .single-info-box-item-icon svg path {
//    fill: #FFF;
//  }

.info-box .single-info-box-item:hover .single-info-box-item-icon svg {
  color: #00aeff;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 36px;
  margin-top: 16px;
  transition: background 0.5s, border 0.5s, color 0.5s;
}

 // .info-box .single-info-box-item:hover .single-info-box-item-icon svg path {
 //   color: #00aeff;
 // }

.info-box .single-info-box-item-icon i {
  color: #FFF;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 36px;
  margin-top: 16px;
  transition: color 0.5s, border 0.5s;
}

.info-box .single-info-box-item:hover .single-info-box-item-icon {
  background-color: #FFFFFF;
  border: 6px solid #00aeff;
}

  .info-box .single-info-box-item:hover .single-info-box-item-icon i {
    color: #00aeff;
  }

.info-box .single-info-box-item-icon img {
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-top: 12px;
  filter: invert(99%) sepia(0%) saturate(7496%) hue-rotate(251deg) brightness(104%) contrast(104%);
  // color: filter 0.5s;
}

  .info-box .single-info-box-item:hover .single-info-box-item-icon img {
    filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
    // transition: filter 0.5s;
  }

  /*
    .info-box .single-info-box-item-icon:hover {
      background-color: #FFFFFF;
      border: 6px solid #00aeff;
    } */

/*
    .info-box .single-info-box-item-icon:hover img {
      filter: invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
    } */

  /*======================================
    Items Category CSS
========================================*/
.categories {
  background-color: transparent;
  // height: 0;
}

    .categories .tns-controls button {
      width: 46px;
      height: 46px;
      cursor: pointer;
      z-index: 2;
      color: #fff;
      font-size: 17px;
      background: #00aeff;
      border: none;
      padding: 2px 5px;
      border-radius: 50%;
      -webkit-transition: all .3s ease-out 0s;
      transition: all .3s ease-out 0s;
      position: absolute;
      z-index: 99;
      top: 50%;
      font-size: 14px;
      color: #fff;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

      .categories .tns-controls button:hover {
        background-color: #081828;
        color: #fff;
      }

    .categories:hover .tns-controls button {
      opacity: 1;
      visibility: visible;
    }

    .categories .tns-controls button:first-child {
      left: -23px;
    }

    .categories .tns-controls button:last-child {
      right: -23px;
    }

    .categories .cat-inner {
      padding: 30px 40px;
      // border: 1px solid #eee;
      background: #f4faff;
      border-radius: 6px;
      position: relative;
      z-index: 9;
      // -webkit-transform: translateY(-50%);
      // transform: translateY(-50%);
      // box-shadow: 0px 10px 30px #00000010;
    }

    .categories.style2 {
      height: auto;
      padding: 100px 0;
    }

      .categories.style2 .section-title {
        margin-bottom: 50px;
      }

      .categories.style2 .cat-inner {
        -webkit-transform: none;
        transform: none;
        padding: 0;
        border: none;
      }

      .categories.style2 .single-cat {
        margin-top: 30px;
        border: 1px solid #eee;
        padding: 25px 10px;
      }

        .categories.style2 .single-cat:hover {
          -webkit-box-shadow: 0px 10px 15px #00000012;
          box-shadow: 0px 10px 15px #00000012;
          background-color: #fff;
          -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
        }

    .categories .single-cat {
      text-align: center;
      padding: 15px 10px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      display: block;
      border-radius: 0;
      position: relative;
      overflow: hidden;
      z-index: 2;
      border: none;
      border-radius: 6px;
      min-width: 80px;
      min-height: 80px;
    }

      .categories .single-cat:hover {
        // background-color: #00aeff;
      }

      .categories .single-cat .icon-wrapper-active {
        background-color: #00aeff;
        border-radius: 50%;
      }

        .categories .single-cat .icon-wrapper-active img {
          filter: saturate(0%) invert(99%) hue-rotate(251deg) brightness(0%) invert(100%) contrast(104%);
          // transition: filter 0.5s;
        }

  .categories .single-cat .icon-wrapper {
    width: 60px;
    padding: 10px;
  }


      .categories .single-cat .icon {
        justify-content: center;
        display: flex;
      }

      .categories .single-cat .icon img {
        width: 40px;
      }

      .categories .single-cat .icon-wrapper:hover {
        background-color: #00aeff;
        border-radius: 50%;
      }

        .categories .single-cat .icon-wrapper:hover img {
          filter: saturate(0%) invert(99%) hue-rotate(251deg) brightness(0%) invert(100%) contrast(104%);
          // transition: filter 0.5s;
          transition: background 0.5s, border 0.5s, color 0.5s;
        }

      .categories .single-cat h3 {
        display: block;
        margin-top: 10px;
        font-size: 14px;
        line-height: 26px;
        font-weight: 500;
        color: #081828;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

      .categories .single-cat:hover h3 {
        color: #00aeff;
      }

      .categories .single-cat .total {
        font-size: 13px;
        padding: 5px 10px;
        font-weight: 500;
        text-align: center;
        background: #f9f9f9;
        border: 1px solid #eee;
        border-radius: 5px;
        color: #081828;
        display: inline-block;
        margin-top: 15px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

      .categories .single-cat:hover .total {
        background-color: #00aeff;
        color: #fff;
        border-color: transparent;
      }

  .all-categories {
    border-bottom: 1px solid #eee;
    padding-bottom: 70px;
  }

    .all-categories .categories-title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .all-categories .cat-title {
      font-size: 16px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      padding: 15px 20px;
      border-radius: 4px;
      background: #00aeff;
      color: #fff;
    }

      .all-categories .cat-title span {
        font-weight: 400;
        display: inline-block;
        margin-left: 5px;
        font-size: 14px;
      }

    .all-categories ul {
      margin-bottom: 30px;
    }

      .all-categories ul li {
        display: block;
        margin-bottom: 7px;
      }

        .all-categories ul li:last-child {
          margin: 0;
        }

        .all-categories ul li a {
          color: #888;
          padding: 10px 15px;
          border: 1px solid #eee;
          display: block;
          border-radius: 4px;
        }

          .all-categories ul li a:hover {
            color: #fff;
            background-color: #00aeff;
            border-color: transparent;
          }

  /* Category Page*/
  .category-page {
    background-color: #f9f9f9;
  }

    .category-page .single-ad-grid {
      background-color: #fff;
    }

    .category-page .category-grid-topbar {
      padding: 15px 20px;
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid #eee;
      padding-right: 15px;
    }

      .category-page .category-grid-topbar .title {
        font-size: 15px;
        font-weight: 500;
        color: #888;
        float: left;
      }

      .category-page .category-grid-topbar .nav {
        border: none;
        float: right;
      }

        .category-page .category-grid-topbar .nav button {
          border: none;
          padding: 5px 10px 3px 10px;
          border: 1px solid #eee;
          border-radius: 4px;
          margin-right: 6px;
        }

          .category-page .category-grid-topbar .nav button.active {
            background-color: #00aeff;
            border-color: transparent;
            color: #fff;
          }

          .category-page .category-grid-topbar .nav button:last-child {
            margin: 0;
          }

  .category-sidebar .single-widget {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-bottom: 30px;
  }

    .category-sidebar .single-widget:last-child {
      margin-bottom: 0;
    }

    .category-sidebar .single-widget h3 {
      font-size: 18px;
      font-weight: 600;
      display: block;
      margin-bottom: 25px;
    }

    .category-sidebar .single-widget.search form {
      position: relative;
    }

      .category-sidebar .single-widget.search form input {
        height: 50px;
        width: 100%;
        border: 1px solid #eee;
        border-radius: 6px;
        color: #081828;
        padding: 0px 15px;
        padding-right: 50px;
      }

      .category-sidebar .single-widget.search form button {
        height: 40px;
        width: 40px;
        text-align: center;
        display: block;
        border-radius: 6px;
        border: none;
        background-color: transparent;
        color: #081828;
        position: absolute;
        right: 5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

        .category-sidebar .single-widget.search form button:hover {
          color: #00aeff;
        }

    .category-sidebar .single-widget.range input {
      color: #081828;
      cursor: pointer;
    }

    .category-sidebar .single-widget.range ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #081828;
      opacity: 1;
      /* Firefox */
    }

    .category-sidebar .single-widget.range :-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #081828;
      opacity: 1;
      /* Firefox */
    }

    .category-sidebar .single-widget.range ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #081828;
      opacity: 1;
      /* Firefox */
    }

    .category-sidebar .single-widget.range ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #081828;
      opacity: 1;
      /* Firefox */
    }

    .category-sidebar .single-widget.range :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #081828;
    }

    .category-sidebar .single-widget.range ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #081828;
    }

    .category-sidebar .single-widget.range .range-inner {
      position: relative;
      padding-left: 15px;
    }

    .category-sidebar .single-widget.range label {
      color: #081828;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 15px;
    }

    .category-sidebar .single-widget.range #rangePrimary {
      display: inline-block;
      border: none;
    }

    .category-sidebar .single-widget.range input[type="range"]::-webkit-slider-thumb {
      background: #00aeff !important;
    }

    .category-sidebar .single-widget.range input[type="range"]::-moz-range-thumb {
      background: #00aeff !important;
    }

    .category-sidebar .single-widget.range input[type="range"]::-ms-thumb {
      background: #00aeff !important;
    }

    .category-sidebar .single-widget.condition .form-check {
      display: block;
      margin-bottom: 10px;
    }

      .category-sidebar .single-widget.condition .form-check:last-child {
        margin: 0;
      }

    .category-sidebar .single-widget.condition .form-check-label {
      cursor: pointer;
    }

    .category-sidebar .single-widget.condition .form-check-input {
      cursor: pointer;
    }

      .category-sidebar .single-widget.condition .form-check-input:checked {
        background-color: #00aeff;
        border-color: #00aeff;
      }

    .category-sidebar .single-widget .list li {
      margin-bottom: 25px;
    }

      .category-sidebar .single-widget .list li:last-child {
        margin: 0;
      }

      .category-sidebar .single-widget .list li a {
        color: #888;
        display: block;
        position: relative;
        padding-right: 35px;
      }

        .category-sidebar .single-widget .list li a i {
          display: inline-block;
          margin-right: 5px;
        }

        .category-sidebar .single-widget .list li a span {
          height: 30px;
          width: 30px;
          line-height: 28px;
          text-align: center;
          display: block;
          border: 1px solid #eee;
          border-radius: 50%;
          font-size: 13px;
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        .category-sidebar .single-widget .list li a:hover {
          color: #00aeff;
        }

          .category-sidebar .single-widget .list li a:hover span {
            color: #fff;
            background-color: #00aeff;
            border-color: transparent;
          }

    .category-sidebar .single-widget.banner a img {
      width: 100%;
    }

  /*======================================
    Items Grid CSS
========================================*/
  .items-grid {
    background: #f9f9f9;
  }

    .items-grid.custom-padding {
      padding-top: 220px;
    }

    .items-grid .section-title {
      margin-bottom: 50px;
    }

    .items-grid .single-grid {
      -webkit-box-shadow: 0 10px 30px rgba(35, 38, 45, 0.14) !important;
      box-shadow: 0 10px 30px rgba(35, 38, 45, 0.14) !important;
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
      margin-top: 30px;
    }

      .items-grid .single-grid .image {
        position: relative;
      }

        .items-grid .single-grid .image .author {
          background: transparent;
          padding: 15px 20px;
          border-radius: 0;
          position: absolute;
          left: 0;
          bottom: 5px;
          overflow: visible;
          width: 100%;
          z-index: 2 !important;
        }

          .items-grid .single-grid .image .author::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            height: 110px;
            width: 100%;
            background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000e3));
            background: linear-gradient(transparent, #000000e3);
            z-index: -1;
            overflow: visible;
          }

          .items-grid .single-grid .image .author .author-image {
            float: left;
          }

            .items-grid .single-grid .image .author .author-image a {
              color: #081828;
            }

              .items-grid .single-grid .image .author .author-image a:hover {
                color: #00aeff;
              }

                .items-grid .single-grid .image .author .author-image a:hover span {
                  color: #00aeff;
                }

              .items-grid .single-grid .image .author .author-image a span {
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                margin-left: 5px;
                color: #fff;
              }

            .items-grid .single-grid .image .author .author-image img {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              display: inline-block;
            }

            .items-grid .single-grid .image .author .author-image p {
              display: inline-block;
              margin-left: 5px;
            }

          .items-grid .single-grid .image .author .sale {
            display: inline-block;
            background: #00aeff;
            color: #fff;
            font-size: 14px;
            float: right;
            font-weight: 500;
            padding: 7px 15px;
            border-radius: 6px;
            font-family: 'Jost', sans-serif;
          }

        .items-grid .single-grid .image .item-position {
          display: inline-block;
          background: #00aeff;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          padding: 8px 15px;
          border-radius: 6px;
          position: absolute;
          right: 15px;
          top: 15px;
          -webkit-box-shadow: 0 5px 10px rgba(35, 38, 45, 0.14) !important;
          box-shadow: 0 5px 10px rgba(35, 38, 45, 0.14) !important;
          font-family: 'Jost', sans-serif;
        }

          .items-grid .single-grid .image .item-position i {
            display: inline-block;
            margin-right: 3px;
          }

        .items-grid .single-grid .image img {
          width: 100%;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }

        .items-grid .single-grid .image .thumbnail {
          overflow: hidden;
        }

      .items-grid .single-grid:hover .image .thumbnail img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      .items-grid .single-grid .content .top-content {
        padding: 30px 30px 25px 30px;
      }

        .items-grid .single-grid .content .top-content .tag {
          display: inline-block;
          margin-bottom: 5px;
          color: #888;
          font-size: 14px;
          font-weight: 500;
        }

          .items-grid .single-grid .content .top-content .tag i {
            display: inline-block;
            margin-right: 5px;
            color: #00aeff;
          }

          .items-grid .single-grid .content .top-content .tag:hover {
            color: #00aeff;
          }

        .items-grid .single-grid .content .top-content .title {
          display: block;
          margin-bottom: 10px;
          line-height: 30px;
        }

          .items-grid .single-grid .content .top-content .title a {
            color: #081828;
            font-size: 20px;
            font-weight: 600;
          }

            .items-grid .single-grid .content .top-content .title a:hover {
              color: #00aeff;
            }

        .items-grid .single-grid .content .top-content .update-time {
          font-size: 14px;
        }

        .items-grid .single-grid .content .top-content .rating {
          margin-top: 10px;
        }

          .items-grid .single-grid .content .top-content .rating li {
            display: inline-block;
            color: #FFAB02;
          }

            .items-grid .single-grid .content .top-content .rating li a {
              color: #888;
              font-weight: 400;
              font-size: 13px;
            }

              .items-grid .single-grid .content .top-content .rating li a:hover {
                color: #00aeff;
              }

        .items-grid .single-grid .content .top-content .info-list {
          margin-top: 10px;
          overflow: hidden;
        }

          .items-grid .single-grid .content .top-content .info-list li {
            display: block;
            float: left;
            width: 50%;
            line-height: 30px;
            font-size: 14px;
            font-weight: 500;
          }

            .items-grid .single-grid .content .top-content .info-list li a {
              color: #888;
              font-weight: 500;
            }

              .items-grid .single-grid .content .top-content .info-list li a:hover {
                color: #00aeff;
              }

              .items-grid .single-grid .content .top-content .info-list li a i {
                color: #00aeff;
                display: inline-block;
                margin-right: 3px;
              }

            .items-grid .single-grid .content .top-content .info-list li:last-child {
              margin: 0;
            }

      .items-grid .single-grid .content .bottom-content {
        padding: 15px 30px;
        border-top: 1px solid #eee;
        overflow: hidden;
      }

        .items-grid .single-grid .content .bottom-content .price {
          float: left;
          font-size: 14px;
          position: relative;
          font-weight: 500;
          top: 8px;
          font-family: 'Jost', sans-serif;
        }

          .items-grid .single-grid .content .bottom-content .price span {
            color: #00aeff;
            font-weight: 700;
            font-size: 18px;
            display: inline-block;
            margin-left: 5px;
          }

        .items-grid .single-grid .content .bottom-content .like {
          float: right;
          color: #00aeff;
          font-size: 18px;
          height: 40px;
          width: 40px;
          line-height: 40px;
          text-align: center;
          display: block;
          border-radius: 50%;
          border: 1px solid #eee;
        }

          .items-grid .single-grid .content .bottom-content .like:hover {
            color: #fff;
            background: #00aeff;
            border-color: transparent;
          }

  /*======================================
    Browse Cities CSS
========================================*/
  /* .browse-cities {
    background-color: #f9f9f9;
  }

    .browse-cities .section-title {
      margin-bottom: 50px;
    }

    .browse-cities .single-city {
      margin-top: 30px;
      position: relative;
      z-index: 3;
      border-radius: 6px;
      overflow: hidden;
    }

      .browse-cities .single-city .info-box {
        position: relative;
      }

        .browse-cities .single-city .info-box::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          height: 130px;
          width: 100%;
          background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000f0));
          background: linear-gradient(transparent, #000000f0);
          z-index: 0;
        }

      .browse-cities .single-city .more-btn {
        font-size: 30px;
        color: #fff;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: scale(0);
        transform: scale(0);
        position: absolute;
        right: 25px;
        bottom: 25px;
      }

        .browse-cities .single-city .more-btn:hover {
          color: #00aeff;
        }

      .browse-cities .single-city:hover .more-btn {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      .browse-cities .single-city .image {
        z-index: -1;
        position: relative;
      }

        .browse-cities .single-city .image img {
          height: 100%;
          width: 100%;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }

      .browse-cities .single-city:hover .image img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      .browse-cities .single-city .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 25px;
        padding-right: 70px;
      }

        .browse-cities .single-city .content .name {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          display: block;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
          display: inline-block;
        }

          .browse-cities .single-city .content .name:hover {
            color: #00aeff;
          }

          .browse-cities .single-city .content .name span {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            display: block;
            margin-top: 4px;
          } */

  /*======================================
    Ads Grid CSS
========================================*/

  .items-tab {
    background: #fff;
  }

  .popular-vouchers-all-button {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    padding: 15px 30px;
    margin-top: 25px;
    max-width: fit-content;
    background-color: #00aeff !important;
    color: #fff;
    border: none;
    transition: 0.5s;
    border-radius: 4px;
  }

  .popular-vouchers-all-button:hover {
    background-color: #081828!important;
    color: #fff;
    box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  }

  .popular-vouchers-all-button-wrapper {
  width: 100%;
  display: block;
  position: relative;
}

  .popular-vouchers-tab {
    // background: #f7f7f770;
    // background-color: #f7f7f770;
    background: #FFF;
    background-color: #FFF;
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .items-tab.custom-padding {
    padding-top: 150px;
  }

  .items-tab .nav-tabs {
    border-bottom: 2px solid #eee;
    width: 100%;
    text-align: center;
    margin-bottom: 30px !important;
  }

  .items-tab .nav {
    display: inline-block !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: transparent;
  }

  .items-tab .nav-tabs .nav-link {
    display: inline-block !important;
    background: transparent !important;
    color: #081828;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 40px;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    position: relative;
    bottom: -1px;
  }

    .items-tab .nav-tabs .nav-link:hover {
      color: #00aeff;
    }

    .items-tab .nav-tabs .nav-link.active {
      color: #00aeff;
      border: none;
      border-bottom: 2px solid #00aeff;
    }

  .single-item-grid {
    // background: #f7f7f770;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 30px;
    border: 1px solid #3333331a;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor: pointer;
    -webkit-box-shadow: 0px 10px 30px #00000010;
    box-shadow: 0px 10px 30px #00000010;
    background: #fff;
  }

  .single-item-grid-wrapper {
    display: flex;
  }

  .single-item-grid:hover {
    -webkit-box-shadow: 0px 10px 30px #00000010;
    box-shadow: 0px 10px 30px #00000010;
    background: #fff;
  }

  .single-item-grid .image {
    overflow: hidden;
    position: relative;
  }

    .single-item-grid .image a {
      width: 100%;
    }

    .single-item-grid .image img {
      width: 100%;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      aspect-ratio: 18 / 9;
      position: relative;
    }

  .single-item-grid .text-placeholder {
    width: 100%;
    min-height: 20px;
    border-radius: 4px;
    max-height: 100%;
    position: relative;
    top: 0;
    background-color: #ECECEC;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .single-item-grid .cat-placeholder {
    width: 50%;
    min-height: 20px;
    max-height: 20px;
  }

  .single-item-grid .desc-placeholder {
    width: 100%;
    min-height: 80px;
    max-height: 80px;
    margin-bottom: 25px;
  }

  .single-item-grid .voucher-val-placeholder {
    width: 75%;
    min-height: 25px;
    max-height: 25px;
  }

  .single-item-grid .voucher-val2-placeholder {
    width: 95%;
    min-height: 25px;
    max-height: 25px;
  }

  .single-item-grid .primary-image-placeholder {
    // background: #ECECEC;
    width: 100%;
    aspect-ratio: 18 / 9;
    position: absolute;
    top: 0;
    background-color: #ECECEC;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

    .single-item-grid .primary-image-placeholder .spinner-border {
      width: 1.8rem;
      height: 1.8rem;
      margin-top: 3px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25%
    }

  .single-item-grid .image .cross-badge {
    position: absolute;
    bottom: 5px;
    left: 15px;
  }

  .single-item-grid .image .cross-badge-text {
    position: absolute;
    bottom: 10px;
    left: 70px;
    font-size: 13px;
    color: #FFF;
    font-weight: 600;
  }

  .single-item-grid .image .cross-badge {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    display: inline-block;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 10px;
  }

    .single-item-grid .image .cross-badge img {
      width: 40px;
      height: 40px;
      transition: all 0.3s ease;
      aspect-ratio: 1 / 1;
      border-radius: 4px;
    }

    .single-item-grid .image .cross-badge .logo-placeholder {
      width: 40px;
      height: 40px;
      aspect-ratio: 1 / 1;
      position: absolute;
      top: 0;
      border-radius: 4px;
      background-color: #ECECEC;
      animation-name: color;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

  .single-item-grid .image .flat-badge {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .single-item-grid .image .flat-badge {
    color: #fff;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 18px;
    letter-spacing: 0.3px;
    text-transform: capitalize;
  }

  .single-item-grid .image .sale {
    background: #dc3545;
  }

  .single-item-grid .image .rent {
    background: #03a70c;
  }

  .single-item-grid:hover .image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .single-item-grid .content {
    padding: 20px 25px 20px 20px;
  }

    .single-item-grid .content .tag {
      font-size: 14px;
      color: #888;
      font-weight: 500;
    }

      .single-item-grid .content .tag:hover {
        color: #00aeff;
      }

    .single-item-grid .content .campaign-card-category {
      font-size: 12px;
      color: #888;
      font-weight: 500;
      text-align: right;
      width: 100%;
      margin-top: 25px;
    }

      .single-item-grid .content .campaign-card-category:hover {
        color: #00aeff;
      }

    .single-item-grid .content .campaign-card-company-name {
      font-size: 15px;
      color: #081828;
      font-weight: 600;
    }

    .single-item-grid .content .title {
      border-top: 1px solid #eee;
      margin-top: 15px;
      padding-top: 15px;
      margin-bottom: 10px;
    }

      .single-item-grid .content .title a {
        font-size: 15px;
        font-weight: 500;
        text-align: justify;
        color: #081828;
      }

        .single-item-grid .content .title a div {
          font-size: 15px;
          font-weight: 500;
          text-align: justify;
          color: #081828;
          display: block; /* Fallback for non-webkit */
          display: -webkit-box;
          height: 6.5em; /* Fallback for non-webkit, line-height * 5 */
          line-height: 1.3em;
          -webkit-line-clamp: 5; /* if you change this, make sure to change the fallback line-height and height */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .single-item-grid .content .title a:hover {
          color: #00aeff;
        }

    .single-item-grid .content .location {
      margin-top: 10px;
    }

      .single-item-grid .content .location a {
        color: #888;
      }

        .single-item-grid .content .location a i {
          color: #00aeff;
          display: inline-block;
          margin-right: 5px;
        }

        .single-item-grid .content .location a:hover {
          color: #00aeff;
        }

    .single-item-grid .content .info {
      overflow: hidden;
      margin-bottom: 15px;
    }

      .single-item-grid .content .info .priceDesc {
        font-size: 12px;
        font-weight: 600;
        float: left;
        position: relative;
        top: 1px;
        min-height: 24px;
      }

      .single-item-grid .content .info .priceDesc p {
        font-size: 12px;
        line-height: 22px;
        font-weight: 600;
        color: #888;
      }

.single-item-grid .content .info .price {
  font-size: 18px;
  font-weight: 600;
  color: #00aeff;
  float: left;
  position: relative;
  top: 1px;
  margin-left: 4px;
}

      .single-item-grid .content .info .like {
        float: right;
      }

        .single-item-grid .content .info .like a {
          color: #888;
          font-size: 14px;
          height: 35px;
          width: 35px;
          line-height: 35px;
          text-align: center;
          display: block;
          border: 1px solid #eee;
          border-radius: 50%;
        }

          .single-item-grid .content .info .like a:hover {
            color: #fff;
            background: #00aeff;
            border-color: transparent;
          }

  /*======================================
    Newsletter CSS
========================================*/
  /* .newsletter {
    padding: 80px;
    background-color: #00aeff;
    background-image: url(./images/newsletter/newsletter-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

    .newsletter .inner-content {
      position: relative;
    }

    .newsletter .title {
      padding-left: 80px;
    }

      .newsletter .title i {
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        display: inline-block;
        background: #fff;
        color: #00aeff;
        border-radius: 50%;
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      .newsletter .title h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 50px;
        color: #fff;
        line-height: 40px;
      }

      .newsletter .title p {
        color: #fff;
        display: block;
        margin-top: 0px;
        font-size: 16px;
        font-family: 'Jost', sans-serif;
      }

    .newsletter .newsletter-form {
      padding-left: 80px;
      position: relative;
    }

      .newsletter .newsletter-form input {
        height: 55px;
        width: 100%;
        display: inline-block;
        background: transparent;
        border: none;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 6px;
        padding: 0 142px 0 20px;
        color: #fff !important;
        font-size: 16px;
        border-radius: 6px;
      }

      .newsletter .newsletter-form ::-webkit-input-placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form :-ms-input-placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form ::-ms-input-placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form ::placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form :-ms-input-placeholder {
        color: #fff;
      }

      .newsletter .newsletter-form ::-ms-input-placeholder {
        color: #fff;
      }

      .newsletter .newsletter-form .button {
        display: inline-block;
        position: absolute;
        right: 0;
      }

      .newsletter .newsletter-form .button .btn {
        display: inline-block;
        background: #fff;
        color: #00aeff;
        height: 55px;
        border-radius: 0px 6px 6px 0;
      }

      .newsletter .newsletter-form .button .btn:hover {
        color: #081828;
      } */

  /*======================================
    Dashbord CSS
========================================*/
  .dashboard {
    background: #f9f9f9;
  }

    .dashboard .main-content .details-lists .single-list {
      position: relative;
      background: transparent;
      border-radius: 6px;
      border: 0px;
      padding: 50px 30px;
      padding-left: 115px;
    }

      .dashboard .main-content .details-lists .single-list .list-icon {
        height: 65px;
        width: 65px;
        line-height: 69px;
        border: 2px solid #15c0b03d;
        border-radius: 50%;
        position: absolute;
        left: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
      }

        .dashboard .main-content .details-lists .single-list .list-icon i {
          font-size: 25px;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-image: linear-gradient(45deg, #15c0b0, #3fdd90);
        }

      .dashboard .main-content .details-lists .single-list.two .list-icon {
        border-color: #ddd7fb;
      }

        .dashboard .main-content .details-lists .single-list.two .list-icon i {
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-image: linear-gradient(45deg, #5d45fd, #6197ff);
        }

      .dashboard .main-content .details-lists .single-list.three .list-icon {
        border-color: #f5e3f3;
      }

        .dashboard .main-content .details-lists .single-list.three .list-icon i {
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-image: linear-gradient(45deg, #de6dd5, #ff5eb3);
        }

      .dashboard .main-content .details-lists .single-list h3 {
        font-size: 23px;
        font-weight: 600;
        position: relative;
        top: -2px;
      }

        .dashboard .main-content .details-lists .single-list h3 span {
          display: block;
          font-size: 14px;
          font-weight: 400;
          margin-top: 5px;
        }

    .dashboard .main-content .activity-log {
      margin-top: 30px;
      background: #fff;
      border-radius: 6px;
      border: 1px solid #eee;
      padding-bottom: 20px;
    }

      .dashboard .main-content .activity-log .block-title {
        margin-bottom: 20px;
      }

      .dashboard .main-content .activity-log ul li {
        display: block;
        position: relative;
        padding-right: 50px;
        padding-left: 65px;
        padding-top: 20px;
        padding-bottom: 20px;
        background: #fff;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

        .dashboard .main-content .activity-log ul li:hover {
          background: #f9f9f9;
        }

        .dashboard .main-content .activity-log ul li .log-icon {
          position: absolute;
          left: 30px;
          top: 25px;
        }

          .dashboard .main-content .activity-log ul li .log-icon i {
            color: #00aeff;
            font-size: 16px;
          }

        .dashboard .main-content .activity-log ul li .title {
          font-size: 14px;
          color: #666;
          font-weight: 500;
        }

          .dashboard .main-content .activity-log ul li .title:hover {
            color: #00aeff;
          }

        .dashboard .main-content .activity-log ul li .time {
          display: block;
          font-size: 14px;
          color: #888;
          margin-top: 2px;
        }

        .dashboard .main-content .activity-log ul li .remove {
          display: block;
        }

          .dashboard .main-content .activity-log ul li .remove a {
            position: absolute;
            right: 30px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            height: 15px;
            width: 15px;
            line-height: 15px;
            background: #f4193f;
            color: #fff;
            font-size: 10px;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            border-radius: 3px;
          }

            .dashboard .main-content .activity-log ul li .remove a:hover {
              color: #fff;
              background: #081828;
            }

        .dashboard .main-content .activity-log ul li:hover .remove a {
          opacity: 1;
          visibility: visible;
        }

    .dashboard .main-content .recent-items {
      padding-bottom: 20px;
    }

      .dashboard .main-content .recent-items .block-title {
        margin-bottom: 20px;
      }

      .dashboard .main-content .recent-items ul li {
        display: block;
        position: relative;
        padding-right: 50px;
        padding-left: 110px;
        padding-top: 25px;
        padding-bottom: 25px;
        background: #fff;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

        .dashboard .main-content .recent-items ul li .image a {
          position: absolute;
          left: 30px;
          top: 15px;
        }

        .dashboard .main-content .recent-items ul li .image img {
          height: 60px;
          width: 60px;
          border-radius: 3px;
        }

        .dashboard .main-content .recent-items ul li:hover {
          background: #f9f9f9;
        }

        .dashboard .main-content .recent-items ul li .title {
          font-size: 14px;
          color: #666;
          font-weight: 500;
        }

          .dashboard .main-content .recent-items ul li .title:hover {
            color: #00aeff;
          }

        .dashboard .main-content .recent-items ul li .time {
          display: block;
          font-size: 14px;
          color: #888;
        }

        .dashboard .main-content .recent-items ul li .remove {
          display: block;
        }

          .dashboard .main-content .recent-items ul li .remove a {
            position: absolute;
            right: 30px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            height: 15px;
            width: 15px;
            line-height: 15px;
            background: #f4193f;
            color: #fff;
            font-size: 10px;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            border-radius: 3px;
          }

            .dashboard .main-content .recent-items ul li .remove a:hover {
              color: #fff;
              background: #081828;
            }

        .dashboard .main-content .recent-items ul li:hover .remove a {
          opacity: 1;
          visibility: visible;
        }

  /* Dashboard Block */
  .dashboard-block {
    border: 1px solid #eee;
    border-radius: 6px;
    background: #fff;
    // margin-top: 30px;
    padding-bottom: 30px;
    position: relative;
  }

    .dashboard-block .block-title {
      padding: 25px 30px;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid #eee;
      margin-bottom: 30px;
    }

  /* Dasshboard Sidebar */
  .dashboard-sidebar {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
  }

    .dashboard-sidebar .user-image {
      position: relative;
      padding: 30px;
      border-bottom: 1px solid #eee;
      padding-left: 100px;
      padding-bottom: 40px;
    }

      .dashboard-sidebar .user-image img {
        height: 50px;
        width: 50px;
        border-radius: 3px;
        position: absolute;
        left: 30px;
        top: 30px;
      }

      .dashboard-sidebar .user-image h4 {
        font-size: 15px;
        font-weight: 400;
        color: #888;
        margin-top: 5px;
      }

      .dashboard-sidebar .user-image h3 {
        font-size: 18px;
        font-weight: 600;
        color: #081828;
        margin-top: 5px;
      }

        .dashboard-sidebar .user-image h3 span {
          display: block;
          margin-top: 3px;
        }

          .dashboard-sidebar .user-image h3 span a {
            font-weight: 400;
            color: #888;
            font-size: 15px;
          }

            .dashboard-sidebar .user-image h3 span a:hover {
              color: #00aeff;
            }

    .dashboard-sidebar .dashboard-menu {
      padding: 30px 0;
    }

      .dashboard-sidebar .dashboard-menu ul li {
        display: block;
        margin-bottom: 15px;
      }

        .dashboard-sidebar .dashboard-menu ul li:last-child {
          margin: 0;
        }

        .dashboard-sidebar .dashboard-menu ul li a {
          color: #888;
          font-weight: 500;
          font-size: 15px;
          display: block;
          position: relative;
          padding: 6px 30px;
          cursor: pointer;
        }

          .dashboard-sidebar .dashboard-menu ul li a i {
            display: inline-block;
            margin-right: 10px;
            font-size: 15px;
            position: relative;
            top: 1px;
          }

          .dashboard-sidebar .dashboard-menu ul li a img {
            display: inline-block;
            margin-right: 4px;
            font-size: 15px;
            position: relative;
            top:-4px;
            width: 18px;
            height: 18px;
            filter: invert(51%) sepia(0%) saturate(84%) hue-rotate(215deg) brightness(104%) contrast(96%);
          }

          .dashboard-sidebar .dashboard-menu ul li a::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            height: 100%;
            width: 3px;
            border-radius: 0 3px 3px 0;
            background: #00aeff;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all 0.4s ease;
            transition: all 0.4s ease;
          }

          .dashboard-sidebar .dashboard-menu ul li a.active {
            color: #00aeff;
          }

            .dashboard-sidebar .dashboard-menu ul li a.active img {
              filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
              // filter: invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
            }

          .dashboard-sidebar .dashboard-menu ul li a:hover img {
            filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
            // filter: invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
          }

          .dashboard-sidebar .dashboard-menu ul li a.active::before {
            opacity: 1;
            visibility: visible;
          }

          .dashboard-sidebar .dashboard-menu ul li a:hover {
            color: #00aeff;
          }

            .dashboard-sidebar .dashboard-menu ul li a:hover::before {
              opacity: 1;
              visibility: visible;
            }

      .dashboard-sidebar .dashboard-menu .button {
        margin-top: 60px;
        padding: 0px 30px;
      }

        .dashboard-sidebar .dashboard-menu .button .btn {
          background: #fee8eb;
          color: #f73357;
          padding: 13px 35px;
          font-size: 14px;
          font-weight: 600;
        }

          .dashboard-sidebar .dashboard-menu .button .btn:hover {
            color: #fff;
            background: #f73357;
          }

  .profile-settings-block .inner-block {
    padding: 0px 30px;
  }

  .profile-settings-block .image img {
    height: 150px;
    width: 150px;
    border-radius: 6px;
  }

  .profile-settings-block .profile-setting-form {
    margin-top: 45px;
  }

    .profile-settings-block .profile-setting-form .form-group {
      margin-bottom: 30px;
    }

      .profile-settings-block .profile-setting-form .form-group label {
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
        color: #081828;
      }

      .profile-settings-block .profile-setting-form .form-group input {
        height: 50px;
        width: 100%;
        border: 1px solid #eee;
        border-radius: 6px;
        color: #081828;
        background: #fff;
        padding: 0px 20px;
      }

      .profile-settings-block .profile-setting-form .form-group textarea {
        height: 130px;
        padding: 20px;
        border: 1px solid #eee;
        border-radius: 6px;
        color: #081828;
        width: 100%;
      }

      .profile-settings-block .profile-setting-form .form-group.upload-image input {
        padding: 10px;
        font-size: 14px;
        color: #888;
      }

  .password-change-block .inner-block {
    padding: 0px 30px;
  }


.close-content {
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: 10px;
}


/* FOR ME PAGE */

.for-me-graph-block {
  padding: 0px;
  border: 0 !important;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
  height: 160px;
  background: transparent!important;
}

.for-me-graph-block-first {
  padding-left: 10px;
}

.for-me-graph-block-last {
  padding-right: 10px;
}

.for-me-graph-block .row {
  padding: 20px;
  border: 1px solid #eee;
  padding-bottom: 0px;
  padding-top: 40px;
  vertical-align: middle;
  height: 160px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 6px;
}

.for-me-wallet-block {
  min-height: 450px;
  margin-left: -15px;
  margin-right: 10px;
}

.for-me-graph-name {
}

.for-me-graph-icon {
  width: 100%;
  // max-width: 74px;
  min-width: 60px;
  aspect-ratio: 1 / 1;
  // height: 82px;
  border-radius: 50%;
  border: 1px solid green;
  padding: 20px;
}

.for-me-graph-icon-1 {
  border: 1px solid #15c0b0;
  opacity: 0.8;
  color: #15c0b0;
  font-size: 24px;
  filter: invert(60%) sepia(91%) saturate(1890%) hue-rotate(130deg) brightness(96%) contrast(84%);
}

.for-me-graph-icon-2 {
  border: 1px solid #5830E0;
  opacity: 0.8;
  color: #5830E0;
  // font-size: 24px;
  filter: invert(15%) sepia(82%) saturate(4760%) hue-rotate(254deg) brightness(91%) contrast(93%);
}

.for-me-graph-icon-3 {
  border: 1px solid #00aeff;
  opacity: 0.8;
  filter: invert(58%) sepia(82%) saturate(3929%) hue-rotate(171deg) brightness(105%) contrast(103%);
}

.for-me-graph-count {
}

.for-me-wallet-row {
  border-bottom: 1px solid #eee;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 0px;
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

.for-me-wallet-row-header {
  border-bottom: 2px solid #eee;
}

.for-me-wallet-row > div {
  padding-left: 0px;
  padding-right: 0px;
}

.for-me-wallet-header {
  text-align: center;
  font-size: 15px;
  color: #888;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
}

.for-me-wallet-logo-wrapper {
  float: left;
  width: 60px;
  margin-right: 10px;
}

.for-me-wallet-logo {
  height: 60px;
  width: 60px;
  border-radius: 3px;
}

.for-me-wallet-text-wrapper {
  float: right;
  width: calc(100% - 80px);
  padding-top: 0px;
  margin-top: -5px;
}

.for-me-wallet-text {
  text-align: center;
  font-size: 12px;
  font-family: 'Jost', sans-serif;
}

.for-me-wallet-text-code {
  text-align: center;
  font-size: 12px;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  word-break: break-all;
}

.for-me-wallet-text-code i {
  cursor: pointer;
  font-size: 18px;

}

  .for-me-wallet-text-code i:hover {
    cursor: pointer;
    font-size: 18px;
    color: #00aeff;
  }

.for-me-wallet-text-company {
  text-align: left;
  font-size: 16px;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  white-space: nowrap;
}

.for-me-wallet-text-link {
  text-align: left;
  font-size: 12px;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  cursor: pointer;
  color: #00aeff;
  white-space: nowrap;
}

.for-me-wallet-text-large {
  text-align: center;
  font-size: 16px;
  font-family: 'Jost', sans-serif;
}

.for-me-wallet-info-text {
  position: absolute;
  bottom: 5px;
  right: 15px;
  width: fit-content;
}

.for-me-wallet-text-cancelled {
  color: #888;
}

.for-me-wallet-text-gifted {
  color: #00aeff;
}

.for-me-wallet-text-used {
  color: #e8aa3d;
}

.for-me-wallet-text-unused {
  color: #00D13F;
}

.for-me-list-nav {
  padding-top: 28px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

  .for-me-list-nav ul {
    padding: 0px 30px;
  }

    .for-me-list-nav ul li {
      display: inline-block;
      margin-right: 7px;
      margin-bottom: 8px;
    }

      .for-me-list-nav ul li:last-child {
        margin: 0;
      }

      .for-me-list-nav ul li a {
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 8px 12px 8px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #888;
      }

        .for-me-list-nav ul li a span {
          display: inline-block;
          border: 1px solid #eee;
          font-size: 11px;
          margin-left: 3px;
          padding: 1px 6px;
          border-radius: 4px;
          font-weight: 600;
        }

        .for-me-list-nav ul li a:hover {
          color: #fff;
          background: #00aeff;
          border-color: transparent;
        }

          .for-me-list-nav ul li a:hover span {
            color: #00aeff;
            background: #fff;
            border-color: transparent;
          }

      .for-me-list-nav ul li .active {
        color: #fff;
        background: #00aeff;
        border-color: transparent;
      }

        .for-me-list-nav ul li .active span {
          color: #00aeff;
          background: #fff;
          border-color: transparent;
        }

      .for-me-list-nav ul li.active a {
        color: #fff;
        background: #00aeff;
        border-color: transparent;
      }

        .for-me-list-nav ul li.active a span {
          color: #00aeff;
          background: #fff;
          border-color: transparent;
        }

  /* Default Form Style */
  .default-form-style {
    margin-top: 30px;
  }

    .default-form-style .form-group {
      margin-bottom: 30px;
    }

      .default-form-style .form-group.button .btn {
        padding: 13px 30px;
        margin-left: 10px;
      }

        .default-form-style .form-group.button .btn.alt-btn {
          background: #081828;
          color: #fff;
        }

          .default-form-style .form-group.button .btn.alt-btn:hover {
            color: #fff;
            background: #00aeff;
          }

        .default-form-style .form-group.button .btn:first-child {
          margin: 0;
        }

      .default-form-style .form-group label {
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
        color: #081828;
      }

      .default-form-style .form-group input {
        height: 50px;
        width: 100%;
        border: 1px solid #eee;
        border-radius: 6px;
        color: #081828;
        background: #fff;
        padding: 0px 20px;
      }

      .default-form-style .form-group select {
        height: 50px;
        width: 100%;
        border: 1px solid #eee;
        border-radius: 6px;
        color: #081828;
        background: #fff;
        padding: 0px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
      }

        .default-form-style .form-group select:focus {
          outline: none !important;
        }

      .default-form-style .form-group .selector-head {
        position: relative;
      }

      .default-form-style .form-group .arrow {
        position: absolute;
        color: #888;
        right: 20px;
        top: 50%;
        z-index: 2;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 12px;
      }

      .default-form-style .form-group textarea {
        height: 130px;
        padding: 20px;
        border: 1px solid #eee;
        border-radius: 6px;
        color: #081828;
        width: 100%;
      }

  /* Post Ad Tab */
  /* .post-ad-tab {
    padding: 0px 30px;
  }

    .post-ad-tab nav .nav-tabs {
      border-bottom: 2px solid #eee;
    }

    .post-ad-tab nav button {
      padding: 20px 40px;
      border: none !important;
      text-align: left;
      position: relative;
      padding-left: 100px;
      border-radius: 0 !important;
      border-radius: 6px 6px 0 0 !important;
      margin: 0 !important;
      width: 33.33333333333333%;
      position: relative;
      bottom: -1px;
      font-size: 17px;
      font-weight: 600;
      color: #081828;
    }

      .post-ad-tab nav button .serial {
        height: 50px;
        width: 50px;
        line-height: 50px;
        border: 1px solid #eee;
        border-radius: 50%;
        text-align: center;
        color: #888;
        font-size: 19px;
        font-weight: 600;
        display: block;
        position: absolute;
        left: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      .post-ad-tab nav button.active {
        color: #fff !important;
      }

        .post-ad-tab nav button.active .serial {
          border-color: transparent;
          background: #fff;
          color: #00aeff;
        }

        .post-ad-tab nav button.active .sub-title {
          color: #fff;
        }

      .post-ad-tab nav button .sub-title {
        display: block;
        color: #888;
        font-size: 14px;
        font-weight: 400;
      }

      .post-ad-tab nav button:hover {
        border: none !important;
      }

      .post-ad-tab nav button.active {
        background: #00aeff !important;
        border: none !important;
      }

    .post-ad-tab .step-two-content .upload-input {
      position: relative;
      height: 240px;
    }

      .post-ad-tab .step-two-content .upload-input input {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px dashed rgba(124, 134, 154, 0.25);
        text-indent: -9999px;
        z-index: 2;
        border-radius: 6px;
      }

      .post-ad-tab .step-two-content .upload-input .plus-icon {
        height: 80px;
        width: 80px;
        color: #fff;
        background: #88888859;
        display: inline-block;
        line-height: 80px;
        font-size: 18px;
        border-radius: 5px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

      .post-ad-tab .step-two-content .upload-input:hover .plus-icon {
        background-color: #00aeff;
      }

      .post-ad-tab .step-two-content .upload-input .content {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 30px;
        top: 0;
        left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 2;
      }

    .post-ad-tab .step-two-content .video-label {
      display: inline-block;
    }

      .post-ad-tab .step-two-content .video-label span {
        color: #888;
        display: inline-block;
        margin-left: 12px;
        font-weight: 400;
        font-size: 14px;
      }

    .post-ad-tab .step-two-content .add-video {
      text-transform: capitalize;
      font-weight: 600;
      color: #888;
      font-size: 14px;
      margin-top: 20px;
    }

      .post-ad-tab .step-two-content .add-video:hover {
        color: #00aeff;
      }

      .post-ad-tab .step-two-content .add-video i {
        display: inline-block;
        margin-right: 5px;
      }

    .post-ad-tab .step-two-content .tag-label {
      display: inline-block;
    }

      .post-ad-tab .step-two-content .tag-label span {
        color: #888;
        display: inline-block;
        margin-left: 12px;
        font-weight: 400;
        font-size: 14px;
      }

    .post-ad-tab .step-three-content .google-map {
      margin-bottom: 30px;
    }

    .post-ad-tab .step-three-content .form-check {
      display: block;
      margin-bottom: 20px;
    } */

  /* List Nav */
  .list-nav {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
  }

    .list-nav ul {
      padding: 0px 30px;
    }

      .list-nav ul li {
        display: inline-block;
        margin-right: 7px;
        margin-bottom: 8px;
      }

        .list-nav ul li:last-child {
          margin: 0;
        }

        .list-nav ul li a {
          border: 1px solid #eee;
          border-radius: 4px;
          padding: 8px 12px 8px 15px;
          font-size: 14px;
          font-weight: 500;
          color: #888;
        }

          .list-nav ul li a span {
            display: inline-block;
            border: 1px solid #eee;
            font-size: 11px;
            margin-left: 3px;
            padding: 1px 6px;
            border-radius: 4px;
            font-weight: 600;
          }

          .list-nav ul li a:hover {
            color: #fff;
            background: #00aeff;
            border-color: transparent;
          }

            .list-nav ul li a:hover span {
              color: #00aeff;
              background: #fff;
              border-color: transparent;
            }

        .list-nav ul li.active a {
          color: #fff;
          background: #00aeff;
          border-color: transparent;
        }

          .list-nav ul li.active a span {
            color: #00aeff;
            background: #fff;
            border-color: transparent;
          }

  /* My Items */
  .my-items {
    padding: 0 30px;
  }

    .my-items .item-list-title {
      padding-bottom: 15px;
      border-bottom: 2px solid #eee;
    }

      .my-items .item-list-title p {
        color: #888;
        font-weight: 500;
        font-family: 'Jost', sans-serif;
      }

    .my-items .single-item-list {
      padding: 20px 0;
      border-bottom: 1px solid #eee;
    }

      .my-items .single-item-list .content {
        position: relative;
        top: -6px;
      }

      .my-items .single-item-list .item-image {
        position: relative;
        padding-left: 80px;
      }

        .my-items .single-item-list .item-image img {
          position: absolute;
          left: 0;
          top: 0;
          height: 60px;
          width: 60px;
          border-radius: 3px;
        }

        .my-items .single-item-list .item-image .title a {
          font-size: 15px;
          font-weight: 400;
          color: #888;
        }

          .my-items .single-item-list .item-image .title a:hover {
            color: #00aeff;
          }

        .my-items .single-item-list .item-image .price {
          font-size: 14px;
          color: #888;
          display: block;
          margin-top: 3px;
        }

      .my-items .single-item-list p {
        color: #888;
        font-family: 'Jost', sans-serif;
      }

      .my-items .single-item-list .action-btn li {
        display: inline-block;
        margin-right: 8px;
      }

        .my-items .single-item-list .action-btn li:last-child {
          margin: 0;
        }

        .my-items .single-item-list .action-btn li a {
          height: 30px;
          width: 30px;
          line-height: 30px;
          border: 1px solid #eee;
          border-radius: 50%;
          color: #888;
          text-align: center;
          display: block;
        }

          .my-items .single-item-list .action-btn li a:hover {
            color: #fff;
            background: #00aeff;
            border-color: transparent;
          }

  /* Messages Body */
  .messages-body .form-head {
    border-bottom: 1px solid #eee;
  }

    .messages-body .form-head .username-title {
      font-size: 15px;
      font-weight: 500;
      padding-right: 30px;
      color: #888;
    }

  .messages-body .chat-search-form {
    position: relative;
    border-right: 1px solid #eee;
    height: 100%;
    padding: 30px;
  }

    .messages-body .chat-search-form input {
      height: 100%;
      width: 100%;
      border: none;
      color: #081828;
      font-size: 15px;
      font-weight: 400;
      background: #fff;
      padding-right: 80px;
    }

    .messages-body .chat-search-form button {
      font-size: 14px;
      color: #081828;
      background: transparent;
      border: none;
      position: absolute;
      right: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #eee;
      border-radius: 50%;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

      .messages-body .chat-search-form button:hover {
        color: #fff;
        background: #00aeff;
        border-color: transparent;
      }

  .messages-body .user-list {
    border-right: 1px solid #eee;
    height: 100%;
    padding-bottom: 30px;
    overflow-y: auto;
    height: 480px;
  }

    .messages-body .user-list ul li {
      display: block;
    }

      .messages-body .user-list ul li a {
        position: relative;
        padding-left: 70px;
        width: 100%;
        padding: 30px 65px 30px 100px;
      }

        .messages-body .user-list ul li a:hover {
          background: #f9f9f9;
        }

        .messages-body .user-list ul li a .image {
          position: absolute;
          left: 30px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

          .messages-body .user-list ul li a .image::before {
            position: absolute;
            content: "";
            right: 0;
            bottom: 2px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #00d13f;
            border: 2px solid #fff;
          }

          .messages-body .user-list ul li a .image.busy::before {
            background: #f73d64;
          }

          .messages-body .user-list ul li a .image img {
            height: 50px;
            width: 50px;
            display: block;
            border-radius: 50%;
          }

        .messages-body .user-list ul li a .username {
          font-size: 14px;
          font-weight: 400;
          display: block;
          color: #081828;
        }

        .messages-body .user-list ul li a .short-message {
          font-size: 14px;
          font-weight: 400;
          display: block;
          color: #888;
          margin-top: 3px;
        }

        .messages-body .user-list ul li a .unseen-message {
          position: absolute;
          right: 30px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background: #f73c67;
          padding: 5px 12px;
          font-size: 12px;
          font-weight: 400;
          border-radius: 30px;
          color: #fff;
          font-weight: 500;
        }

        .messages-body .user-list ul li a .time {
          position: absolute;
          right: 30px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 12px;
          font-weight: 400;
          color: #888;
          font-weight: 500;
        }

  .messages-body .chat-list {
    padding-right: 30px;
    padding-top: 30px;
  }

    .messages-body .chat-list .single-chat-head {
      height: 309px;
      overflow-y: auto;
    }

      .messages-body .chat-list .single-chat-head li {
        position: relative;
        padding-left: 75px;
        margin-bottom: 20px;
      }

        .messages-body .chat-list .single-chat-head li:last-child {
          margin: 0;
        }

        .messages-body .chat-list .single-chat-head li.left img {
          height: 50px;
          width: 50px;
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          border-radius: 50%;
        }

        .messages-body .chat-list .single-chat-head li.left .text {
          background: #f9f9f9;
          padding: 30px;
          font-size: 16px;
          line-height: 25px;
          color: #666;
          border-radius: 6px;
          position: relative;
          font-weight: 400;
          font-family: 'Jost', sans-serif;
        }

          .messages-body .chat-list .single-chat-head li.left .text::before {
            position: absolute;
            left: -16px;
            bottom: 16px;
            content: "";
            border: 8px solid #f9f9f9;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
          }

          .messages-body .chat-list .single-chat-head li.left .text .time {
            display: block;
            color: #00aeff;
            margin-top: 10px;
            font-size: 14px;
          }

        .messages-body .chat-list .single-chat-head li.right {
          padding: 0;
          padding-right: 75px;
        }

          .messages-body .chat-list .single-chat-head li.right img {
            height: 50px;
            width: 50px;
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            border-radius: 50%;
          }

          .messages-body .chat-list .single-chat-head li.right .text {
            background: #f9f9f9;
            padding: 30px;
            font-size: 16px;
            line-height: 25px;
            color: #666;
            border-radius: 6px;
            position: relative;
            font-weight: 400;
            font-family: 'Jost', sans-serif;
          }

            .messages-body .chat-list .single-chat-head li.right .text::before {
              position: absolute;
              right: -16px;
              bottom: 16px;
              content: "";
              border: 8px solid #f9f9f9;
              border-right-color: transparent;
              border-top-color: transparent;
              border-bottom-color: transparent;
            }

            .messages-body .chat-list .single-chat-head li.right .text .time {
              display: block;
              color: #00aeff;
              margin-top: 10px;
              font-size: 14px;
            }

    .messages-body .chat-list .reply-block {
      padding: 30px 0;
      margin-top: 40px;
      border-top: 1px solid #eee;
    }

      .messages-body .chat-list .reply-block .add-media-list {
        display: inline-block;
      }

        .messages-body .chat-list .reply-block .add-media-list li {
          display: inline-block;
          margin-right: 5px;
        }

          .messages-body .chat-list .reply-block .add-media-list li:last-child {
            margin: 0;
          }

          .messages-body .chat-list .reply-block .add-media-list li a {
            height: 40px;
            width: 40px;
            text-align: center;
            color: #888;
            font-size: 14px;
            display: block;
            border: 1px solid #eee;
            border-radius: 50%;
            line-height: 40px;
          }

            .messages-body .chat-list .reply-block .add-media-list li a:hover {
              color: #fff;
              background: #00aeff;
              border-color: transparent;
            }

      .messages-body .chat-list .reply-block input {
        height: 100%;
        width: 280px;
        color: #081828;
        border: none;
        padding: 0px 20px;
        font-size: 15px;
      }

      .messages-body .chat-list .reply-block button {
        display: inline-block;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 48px;
        color: #fff;
        background: #00aeff;
        border-radius: 50%;
        float: right;
        border: none;
        font-size: 18px;
        position: relative;
        top: -4px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        padding-left: 3px;
        padding-bottom: 3px;
      }

        .messages-body .chat-list .reply-block button img {
          width: 17px;
        }

        .messages-body .chat-list .reply-block button:hover {
          background: #081828;
          color: #fff;
        }

  /* Default List Style  */
  .default-list-style .default-list-title {
    padding: 0px 30px 15px 30px;
    border-bottom: 2px solid #eee;
  }

    .default-list-style .default-list-title p {
      color: #888;
      font-weight: 500;
    }

  .default-list-style .action-btn li {
    display: inline-block;
    margin-right: 8px;
  }

    .default-list-style .action-btn li:last-child {
      margin-right: 0;
    }

    .default-list-style .action-btn li a {
      height: 30px;
      width: 30px;
      line-height: 30px;
      border: 1px solid #eee;
      border-radius: 50%;
      color: #888;
      text-align: center;
      display: block;
    }

      .default-list-style .action-btn li a:hover {
        background: #00aeff;
        color: #fff;
        border-color: transparent;
      }

  .default-list-style .single-list {
    padding: 25px 30px;
    border-bottom: 1px solid #eee;
  }

    .default-list-style .single-list p span {
      display: block;
      font-size: 14px;
      margin-top: 4px;
      font-style: italic;
      font-weight: 400;
    }

    .default-list-style .single-list p.paid {
      color: #00D13F;
    }

    .default-list-style .single-list p.unpaid {
      color: #F53C66;
    }

    .default-list-style .single-list p.pending {
      color: #e8aa3d;
    }

  .default-list-style .pagination {
    padding: 0px 30px;
  }

  /* Close Account */

.close-account-button {
  color: #AAA;
  cursor: pointer;
}

.close-content {
  padding: 80px 50px;
  text-align: center;
}

    .close-content h2 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .close-content h4 {
      font-size: 17px;
      font-weight: 400;
      color: #888;
    }

    .close-content .button {
      margin-top: 40px;
    }

      .close-content .button .btn {
        padding: 12px 30px;
        margin-right: 6px;
      }

        .close-content .button .btn:last-child {
          margin: 0;
        }

  /*======================================
    Login CSS
========================================*/
  .login {
    background-color: #f9f9f9;
  }

    .login .form-head {
      padding: 50px;
      border: 1px solid #eee;
      border-radius: 6px;
      background-color: #fff;
    }

      .login .form-head .title {
        font-size: 30px;
        line-height: 1.42;
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;
      }

      .login .form-head form .form-group {
        margin-bottom: 25px;
      }

        .login .form-head form .form-group label {
          display: block;
          margin-bottom: .5rem;
          color: #888;
          font-size: 15px;
          font-weight: 400;
        }

        .login .form-head form .form-group input {
          width: 100%;
          min-height: 56px;
          padding: 3px 20px;
          color: #081828;
          border: 1px solid #eee;
          border-radius: 6px;
          outline: 0;
          background-color: #fff;
          font-size: 15px;
        }

      .login .form-head form .check-and-pass .form-check {
        float: left;
      }

        .login .form-head form .check-and-pass .form-check a {
          color: #00aeff;
        }

        .login .form-head form .check-and-pass .form-check .form-check-input:checked {
          background-color: #00aeff;
          border-color: #00aeff;
        }

      .login .form-head form .check-and-pass .form-check-label a:hover {
        text-decoration: underline;
      }

      .login .form-head form .check-and-pass .lost-pass {
        float: right;
        color: #777;
        position: relative;
        top: -2px;
      }

        .login .form-head form .check-and-pass .lost-pass:hover {
          color: #00aeff;
          text-decoration: underline;
        }

      .login .form-head form .button {
        margin-top: 25px;
      }

        .login .form-head form .button .btn {
          width: 100%;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
        }

          .login .form-head form .button .btn:hover {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
          }

      .login .form-head form .outer-link {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #888;
        margin-top: 30px;
        text-align: center;
        font-family: 'Jost', sans-serif;
      }

        .login .form-head form .outer-link a {
          color: #00aeff;
        }

          .login .form-head form .outer-link a:hover {
            text-decoration: underline;
          }

      .login .form-head .alt-option {
        margin: 30px 0;
        text-align: center;
        display: inline-block;
        position: relative;
        width: 100%;
        z-index: 1;
      }

        .login .form-head .alt-option span {
          font-size: 15px;
          background: #fff;
          color: #888;
          padding: 5px 15px;
        }

        .login .form-head .alt-option::before {
          position: absolute;
          left: 0;
          top: 50%;
          height: 1px;
          width: 100%;
          background: #eee;
          content: "";
          z-index: -1;
        }

      .login .form-head .socila-login ul li {
        display: block;
        margin-bottom: 12px;
      }

        .login .form-head .socila-login ul li:last-child {
          margin: 0;
        }

        .login .form-head .socila-login ul li a {
          padding: 15px 20px;
          text-align: center;
          border: 1px solid #eee;
          border-radius: 6px;
          display: block;
          color: #888;
          font-size: 15px;
        }

          .login .form-head .socila-login ul li a.facebook:hover {
            color: #fff;
            background: #3b5999;
            border-color: transparent;
          }

            .login .form-head .socila-login ul li a.facebook:hover i {
              color: #fff;
            }

          .login .form-head .socila-login ul li a.facebook i {
            color: #3b5999;
          }

          .login .form-head .socila-login ul li a.google:hover {
            color: #fff;
            background: #dd4b39;
            border-color: transparent;
          }

            .login .form-head .socila-login ul li a.google:hover i {
              color: #fff;
            }

          .login .form-head .socila-login ul li a.google i {
            color: #dd4b39;
          }

          .login .form-head .socila-login ul li a i {
            display: inline-block;
            margin-right: 10px;
            font-size: 15px;
            -webkit-transition: all 0.4s ease;
            transition: all 0.4s ease;
          }

  /*======================================
    Why Choose CSS
========================================*/
  .why-choose .choose-image img {
    height: 100%;
    width: 100%;
  }

  .why-choose .choose-content .title {
    font-size: 32px;
    margin-bottom: 20px;
    line-height: 45px;
    text-transform: capitalize;
    position: relative;
    font-weight: 600;
    padding-bottom: 20px;
  }

.why-choose .choose-content .single-list {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #00aeff;
  border-radius: 6px;
  border: 0px;
  text-align: center;
  min-height: 360px;
}

  .why-choose .choose-content .single-list-transparent {
    position: relative;
    margin-top: 30px;
    padding: 40px;
    background: transparent !important;
    border-radius: 6px;
    border: 0px;
    text-align: center;
  }

  .why-choose .choose-content .single-list-last {
    position: relative;
    margin-top: 30px;
    padding: 40px;
    background: #ff801b !important;
    border-radius: 6px;
    border: 0px;
    text-align: center;
  }


  .why-choose .choose-content .single-list .single-list-number {
    font-size: 32px;
    display: inline-block;
    margin-bottom: 10px;
    height: 110px;
    width: 110px;
    line-height: 105px;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: #FFFFFF;
    border: 4px solid #F4FAFF;
    color: #00aeff;
  }

    .why-choose .choose-content .single-list .single-list-number:hover {
      background-color: #00aeff;
      border: 4px solid #FFFFFF;
      color: #FFFFFF;
      transition: background 0.5s, border 0.5s, color 0.5s;
    }

  .why-choose .choose-content .single-list .single-list-number-last {
    font-size: 32px;
    display: inline-block;
    margin-bottom: 10px;
    height: 110px;
    width: 110px;
    line-height: 105px;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: #FFFFFF;
    border: 4px solid #E2CEBE;
    color: #ff801b;
  }

    .why-choose .choose-content .single-list .single-list-number-last:hover {
      background-color: #E2CEBE;
      border: 4px solid #FFFFFF;
      color: #FFFFFF;
      transition: background 0.5s, border 0.5s, color 0.5s;
    }

  .why-choose .choose-content .single-list:hover i {
    color: #00aeff;
    background-color: transparent;
    border-color: #00aeff;
  }

  .why-choose .choose-content .single-list h4 {
    font-size: 22px;
    font-weight: 600;
    display: block;
    margin-bottom: 20px;
    // color: #FFFFFF;
    color: #081828;
    margin-top: 20px;
    min-height: 40px;
  }

  .why-choose .choose-content .single-list-last h4 {
    color: #FFFFFF;
    min-height: 60px;
  }

  .why-choose .choose-content .single-list-transparent h4 {
    // color: #FFFFFF;
    color: #FFFFFF;
    min-height: 60px;
  }

  .why-choose .choose-content .single-list p {
    font-size: 16px;
    color: #FFFFFF;
  }

  .why-choose .choose-content .single-list-last p {
    font-size: 16px;
    color: #FFFFFF;
  }

  .why-choose .choose-content .single-list-transparent p {
    font-size: 16px;
    color: #888;
  }

  .why-choose .choose-content .single-work {
    text-align: center;
    padding: 40px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
    position: relative;
    min-height: 340px;
  }

    .why-choose .choose-content .single-work .single-list-logo-wrapper {
      width: 100%;
    }

    .why-choose .choose-content .single-work .single-list-logo-wrapper2 {
      width: 80px;
      margin-left: calc(50% - 63px);
      margin-right: calc(50% - 63px);
      top: 44px;
      position: absolute;
      display: block;
    }

    .why-choose .choose-content .single-work .single-list-logo-img {
      text-align: center;
      position: absolute;
      background: url(../assets/images/logo_blue.webp);
      border: 0px;
      top: 0px;
      margin-left: auto;
      margin-right: auto;
      pointer-events: none;
      width: 25px;
      aspect-ratio: 1 / 1;
      background-size: cover;
      z-index: 999;
      top: 50%;
      left: 38%;
    }

    .why-choose .choose-content .single-work img {
      text-align: center;
      padding: 5px;
      width: 80px;
      height: 80px;
      background-color: #00AEFF;
      border-radius: 50%;
      border: 4px solid #00aeff;
    }

    .why-choose .choose-content .single-work svg {
      text-align: center;
      padding: 5px;
      width: 80px;
      height: 80px;
      color: #FFF;
      background-color: #00AEFF;
      border-radius: 50%;
      border: 4px solid #00aeff;
      transition: color 0.5s, border 0.5s;
    }

    .why-choose .choose-content .single-work i {
      text-align: center;
      padding: 5px;
      padding-top: 13px;
      font-size: 60px;
      width: 80px;
      height: 80px;
      color: #FFF;
      background-color: #00AEFF;
      border-radius: 50%;
      border: 4px solid #00aeff;
      transition: color 0.5s, border 0.5s;
    }

    .why-choose .choose-content .single-work:hover img {
      background-color: transparent;
      border: 4px solid #FFF;
      filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
      // transition: filter 0.5s;
    }

    .why-choose .choose-content .single-work:hover svg {
      background-color: transparent;
      border: 4px solid #00aeff;
      color: #00aeff;
      transition: color 0.5s, border 0.5s;
    }

    .why-choose .choose-content .single-work:hover i {
      background-color: transparent;
      border: 4px solid #00aeff;
      color: #00aeff;
      transition: color 0.5s, border 0.5s;
    }

    .why-choose .choose-content .single-work:hover .single-list-logo-img {
      filter: saturate(0%) brightness(0%) invert(100%) contrast(100%);
      // transition: filter 0.5s;
    }

.why-choose .choose-content .single-list-last img {
  text-align: center;
  padding: 20px;
  background-color: #ff801b;
  border-radius: 50%;
  border: 4px solid #ff801b;
}

  .why-choose .choose-content .single-list-last img:hover {
    background-color: transparent;
    border: 4px solid #FFF;
    filter: none !important;
    // transition: filter 0.5s;
  }

  .why-choose .choose-content .single-work .serial {
    height: 80px;
    width: 80px;
    line-height: 68px;
    background-color: #00aeff;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    // border: 6px solid #00aeff;
    border: 6px solid #FFF;
    display: inline-block;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 25px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .why-choose .choose-content .single-work:hover .serial {
    color: #00aeff;
    background-color: transparent;
    border-color: #00aeff;
  }

  .why-choose .choose-content .single-work h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .why-choose .choose-content .single-work p {
    font-size: 16px;
    line-height: 24px;
    min-height: 72px;
  }


/*======================================
    vouchers are important CSS
========================================*/
.vouchers-are-important .choose-image img {
  height: 100%;
  width: 100%;
}

.vouchers-are-important .choose-content .title {
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  padding-bottom: 20px;
}

.vouchers-are-important .choose-content .single-list {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #00aeff;
  border-radius: 6px;
  border: 0px;
  text-align: center;
  min-height: 320px;
}

.vouchers-are-important .choose-content .single-list-transparent {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: transparent !important;
  border-radius: 6px;
  border: 0px;
  text-align: center;
}

.vouchers-are-important .choose-content .single-list-last {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #ff801b !important;
  border-radius: 6px;
  border: 0px;
  text-align: center;
}


.vouchers-are-important .choose-content .single-list .single-list-number {
  font-size: 26px;
  display: inline-block;
  margin-bottom: 10px;
  height: 90px;
  width: 90px;
  line-height: 85px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #F4FAFF;
  color: #00aeff;
}

.vouchers-are-important .choose-content .single-list:hover .single-list-number {
  background-color: #00aeff;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s;
}

.vouchers-are-important .choose-content .single-list .single-list-number-last {
  font-size: 26px;
  display: inline-block;
  margin-bottom: 10px;
  height: 90px;
  width: 90px;
  line-height: 85px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #FFFFFF;
  color: #ff801b;
}

.vouchers-are-important .choose-content .single-list:hover .single-list-number-last {
  background-color: #ff801b;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s;
}

.vouchers-are-important .choose-content .single-list:hover i {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff;
}

.vouchers-are-important .choose-content .single-list h4 {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
  color: #FFFFFF;
  margin-top: 20px;
  min-height: 40px;
}

.vouchers-are-important .choose-content .single-list-last h4 {
  color: #FFFFFF;
  min-height: 40px;
}

.vouchers-are-important .choose-content .single-list-transparent h4 {
  // color: #FFFFFF;
  color: #FFFFFF;
  min-height: 40px;
}

.vouchers-are-important .choose-content .single-list p {
  font-size: 16px;
  color: #FFFFFF;
}

.vouchers-are-important .choose-content .single-list-last p {
  font-size: 16px;
  color: #FFFFFF;
}

.vouchers-are-important .choose-content .single-list-transparent p {
  font-size: 16px;
  color: #888;
}

.vouchers-are-important .choose-content .single-work {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.vouchers-are-important .choose-content .single-work img {
  text-align: center;
  padding: 20px;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
}

  .vouchers-are-important .choose-content .single-work img:hover {
    background-color: transparent;
    border: 4px solid #FFF;
    filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
    // transition: filter 0.5s;
  }


.vouchers-are-important .choose-content .single-list-last img {
  text-align: center;
  padding: 20px;
  background-color: #ff801b;
  border-radius: 50%;
  border: 4px solid #ff801b;
}

  .vouchers-are-important .choose-content .single-list-last img:hover {
    background-color: transparent;
    border: 4px solid #FFF;
    filter: none !important;
    // transition: filter 0.5s;
  }

.vouchers-are-important .choose-content .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  // border: 6px solid #00aeff;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.vouchers-are-important .choose-content .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff;
}

.vouchers-are-important .choose-content .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.vouchers-are-important .choose-content .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px;
}

  /*======================================
    Item Details CSS
========================================*/
  .item-details {
    background-color: #f9f9f9;
  }

    .item-details .top-area {
      background-color: #fff;
      padding: 30px;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .item-details .product-images img {
      width: 100%;
      aspect-ratio: 18 / 9;
    }

    .item-details .product-images .primary-placeholder {
      width: 100%;
      aspect-ratio: 18 / 9;
      background-color: #ECECEC;
      animation-name: color;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    .item-details .product-images .images {
      display: -ms-grid;
      display: grid;
      Grid-template-columns: repeat(5, 1fr);
      Grid-gap: 1em 1em;
      Margin-top: 1em;
    }

  @-webkit-keyframes fadeIn {
    to {
      Opacity: 1;
    }
  }

  @keyframes fadeIn {
    to {
      Opacity: 1;
    }
  }

  .item-details .product-images .fade-in {
    -webkit-animation: fadeIn 0.5s ease-in 1 forwards;
    animation: fadeIn 0.5s ease-in 1 forwards;
  }

  .item-details .product-info {
    padding-left: 40px;
  }

  .item-details .product-info-edit-button {
    color: #00aeff;
    font-size: 14px;
    float: right;
    font-weight: 400;
    cursor: pointer;
  }


.item-details .recipient-next-button {
  margin-top: 30px;
  margin-left: 10px;
  width: 100%;
  height: 67px;
  // min-height: 100px;
  // max-height: 100px;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  // font-weight: 600;
  background: rgb(0, 174, 255);
  right: 6px;
  position: relative;
}

  .item-details .recipient-next-button:hover {
    border: 1px solid #00B2FF !important;
    background: #FFF !important;
    color: #00B2FF !important;
    transition: background 0.5s, border 0.5s, color 0.5s;
  }

.item-details .recipient-next-button-info {
  padding-left: 15px;
  text-align: center;
  white-space: nowrap;
}

.item-details .recipient-when-to-send-button {
  margin-top: 30px;
  margin-left: 10px;
  width: 100%;
  height: 90%;
  // min-height: 100px;
  // max-height: 100px;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  // font-weight: 600;
  background: rgb(0, 174, 255);
  right: -6px;
  position: relative;
}

.item-details .recipient-when-to-send-button div {
  margin-top: 5px;
}

.item-details .recipient-when-to-send-button-info {
  padding-left: 5px;
  text-align: center;
  margin-right: -25px;
  white-space: nowrap;
}

.item-details .recipient-when-to-send-button-icon {
  margin-right: auto;
  margin-left: auto;
  width: 24px;
  height: 24px;
  color: rgb(255, 255, 255);
  position: relative;
}


.item-details .product-info h4 {
  font-size: 22px;
}

  .item-details .product-info .title {
    font-size: 30px;
    margin-bottom: 10px;
    min-height: 36px;
  }

  .item-details .product-info .website {
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 15px;
    color: #00B2FF;
    font-weight: 600;
    min-height: 23px;
  }

  .item-details .product-info .location {
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 15px;
    color: #888;
  }

    .item-details .product-info .location i {
      display: inline-block;
      margin-right: 5px;
      color: #00aeff;
    }

    .item-details .product-info .location a {
      color: #888;
    }

      .item-details .product-info .location a:hover {
        color: #00aeff;
      }

  .item-details .product-info .price {
    color: #00aeff;
    display: block;
  }

  .item-details .product-info .list-info {
    // border-top: 1px solid #eee;
    margin-top: 5px;
    padding-top: 10px;
    position: relative;
    margin-left: 15px;
  }

  .item-details .product-info .list-info-input-label {
    margin-bottom: 7px;
    font-weight: 400;
  }

.item-details .product-info .list-info .form-control {
  font-size: 15px;
  border: 1px solid #BAE9FF;
  box-shadow: none;
}

.item-details .product-info .list-info .list-info-date {
  border: 1px solid rgb(186, 233, 255);
  box-shadow: none;
}

  .item-details .product-info .list-info .list-info-date .rw-widget-input {
    border: 0;
    box-shadow: none;
  }

    .item-details .product-info .list-info .list-info-date .rw-select-bordered {
      border-left: 1px solid rgb(186, 233, 255);
    }


.item-details .product-info .list-info .list-info-date .rw-widget-container {
  border: 0;
  box-shadow: none;
}

.item-details .product-info .list-info .list-info-char-count {
  position: absolute;
  right: 20px;
  bottom: 0px;
}

  .item-details .product-info .list-info .list-info-textarea {
    min-height: 110px;
  }

  .item-details .product-info .list-info h4 {
    font-size: 20px;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
  }

  .item-details .product-info .list-info ul li {
    display: block;
    margin-bottom: 6px;
  }

    .item-details .product-info .list-info ul li:last-child {
      margin: 0;
    }

    .item-details .product-info .list-info ul li span {
      display: inline-block;
      font-weight: 500;
      min-width: 100px;
    }

  .item-details .main-img {
    position: relative;
  }

.item-details .product-info-logo {
  padding: 10px;
  border-radius: 2px;
  padding-left: 0;
  width: 13% !important;
  max-width: 20%;
  position: absolute;
  left: 10px;
  bottom: 0px;
  border-radius: 18px;
  min-width: 70px !important;
  aspect-ratio: 1 / 1!important;
}

  .item-details .product-info-logo .logo-placeholder {
    padding: 10px;
    border-radius: 2px;
    padding-left: 0;
    width: 13% !important;
    max-width: 20%;
    position: absolute;
    left: 10px;
    bottom: 0px;
    border-radius: 18px;
    min-width: 70px !important;
    aspect-ratio: 1 / 1 !important;
    background-color: #ECECEC;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .item-details .product-info .contact-info {
    margin-top: 30px;
  }

    .item-details .product-info .contact-info ul li {
      display: inline-block;
      margin-right: 10px;
    }

      .item-details .product-info .contact-info ul li:last-child {
        margin: 0;
      }

      .item-details .product-info .contact-info ul li .call {
        position: relative;
        padding: 15px 40px;
        padding-left: 80px;
        color: #fff;
        background-color: #00aeff;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        float: left;
      }

        .item-details .product-info .contact-info ul li .call:hover {
          color: #fff;
          background-color: #081828;
        }

        .item-details .product-info .contact-info ul li .call span {
          font-size: 14px;
          display: block;
          margin-top: 2px;
          font-weight: 400;
        }

        .item-details .product-info .contact-info ul li .call i {
          color: #fff;
          position: absolute;
          left: 30px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 30px;
        }

      .item-details .product-info .contact-info ul li .mail {
        padding: 14px 30px;
        color: #00aeff;
        background-color: #fff;
        border: 2px solid #eee;
        border-radius: 4px;
        font-size: 30px;
        float: left;
      }

        .item-details .product-info .contact-info ul li .mail:hover {
          background-color: #00aeff;
          color: #fff;
          border-color: transparent;
        }

  .item-details .product-info .social-share {
    display: block;
    margin-top: 35px;
  }

    .item-details .product-info .social-share h4 {
      font-size: 16px;
      font-weight: 500;
      color: #888;
      display: inline-block;
      margin-right: 15px;
    }

    .item-details .product-info .social-share ul {
      display: inline-block;
    }

      .item-details .product-info .social-share ul li {
        display: inline-block;
        margin-right: 5px;
      }

        .item-details .product-info .social-share ul li:last-child {
          margin-right: 0;
        }

        .item-details .product-info .social-share ul li a {
          height: 40px;
          width: 40px;
          text-align: center;
          display: block;
          border-radius: 50%;
          border: 1px solid #eee;
          background-color: #fff;
          color: #00aeff;
          line-height: 40px;
          font-size: 14px;
        }

          .item-details .product-info .social-share ul li a.facebook {
            color: #3b5999;
          }

            .item-details .product-info .social-share ul li a.facebook:hover {
              border-color: transparent;
              background-color: #3b5999;
              color: #fff;
            }

          .item-details .product-info .social-share ul li a.twitter {
            color: #55acee;
          }

            .item-details .product-info .social-share ul li a.twitter:hover {
              border-color: transparent;
              background-color: #55acee;
              color: #fff;
            }

          .item-details .product-info .social-share ul li a.google {
            color: #dd4b39;
          }

            .item-details .product-info .social-share ul li a.google:hover {
              border-color: transparent;
              background-color: #dd4b39;
              color: #fff;
            }

          .item-details .product-info .social-share ul li a.linkedin {
            color: #0077B5;
          }

            .item-details .product-info .social-share ul li a.linkedin:hover {
              border-color: transparent;
              background-color: #0077B5;
              color: #fff;
            }

          .item-details .product-info .social-share ul li a.pinterest {
            color: #bd081c;
          }

            .item-details .product-info .social-share ul li a.pinterest:hover {
              border-color: transparent;
              background-color: #bd081c;
              color: #fff;
            }

  /* Item Details Blocks */
  .item-details-blocks .single-block {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-top: 30px;
  }

    .item-details-blocks .single-block h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
    }

    .item-details-blocks .single-block.description p {
      margin: 15px 0;
      font-size: 16px;
    }

    .item-details-blocks .single-block.description ul {
      display: block;
      margin: 30px 0;
    }

      .item-details-blocks .single-block.description ul li {
        display: block;
        margin-bottom: 5px;
        font-size: 15px;
      }

        .item-details-blocks .single-block.description ul li:last-child {
          margin-bottom: 0;
        }

    .item-details-blocks .single-block.tags ul li {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 8px;
    }

      .item-details-blocks .single-block.tags ul li a {
        padding: 8px 15px;
        border: 1px solid #eee;
        color: #888;
        border-radius: 4px;
      }

        .item-details-blocks .single-block.tags ul li a:hover {
          color: #00aeff;
          border-color: #00aeff;
        }

    .item-details-blocks .single-block.comments .single-comment {
      position: relative;
      padding-left: 110px;
      margin-top: 40px;
    }

      .item-details-blocks .single-block.comments .single-comment img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
      }

      .item-details-blocks .single-block.comments .single-comment h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 2px;
      }

      .item-details-blocks .single-block.comments .single-comment span {
        display: block;
        margin-bottom: 25px;
      }

      .item-details-blocks .single-block.comments .single-comment p {
        font-size: 16px;
      }

      .item-details-blocks .single-block.comments .single-comment .reply {
        padding: 8px 15px;
        border: 1px solid #eee;
        color: #888;
        border-radius: 4px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
      }

        .item-details-blocks .single-block.comments .single-comment .reply i {
          display: inline-block;
          margin-right: 2px;
        }

        .item-details-blocks .single-block.comments .single-comment .reply:hover {
          color: #00aeff;
          border-color: #00aeff;
        }

  /* Item Details Sidebar */
  .item-details-sidebar .single-block.author {
    position: relative;
  }

    .item-details-sidebar .single-block.author img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .item-details-sidebar .single-block.author .content {
      position: relative;
      padding-left: 70px;
    }

      .item-details-sidebar .single-block.author .content h4 {
        font-size: 16px;
        font-weight: 500;
      }

      .item-details-sidebar .single-block.author .content span {
        font-size: 14px;
        color: #888;
        margin-top: 4px;
        display: block;
      }

      .item-details-sidebar .single-block.author .content .see-all {
        font-size: 15px;
        margin-top: 20px;
        color: #00aeff;
      }

        .item-details-sidebar .single-block.author .content .see-all:hover {
          color: #081828;
        }

  .item-details-sidebar .single-block.contant-seller .form-control-custom {
    margin-bottom: 15px;
    height: 50px;
  }

    .item-details-sidebar .single-block.contant-seller .form-control-custom::-webkit-input-placeholder {
      color: #081828;
      padding: 0 !important;
    }

    .item-details-sidebar .single-block.contant-seller .form-control-custom:-ms-input-placeholder {
      color: #081828;
      padding: 0 !important;
    }

    .item-details-sidebar .single-block.contant-seller .form-control-custom::-ms-input-placeholder {
      color: #081828;
      padding: 0 !important;
    }

    .item-details-sidebar .single-block.contant-seller .form-control-custom::placeholder {
      color: #081828;
      padding: 0 !important;
    }

  .item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom {
    height: 150px;
  }

    .item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom::-webkit-input-placeholder {
      color: #081828;
      padding: 0 !important;
    }

    .item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom:-ms-input-placeholder {
      color: #081828;
      padding: 0 !important;
    }

    .item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom::-ms-input-placeholder {
      color: #081828;
      padding: 0 !important;
    }

    .item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom::placeholder {
      color: #081828;
      padding: 0 !important;
    }


/* What steppr offers */
.what-steppr-offers {
  background-color: #F4FAFF;
}

.what-steppr-offers-card-text {
  height: 50%;
  position: relative;
}

.what-steppr-offers-card-text-top {
  margin-top: 30%;
}

  .what-steppr-offers-card-text > h3 {
    // position: absolute;
    bottom: 0;
    margin-top: 60%;
  }

  .what-steppr-offers-card-text > p {
    position: absolute;
    top: 0;
  }

.what-steppr-offers-row {
  display: flex;
}

.what-steppr-offers-row .col-12 {
  margin-bottom: 10px;
}

.what-steppr-offers .single-work {
  text-align: center;
  padding: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  min-height: 260px;
}

  .what-steppr-offers .single-work:hover {
    color: #FFF;
    background-color: #00aeff;
    transition: background 0.5s, border 0.5s, color 0.5s;
  }


    .what-steppr-offers .single-work:hover h3 {
      color: #FFF;
      transition: background 0.5s, border 0.5s, color 0.5s;
    }

.what-steppr-offers .single-work-odd {
  color: #FFF;
  background-color: #00aeff;
}

  .what-steppr-offers .single-work-odd h3 {
    color: #FFF;
  }

  .what-steppr-offers .single-work-odd:hover {
    background-color: #fff;
  }


  .what-steppr-offers .single-work-odd:hover h3 {
    color: #081828;
  }

    .what-steppr-offers .single-work-odd:hover p {
      color: #888;
    }

  .what-steppr-offers .single-work .serial {
    height: 80px;
    width: 80px;
    line-height: 68px;
    background-color: #00aeff;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    // border: 6px solid #00aeff;
    border: 6px solid #FFF;
    display: inline-block;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 25px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .what-steppr-offers .single-work:hover .serial {
    color: #00aeff;
    background-color: transparent;
    border-color: #00aeff;
  }

  .what-steppr-offers .single-work h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .what-steppr-offers .single-work p {
    font-size: 16px;
    line-height: 24px;
    min-height: 72px;
  }

  /* How It Works */
  .how-works {
    background-color: #00aeff;
  }


.connect-easily .choose-image img {
  height: 100%;
  width: 100%;
}

.connect-easily .choose-content .title {
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  padding-bottom: 20px;
}

.connect-easily .choose-content .single-list {
  position: relative;
  margin-bottom: 30px;
  padding: 40px;
  background: #00aeff;
  border-radius: 6px;
  border: 0px;
  text-align: center;
  min-height: 180px;
}

.connect-easily .choose-content .single-list-transparent {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: transparent !important;
  border-radius: 6px;
  border: 0px;
  text-align: center;
}

.connect-easily .choose-content .single-list-last {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #ff801b !important;
  border-radius: 6px;
  border: 0px;
  text-align: center;
}


.connect-easily .choose-content .single-list .single-list-number {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
  width: 110px;
  line-height: 105px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #F4FAFF;
  color: #00aeff;
}

  .connect-easily .choose-content .single-list .single-list-number:hover {
    background-color: #00aeff;
    border: 4px solid #FFFFFF;
    color: #FFFFFF;
    transition: background 0.5s, border 0.5s, color 0.5s;
  }

.connect-easily .choose-content .single-list .single-list-number-last {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
  width: 110px;
  line-height: 105px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #E2CEBE;
  color: #ff801b;
}

  .connect-easily .choose-content .single-list .single-list-number-last:hover {
    background-color: #E2CEBE;
    border: 4px solid #FFFFFF;
    color: #FFFFFF;
    transition: background 0.5s, border 0.5s, color 0.5s;
  }

.connect-easily .choose-content .single-list:hover i {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff;
}

.connect-easily .choose-content .single-list h4 {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
  // color: #FFFFFF;
  color: #081828;
  margin-top: 20px;
  min-height: 40px;
}

.connect-easily .choose-content .single-list-last h4 {
  color: #FFFFFF;
  min-height: 60px;
}

.connect-easily .choose-content .single-list-transparent h4 {
  // color: #FFFFFF;
  color: #FFFFFF;
  min-height: 60px;
}

.connect-easily .choose-content .single-list p {
  font-size: 16px;
  // color: #FFFFFF;
}

.connect-easily .choose-content .single-list-last p {
  font-size: 16px;
  color: #FFFFFF;
}

.connect-easily .choose-content .single-list-transparent p {
  font-size: 16px;
  color: #888;
}

.connect-easily .choose-content .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  position: relative;
  min-height: 320px;
}

.connect-easily .choose-content .single-work .single-list-logo-wrapper {
  width: 100%;
}

.connect-easily .choose-content .single-work .single-list-logo-wrapper2 {
  width: 80px;
  margin-left: calc(50% - 63px);
  margin-right: calc(50% - 63px);
  top: 44px;
  position: absolute;
  display: block;
}

  .connect-easily .choose-content .single-work .single-list-logo-img {
    text-align: center;
    position: absolute;
    background: url(../assets/images/logo_blue.webp);
    border: 0px;
    top: 0px;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
    width: 25px;
    aspect-ratio: 1 / 1;
    background-size: cover;
    z-index: 999;
    top: 50%;
    left: 38%;
  }

.connect-easily .choose-content .single-work img {
  text-align: center;
  padding: 5px;
  width: 80px;
  height: 80px;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
}

.connect-easily .choose-content .single-work svg {
  text-align: center;
  padding: 5px;
  width: 80px;
  height: 80px;
  color: #FFF;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
  transition: color 0.5s, border 0.5s;
}

.connect-easily .choose-content .single-work i {
  text-align: center;
  padding: 5px;
  padding-top: 13px;
  font-size: 60px;
  width: 80px;
  height: 80px;
  color: #FFF;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
  transition: color 0.5s, border 0.5s;
}

.connect-easily .choose-content .single-work:hover img {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
  // transition: filter 0.5s;
}

.connect-easily .choose-content .single-work:hover svg {
  background-color: transparent;
  border: 4px solid #00aeff;
  color: #00aeff;
  transition: color 0.5s, border 0.5s;
}

.connect-easily .choose-content .single-work:hover i {
  background-color: transparent;
  border: 4px solid #00aeff;
  color: #00aeff;
  transition: color 0.5s, border 0.5s;
}

.connect-easily .choose-content .single-work:hover .single-list-logo-img {
  filter: saturate(0%) brightness(0%) invert(100%) contrast(100%);
  // transition: filter 0.5s;
}

.connect-easily .choose-content .single-list-last img {
  text-align: center;
  padding: 20px;
  background-color: #ff801b;
  border-radius: 50%;
  border: 4px solid #ff801b;
}

.connect-easily .choose-content .single-list-last img:hover {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: none !important;
  // transition: filter 0.5s;
}

.connect-easily .choose-content .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  // border: 6px solid #00aeff;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.connect-easily .choose-content .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff;
}

.connect-easily .choose-content .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.connect-easily .choose-content .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px;
}


.connect-easily .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  min-height: 250px;
  margin-bottom: 0px;
}

    .connect-easily .single-work .serial {
      height: 80px;
      width: 80px;
      line-height: 68px;
      background-color: #00aeff;
      color: #fff;
      text-align: center;
      border-radius: 50%;
      // border: 6px solid #00aeff;
      border: 6px solid #FFF;
      display: inline-block;
      margin-bottom: 25px;
      font-weight: 700;
      font-size: 25px;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

    .connect-easily .single-work:hover .serial {
      color: #00aeff;
      background-color: transparent;
      border-color: #00aeff;
    }

    .connect-easily .single-work h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .connect-easily .single-work p {
      font-size: 16px;
      line-height: 24px;
      min-height: 72px;
    }

  .connect-easily-row {
    width: 100%;
  }

  @media (min-width: 992px) {
    .connect-easily-row .col-md-2 {
      width: 20%;
    }

    .how-works-row {
      display: flex;
    }

      .how-works-row .col {
        display: flex;
      }

      .how-works-row .col-12 {
        display: flex;
      }

      .how-works-row .col-md-3 {
        display: flex;
      }

      .how-works-row .col-lg-3 {
        display: flex;
      }
  }

.how-works .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  min-height: 320px;
}

  .how-works .single-work .serial {
    height: 80px;
    width: 80px;
    line-height: 68px;
    background-color: #00aeff;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    // border: 6px solid #00aeff;
    border: 6px solid #00aeff;
    display: inline-block;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 25px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .how-works .single-work:hover .serial {
    color: #00aeff;
    background-color: transparent;
    border-color: #00aeff;
    transition: background 0.5s, border 0.5s, color 0.5s;
  }

    .how-works .single-work h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 20px;
      min-height: 40px;
    }

    .how-works .single-work p {
      font-size: 16px;
      line-height: 24px;
      min-height: 72px;
    }

  /*======================================
    Call Action CSS
========================================*/
  /* .call-action {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 2;
    padding: 150px 0;
    background-attachment: fixed;
  }

    .call-action.overlay::before {
      background-color: #081828;
      opacity: 0.9;
      z-index: -1;
    }

    .call-action .content {
      text-align: center;
    }

      .call-action .content h2 {
        font-size: 35px;
        font-weight: 600;
        color: #fff;
        display: block;
        margin-bottom: 15px;
      }

      .call-action .content p {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }

      .call-action .content .button {
        margin-top: 30px;
      }

        .call-action .content .button .btn:hover {
          color: #081828;
          background: #fff;
        } */

  /*======================================
    Bookmarked CSS
========================================*/
  /* .bookmarked {
    background-color: #f9f9f9;
  }

    .bookmarked .job-items {
      padding: 30px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
    }

      .bookmarked .job-items .manage-content {
        padding: 25px 0;
        border-bottom: 1px solid #f1f1f1;
      }

        .bookmarked .job-items .manage-content:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        .bookmarked .job-items .manage-content .title-img .can-img {
          float: left;
          margin-right: 8px;
        }

        .bookmarked .job-items .manage-content .title-img h3 {
          font-weight: 600;
          position: relative;
          top: 8px;
        }

          .bookmarked .job-items .manage-content .title-img h3 span {
            font-size: 13px;
            font-weight: 400;
            display: block;
            margin-top: 5px;
          }

        .bookmarked .job-items .manage-content .time {
          background: #2042e314;
          color: #00aeff;
          padding: 5px 14px;
          border-radius: 3px;
          font-size: 13px;
        }

        .bookmarked .job-items .manage-content .can-img img {
          width: 50px;
          margin: 5px 15px;
        }

        .bookmarked .job-items .manage-content .location {
          font-size: 13px;
          position: relative;
          padding-left: 20px;
        }

          .bookmarked .job-items .manage-content .location i {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 3px;
          }

        .bookmarked .job-items .manage-content p i {
          font-size: 14px;
        }

        .bookmarked .job-items .manage-content h3 {
          font-size: 15px;
          font-weight: 500;
        }

        .bookmarked .job-items .manage-content .button .btn {
          padding: 8px 12px;
          font-size: 13px;
        } */

  /*======================================
    Responsive CSS
========================================*/
  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    .section-title {
      margin-bottom: 50px;
      padding: 0 220px;
    }

    .header .header .login-button {
      margin-left: 20px;
    }

    .header .login-button ul {
      padding: 0;
    }

    .header .nav-item {
      margin-right: 30px;
    }

      .header .nav-item a {
        // padding: 32px 0 !important;
        padding: 18px 0 !important;
        padding-top: 38px !important;
      }

      .header .nav-item .sub-menu li a {
        padding: 12px 15px !important;
      }

      .header .nav-item .sub-menu.mega-menu {
        width: 460px;
      }

    .hero-area {
      padding-top: 60px;
      padding-bottom: 60px;
    }

      .hero-area .hero-text h2 {
        font-size: 38px;
      }

      .hero-area .hero-text .button .btn {
        padding: 12px 10px;
        margin: 12px 0px;
      }

      .hero-area.style2 .hero-text h2 {
        font-size: 42px;
      }
  }

  /* Tablet Screen */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section {
      padding: 60px 0px;
    }

    .section-title {
      margin-bottom: 30px;
      padding: 0px 90px;
    }

      .section-title h2 {
        font-size: 30px;
        line-height: 38px;
      }

      .section-title p {
        font-size: 16px;
      }

      .section-title.align-left {
        padding: 0;
        padding-right: 200px;
      }

    .breadcrumbs {
      padding-top: 50px;
      padding-bottom: 20px;
    }

      .breadcrumbs .page-title {
        font-size: 28px;
      }

    .button .btn {
      padding: 12px 25px;
      font-size: 14px;
      font-weight: 500;
    }

    #scrollUp {
      bottom: 55px;
    }

    .header {
      padding: 15px 0;
    }

    .navbar-area.sticky {
      padding: 15px 0;
      min-height: 80px;
      max-height: 80px;
    }

    .navbar-brand img {
      width: 170px;
    }

    .sticky .navbar .navbar-nav .nav-item .sub-menu .nav-item a {
      color: #081828;
    }

    .sticky .navbar .navbar-nav .nav-item:hover a {
      color: #00aeff;
    }

    .breadcrumbs .bread-list {
      margin-top: 8px;
    }

    .why-choose .choose-content .single-work {
      padding: 10px;
    }

      .why-choose .choose-content .single-work img {
        padding: 5px;
      }

    .why-choose .choose-content .single-work .single-list-logo-wrapper2 {
      top: 38px;
    }

      .why-choose .choose-content .single-work .single-list-logo-img {
        top: 20%;
        left: 50%;
      }

    .hero-area {
      padding-top: 60px;
      padding-bottom: 60px;
    }

      .hero-area .hero-text h2 {
        font-size: 38px;
      }

      .hero-area .hero-text .button .btn {
        padding: 12px 10px;
        margin: 12px 0px;
      }

      .hero-area.style2 .hero-text {
        text-align: center;
      }

        .hero-area.style2 .hero-text h2 {
          font-size: 38px;
        }

          .hero-area.style2 .hero-text h2::before {
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }

      .hero-area.style3 {
        padding-top: 60px;
        padding-bottom: 60px;
      }

        .hero-area.style3 .hero-text {
          text-align: center;
        }

          .hero-area.style3 .hero-text h2 {
            font-size: 38px;
          }

            .hero-area.style3 .hero-text h2::before {
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
            }

        .hero-area.style3 .search-form {
          margin-top: 30px;
        }

    .items-tab.custom-padding {
      padding-top: 120px;
    }

    .categories.style2 {
      height: auto;
      padding: 60px 0;
    }

    .categories .single-cat .icon img {
      width: 35px;
    }

    .our-achievement {
      padding-top: 30px;
    }

      .our-achievement .single-achievement {
        padding: 0;
      }

        .our-achievement .single-achievement h3 {
          font-size: 30px;
        }

        .our-achievement .single-achievement p {
          font-size: 16px;
        }

    .single-ad-grid .align-items-center {
      -webkit-box-align: inherit !important;
      -ms-flex-align: inherit !important;
      align-items: inherit !important;
    }

    .single-ad-grid .image {
      height: 100%;
    }

      .single-ad-grid .image a {
        height: 100%;
      }

      .single-ad-grid .image img {
        height: 100%;
      }

    .items-tab.custom-padding {
      padding-top: 150px;
    }

    .items-tab .section-title {
      margin-bottom: 30px;
    }

    .items-tab .single-grid .content .top-content .info-list li a {
      line-height: 20px;
    }

    /* .browse-cities .section-title {
      margin-bottom: 30px;
    }

    .pricing-table .section-title {
      margin-bottom: 30px;
    }

    .testimonials {
      padding-bottom: 100px !important;
    }

      .testimonials .tns-nav {
        bottom: 50px;
      } */

    .connect-easily .single-work {
      text-align: center;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .how-works .single-work {
      padding: 25px 15px;
      margin-top: 30px;
      min-height: 240px;
    }

      .how-works .single-work .serial {
        height: 70px;
        width: 70px;
        line-height: 60px;
        border: 4px solid #FFF;
        margin-bottom: 25px;
        font-size: 22px;
      }

      .how-works .single-work h3 {
        font-size: 20px;
        margin-bottom: 8px;
      }

      .how-works .single-work p {
        font-size: 15px;
      }

    .item-details .product-info {
      padding-left: 0;
      margin-top: 40px;
    }

    .latest-news-area .single-news {
      margin-top: 30px;
    }

    .newsletter .newsletter-form {
      padding-left: 0;
    }

      .newsletter .newsletter-form input {
        padding-right: 135px;
      }

    .about-us .content-right {
      margin-top: 30px;
      padding-right: 0;
      padding-left: 0;
    }

    /* .post-ad-tab nav .nav-tabs {
      border: none;
      padding: 20px;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .post-ad-tab .step-two-content .upload-input {
      margin-bottom: 20px;
    }

    .post-ad-tab nav button {
      border-radius: 6px !important;
      width: 100%;
    } */

    .contact-us .form-main {
      margin-top: 30px;
    }

    /* .map-section .mapouter {
      height: 400px;
    }

    .map-section .gmap_canvas {
      height: 400px;
    }

    .map-section iframe {
      height: 400px;
    } */

    .faq {
      padding-bottom: 40px;
    }

    /* .coming-soon .soon-content .text h2 {
      font-size: 35px !important;
    } */

    .dashboard .main-content .details-lists .single-list {
      padding: 0;
      padding: 20px;
      text-align: center;
    }

      .dashboard .main-content .details-lists .single-list .list-icon {
        height: 55px;
        width: 55px;
        line-height: 55px;
        -webkit-transform: translateY(-50%);
        transform: translateY(0%);
        text-align: center;
        position: relative;
        text-align: center;
        left: 0;
        top: 0;
        display: inline-block;
        margin-bottom: 15px;
      }

        .dashboard .main-content .details-lists .single-list .list-icon i {
          font-size: 20px;
        }

    .dashboard-sidebar {
      margin-bottom: 30px;
    }

    .messages-body .chat-search-form {
      border-right: none;
    }

    .messages-body .form-head .username-title {
      text-align: left;
      padding-left: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #eee;
      padding-top: 30px;
    }

    .messages-body .user-list {
      border-right: none;
    }

    .messages-body .chat-list {
      padding-left: 30px;
      border-top: 1px solid #eee;
    }

      .messages-body .chat-list .reply-block input {
        width: 240px;
      }

    .close-content h2 {
      font-size: 26px;
    }

    .latest-news-area.blog-list {
      padding-top: 30px !important;
    }

    .blog-grid-page {
      margin-top: 30px;
    }

      .blog-grid-page.pagination {
        margin-top: 10px !important;
      }

    .footer .footer-top {
      padding: 50px 0 !important;
      padding-top: 45px !important;
    }

    .footer .single-footer h3 {
      margin-bottom: 25px !important;
    }

    .footer .single-footer {
      margin-top: 40px;
    }

    .footer .footer-bottom .footer-social {
      margin-top: 30px !important;
    }
  }

  /* Mobile Screen */
  @media only screen and (max-width: 767px) {
    .section {
      padding: 60px 0px;
    }

    .section-title {
      margin-bottom: 25px;
      padding: 0px 10px;
    }

      .section-title h2 {
        font-size: 25px;
        margin-top: 3px;
        line-height: 35px;
      }

      .section-title p {
        font-size: 16px;
      }

      .section-title.align-left {
        padding: 0;
        padding-right: 0;
      }

    .breadcrumbs {
      padding-top: 50px;
      padding-bottom: 20px;
    }

      .breadcrumbs .breadcrumbs-content {
        text-align: center;
      }

      .breadcrumbs .page-title {
        font-size: 22px;
        line-height: 30px !important;
      }

      .breadcrumbs .breadcrumb-nav {
        float: none !important;
        margin-top: 15px;
        text-align: center;
      }

    .button .btn {
      padding: 12px 25px;
      font-size: 14px;
      font-weight: 500;
    }

    .stripe-pay-modal {
      margin-top: 35%;
    }

    #scrollUp {
      bottom: 55px;
    }

    .navbar-brand img {
      width: 170px;
    }

    .header-button {
      // display: none;
    }

    .header {
      padding: 20px 0;
    }

      .header .add-list-button {
        display: none;
      }

      .header .login-button {
        // display: none;
        margin-left: 0px;
      }

      .header .navbar-collapse {
        top: 59px !important;
        border-top: 1px solid #eee;
      }

    .sticky .navbar .navbar-nav .nav-item .sub-menu .nav-item a {
      color: #081828;
    }

    .sticky .navbar .navbar-nav .nav-item:hover a {
      color: #00aeff;
    }

    .breadcrumbs .bread-list {
      margin-top: 8px;
    }

    .navbar-area.sticky {
      padding: 20px 0;
      min-height: 80px;
      max-height: 80px;
    }

    .navbar-brand img {
      width: 170px;
    }

    .breadcrumbs .bread-list {
      margin-top: 8px;
    }

    .for-me-graph-block {
      padding-left: 25px;
      padding-right: 0px;
    }

    .for-me-graph-block-first {
      padding-left: 25px;
      padding-right: 0px;
    }

    .for-me-graph-block-last {
      padding-right: 0px;
    }

    .for-me-graph-block .row {
      padding: 20px;
      border: 1px solid #eee;
      padding-bottom: 0px;
      padding-top: 40px;
      vertical-align: middle;
      height: 160px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 6px;
    }

    .for-me-wallet-block {
      min-height: 450px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 30px!important;
      padding-bottom: 80px;
    }

    .for-me-wallet-text {
      text-align: center;
    }

    .for-me-wallet-text-large {
      text-align: center;
    }

    .for-me-wallet-text-company {
      font-size: 16px;
      line-height: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .for-me-wallet-text-wrapper {
      padding-top: 0;
      margin-top: 0;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .for-me-wallet-text-code {
      font-size: 11px;
    }

    .why-choose .choose-content .single-work .single-list-logo-wrapper2 {
      top: 46px;
    }

    .why-choose .choose-content .single-work .single-list-logo-img {
      left: 38%;
    }

    .why-choose .choose-content .single-list {
      min-height: 300px;
    }

    .vouchers-are-important .choose-content .single-list {
      min-height: 300px;
    }

    .hero-area {
      padding-top: 60px;
      padding-bottom: 60px;
    }

      .hero-area .hero-text h2 {
        font-size: 32px;
        line-height: 38px;
      }

      .hero-area .hero-text .button .btn {
        padding: 12px 10px;
        margin: 12px 0px;
      }

      .hero-area .search-form {
        margin-top: 30px;
        background: #fff3;
        border-radius: 6px;
        padding: 40px;
      }

        .hero-area .search-form .search-input {
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
        }

        .hero-area .search-form .btn {
          margin: 0;
        }

      .hero-area.style2 {
        padding-top: 60px;
        padding-bottom: 60px;
      }

        .hero-area.style2 .hero-text {
          text-align: center;
        }

          .hero-area.style2 .hero-text h2 {
            font-size: 32px;
            line-height: 38px;
          }

            .hero-area.style2 .hero-text h2::before {
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
            }

        .hero-area.style2 .search-form {
          background-color: #fff;
          padding: 25px;
        }

      .hero-area.style3 {
        padding-top: 60px;
        padding-bottom: 60px;
      }

        .hero-area.style3 .hero-text {
          text-align: center;
        }

          .hero-area.style3 .hero-text h2 {
            font-size: 32px;
            line-height: 38px;
          }

            .hero-area.style3 .hero-text h2::before {
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
            }

        .hero-area.style3 .categories {
          margin-top: 20px;
        }

          .hero-area.style3 .categories .single-cat {
            margin-top: 20px;
          }

        .hero-area.style3 .search-form {
          margin-top: 30px;
          -webkit-transform: translateY(0);
          transform: translateY(0);
          padding-right: 40px;
        }

    .items-tab.custom-padding {
      padding-top: 50px;
    }

    .categories.style2 {
      height: auto;
      padding: 60px 0;
    }

    .category-page .category-grid-topbar {
      margin-top: 30px;
    }

      .category-page .category-grid-topbar .nav {
        float: none;
        margin-top: 15px;
      }

    .our-achievement {
      padding-top: 60px;
    }

      .our-achievement .single-achievement {
        padding: 0;
      }

        .our-achievement .single-achievement h3 {
          font-size: 30px;
        }

        .our-achievement .single-achievement p {
          font-size: 16px;
        }

    .categories .tns-controls button {
      width: 30px;
      height: 46px;
      opacity: 1;
      visibility: visible;
      border-radius: 0;
    }

      .categories .tns-controls button:first-child {
        left: 0;
        border-radius: 0 6px 6px 0;
      }

      .categories .tns-controls button:last-child {
        right: 0;
        border-radius: 6px 0 0 6px;
      }

    .categories .single-cat .icon img {
      width: 35px;
    }

    .categories .cat-inner {
      top: 60px;
    }

    .items-grid.custom-padding {
      padding-top: 150px;
    }

    .items-grid .section-title {
      margin-bottom: 30px;
    }

    .items-grid .single-grid .content .top-content .info-list li a {
      line-height: 20px;
    }

    /* .browse-cities .section-title {
      margin-bottom: 30px;
    } */

    .call-action .content h2 {
      font-size: 28px;
    }

    .items-tab .nav {
      border: none;
      margin: 0 !important;
    }

    .items-tab .nav-tabs .nav-link {
      padding: 12px 40px;
      display: block !important;
      text-align: center !important;
      width: 100%;
      // background: #eee !important;
      margin-bottom: 7px;
      border-radius: 6px 6px 0 0;
    }

    /* .pricing-table .section-title {
      margin-bottom: 30px;
    }

    .testimonials {
      padding-bottom: 80px !important;
    }

      .testimonials .single-testimonial {
        padding: 35px 30px;
      }

        .testimonials .single-testimonial .quote-icon i {
          font-size: 35px;
        }

      .testimonials .tns-nav {
        bottom: 45px;
      } */

    .connect-easily .single-work {
      text-align: center;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .how-works .single-work {
      padding: 25px 15px;
      margin-top: 30px;
      min-height: 180px;
    }

      .how-works .single-work .serial {
        height: 70px;
        width: 70px;
        line-height: 60px;
        border: 4px solid #FFF;
        margin-bottom: 25px;
        font-size: 22px;
      }

      .how-works .single-work h3 {
        font-size: 20px;
        margin-bottom: 8px;
      }

      .how-works .single-work p {
        font-size: 15px;
      }

    /* .newsletter .title {
      text-align: center;
      padding: 0;
    }

      .newsletter .title i {
        position: relative;
        top: 0 !important;
        display: inline-block;
        text-align: center;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 15px;
      }

    .newsletter .newsletter-form {
      margin-top: 20px;
    }

      .newsletter .newsletter-form .button {
        position: relative;
        margin-top: 15px;
        width: 100%;
      }

        .newsletter .newsletter-form .button .btn {
          width: 100%;
          border-radius: 6px;
        } */

    /* .faq-area {
      padding-bottom: 20px !important;
    }

      .faq-area .single-faq {
        margin-bottom: 45px;
      }

      .faq-area .heading {
        font-size: 15px;
        line-height: 25px;
      } */

    .brand-area .section-title.align-left {
      padding-right: 0;
      margin-bottom: 40px !important;
    }

    .client-logo-section {
      padding: 0 !important;
    }

      .client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img {
        max-width: 170px;
      }

    .about-us .content-right {
      margin-top: 30px;
      padding-right: 0;
      padding-left: 0;
    }

      .about-us .content-right h2 {
        font-size: 25px;
        line-height: 35px;
      }

      .about-us .content-right h3 {
        margin-top: 30px;
      }

    .latest-news-area .section-title {
      margin-bottom: 10px;
    }

    .latest-news-area .single-news {
      margin-top: 30px;
    }

      .latest-news-area .single-news .content-body .title {
        line-height: 24px !important;
      }

        .latest-news-area .single-news .content-body .title a {
          font-size: 17px !important;
        }

    .post-details .post-meta li {
      margin-bottom: 8px;
    }

    .post-details p {
      margin: 20px 0;
    }

    .post-image .blog-inner-big-img {
      margin-bottom: 1.5rem !important;
    }

    .post-details .list {
      margin-left: 0 !important;
    }

      .post-details .list li i {
        top: 6px !important;
      }

    .post-comments {
      padding: 30px;
    }

      .post-comments .comments-list {
        padding: 0 !important;
      }

        .post-comments .comments-list li {
          padding: 0 !important;
        }

          .post-comments .comments-list li .comment-img {
            position: relative !important;
          }

    .comment-form {
      padding: 30px;
    }

      .comment-form form .form-box .form-control-custom {
        height: 50px;
        margin-bottom: 15px;
      }

    .post-comments .comments-list li.children {
      margin-left: 0 !important;
    }

    .post-comments .comments-list li {
      padding-top: 30px !important;
    }

      .post-comments .comments-list li .comment-desc {
        margin-top: -15px;
      }

      .post-comments .comments-list li .comment-img img {
        max-width: 70px;
        max-height: 70px;
        display: inline-block !important;
        margin-bottom: -15px !important;
      }

      .post-comments .comments-list li .comment-desc .desc-top .reply-link {
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        padding: 8px 25px;
        position: relative;
        margin-top: 15px;
      }

    .post-comments .comments-list li {
      padding-left: 100px;
    }

    .latest-news-area.blog-list {
      padding-top: 20px;
    }

    .blog-list .single-news {
      margin-bottom: 0 !important;
    }

    .sidebar {
      margin-top: 30px;
    }

      .sidebar .widget.search-widget form input {
        padding: 0 80px 0 20px;
      }

      .sidebar .widget.social-widget ul li {
        margin-bottom: 10px;
      }

    .post-details .post-title {
      line-height: 30px;
    }

      .post-details .post-title a {
        font-size: 24px;
        font-weight: 600;
      }

    .error-area .error-content h1 {
      font-size: 60px !important;
      line-height: 80px !important;
    }

    .error-area .error-content h2 {
      font-size: 18px !important;
    }

    /*
    .maill-success .success-content h1 {
      font-size: 28px !important;
    }

    .maill-success .success-content h2 {
      font-size: 18px !important;
    } */

    .item-details .product-info {
      padding-left: 0;
      margin-top: 30px;
    }

      .item-details .product-info .contact-info ul li {
        margin: 0;
        width: 100%;
      }

        .item-details .product-info .contact-info ul li:last-child {
          width: 100%;
          text-align: center;
          margin-top: 8px;
        }

          .item-details .product-info .contact-info ul li:last-child a {
            width: 100%;
          }

      .item-details .product-info .social-share h4 {
        display: block;
        margin-bottom: 15px;
      }

    .item-details-blocks .single-block.comments .single-comment {
      position: relative;
      padding-left: 0;
      margin-top: 30px;
    }

      .item-details-blocks .single-block.comments .single-comment img {
        position: relative;
        margin-bottom: 15px;
      }

    .item-details .product-images .images {
      display: -ms-grid;
      display: grid;
      Grid-template-columns: repeat(4, 1fr);
      Grid-gap: 1em 1em;
      Margin-top: 1em;
    }

    .item-details .product-info .social-share ul li {
      margin-bottom: 8px;
    }

    .item-details .product-info .contact-info ul li .call {
      width: 100%;
    }

    .item-details .product-info .title {
      font-size: 25px;
      margin-bottom: 10px;
    }


    .item-details .product-info .contact-info ul li .call {
      width: 100%;
    }

    .item-details .recipient-next-button-info {
      padding-left: 10px;
      white-space: normal;
      margin-right: -3px;
      text-align: right;
    }

  /*  .item-details .recipient-when-to-send-button {
      margin-left: 0px;
      right: 0px;
    } */

    .item-details .recipient-when-to-send-button-info {
      padding-left: 5px;
      text-align: center;
      margin-right: -15px;
      margin-left: 15px;
      white-space: normal;
      width: 100%;
    }

    .latest-news-area .single-news {
      margin-top: 30px;
    }

    /* .newsletter .newsletter-form {
      padding-left: 0;
    }

    .newsletter .newsletter-form input {
      padding-right: 135px;
    } */

    /* .post-ad-tab nav .nav-tabs {
      border: none;
      padding: 20px;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .post-ad-tab .step-two-content .upload-input {
      margin-bottom: 20px;
    }

    .post-ad-tab nav button {
      border-radius: 6px !important;
      width: 100%;
    } */

    .login .form-head {
      padding: 30px;
    }

      .login .form-head .title {
        font-size: 25px;
      }

      .login .form-head .alt-option {
        margin: 20px 0;
      }

      .login .form-head form .form-group {
        margin-bottom: 18px;
      }

    .contact-us .form-main {
      margin-top: 30px;
    }

    .contact-us .single-head {
      padding: 30px !important;
    }

    .contact-us .form-main {
      padding: 30px !important;
    }

    .contact-us .contant-inner-title h2 {
      font-size: 22px;
    }

    /*.map-section .mapouter {
      height: 300px;
    }

    .map-section .gmap_canvas {
      height: 300px;
    }

    .map-section iframe {
      height: 300px;
    } */

    .contact-us .form-main .form-title h2 {
      font-size: 25px;
    }

    .faq {
      padding-bottom: 30px;
    }

    .login .form-head form .check-and-pass .form-check {
      float: none;
      text-align: center;
      display: block;
      margin-top: 10px;
    }

    .login .form-check .form-check-input {
      float: left;
      margin-left: -1.5em;
      float: none;
      text-align: center;
    }

    .login .form-head form .check-and-pass .lost-pass {
      float: right;
      color: #777;
      position: relative;
      top: -2px;
      float: none;
      margin-top: 10px;
      text-align: center;
      display: block;
    }

    /* .coming-soon .soon-content .text h2 {
      font-size: 28px !important;
      margin-bottom: 10px !important;
      line-height: 32px !important;
    }

    .coming-soon .soon-content .text {
      margin-bottom: 10px !important;
    }

    .coming-soon .soon-content .box {
      margin-top: 18px !important;
    } */

    .dashboard .main-content .details-lists .single-list {
      margin-top: 30px;
    }

    .dashboard-sidebar {
      margin-bottom: 30px;
    }

    .profile-settings-block .profile-setting-form .form-group {
      margin-bottom: 20px !important;
    }

    .close-account-button {
      position: absolute;
      bottom: 20px;
    }

    .close-content {
      margin-bottom: 5px;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 0;
    }

    .my-items .item-list-title {
      text-align: left;
    }

      .my-items .item-list-title p {
        padding: 5px 0;
      }

      .my-items .item-list-title .align-right {
        float: none;
        text-align: left !important;
      }

    .my-items .single-item-list p {
      padding: 8px 0;
    }

    .my-items .single-item-list .item-image {
      margin-bottom: 10px;
    }

    .my-items .single-item-list .action-btn {
      margin-top: 10px;
    }

    .my-items .single-item-list .align-right {
      text-align: left !important;
    }

    .messages-body .chat-search-form {
      border-right: none;
    }

    .messages-body .form-head .username-title {
      text-align: left;
      padding-left: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #eee;
      padding-top: 30px;
    }

    .messages-body .user-list {
      border-right: none;
    }

    .messages-body .chat-list {
      padding-left: 30px;
      border-top: 1px solid #eee;
    }

      .messages-body .chat-list .single-chat-head li.left img {
        height: 35px;
        width: 35px;
        bottom: 6px;
      }

      .messages-body .chat-list .single-chat-head li.right img {
        height: 35px;
        width: 35px;
        bottom: 6px;
      }

      .messages-body .chat-list .single-chat-head li.left {
        padding: 0;
        padding-left: 50px;
      }

      .messages-body .chat-list .single-chat-head li.right {
        padding: 0;
        padding-right: 50px;
      }

      .messages-body .chat-list .single-chat-head li.left .text {
        padding: 17px;
        font-size: 15px;
        line-height: 21px;
      }

        .messages-body .chat-list .single-chat-head li.left .text .time {
          font-size: 13px;
        }

      .messages-body .chat-list .single-chat-head li.right .text {
        padding: 17px;
        font-size: 15px;
        line-height: 21px;
      }

        .messages-body .chat-list .single-chat-head li.right .text .time {
          font-size: 13px;
        }

      .messages-body .chat-list .reply-block input {
        width: 100%;
        padding: 20px;
        border: 1px solid #eee;
        margin-top: 10px;
        padding-right: 75px;
        border-radius: 6px;
      }

      .messages-body .chat-list .reply-block {
        position: relative;
      }

        .messages-body .chat-list .reply-block button {
          display: inline-block;
          height: 50px;
          width: 50px;
          text-align: center;
          line-height: 48px;
          color: #fff;
          background: #00aeff;
          border-radius: 6px;
          border: none;
          font-size: 18px;
          position: absolute;
          top: -4px;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
          padding-left: 3px;
          padding-bottom: 3px;
          right: 8px;
          top: 87px;
        }

      .messages-body .chat-list .single-chat-head li.right .text {
        padding: 20px;
        font-size: 15px;
      }

      .messages-body .chat-list .single-chat-head li.left .text {
        padding: 20px;
        font-size: 15px;
      }

    .close-content h2 {
      font-size: 22px;
    }

    .close-content {
      padding: 50px 30px;
    }

      .close-content .button .btn {
        width: 75%;
        margin: 0;
        margin-bottom: 10px;
      }

        .close-content .button .btn:last-child {
          margin: 0;
        }

    .default-list-style .default-list-title p {
      padding: 3px 0;
    }

    .default-list-title .align-right {
      text-align: left;
    }

    .default-list-style .single-list p {
      padding: 3px 0;
    }

    .default-list-style .single-list .action-btn {
      margin-top: 5px;
    }

    .default-list-style .single-list .align-right {
      text-align: left;
    }

    .post-details .detail-inner {
      padding: 20px;
      margin-bottom: 30px;
    }

    .post-details .post-title a {
      font-size: 22px;
      font-weight: 600;
    }

    blockquote {
      margin: 30px 0;
      padding: 20px;
    }

      blockquote .icon i {
        font-size: 30px;
      }

    .post-social-media .share-title {
      margin-bottom: 25px;
    }

    .comment-title {
      margin-bottom: 0px !important;
    }

    .sidebar .widget {
      padding: 30px;
    }

    .post-details .post-image img {
      margin-top: 20px;
    }

    .post-details .post-social-media ul {
      margin-left: 0;
    }

    .footer .footer-top {
      padding: 30px 0 !important;
      padding-top: 25px !important;
    }

    .footer .footer-bottom .inner {
      padding: 30px 0 !important;
    }

    .footer .single-footer h3 {
      margin-bottom: 25px !important;
    }

    .footer .single-footer {
      margin-top: 40px;
      text-align: center;
    }

    .footer .footer-bottom .footer-social {
      margin-top: 30px !important;
    }
  }

  /*======================================
	Contact CSS
========================================*/
  .contact-us {
    position: relative;
    background-color: #f9f9f9;
  }

    .contact-us .contact-head {
      overflow: hidden;
      position: relative;
      z-index: 5;
    }

    .contact-us .title {
      margin-bottom: 30px;
    }

    .contact-us .contant-inner-title {
      margin-bottom: 45px;
    }

      .contact-us .contant-inner-title h2 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 5px;
        color: #081828;
        text-transform: capitalize;
      }

      .contact-us .contant-inner-title p {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        margin-top: 10px;
        display: block;
      }

    .contact-us .single-head {
      padding: 50px;
      height: 100%;
      position: relative;
      z-index: 5;
      overflow: hidden;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .contact-us .single-info {
      text-align: left;
      margin-bottom: 35px;
      position: relative;
    }

      .contact-us .single-info:last-child {
        margin: 0;
      }

      .contact-us .single-info h3 {
        font-size: 20px;
        margin-bottom: 15px;
      }

      .contact-us .single-info ul {
        display: inline-block;
      }

        .contact-us .single-info ul li {
          font-weight: 400;
          display: block;
          margin-bottom: 5px;
          font-size: 14px;
          color: #888;
        }

          .contact-us .single-info ul li:last-child {
            margin: 0;
          }

          .contact-us .single-info ul li a {
            font-weight: 400;
            color: #7E8890;
            display: inline-block;
            font-size: 15px;
          }

            .contact-us .single-info ul li a:hover {
              color: #00aeff;
            }

    .contact-us .contact-social {
      display: block;
      margin-top: 65px;
    }

      .contact-us .contact-social h5 {
        color: #081828;
        font-weight: 600;
        font-size: 18px;
      }

      .contact-us .contact-social ul li {
        display: inline-block;
        margin-right: 6px;
      }

        .contact-us .contact-social ul li:last-child {
          margin-right: 0;
        }

        .contact-us .contact-social ul li a {
          font-size: 14px;
          color: #555;
          height: 40px;
          width: 40px;
          text-align: center;
          display: block;
          line-height: 40px;
          background: #f9f9f9;
          border: 1px solid #eee;
          border-radius: 50%;
          position: relative;
          z-index: 3;
        }

          .contact-us .contact-social ul li a:hover {
            color: #fff;
            background-color: #00aeff;
            border-color: transparent;
          }

    .contact-us .single-info .title {
      margin-bottom: 10px;
      font-weight: 500;
      color: #fff;
      font-size: 18px;
    }

    .contact-us .form-main {
      padding: 45px 50px 50px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
      height: 100%;
    }

      .contact-us .form-main .form-title {
        margin-bottom: 30px;
      }

        .contact-us .form-main .form-title h2 {
          margin-bottom: 20px;
          font-size: 30px;
        }

        .contact-us .form-main .form-title p {
          font-size: 16px;
        }

    .contact-us .form .form-group {
      margin-bottom: 15px;
      display: block;
    }

      .contact-us .form .form-group input {
        height: 55px;
        line-height: 55px;
        width: 100%;
        border: 1px solid #e6e2f5;
        padding: 0px 20px;
        color: #333;
        border-radius: 0px;
        font-weight: 400;
        border-radius: 5px;
      }

      .contact-us .form .form-group textarea {
        height: 180px;
        width: 100%;
        border: 1px solid #e6e2f5;
        padding: 15px 20px;
        color: #333;
        border-radius: 0px;
        resize: none;
        font-weight: 400;
        border-radius: 5px;
        resize: vertical;
      }

    .contact-us .form .button {
      margin: 0;
    }

      .contact-us .form .button .btn {
        height: 50px;
        border: none;
      }

  /* .map-section {
    background-color: #f9f9f9;
  } */

  /*======================================
	Error 404 CSS
========================================*/
  .error-area {
    height: 100vh;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .d-table {
    width: 100%;
    height: 100%;
  }

  .d-table {
    display: table !important;
  }

  .d-table-cell {
    vertical-align: middle;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  .error-area .error-content h1 {
    font-size: 100px;
    color: #00aeff;
    margin-bottom: 8px;
    font-weight: 800;
    line-height: 100px;
  }

  .error-area .error-content h2 {
    font-size: 25px;
    margin-bottom: 8px;
    color: #081828;
  }

  .error-area .error-content p {
    font-weight: 500;
    margin-bottom: 40px;
  }

  /*======================================
	Mail Success CSS
========================================*/
  .maill-success {
    height: 100vh;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .d-table {
    width: 100%;
    height: 100%;
  }

  .d-table {
    display: table !important;
  }

  .d-table-cell {
    vertical-align: middle;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  /*
  .maill-success .success-content h1 {
    font-size: 40px;
    color: #00aeff;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .maill-success .success-content h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #081828;
  }

  .maill-success .success-content p {
    font-weight: 500;
    margin-bottom: 20px;
  } */

  /*======================================
	Coming Soon CSS
========================================*/
  /* .coming-soon {
    height: 100vh;
    text-align: center;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: #f9f9f9;
    z-index: 2;
  }

    .coming-soon .verticle-lines .vlines {
      width: 3px;
      height: 100%;
      background: #9b9b9b;
      position: absolute;
      top: 0;
      bottom: 0;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-name: lineanim;
      animation-name: lineanim;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      opacity: 0.05;
      z-index: -1;
    }

      .coming-soon .verticle-lines .vlines.one {
        left: 20%;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }

      .coming-soon .verticle-lines .vlines.two {
        left: 40%;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }

      .coming-soon .verticle-lines .vlines.three {
        left: 60%;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
      }

      .coming-soon .verticle-lines .vlines.four {
        left: 80%;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
      } */

  @-webkit-keyframes lineanim {
    50% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: top left;
      transform-origin: top left;
    }

    50.1% {
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
    }

    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
    }
  }

  @keyframes lineanim {
    50% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: top left;
      transform-origin: top left;
    }

    50.1% {
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
    }

    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
    }
  }

  .d-table {
    width: 100%;
    height: 100%;
  }

  .d-table {
    display: table !important;
  }

  .d-table-cell {
    vertical-align: middle;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  /* .coming-soon .soon-content {
    text-align: center;
  }

    .coming-soon .soon-content .text {
      margin-bottom: 60px;
    }

      .coming-soon .soon-content .text h2 {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        color: #081828;
        display: block;
        margin-bottom: 20px;
        text-transform: capitalize;
      }

      .coming-soon .soon-content .text p {
        color: #888;
        font-size: 16px;
      }

    .coming-soon .soon-content .box {
      background: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
      width: 100px;
      height: 100px;
      margin: 0 5px;
      display: inline-block;
      padding-top: 25px;
    }

    .coming-soon .soon-content h2 {
      text-align: center;
      padding-top: 2px;
      color: #081828;
      font-size: 15px;
      font-weight: 400;
    }

    .coming-soon .soon-content .box h1 {
      font-size: 26px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 5px;
      color: #00aeff;
    }

    .coming-soon .soon-content .alert {
      display: none;
    } */

  /*======================================
	Footer CSS
========================================*/
  .footer {
    background: #081828;
  }

    .footer .footer-top {
      padding: 100px 0;
      padding-top: 70px;
      border-bottom: 1px solid #ebebeb s;
    }

    .footer .single-footer {
      margin-top: 30px;
    }

      .footer .single-footer.mobile-app .app-button .btn {
        border: 1px solid #eeeeee4a;
        border-radius: 6px;
        padding: 12px 35px;
        position: relative;
        text-align: left;
        padding-left: 70px;
        margin-bottom: 12px;
        min-width: 192px;
        color: #e1e1e1;
      }

        .footer .single-footer.mobile-app .app-button .btn:hover {
          border-color: transparent;
          background: #00aeff;
          color: #fff;
        }

        .footer .single-footer.mobile-app .app-button .btn:last-child {
          margin: 0;
        }

        .footer .single-footer.mobile-app .app-button .btn i {
          font-size: 28px;
          position: absolute;
          left: 22px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        .footer .single-footer.mobile-app .app-button .btn .text {
          font-size: 14px;
          font-weight: 500;
        }

          .footer .single-footer.mobile-app .app-button .btn .text .small-text {
            font-weight: 400;
            font-size: 13px;
            display: block;
            margin-bottom: -1px;
          }

      .footer .single-footer.f-link li {
        display: block;
        margin-bottom: 10px;
      }

        .footer .single-footer.f-link li:last-child {
          margin: 0;
        }

        .footer .single-footer.f-link li a {
          font-size: 15px;
          font-weight: 400;
          color: #e1e1e1;
        }

          .footer .single-footer.f-link li a:hover {
            color: #00aeff;
          }

      .footer .single-footer.f-contact ul li {
        display: block;
        margin-bottom: 25px;
        line-height: 25px;
        color: #e1e1e1;
      }

        .footer .single-footer.f-contact ul li:last-child {
          margin-bottom: 0;
        }

      .footer .single-footer h3 {
        font-size: 18px;
        font-weight: 600;
        display: block;
        margin-bottom: 35px;
        color: #fff;
      }

    .footer .footer-bottom {
      text-align: center;
      position: relative;
      z-index: 1;
    }

      .footer .footer-bottom .inner {
        border-top: 1px solid #ebebeb4f;
        padding: 50px 0;
      }

      .footer .footer-bottom .footer-bottom-links li {
        display: inline-block;
        margin-right: 15px;
        position: relative;
        padding-right: 15px;
      }

        .footer .footer-bottom .footer-bottom-links li::before {
          position: absolute;
          content: "";
          right: -3px;
          top: 50%;
          height: 1px;
          width: 5px;
          background: #888;
        }

        .footer .footer-bottom .footer-bottom-links li:last-child {
          margin: 0;
          padding: 0;
        }

          .footer .footer-bottom .footer-bottom-links li:last-child::before {
            display: none;
          }

        .footer .footer-bottom .footer-bottom-links li a {
          color: #e1e1e1;
          font-weight: 400;
        }

          .footer .footer-bottom .footer-bottom-links li a:hover {
            color: #00aeff;
          }

      .footer .footer-bottom .copyright-text {
        margin-top: 15px;
        color: #e1e1e1;
        font-family: 'Jost', sans-serif;
      }

        .footer .footer-bottom .copyright-text a {
          color: #e1e1e1;
        }

          .footer .footer-bottom .copyright-text a:hover {
            color: #00aeff;
          }

      .footer .footer-bottom .footer-social {
        margin-top: 40px;
      }

        .footer .footer-bottom .footer-social li {
          display: inline-block;
          margin-right: 10px;
        }

          .footer .footer-bottom .footer-social li:last-child {
            margin: 0;
          }

          .footer .footer-bottom .footer-social li a {
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            display: block;
            border-radius: 50%;
            color: #fff;
            background: transparent;
            border: 1px solid #eee3;
          }

            .footer .footer-bottom .footer-social li a:hover {
              color: #fff;
              background: #00aeff;
              border-color: transparent;
            }
