@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@charset "UTF-8";
/*!
   * Bootstrap  v5.3.3 (https://getbootstrap.com/)
   * Copyright 2011-2024 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color); }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline; }
  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0; }
  .form-control::-webkit-datetime-edit {
    display: block;
    padding: 0; }
  .form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg); }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    -webkit-appearance: none;
            appearance: none;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    appearance: none;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.375rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label,
  .form-floating > .form-control:disabled ~ label {
    color: #6c757d; }
    .form-floating > :disabled ~ label::after,
    .form-floating > .form-control:disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked:focus-visible + .btn {
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d; }

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529; }

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg); }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm); }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: var(--bs-border-radius); }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: calc(var(--bs-border-width) * -1); }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1); }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd; }
  .nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius); }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  grid-gap: var(--bs-nav-underline-gap);
  gap: var(--bs-nav-underline-gap); }
  .nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent; }
    .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
      border-bottom-color: currentcolor; }
  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color); }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type > .accordion-header .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type > .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush > .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush > .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
    border-radius: 0; }
  .accordion-flush > .accordion-item > .accordion-collapse {
    border-radius: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color); }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis); }

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis); }

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis); }

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis); }

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis); }

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis); }

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis); }

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis); }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/; }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/; }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important; }

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-warning:hover, .link-warning:focus {
    color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
            text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
            text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  align-items: center;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
          text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0)); }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  object-fit: contain !important; }

.object-fit-cover {
  object-fit: cover !important; }

.object-fit-fill {
  object-fit: fill !important; }

.object-fit-scale {
  object-fit: scale-down !important; }

.object-fit-none {
  object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: var(--bs-box-shadow) !important; }

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important; }

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important; }

.shadow-none {
  box-shadow: none !important; }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important; }

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important; }

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important; }

.gap-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important; }

.gap-4 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important; }

.gap-5 {
  grid-gap: 3rem !important;
  gap: 3rem !important; }

.row-gap-0 {
  grid-row-gap: 0 !important;
  row-gap: 0 !important; }

.row-gap-1 {
  grid-row-gap: 0.25rem !important;
  row-gap: 0.25rem !important; }

.row-gap-2 {
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important; }

.row-gap-3 {
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important; }

.row-gap-4 {
  grid-row-gap: 1.5rem !important;
  row-gap: 1.5rem !important; }

.row-gap-5 {
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important; }

.column-gap-0 {
  grid-column-gap: 0 !important;
  column-gap: 0 !important; }

.column-gap-1 {
  grid-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important; }

.column-gap-2 {
  grid-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important; }

.column-gap-3 {
  grid-column-gap: 1rem !important;
  column-gap: 1rem !important; }

.column-gap-4 {
  grid-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important; }

.column-gap-5 {
  grid-column-gap: 3rem !important;
  column-gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
          text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
          text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    object-fit: contain !important; }
  .object-fit-sm-cover {
    object-fit: cover !important; }
  .object-fit-sm-fill {
    object-fit: fill !important; }
  .object-fit-sm-scale {
    object-fit: scale-down !important; }
  .object-fit-sm-none {
    object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-sm-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important; }
  .gap-sm-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important; }
  .gap-sm-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-sm-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-sm-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .row-gap-sm-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important; }
  .row-gap-sm-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important; }
  .column-gap-sm-0 {
    grid-column-gap: 0 !important;
    column-gap: 0 !important; }
  .column-gap-sm-1 {
    grid-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    grid-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    grid-column-gap: 1rem !important;
    column-gap: 1rem !important; }
  .column-gap-sm-4 {
    grid-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    grid-column-gap: 3rem !important;
    column-gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    object-fit: contain !important; }
  .object-fit-md-cover {
    object-fit: cover !important; }
  .object-fit-md-fill {
    object-fit: fill !important; }
  .object-fit-md-scale {
    object-fit: scale-down !important; }
  .object-fit-md-none {
    object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-md-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important; }
  .gap-md-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important; }
  .gap-md-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-md-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-md-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .row-gap-md-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important; }
  .row-gap-md-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important; }
  .column-gap-md-0 {
    grid-column-gap: 0 !important;
    column-gap: 0 !important; }
  .column-gap-md-1 {
    grid-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    grid-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    grid-column-gap: 1rem !important;
    column-gap: 1rem !important; }
  .column-gap-md-4 {
    grid-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    grid-column-gap: 3rem !important;
    column-gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    object-fit: contain !important; }
  .object-fit-lg-cover {
    object-fit: cover !important; }
  .object-fit-lg-fill {
    object-fit: fill !important; }
  .object-fit-lg-scale {
    object-fit: scale-down !important; }
  .object-fit-lg-none {
    object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-lg-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important; }
  .gap-lg-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important; }
  .gap-lg-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-lg-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-lg-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .row-gap-lg-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important; }
  .row-gap-lg-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important; }
  .column-gap-lg-0 {
    grid-column-gap: 0 !important;
    column-gap: 0 !important; }
  .column-gap-lg-1 {
    grid-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    grid-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    grid-column-gap: 1rem !important;
    column-gap: 1rem !important; }
  .column-gap-lg-4 {
    grid-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    grid-column-gap: 3rem !important;
    column-gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    object-fit: contain !important; }
  .object-fit-xl-cover {
    object-fit: cover !important; }
  .object-fit-xl-fill {
    object-fit: fill !important; }
  .object-fit-xl-scale {
    object-fit: scale-down !important; }
  .object-fit-xl-none {
    object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-xl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important; }
  .gap-xl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important; }
  .gap-xl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-xl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-xl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .row-gap-xl-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important; }
  .row-gap-xl-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important; }
  .column-gap-xl-0 {
    grid-column-gap: 0 !important;
    column-gap: 0 !important; }
  .column-gap-xl-1 {
    grid-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    grid-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    grid-column-gap: 1rem !important;
    column-gap: 1rem !important; }
  .column-gap-xl-4 {
    grid-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    grid-column-gap: 3rem !important;
    column-gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    object-fit: contain !important; }
  .object-fit-xxl-cover {
    object-fit: cover !important; }
  .object-fit-xxl-fill {
    object-fit: fill !important; }
  .object-fit-xxl-scale {
    object-fit: scale-down !important; }
  .object-fit-xxl-none {
    object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-xxl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-xxl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .row-gap-xxl-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important; }
  .row-gap-xxl-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important; }
  .column-gap-xxl-0 {
    grid-column-gap: 0 !important;
    column-gap: 0 !important; }
  .column-gap-xxl-1 {
    grid-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    grid-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    grid-column-gap: 1rem !important;
    column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    grid-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    grid-column-gap: 3rem !important;
    column-gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*!

 =========================================================
 * Vibe - Dashboard Theme - v1.0.0
 =========================================================

 * Product: http://www.nicedash.com/dashboards/vibe
 * Copyright 2018 Nice Dash (http://www.nicedash.com)

 =========================================================

 */
/* GLOBAL STYLES
   ----------------------------- */
body {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

hr {
  border-color: #dcdcdc; }

/* TYPOGRAPHY STYLES
   ----------------------------- */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0; }

h1, .h1,
.h1 {
  font-size: 2.5rem; }

h2, .h2,
.h2 {
  font-size: 2rem; }

h3, .h3,
.h3 {
  font-size: 1.5rem; }

h4, .h4,
.h4 {
  font-size: 1.25rem; }

h5, .h5,
.h5 {
  font-size: 1rem; }

h6, .h6,
.h6 {
  font-size: 0.75rem; }

.error-number {
  color: #22262e;
  font-size: 120px;
  font-weight: 600;
  line-height: 130px; }

.back-to-link {
  position: absolute;
  top: 20px;
  left: 40px; }

/* COLORS STYLES
   ----------------------------- */
.bg-orange {
  background-color: #f68b38 !important; }

.bg-blue {
  background-color: #127cd8 !important; }

.bg-gold {
  background-color: #ffcc00 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-light-grey {
  background-color: #f2f4f7 !important; }

.bg-primary {
  background-color: #127cd8 !important; }

.bg-success {
  background-color: #22b66e !important; }

.bg-warning {
  background-color: #ffb400 !important; }

.bg-danger {
  background-color: #e74c4b !important; }

.bg-info {
  background-color: #337ab7 !important; }

.bg-facebook {
  background-color: #3b5998; }

.bg-twitter {
  background-color: #55acee; }

.bg-linkedin {
  background-color: #55acee; }

.bg-github {
  background-color: #55acee; }

.text-facebook {
  color: #3b5998; }

.text-twitter {
  color: #55acee; }

.text-linkedin {
  color: #1178b2; }

.text-github {
  color: #24292e; }

.text-primary {
  color: #127cd8 !important; }

.text-success {
  color: #22b66e !important; }

.text-info {
  color: #337ab7 !important; }

.text-warning {
  color: #ffb400 !important; }

.text-danger {
  color: #e74c4b !important; }

.text-gold {
  color: #ffcc00 !important; }

.text-muted {
  color: #85939d; }

.text-facebook {
  color: #3b5998; }

.text-twitter {
  color: #55acee; }

/* LIST STYLES
   ----------------------------- */
.list {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px; }

.list-item {
  display: block;
  position: relative;
  padding: 8px 16px; }

/* TABLE STYLES
   ----------------------------- */
.rs-table-row-header .rs-table-cell {
  padding-left: 3px;
  padding-right: 3px;
  text-align: left; }

table.table {
  border-radius: 3px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #eceff2;
  width: 100%;
  color: #85939d;
  background: #fff; }
  table.table thead {
    background: #f8f9fb; }
    table.table thead th {
      font-weight: 400;
      padding: 15px 20px;
      text-transform: uppercase;
      border: 1px solid #eceff2;
      border-radius: 3px; }
  table.table tbody td {
    padding: 10px 20px;
    border: 1px solid #eceff2;
    border-radius: 3px;
    vertical-align: middle; }
  table.table.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f6f7fb; }

table.table-custom {
  box-shadow: none; }
  table.table-custom thead {
    background: transparent;
    border-bottom: 2px solid #eceff2; }
    table.table-custom thead th {
      border: 0;
      border-bottom: 1px solid #eceff2; }
  table.table-custom tbody tr {
    border-bottom: 1px solid #eceff2; }
    table.table-custom tbody tr td {
      border: 0; }
    table.table-custom tbody tr:hover {
      background: #eceff2; }

table.table-hover > tbody > tr:hover {
  background-color: #e9f8ff; }

.table-responsive {
  padding: 1px;
  max-height: 800px; }

/* IMAGE STYLES
   ----------------------------- */
img {
  max-width: 100%; }

/* BUTTON STYLES
   ----------------------------- */
.btn {
  border-radius: 4px;
  transition: all 0.15s ease; }
  .btn.btn-primary {
    background-color: #127cd8;
    border: 1px solid #127cd8; }
    .btn.btn-primary:focus, .btn.btn-primary:hover {
      background-color: #0e61a9;
      border: 1px solid #0e61a9; }
  .btn.btn-secondary {
    border: 1px solid #d2d2d2;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    color: #616161;
    background: #f8f8fa;
    background: linear-gradient(to bottom, #ffffff 0%, #f8f8fa 100%); }
    .btn.btn-secondary:focus, .btn.btn-secondary:hover {
      background: #e5e8ee;
      background: linear-gradient(to bottom, #ffffff 0%, #e5e8ee 100%); }
    .btn.btn-secondary:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.4); }
    .btn.btn-secondary:not(:disabled):not(.disabled):active {
      background: #dce0e8;
      background: linear-gradient(to bottom, #f7f7f7 0%, #dce0e8 100%);
      color: #7a7a7a !important;
      background-color: #dce0e8 !important;
      border-color: #dfe3ea; }
  .btn.btn-success {
    color: #fff;
    background-color: #22b66e;
    border: 1px solid #22b66e; }
    .btn.btn-success:focus, .btn.btn-success:hover {
      background-color: #1a8b54;
      border: 1px solid #1a8b54; }
  .btn.btn-danger {
    color: #fff;
    background-color: #e74c4b;
    border: 1px solid #e74c4b; }
    .btn.btn-danger:focus, .btn.btn-danger:hover {
      background-color: #e11f1e;
      border: 1px solid #e11f1e; }
  .btn.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border: 1px solid #5bc0de; }
    .btn.btn-info:focus, .btn.btn-info:hover {
      background-color: #31b0d5;
      border: 1px solid #31b0d5; }
  .btn.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border: 1px solid #f0ad4e; }
    .btn.btn-warning:focus, .btn.btn-warning:hover {
      background-color: #ec971f;
      border: 1px solid #ec971f; }
    .btn.btn-warning:active {
      color: #fff !important; }
  .btn.btn-outline-primary {
    border: 1px solid #127cd8; }
    .btn.btn-outline-primary:focus, .btn.btn-outline-primary:hover {
      border: 1px solid #127cd8; }
  .btn.btn-outline-secondary {
    border: 1px solid #5f5f5f; }
    .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary:hover {
      border: 1px solid #d2d2d2;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      color: #616161;
      background: #f8f8fa;
      background: linear-gradient(to bottom, #ffffff 0%, #f8f8fa 100%);
      border: 1px solid #464646; }
      .btn.btn-outline-secondary:focus:focus, .btn.btn-outline-secondary:focus:hover, .btn.btn-outline-secondary:hover:focus, .btn.btn-outline-secondary:hover:hover {
        background: #e5e8ee;
        background: linear-gradient(to bottom, #ffffff 0%, #e5e8ee 100%); }
      .btn.btn-outline-secondary:focus:focus, .btn.btn-outline-secondary:hover:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.4); }
      .btn.btn-outline-secondary:focus:not(:disabled):not(.disabled):active, .btn.btn-outline-secondary:hover:not(:disabled):not(.disabled):active {
        background: #dce0e8;
        background: linear-gradient(to bottom, #f7f7f7 0%, #dce0e8 100%);
        color: #7a7a7a !important;
        background-color: #dce0e8 !important;
        border-color: #dfe3ea; }
  .btn.btn-outline-success {
    border: 1px solid #22b66e; }
    .btn.btn-outline-success:focus, .btn.btn-outline-success:hover {
      border: 1px solid #22b66e; }
  .btn.btn-outline-info {
    border: 1px solid #5bc0de; }
    .btn.btn-outline-info:focus, .btn.btn-outline-info:hover {
      border: 1px solid #5bc0de; }
  .btn.btn-outline-warning {
    border: 1px solid #f0ad4e; }
    .btn.btn-outline-warning:focus, .btn.btn-outline-warning:hover {
      border: 1px solid #f0ad4e; }
  .btn.btn-outline-danger {
    border: 1px solid #e74c4b; }
    .btn.btn-outline-danger:focus, .btn.btn-outline-danger:hover {
      border: 1px solid #e74c4b; }

/* ALERT STYLES
   ----------------------------- */
.alert {
  border: 0; }
  .alert.alert-primary {
    color: rgba(255, 255, 255, 0.85);
    background-color: #127cd8; }
    .alert.alert-primary .close {
      color: rgba(255, 255, 255, 0.85);
      opacity: 1;
      text-shadow: 0px 1px 0px #0f69b7; }
    .alert.alert-primary a {
      color: rgba(255, 255, 255, 0.85);
      text-decoration: underline; }
  .alert.alert-info {
    color: rgba(255, 255, 255, 0.85);
    background-color: #162c50; }
    .alert.alert-info .close {
      color: rgba(255, 255, 255, 0.85);
      opacity: 1;
      text-shadow: 0px 1px 0px #0e1d34; }
    .alert.alert-info a {
      color: rgba(255, 255, 255, 0.85);
      text-decoration: underline; }
  .alert.alert-warning {
    color: #fff;
    background-color: #ffb400; }
    .alert.alert-warning .close {
      color: #fff;
      opacity: 1;
      text-shadow: 0px 1px 0px #db9b00; }
    .alert.alert-warning a {
      color: #fff;
      text-decoration: underline; }
  .alert.alert-success {
    color: rgba(255, 255, 255, 0.85);
    background-color: #22b66e; }
    .alert.alert-success .close {
      color: rgba(255, 255, 255, 0.85);
      opacity: 1;
      text-shadow: 0px 1px 0px #1c985c; }
    .alert.alert-success a {
      color: rgba(255, 255, 255, 0.85);
      text-decoration: underline; }
  .alert.alert-danger {
    color: rgba(255, 255, 255, 0.85);
    background-color: #e74c4b; }
    .alert.alert-danger .close {
      color: rgba(255, 255, 255, 0.85);
      opacity: 1;
      text-shadow: 0px 1px 0px #e32d2c; }
    .alert.alert-danger a {
      color: rgba(255, 255, 255, 0.85);
      text-decoration: underline; }
  .alert .close {
    top: 0; }

.page-level-alert {
  margin-bottom: 0;
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99; }

/* CARD STYLES
   ----------------------------- */
.card {
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border: 0;
  margin-bottom: 20px; }
  .card hr {
    margin-left: -16px;
    margin-right: -16px;
    border-color: #ebeef2; }

.card-header,
.card-footer {
  color: #85939d;
  padding: 16px 16px;
  border-color: transparent;
  position: relative;
  font-size: 15px;
  background-color: transparent; }

.card-header {
  padding-bottom: 0; }

.card-body {
  padding: 16px 16px; }

.card-footer {
  border-color: #dcdcdc; }

.badge {
  border-radius: 3px;
  line-height: inherit; }

/* CODE STYLES
   ----------------------------- */
pre {
  background-color: #FFFFFF;
  overflow-x: scroll;
  padding: 0;
  padding: 3px;
  white-space: pre-wrap;
  max-height: 250px;
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #EEF1F6;
  border-radius: 4px; }

code {
  color: #127cd8;
  background-color: #a5d1f8; }

/* SWITCH STYLES
   ----------------------------- */
.switch {
  padding-right: 0;
  cursor: pointer; }

.switch-input {
  opacity: 0;
  position: absolute; }
  .switch-input + .switch-label {
    position: relative;
    display: inline-block; }
    .switch-input + .switch-label:before {
      content: ' ';
      border-radius: 50px;
      display: inline-block;
      background-color: #a9a9a9;
      width: 40px;
      height: 24px;
      transition: background 0.2s ease; }
    .switch-input + .switch-label:after {
      content: ' ';
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 48px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      transition: left 0.2s ease; }
  .switch-input:checked + .switch-label:before {
    background-color: #127cd8; }
  .switch-input:checked + .switch-label:after {
    left: 18px;
    background-color: #fff; }

.switch-lg .switch-input + .switch-label:before {
  width: 50px;
  height: 34px; }

.switch-lg .switch-input + .switch-label:after {
  width: 30px;
  height: 30px; }

.switch-lg .switch-input:checked + .switch-label:after {
  left: 18px; }

.switch.switch-primary > .switch-input:checked + .switch-label:before {
  background-color: #127cd8; }

.switch.switch-success > .switch-input:checked + .switch-label:before {
  background-color: #22b66e; }

.switch.switch-danger > .switch-input:checked + .switch-label:before {
  background-color: #e74c4b; }

.switch.switch-info > .switch-input:checked + .switch-label:before {
  background-color: #337ab7; }

.switch.switch-warning > .switch-input:checked + .switch-label:before {
  background-color: #ffb400; }

/* TAB STYLES
   ----------------------------- */
.tabs-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  margin-bottom: 20px; }

.nav-tabs {
  background-color: #f5f6f7;
  border-bottom: 0;
  border-bottom: 1px solid #ebeef0; }
  .nav-tabs > li {
    border-right: 1px solid #ebeef0; }
    .nav-tabs > li > a {
      border-radius: 0;
      color: #707070;
      padding: 15px 20px;
      font-weight: normal;
      margin-right: 0;
      cursor: pointer;
      border: 1px solid transparent !important; }
      .nav-tabs > li > a:hover {
        background-color: #fafbfc;
        color: #0078bd;
        border-bottom: 1px solid #ebeef0 !important; }
    .nav-tabs > li.active > a {
      color: #1a1a1a;
      background-color: #ffffff;
      border-bottom: 1px solid white !important; }
      .nav-tabs > li.active > a:hover {
        border-bottom: 1px solid transparent !important; }
  .nav-tabs.tabs-justified {
    width: 100%; }
    .nav-tabs.tabs-justified > li {
      display: table-cell;
      width: 1%;
      float: none; }
      .nav-tabs.tabs-justified > li > a {
        text-align: center; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 0px; }
  .tabs-left > .nav-tabs li {
    float: none;
    border-right: 0; }
    .tabs-left > .nav-tabs li a {
      margin-right: 0;
      min-width: 74px;
      border-radius: 4px 0 0 4px;
      margin-right: -1px;
      -webkit-border-top-left-radius: 3px;
      -webkit-border-bottom-left-radius: 3px;
      -moz-border-radius-topleft: 3px;
      -moz-border-radius-bottomleft: 3px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    .tabs-left > .nav-tabs li.active a {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05); }

.tabs-left > .tab-content {
  overflow: auto;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  overflow: hidden; }

/*custom tab*/
.nav.customtab {
  background-color: transparent; }
  .nav.customtab > li {
    border-color: transparent; }
    .nav.customtab > li a,
    .nav.customtab > li a:hover,
    .nav.customtab > li a:focus {
      border: 0px;
      background: transparent; }
    .nav.customtab > li.active a,
    .nav.customtab > li.active a:hover,
    .nav.customtab > li.active a:focus {
      background: transparent !important;
      border: 0px !important;
      border-bottom: 2px solid #458ab6 !important;
      margin-bottom: -1px;
      color: #458ab6; }

.tab-content.customtab {
  background-color: transparent; }

/* LABEL STYLES
   ----------------------------- */
.label-primary {
  background-color: #127cd8 !important; }

.label-success {
  background-color: #22b66e !important; }

.label-warning {
  background-color: #ffb400 !important; }

.label-info {
  background-color: #337ab7 !important; }

.label-danger {
  background-color: #e74c4b !important; }

/* MODAL STYLES
   ----------------------------- */
.modal .modal-header {
  border-bottom: 0; }

.modal .modal-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 0; }

.modal .modal-footer {
  border-top: 0; }

.modal .modal-content {
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 16px; }

.modal.fade .modal-dialog {
  opacity: 0;
  transform: translate(0, -7%) scale(0.9);
  transition: all .25s ease; }

.modal.fade.show .modal-dialog {
  opacity: 1;
  transform: translate(0, 0%) scale(1); }

.modal-backdrop {
  background-color: #162c50; }

/* LOADER STYLES
   ----------------------------- */
.loader {
  width: 50px;
  height: 50px;
  text-indent: -9999em;
  margin: 0 auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top left; }
  .loader.small {
    width: 25px;
    height: 25px; }

/* NOITIFY STYLES
   ----------------------------- */
.notify-wrap {
  position: relative; }

.notify {
  position: absolute;
  top: -4px;
  right: -4px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  opacity: .8;
  z-index: 9;
  background-color: #e74c4b; }
  .notify .notify-ring {
    position: absolute;
    top: -6px;
    right: -6px;
    height: 22px;
    width: 22px;
    border: 3px solid #e74c4b;
    border-radius: 100%;
    -webkit-animation: notify-pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.0; }
  .notify.notify-danger {
    background-color: #e74c4b; }
    .notify.notify-danger .notify-ring {
      border-color: #e74c4b; }
  .notify.notify-info {
    background-color: #337ab7; }
    .notify.notify-info .notify-ring {
      border-color: #337ab7; }
  .notify.notify-success {
    background-color: #22b66e; }
    .notify.notify-success .notify-ring {
      border-color: #22b66e; }
  .notify.notify-warning {
    background-color: #ffb400; }
    .notify.notify-warning .notify-ring {
      border-color: #ffb400; }

/* TIMELINE STYLES
   ----------------------------- */
ul.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto; }
  ul.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    margin-left: 22.5px;
    background-color: #ebebf1; }
  ul.timeline:after {
    content: ' ';
    display: block;
    width: 15px;
    height: 15px;
    background-color: #ebebf1;
    position: absolute;
    top: -8px;
    left: 17px;
    border-radius: 100px; }
  ul.timeline > li {
    position: relative;
    margin-bottom: 20px; }
    ul.timeline > li .timeline-badge {
      z-index: 100;
      position: absolute;
      top: 16px;
      width: 50px;
      height: 50px;
      border-radius: 50% 50% 50% 50%;
      text-align: center;
      font-size: 1.4em;
      line-height: 50px;
      color: #fff;
      overflow: hidden;
      background-color: #4c5667; }
      ul.timeline > li .timeline-badge.success {
        background-color: #22b66e !important; }
      ul.timeline > li .timeline-badge.info {
        background-color: #337ab7 !important; }
      ul.timeline > li .timeline-badge.danger {
        background-color: #e74c4b !important; }
      ul.timeline > li .timeline-badge.warning {
        background-color: #ffb400 !important; }
    ul.timeline > li .timeline-content {
      padding: 20px;
      border: 2px solid #ebebf1;
      border-radius: 4px;
      margin-left: 70px;
      position: relative; }
      ul.timeline > li .timeline-content:before {
        content: " ";
        display: inline-block;
        position: absolute;
        top: 29px;
        left: -10px;
        border-top: 8px solid transparent;
        border-right: 8px solid #ebebf1;
        border-bottom: 8px solid transparent;
        right: auto; }
      ul.timeline > li .timeline-content:after {
        content: " ";
        display: inline-block;
        position: absolute;
        top: 30px;
        left: -7px;
        border-top: 7px solid transparent;
        border-right: 7px solid #fff;
        border-bottom: 7px solid transparent;
        right: auto; }

/* PROGRESS BAR STYLES
   ----------------------------- */
.progress {
  height: 10px;
  border-radius: 100px;
  box-shadow: none;
  background-color: #f5f5f5; }
  .progress .progress-bar {
    box-shadow: none;
    line-height: 10px;
    background-color: #127cd8; }
    .progress .progress-bar:last-child {
      border-radius: 0 20px 20px 0; }
  .progress.progress-stacked .progress-bar {
    border-radius: 0; }
    .progress.progress-stacked .progress-bar:last-child {
      border-radius: 0 100px 100px 0; }
  .progress.progress-sm {
    height: 5px !important; }
    .progress.progress-sm .progress-bar {
      line-height: 5px; }
  .progress.progress-md {
    height: 24px !important; }
    .progress.progress-md .progress-bar {
      line-height: 24px; }
  .progress.progress-lg {
    height: 32px !important; }
    .progress.progress-lg .progress-bar {
      line-height: 32px; }

.progress-bar-primary {
  background-color: #127cd8; }

.progress-bar-success {
  background-color: #22b66e; }

.progress-bar-info {
  background-color: #337ab7; }

.progress-bar-warning {
  background-color: #ffb400; }

.progress-bar-danger {
  background-color: #e74c4b; }

/* PAGE NOTIFICATION STYLES
   ----------------------------- */
.pg-notif-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99; }

.PageLoaderWrap {
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
  opacity: 1;
  transition: 0.3s ease all;
  transition-delay: 0.3s; }
  .PageLoaderWrap .PageLoader {
    height: 100%;
    background: #127cd8;
    width: 0%;
    transition: 0.3s ease all; }

/* PAGINATION STYLES
   ----------------------------- */
.pagination > li > a {
  border: 1px solid #d5dce6;
  color: #333c48;
  background: #f8f8fa;
  background: linear-gradient(to bottom, #ffffff 0%, #f8f8fa 100%); }
  .pagination > li > a:focus, .pagination > li > a:hover {
    background: #e5e8ef;
    background: linear-gradient(to bottom, #ffffff 0%, #e5e8ef 100%); }

.pagination .page-item.active .page-link {
  background: #127cd8;
  border-color: #127cd8; }

.pager li a {
  border: 1px solid #d2d2d2;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  color: #616161;
  background: #f8f8fa;
  background: linear-gradient(to bottom, #ffffff 0%, #f8f8fa 100%); }
  .pager li a:focus, .pager li a:hover {
    background: #e5e8ee;
    background: linear-gradient(to bottom, #ffffff 0%, #e5e8ee 100%); }
  .pager li a:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.4); }
  .pager li a:not(:disabled):not(.disabled):active {
    background: #dce0e8;
    background: linear-gradient(to bottom, #f7f7f7 0%, #dce0e8 100%);
    color: #7a7a7a !important;
    background-color: #dce0e8 !important;
    border-color: #dfe3ea; }

.perks-card {
  background: #FCFCFC;
  border: 1px solid #EEEEEE;
  box-shadow: 5px 5px 5px #EEEAE6;
  cursor: pointer; }
  .perks-card-image {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    aspect-ratio: 16/10; }

.perks-modal-image {
  max-width: 150px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  object-fit: contain;
  aspect-ratio: 1; }

/* BREADCRUMBS STYLES
   ----------------------------- */
.breadcrumb {
  background: none;
  padding: 0;
  font-size: 14px; }
  .breadcrumb a {
    color: #4a4a4a; }
    .breadcrumb a i {
      font-size: 11px; }
  .breadcrumb .breadcrumb-item.active {
    color: #777; }
    .breadcrumb .breadcrumb-item.active a {
      color: #777; }
  .breadcrumb > li + li:before {
    content: "\F054" !important;
    color: #ccc !important;
    font-family: 'FontAwesome';
    font-size: 10px; }

/* FORM STYLES
   ----------------------------- */
label {
  color: #7f8fa4;
  padding-right: 1rem;
  font-weight: 400; }

.form-control {
  outline: none;
  line-height: 1.5em;
  padding-left: .8rem;
  padding-right: .8rem;
  background: #fff;
  border: 1px solid #d5dce6;
  box-shadow: inset 0 2px 0 0 #f2f5f8;
  border-radius: 4px; }

.input-group-addon {
  background-color: #f7f7f7;
  border-color: #e3e3e3; }

.input-group-btn + .form-control {
  border-left: 0; }

.has-success .control-label {
  color: #22b66e; }

.has-success .form-control {
  border-color: #22b66e; }

.has-warning .control-label {
  color: #ffb400; }

.has-warning .form-control {
  border-color: #ffb400; }

.has-error .control-label {
  color: #e74c4b; }

.has-error .form-control {
  border-color: #e74c4b; }

/* SLIDER STYLES
   ----------------------------- */
.noUi-handle {
  border-radius: 999px;
  box-shadow: none; }
  .noUi-handle:before {
    content: none; }
  .noUi-handle:after {
    content: none; }

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  left: -15px;
  border: 1px solid #dbdbdb;
  top: -7px;
  transition: all 0.2s ease; }
  .noUi-horizontal .noUi-handle:hover {
    border: 1px solid #c0c0c0; }
  .noUi-horizontal .noUi-handle:active {
    transform: scale(1.1); }

.noUi-origin {
  border-radius: 0px;
  bottom: 5px; }

.noUi-base {
  background: #eee;
  border-radius: 10px; }

.noUi-horizontal {
  height: 4px; }

.noUi-connect {
  background: #127cd8;
  border-radius: 10px;
  box-shadow: none;
  transition: background 450ms; }

.noUi-tooltip {
  border: 0;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  padding: 5px 8px;
  font-size: 13px; }

/* CHECKBOX STYLES
   ----------------------------- */
.checkbox {
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  padding-left: 22px;
  font-size: 13px;
  transition: border 0.1s linear 0s,color 0.1s linear 0s;
  white-space: nowrap; }
  .checkbox input[type=checkbox] {
    margin-left: -20px;
    margin-right: 6px; }
    .checkbox input[type=checkbox]:before {
      content: "";
      cursor: pointer;
      display: inline-block;
      width: 17px;
      height: 17px;
      position: absolute;
      left: -2px;
      background-color: #ffffff;
      border: 1px solid #d0d0d0;
      top: -3px;
      border-radius: 3px;
      transition: border 0.06s linear 0s,color 0.06s linear 0s; }
    .checkbox input[type=checkbox]:after {
      font-family: 'FontAwesome';
      content: "\F00C";
      color: #ffffff;
      cursor: pointer;
      border-radius: 3px;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 1.2px;
      top: 0px;
      font-size: 11px;
      transition: border 0.06s linear 0s,color 0.06s linear 0s; }
    .checkbox input[type=checkbox]:checked:before {
      border-width: 8.5px;
      border-color: #127cd8; }
    .checkbox input[type=checkbox]:focus:before {
      outline: -webkit-focus-ring-color auto 5px; }
  .checkbox.check-primary input[type=checkbox]:checked:before {
    border-color: #127cd8; }
  .checkbox.check-success input[type=checkbox]:checked:before {
    border-color: #22b66e; }
  .checkbox.check-danger input[type=checkbox]:checked:before {
    border-color: #e74c4b; }
  .checkbox.check-info input[type=checkbox]:checked:before {
    border-color: #337ab7; }
  .checkbox.check-warning input[type=checkbox]:checked:before {
    border-color: #ffb400; }

table.table .checkbox {
  display: inline; }

.input-group-addon .checkbox {
  min-height: 18px;
  margin-right: -18px;
  padding-left: 20px;
  padding-top: 4px; }

.has-error .checkbox,
.has-error .checkbox-inline {
  color: #e74c4b; }
  .has-error .checkbox input[type=checkbox]:before,
  .has-error .checkbox-inline input[type=checkbox]:before {
    border-color: #e74c4b; }

.dropdown-menu {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform-origin: top right;
  box-shadow: none; }
  .dropdown-menu a:hover, .dropdown-menu a:active, .dropdown-menu button:hover, .dropdown-menu button:active {
    background-color: rgba(18, 124, 216, 0.05) !important; }
  .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
    color: rgba(0, 0, 0, 0.87); }

.dropdown-menu {
  opacity: 0;
  transform: scale(0.8);
  backface-visibility: hidden;
  pointer-events: none; }

.dropdown-menu.show {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
  display: block !important; }

.dropdown-toggle:focus {
  outline: -webkit-focus-ring-color auto 5px; }

.show .btn-secondary.dropdown-toggle {
  color: #7a7a7a; }

/* NAV PILLS STYLES
   ----------------------------- */
.nav-pills > li > a:hover, .nav-pills > li > a:focus {
  background-color: rgba(18, 124, 216, 0.1); }

.nav-item .logout-button {
  text-decoration: none;
  width: 100%;
  padding-left: 20px;
  text-align: left; }

.skip-to-content {
  position: absolute;
  width: 1px;
  height: 1px;
  left: calc(50% - 70px);
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  background: #323d50;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  transform: translateY(-100%);
  transition: .2s ease transform; }
  .skip-to-content:focus {
    transform: translateY(0%);
    z-index: 999;
    width: 180px;
    height: auto;
    clip: auto;
    padding: 10px; }

/* SUBNAV STYLES
   ----------------------------- */
.page-sub-nav {
  border-bottom: 1px solid rgba(179, 179, 179, 0.5);
  padding: 0px 15px;
  border-top: 1px solid rgba(230, 230, 230, 0.5);
  margin: -80px 32px 32px 32px;
  margin-bottom: 80px 32px 32px 32px;
  background-color: rgba(255, 255, 255, 0.5); }
  .page-sub-nav > ul > li {
    margin-left: 0; }
    .page-sub-nav > ul > li > .nav-link {
      color: #8e9096;
      padding: 15px;
      border-radius: 0;
      border-bottom: 2px solid;
      border-color: transparent;
      transition: .2s ease all; }
      .page-sub-nav > ul > li > .nav-link:focus, .page-sub-nav > ul > li > .nav-link:hover {
        color: #434448;
        background-color: transparent; }
    .page-sub-nav > ul > li > .nav-link.active {
      color: #127cd8;
      background-color: transparent;
      border-color: #127cd8; }
      .page-sub-nav > ul > li > .nav-link.active:focus, .page-sub-nav > ul > li > .nav-link.active:hover {
        color: #127cd8;
        background-color: transparent; }
  .page-sub-nav .open > a {
    background-color: transparent !important;
    border-color: transparent !important; }
  .page-sub-nav .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid transparent; }

/* TOP NAVIGATION STYLES
   ----------------------------- */
.app-header {
  height: 50px;
  background: white;
  display: flex;
  position: relative;
  z-index: 80;
  width: 100%; }
  @media (min-width: 768px) {
    .app-header .app-header-collapse {
      display: inline-block !important; } }
  .app-header .app-header-collapse-nav {
    float: right; }
  .app-header .top-nav {
    padding: 0 0px;
    width: 100%;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.03), 0 1px 8px rgba(0, 21, 41, 0.12); }
    .app-header .top-nav > nav {
      padding: 5px 10px;
      padding-top: 0;
      height: 100%; }
      .app-header .top-nav > nav > li > a {
        background: transparent !important;
        color: #909090;
        padding: 15px 12px; }
        .app-header .top-nav > nav > li > a i {
          font-size: 16px; }
  .app-header .page-heading {
    font-size: 1.3rem;
    min-width: 270px; }
  .app-header .page-indicators {
    font-size: 0.8rem;
    min-width: 420px;
    float: right;
    padding-top: 0px; }
    .app-header .page-indicators-active {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #5fbc39; }
    .app-header .page-indicators-inactive {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #f3292c; }
    .app-header .page-indicators div {
      float: right;
      display: inline-block;
      font-size: 12px;
      padding-left: 10px;
      margin-top: 3px; }
      .app-header .page-indicators div label {
        padding-right: 5px;
        margin: 0;
        margin-top: 1px; }
  .app-header .navbar-right {
    margin-right: 0;
    float: none; }
  .app-header i {
    position: relative; }
  .app-header .sidebar-toggle {
    display: none; }
    .app-header .sidebar-toggle .fa {
      transform: rotate(0deg); }
    .app-header .sidebar-toggle.is-collapsed .fa {
      transform: rotate(180deg); }

.dropdown-notification {
  min-width: 250px;
  padding: 0; }
  .dropdown-notification .notification-menu-header {
    padding: 10px 15px;
    border-bottom: 1px solid #eee; }
  .dropdown-notification .notifications-list {
    list-style: none;
    padding: 0;
    margin: 0; }
  .dropdown-notification .notification-link {
    padding: 10px 15px; }
  .dropdown-notification .notification-menu-footer {
    padding: 0;
    border-top: 1px solid #eee; }
    .dropdown-notification .notification-menu-footer a {
      text-align: center;
      padding: 15px 15px; }
      .dropdown-notification .notification-menu-footer a:hover, .dropdown-notification .notification-menu-footer a:active {
        background-color: rgba(18, 124, 216, 0.05) !important; }

.mobile-menu-link {
  display: none;
  color: white;
  font-size: 24px;
  line-height: 50px;
  padding: 0 20px; }
  .mobile-menu-link:active, .mobile-menu-link:focus, .mobile-menu-link:hover {
    color: white;
    opacity: 0.8; }

.navbar-light .navbar-toggler {
  border-color: transparent; }

.fixed-top-nav .app-header {
  position: fixed;
  top: 0;
  width: 100%; }

@media screen and (min-width: 992px) {
  .app-header .site-logo-bar {
    position: fixed; } }

@media screen and (max-width: 992px) {
  .app-header {
    display: block;
    height: inherit;
    position: fixed;
    top: 0; }
    .app-header .site-logo-bar {
      width: 100%;
      display: none; }
      .app-header .site-logo-bar .navbar-brand {
        width: 100%;
        text-align: center; }
    .app-header .sidebar-toggle {
      display: block; }
      .app-header .sidebar-toggle i {
        transform: rotate(180deg); }
      .app-header .sidebar-toggle.is-collapsed i {
        transform: rotate(0deg); }
  .mobile-menu-link {
    display: block;
    position: absolute;
    left: 0; } }

@media screen and (max-width: 768px) {
  .navbar-nav {
    padding-top: 20px; } }

.app-sidebar {
  position: fixed;
  z-index: 10;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 240px;
  display: flex;
  flex-direction: column; }
  .app-sidebar.has-alert {
    top: 48px; }
  .app-sidebar > nav {
    flex: 1 1; }
    .app-sidebar > nav::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px; }

.site-logo-bar {
  overflow: hidden; }
  .site-logo-bar .navbar-brand {
    width: 100%;
    padding: 0 20px;
    line-height: 48px;
    color: white;
    font-weight: bold;
    margin-right: 0; }
    .keyboardActive .site-logo-bar .navbar-brand:focus {
      outline: none;
      box-shadow: inset 0px 0px 0px 2px #1a97ff !important; }
    .site-logo-bar .navbar-brand > img {
      max-height: 30px;
      max-width: 30px;
      display: inline-block; }
    .site-logo-bar .navbar-brand .logo-text {
      margin-left: 8px;
      opacity: 1;
      vertical-align: middle; }
      .site-logo-bar .navbar-brand .logo-text img {
        max-height: 40px;
        max-width: 50px; }

@media screen and (min-width: 992px) {
  .side-menu-collapsed .site-logo-bar .navbar-brand {
    padding: 0 10px; }
    .side-menu-collapsed .site-logo-bar .navbar-brand .logo-text {
      opacity: 0; } }

#main-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%; }
  #main-menu .nav-item {
    position: relative; }
    #main-menu .nav-item.active > a {
      border-color: #1a97ff; }
    #main-menu .nav-item.has-submenu ul {
      list-style: none;
      padding: 0;
      height: 0;
      overflow: hidden;
      background: #0b1628; }
      #main-menu .nav-item.has-submenu ul .nav-item {
        background: #0b1628; }
        .keyboardActive #main-menu .nav-item.has-submenu ul .nav-item:focus a, #main-menu .nav-item.has-submenu ul .nav-item:hover a {
          background: #091220; }
        #main-menu .nav-item.has-submenu ul .nav-item a {
          padding-left: 45px; }
          #main-menu .nav-item.has-submenu ul .nav-item a.active {
            background: #091220; }
          .keyboardActive #main-menu .nav-item.has-submenu ul .nav-item a:focus {
            outline: none;
            box-shadow: inset 0px 0px 0px 2px #1a97ff; }
    #main-menu .nav-item.has-submenu.open ul {
      height: auto; }
    #main-menu .nav-item.submenu.open .menu-expand-icon {
      transform: rotate(90deg); }
    #main-menu .nav-item > a {
      display: flex;
      width: 100%;
      text-decoration: none;
      padding: 14px 20px;
      border-color: transparent; }
      #main-menu .nav-item > a.active, #main-menu .nav-item > a:hover {
        color: #fff; }
      #main-menu .nav-item > a:focus {
        outline: none; }
      .keyboardActive #main-menu .nav-item > a:focus {
        box-shadow: inset 0px 0px 0px 2px #1a97ff !important; }
      #main-menu .nav-item > a.active {
        box-shadow: inset 2px 0px #1a97ff; }
    #main-menu .nav-item .nav-item-label {
      opacity: 1;
      vertical-align: middle; }
    #main-menu .nav-item .badge {
      opacity: 1;
      vertical-align: middle;
      margin-left: auto; }
    #main-menu .nav-item.nav-item-spacer {
      flex: 1 1; }
    #main-menu .nav-item .side-nav-icon {
      margin-right: 10px;
      vertical-align: middle;
      position: relative;
      top: 0px;
      left: 0px;
      width: 1.25em; }
  #main-menu li.separator {
    border-top: 1px solid #365a96;
    margin: 10px 20px; }
  #main-menu .menu-expand-icon {
    margin-right: 0;
    line-height: 20px;
    font-size: 11px;
    position: absolute;
    right: 20px;
    top: 16px;
    transform: rotate(0deg); }
  #main-menu .badge {
    background: #1a97ff; }

@media screen and (min-width: 992px) {
  .app:not(.side-menu-collapsed) .app-sidebar > nav {
    flex: 1 1;
    overflow-y: auto;
    height: 100%; }
  .side-menu-collapsed .app-sidebar {
    width: 50px; }
    .side-menu-collapsed .app-sidebar .site-logo {
      padding: 5px; }
      .side-menu-collapsed .app-sidebar .site-logo img {
        width: 100%; }
  .side-menu-collapsed ul#main-menu li.nav-item {
    position: relative;
    width: 50px; }
    .side-menu-collapsed ul#main-menu li.nav-item a {
      text-align: center; }
      .side-menu-collapsed ul#main-menu li.nav-item a i {
        padding-right: 0px;
        left: 3px; }
      .side-menu-collapsed ul#main-menu li.nav-item a .side-nav-icon {
        margin-right: 0px;
        margin-left: 3px; }
      .side-menu-collapsed ul#main-menu li.nav-item a .badge,
      .side-menu-collapsed ul#main-menu li.nav-item a .menu-expand-icon {
        display: none; }
    .side-menu-collapsed ul#main-menu li.nav-item.has-submenu > .nav-submenu {
      margin-left: -20px;
      height: auto;
      display: none;
      z-index: 999; }
      .side-menu-collapsed ul#main-menu li.nav-item.has-submenu > .nav-submenu li.nav-item {
        width: 240px; }
        .side-menu-collapsed ul#main-menu li.nav-item.has-submenu > .nav-submenu li.nav-item .nav-item-label {
          opacity: 1;
          display: block; }
        .side-menu-collapsed ul#main-menu li.nav-item.has-submenu > .nav-submenu li.nav-item .side-nav-icon {
          display: none; }
        .side-menu-collapsed ul#main-menu li.nav-item.has-submenu > .nav-submenu li.nav-item a {
          padding-left: 20px;
          border-color: transparent;
          text-align: left; }
    .keyboardActive .side-menu-collapsed ul#main-menu li.nav-item.has-submenu:focus .nav-submenu, .side-menu-collapsed ul#main-menu li.nav-item.has-submenu:hover .nav-submenu {
      position: absolute;
      top: 0;
      left: 50px;
      margin-left: 0;
      display: inline;
      max-height: 1000px; }
  .side-menu-collapsed ul#main-menu li.separator {
    margin: 10px 15px; } }

@media screen and (max-width: 992px) {
  .app-sidebar {
    transform: translate(-100%);
    transition: all 0.3s ease; }
  .sidebar-overlay {
    background: rgba(33, 53, 86, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99; }
  .side-menu-collapsed {
    height: 100vh; }
    .side-menu-collapsed .app-sidebar {
      transform: translate(0%);
      z-index: 999; }
      .side-menu-collapsed .app-sidebar > nav {
        overflow-y: auto; } }

/* SLIDE PANEL STYLES
   ----------------------------- */
.slide-panel {
  position: fixed;
  right: -370px;
  top: 0;
  width: 370px;
  background: #fff;
  bottom: 0;
  z-index: 1000;
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-perspective: 1000; }
  .slide-panel.open {
    transform: translate3d(-100%, 0, 0); }

.slide-panel-header {
  padding: 20px;
  border-bottom: 1px solid #eee; }

.close-slide-panel {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  color: #aaa; }
  .close-slide-panel:hover {
    color: #2b2b2b; }

@media screen and (max-width: 992px) {
  .slide-panel {
    width: 100%;
    right: -100%; } }

/* SIDE PANEL STYLES
   ----------------------------- */
.sidebar-panel {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border: 0;
  border-radius: 4px;
  overflow: hidden;
  background: white;
  height: 100%;
  display: flex; }
  .sidebar-panel .side-content {
    background: #fbfcfd;
    width: 500px;
    padding: 16px;
    border-right: 1px solid #ebebf1;
    height: 100%;
    box-sizing: border-box;
    display: block; }
  .sidebar-panel .main-content {
    padding: 16px;
    width: 100%; }

.sidebar-panel-mobile-toggle {
  display: none;
  background-color: #fbfcfd;
  width: 100%;
  text-align: center;
  padding: 20px; }
  .sidebar-panel-mobile-toggle:hover, .sidebar-panel-mobile-toggle:focus {
    background-color: #eaeff5;
    text-decoration: none;
    outline: 0 !important; }

@media screen and (min-width: 992px) {
  .side-content {
    display: block !important; } }

@media screen and (max-width: 992px) {
  .sidebar-panel {
    display: block; }
  .sidebar-panel-mobile-toggle {
    display: block; }
  .sidebar-panel .side-content {
    width: 100%;
    height: auto;
    display: none; } }

.tooltip-inner {
  border-radius: 4px; }

.well {
  background-color: #f2f5f9;
  border: 0;
  box-shadow: none; }

/* PAGE STYLES
   ----------------------------- */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

#page-content {
  width: 100%;
  min-width: 100%;
  position: relative; }
  #page-content.has-alert {
    margin-top: 48px; }

#primary-content {
  padding: 80px 32px 32px 32px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }

.page-container {
  width: 100%;
  margin: auto;
  max-width: 1170px; }

.container {
  width: 100%;
  max-width: 1000px;
  padding-left: 0;
  padding-right: 0; }

.app-body {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1; }

.content-wrap {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08); }

.full-bleed-content {
  margin: -80px 32px 32px 32px; }

.narrow-page-container {
  max-width: 1000px;
  margin: auto; }

/* FOOTER STYLES
   ----------------------------- */
/* LOGIN STYLES
   ----------------------------- */
.login-page-wrapper {
  position: relative;
  min-height: 100%;
  z-index: 1; }

.login-wrap {
  width: auto;
  max-width: 400px;
  display: block;
  margin-top: 30px;
  margin: auto; }

/* CHAT STYLES
   ----------------------------- */
.ChatBoxContainer {
  position: fixed;
  bottom: 0;
  right: 40px;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end; }

.Chat-wrap {
  border-top: 1px solid #eee; }

.ChatBox {
  width: 300px;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.2);
  background: white;
  margin-left: 16px; }

.ChatHeader {
  padding: 10px 16px;
  border-radius: 2px 2px 0 0;
  cursor: pointer; }

.ChatDisplay {
  border-color: #eee; }

.ChatSubText {
  font-size: 10px; }

.CloseChat {
  border: 0;
  background: transparent;
  color: #333;
  border-radius: 50px;
  float: right;
  cursor: pointer; }
  .CloseChat:hover {
    background: #eee; }

/* UTILITY STYLES
   ----------------------------- */
.clear {
  display: block;
  overflow: hidden; }

.full-bleed {
  margin: -16px; }

/* SPACER UTILITY STYLES
   ----------------------------- */
.m-a-none {
  margin: 0 !important; }

.m-a-xxs {
  margin: 1px; }

.m-a-xs {
  margin: 4px; }

.m-a-sm {
  margin: 8px; }

.m-a {
  margin: 16px; }

.m-a-md {
  margin: 24px; }

.m-a-lg {
  margin: 32px; }

.m-a-xl {
  margin: 40px; }

.m-a-xxl {
  margin: 48px; }

.m-a-n-xxs {
  margin: -1px; }

.m-a-n-xs {
  margin: -4px; }

.m-a-n-sm {
  margin: -8px; }

.m-a-n {
  margin: -16px; }

.m-a-n-md {
  margin: -24px; }

.m-a-n-lg {
  margin: -32px; }

.m-a-n-xl {
  margin: -40px; }

.m-a-n-xxl {
  margin: -48px; }

.m-t-none {
  margin-top: 0 !important; }

.m-t-xxs {
  margin-top: 1px; }

.m-t-xs {
  margin-top: 4px; }

.m-t-sm {
  margin-top: 8px; }

.m-t {
  margin-top: 16px; }

.m-t-md {
  margin-top: 24px; }

.m-t-lg {
  margin-top: 32px; }

.m-t-xl {
  margin-top: 40px; }

.m-t-xxl {
  margin-top: 48px; }

.m-t-n-xxs {
  margin-top: -1px; }

.m-t-n-xs {
  margin-top: -4px; }

.m-t-n-sm {
  margin-top: -8px; }

.m-t-n {
  margin-top: -16px; }

.m-t-n-md {
  margin-top: -24px; }

.m-t-n-lg {
  margin-top: -32px; }

.m-t-n-xl {
  margin-top: -40px; }

.m-t-n-xxl {
  margin-top: -48px; }

.m-r-none {
  margin-right: 0 !important; }

.m-r-xxs {
  margin-right: 1px; }

.m-r-xs {
  margin-right: 4px; }

.m-r-sm {
  margin-right: 8px; }

.m-r {
  margin-right: 16px; }

.m-r-md {
  margin-right: 24px; }

.m-r-lg {
  margin-right: 32px; }

.m-r-xl {
  margin-right: 40px; }

.m-r-xxl {
  margin-right: 48px; }

.m-r-n-xxs {
  margin-right: -1px; }

.m-r-n-xs {
  margin-right: -4px; }

.m-r-n-sm {
  margin-right: -8px; }

.m-r-n {
  margin-right: -16px; }

.m-r-n-md {
  margin-right: -24px; }

.m-r-n-lg {
  margin-right: -32px; }

.m-r-n-xl {
  margin-right: -40px; }

.m-r-n-xxl {
  margin-right: -48px; }

.m-b-none {
  margin-bottom: 0 !important; }

.m-b-xxs {
  margin-bottom: 1px; }

.m-b-xs {
  margin-bottom: 4px; }

.m-b-sm {
  margin-bottom: 8px; }

.m-b {
  margin-bottom: 16px; }

.m-b-md {
  margin-bottom: 24px; }

.m-b-lg {
  margin-bottom: 32px; }

.m-b-xl {
  margin-bottom: 40px; }

.m-b-xxl {
  margin-bottom: 48px; }

.m-b-n-xxs {
  margin-bottom: -1px; }

.m-b-n-xs {
  margin-bottom: -4px; }

.m-b-n-sm {
  margin-bottom: -8px; }

.m-b-n {
  margin-bottom: -16px; }

.m-b-n-md {
  margin-bottom: -24px; }

.m-b-n-lg {
  margin-bottom: -32px; }

.m-b-n-xl {
  margin-bottom: -40px; }

.m-b-n-xxl {
  margin-bottom: -48px; }

.m-l-none {
  margin-left: 0 !important; }

.m-l-xxs {
  margin-left: 1px; }

.m-l-xs {
  margin-left: 4px; }

.m-l-sm {
  margin-left: 8px; }

.m-l {
  margin-left: 16px; }

.m-l-md {
  margin-left: 24px; }

.m-l-lg {
  margin-left: 32px; }

.m-l-xl {
  margin-left: 40px; }

.m-l-xxl {
  margin-left: 48px; }

.m-l-n-xxs {
  margin-left: -1px; }

.m-l-n-xs {
  margin-left: -4px; }

.m-l-n-sm {
  margin-left: -8px; }

.m-l-n {
  margin-left: -16px; }

.m-l-n-md {
  margin-left: -24px; }

.m-l-n-lg {
  margin-left: -32px; }

.m-l-n-xl {
  margin-left: -40px; }

.m-l-n-xxl {
  margin-left: -48px; }

.p-a-none {
  padding: 0 !important; }

.p-a-xxs {
  padding: 1px; }

.p-a-xs {
  padding: 4px; }

.p-a-sm {
  padding: 8px; }

.p-a {
  padding: 16px; }

.p-a-md {
  padding: 24px; }

.p-a-lg {
  padding: 32px; }

.p-a-xl {
  padding: 40px; }

.p-a-xxl {
  padding: 48px; }

.p-a-n-xxs {
  padding: -1px; }

.p-a-n-xs {
  padding: -4px; }

.p-a-n-sm {
  padding: -8px; }

.p-a-n {
  padding: -16px; }

.p-a-n-md {
  padding: -24px; }

.p-a-n-lg {
  padding: -32px; }

.p-a-n-xl {
  padding: -40px; }

.p-a-n-xxl {
  padding: -48px; }

.p-t-none {
  padding-top: 0 !important; }

.p-t-xxs {
  padding-top: 1px; }

.p-t-xs {
  padding-top: 4px; }

.p-t-sm {
  padding-top: 8px; }

.p-t {
  padding-top: 16px; }

.p-t-md {
  padding-top: 24px; }

.p-t-lg {
  padding-top: 32px; }

.p-t-xl {
  padding-top: 40px; }

.p-t-xxl {
  padding-top: 48px; }

.p-t-n-xxs {
  padding-top: -1px; }

.p-t-n-xs {
  padding-top: -4px; }

.p-t-n-sm {
  padding-top: -8px; }

.p-t-n {
  padding-top: -16px; }

.p-t-n-md {
  padding-top: -24px; }

.p-t-n-lg {
  padding-top: -32px; }

.p-t-n-xl {
  padding-top: -40px; }

.p-t-n-xxl {
  padding-top: -48px; }

.p-r-none {
  padding-right: 0 !important; }

.p-r-xxs {
  padding-right: 1px; }

.p-r-xs {
  padding-right: 4px; }

.p-r-sm {
  padding-right: 8px; }

.p-r {
  padding-right: 16px; }

.p-r-md {
  padding-right: 24px; }

.p-r-lg {
  padding-right: 32px; }

.p-r-xl {
  padding-right: 40px; }

.p-r-xxl {
  padding-right: 48px; }

.p-r-n-xxs {
  padding-right: -1px; }

.p-r-n-xs {
  padding-right: -4px; }

.p-r-n-sm {
  padding-right: -8px; }

.p-r-n {
  padding-right: -16px; }

.p-r-n-md {
  padding-right: -24px; }

.p-r-n-lg {
  padding-right: -32px; }

.p-r-n-xl {
  padding-right: -40px; }

.p-r-n-xxl {
  padding-right: -48px; }

.p-b-none {
  padding-bottom: 0 !important; }

.p-b-xxs {
  padding-bottom: 1px; }

.p-b-xs {
  padding-bottom: 4px; }

.p-b-sm {
  padding-bottom: 8px; }

.p-b {
  padding-bottom: 16px; }

.p-b-md {
  padding-bottom: 24px; }

.p-b-lg {
  padding-bottom: 32px; }

.p-b-xl {
  padding-bottom: 40px; }

.p-b-xxl {
  padding-bottom: 48px; }

.p-b-n-xxs {
  padding-bottom: -1px; }

.p-b-n-xs {
  padding-bottom: -4px; }

.p-b-n-sm {
  padding-bottom: -8px; }

.p-b-n {
  padding-bottom: -16px; }

.p-b-n-md {
  padding-bottom: -24px; }

.p-b-n-lg {
  padding-bottom: -32px; }

.p-b-n-xl {
  padding-bottom: -40px; }

.p-b-n-xxl {
  padding-bottom: -48px; }

.p-l-none {
  padding-left: 0 !important; }

.p-l-xxs {
  padding-left: 1px; }

.p-l-xs {
  padding-left: 4px; }

.p-l-sm {
  padding-left: 8px; }

.p-l {
  padding-left: 16px; }

.p-l-md {
  padding-left: 24px; }

.p-l-lg {
  padding-left: 32px; }

.p-l-xl {
  padding-left: 40px; }

.p-l-xxl {
  padding-left: 48px; }

.p-l-n-xxs {
  padding-left: -1px; }

.p-l-n-xs {
  padding-left: -4px; }

.p-l-n-sm {
  padding-left: -8px; }

.p-l-n {
  padding-left: -16px; }

.p-l-n-md {
  padding-left: -24px; }

.p-l-n-lg {
  padding-left: -32px; }

.p-l-n-xl {
  padding-left: -40px; }

.p-l-n-xxl {
  padding-left: -48px; }

/* WIDTH UTILITY STYLES
   ----------------------------- */
.w-full {
  width: 100%; }

.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block; }

.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block; }

.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block; }

.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center; }

.w-32 {
  width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center; }

.w-40 {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center; }

.w-48 {
  width: 48px;
  height: 48px;
  display: inline-block;
  text-align: center; }

.w-56 {
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center; }

.w-100 {
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center; }

/* DISPLAY UTILITY STYLES
   ----------------------------- */
.block {
  display: block !important; }

.inline-block {
  display: inline-block !important; }

.inline {
  display: inline !important; }

.display-flex {
  display: flex; }

/* TYPOGRAPHY UTILITY STYLES
   ----------------------------- */
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.line-through {
  text-decoration: line-through; }

.bold {
  font-weight: bold; }

.all-caps {
  text-transform: uppercase; }

.hint-text {
  opacity: .7; }

.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* TEXT COLOR STYLES
   ----------------------------- */
.text-inherit {
  color: inherit; }

.text-black {
  color: black; }

.text-white {
  color: white; }

/* IMAGE UTILITY STYLES
   ----------------------------- */
img.img-circle {
  border-radius: 50%; }

/* CURSOR STYLES
   ----------------------------- */
.cursor-pointer {
  cursor: pointer; }

/* BACKGROUND UTILITY STYLES
   ----------------------------- */
.bg-white {
  background: #fff !important; }

/* BODER UTILITY STYLES
   ----------------------------- */
.no-border {
  border-color: transparent;
  border-width: 0; }

.no-borders {
  border-width: 0 !important; }

.b {
  border-color: #d5dce6;
  background-clip: padding-box; }

.b-a {
  border: 1px solid #d5dce6; }

.b-t {
  border-top: 1px solid #d5dce6; }

.b-r {
  border-right: 1px solid #d5dce6; }

.b-b {
  border-bottom: 1px solid #d5dce6; }

.b-l {
  border-left: 1px solid #d5dce6; }

/* BORDER RADIUS STYLES
   ----------------------------- */
.b-radius {
  border-radius: 4px;
  overflow: hidden; }

.b-circle {
  border-radius: 500px;
  overflow: hidden; }

.analytics-row {
  padding-bottom: 3px; }

@charset "UTF-8";
/* HEADER STYLES
   ----------------------------- */
.userDropdown-wrapper {
  display: inline-block;
  margin-right: 15px;
  margin-left: 10px; }

.userDropdown-company {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 48px;
  margin-bottom: 0px; }

.userDropdown-name {
  font-size: 14px;
  text-transform: capitalize;
  line-height: 32px;
  margin-bottom: 0px; }

.userDropdown-role {
  font-size: 10px;
  text-transform: capitalize;
  line-height: 4px;
  text-align: right;
  margin-bottom: 0px; }

/* BODY STYLES
   ----------------------------- */
.company-tab-change-button {
  width: 10%;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: 300px;
  margin-right: 5px; }
  .company-tab-change-button-active {
    background: #00aeff;
    background-color: #00aeff !important; }

.centered-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) !important; }

.status-indicators-active {
  width: 14px;
  height: 14px;
  margin-top: 10px;
  border-radius: 50%;
  background-color: #5fbc39; }

.status-indicators-inactive {
  width: 14px;
  height: 14px;
  margin-top: 10px;
  border-radius: 50%;
  background-color: #f3292c; }

.status-indicators-yellow {
  width: 14px;
  height: 14px;
  margin-top: 10px;
  border-radius: 50%;
  background-color: #Ffdd44; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3292c;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s; }

input:checked + .slider {
  background-color: #5fbc39; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  transform: translateX(16px); }

input:disabled + .slider {
  background-color: #989ba3; }

/* Rounded sliders */
.slider.round {
  border-radius: 24px; }

.slider.round:before {
  border-radius: 50%; }

/*
.custom-checkbox input {
  position: relative;
  z-index: -9999;
}

.custom-checkbox span {
  width: 25px;
  height: 25px;
  display: block;
  background: #d2d2d2;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  border-width: 2px;
}

.custom-checkbox input:checked + span {
  background: #5ebc39;
}  */
/* Customize the label (the container) */
.custom-checkbox {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #eee;
  border-radius: 5px;
  border: 2px solid #ccc; }

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #eee; }

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #5ebc39; }

.custom-checkbox input:disabled ~ .checkmark {
  background-color: #ccc; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: block;
  opacity: 0; }

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
  opacity: 1; }

.custom-checkbox input:disabled ~ .checkmark:after {
  display: block;
  opacity: 0.7; }

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg); }

.roles-select-row {
  margin-bottom: 15px; }

.roles-select-input > input:checked:after {
  background-color: #5fbc39; }

.btn-link:hover {
  text-decoration: none; }

.btn-link:focus {
  text-decoration: none; }

.btn-link:active {
  text-decoration: none; }

.rw-input[disabled] {
  background-color: #e9ecef; }

video {
  background-color: transparent; }

.has-submenu .has-submenu-hover {
  opacity: 1; }
  .has-submenu .has-submenu-hover:hover {
    opacity: 0.85; }

.drag-order-toggle {
  padding: 0px;
  width: 30px;
  height: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 0; }

.text-read-more {
  font-size: 6px;
  font-weight: 600;
  float: right;
  position: relative;
  right: 0;
  padding-left: 10px;
  padding-right: 10px; }
  .text-read-more:hover {
    text-decoration: none; }
  .text-read-more-wrapper {
    padding-top: 10px;
    position: relative; }
  .text-read-more-text {
    padding-left: 15px;
    padding-right: 15px;
    text-align: justify;
    line-height: 16px; }
    .text-read-more-text-hidden {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      max-height: 48px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      padding-left: 15px;
      padding-right: 15px;
      text-align: justify; }

.disabled {
  cursor: default !important; }

.display-inline {
  display: inline; }

.row-disabled {
  color: #7f8fa4; }

.rs-table-cell {
  background: transparent; }

.rs-table-cell-content {
  white-space: normal;
  word-wrap: break-word;
  min-width: min-content; }
  .rs-table-cell-content .btn {
    padding: 0;
    font-size: 14px;
    border: 0;
    white-space: nowrap;
    vertical-align: inherit; }

.company-dashboard-campaigns-table-toggle {
  position: absolute;
  right: 17px;
  bottom: 41px;
  z-index: 999;
  width: 25px;
  height: 25px;
  padding: 0px;
  padding-bottom: 8px;
  font-weight: bold;
  padding-top: 0px;
  text-align: center; }

.rs-table-row-header {
  text-align: center; }
  .rs-table-row-header .rs-table-cell-content div {
    word-break: break-word; }
  .rs-table-row-header .rs-table-cell {
    padding: 2px 0px !important; }
  .rs-table-row-header .rs-table-column-group-cell .rs-table-cell {
    padding: 0 !important; }

.rs-table-cell-header-sort-wrapper {
  display: inline; }
  .rs-table-cell-header-sort-wrapper .rs-table-cell-header-icon-sort:after {
    content: ''; }
  .rs-table-cell-header-sort-wrapper .rs-table-cell-header-icon-sort-asc:after {
    content: '\23F6'; }
  .rs-table-cell-header-sort-wrapper .rs-table-cell-header-icon-sort-desc:after {
    content: '\23F7'; }

.rs-table-column-group-header {
  border-right: 1px solid #eee;
  border-left: 1px solid #eee; }

.rs-table-row-header .rs-table-cell {
  border-left: 1px solid #eee; }

.rs-table-body-row-wrapper {
  overflow: unset; }

.draggable-container {
  height: 700px;
  position: relative;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  overflow: hidden; }
  .draggable-container .resize-handle {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    border: 1px solid;
    line-height: 0.7;
    background: #FFF;
    z-index: 900;
    pointer-events: auto; }
  .draggable-container .handle {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid;
    line-height: 0.7;
    background: #FFF;
    z-index: 900;
    pointer-events: auto; }

.draggable-element {
  display: inline-block;
  position: absolute;
  z-index: 100; }
  .draggable-element-image {
    background-size: cover;
    width: 100%;
    height: 100%; }

.draggable-active {
  border: 2px solid red;
  pointer-events: none; }

.draggable-not-active {
  border: 2px solid #000; }

.draggable-edit-border-bottom {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd; }

.draggable-edit-active {
  border: 1px dotted; }

.draggable-edit-button {
  width: 100%;
  font-size: 12px;
  padding-left: 2px;
  padding-right: 2px; }
  .draggable-edit-button-wrapper {
    padding-left: 5px;
    padding-right: 5px; }

.draggable-edit-color-button {
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  top: 7px; }

.react-tooltip-custom-class {
  max-width: 250px;
  z-index: 1500 !important; }
  .react-tooltip-custom-class-company-payment {
    max-width: 350px; }

.react-tooltip-company-error {
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: inherit;
  min-width: 80px;
  z-index: 1500 !important;
  padding: 1px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  top: 2px !important; }

.mandatory {
  color: red;
  display: inline-block; }

.text-center {
  text-align: center; }

.text-bold {
  font-weight: 700; }

.modal-max-height-page {
  max-height: 93vh;
  overflow-x: hidden; }

.pointer {
  cursor: pointer !important; }

.body-notification-close {
  float: right;
  background: transparent;
  color: #FFF;
  border: 0;
  padding-top: 0;
  box-shadow: none;
  cursor: pointer; }

.card .body {
  /*  &-notification {

      &-close {
        float: right;
        background: transparent;
        color: #FFF;
        border: 0;
        padding-top: 0;
        box-shadow: none;
        cursor: pointer;
      }
    } */ }
  .card .body-title {
    display: inline-block;
    margin-top: 7px; }
  .card .body-add-button {
    margin-bottom: 10px;
    margin-left: 10px; }
  .card .body-filters {
    margin-bottom: 10px; }

.card .filter-button {
  float: right;
  margin-left: 15px; }

.card .filter-button-active {
  color: #fff;
  background-color: #6c757d;
  background: #6c757d;
  border-color: #6c757d; }
  .card .filter-button-active:focus {
    color: #fff;
    background-color: #6c757d;
    background: #6c757d;
    border-color: #6c757d; }
  .card .filter-button-active:active {
    color: #fff;
    background-color: #6c757d;
    background: #6c757d;
    border-color: #6c757d; }
  .card .filter-button-active:hover {
    color: #fff;
    background-color: #6c757d;
    background: #6c757d;
    border-color: #6c757d; }

.card .admin-table th {
  padding: 10px 5px;
  font-size: 11px;
  cursor: default;
  min-width: 70px; }

.card .admin-table td {
  padding: 10px 5px;
  font-size: 11px;
  cursor: default;
  min-width: 70px; }

.card .admin-table button {
  font-size: 13px; }

.card .admin-table-chevron {
  width: 20px;
  height: 20px; }

.card .admin-table-name {
  font-size: 14px;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 0px; }

.card .admin-table-role {
  font-size: 10px;
  text-transform: capitalize;
  text-align: right;
  margin-bottom: 0px; }

.card .admin-table-new-user {
  background: #edea98 !important; }

.card .admin-table-unverified-user {
  background: #f6de7b !important; }

.card .admin-table-read-more {
  font-size: 6px;
  font-weight: 600;
  float: right;
  position: relative;
  right: 0;
  padding-left: 10px;
  padding-right: 10px; }
  .card .admin-table-read-more:hover {
    text-decoration: none; }
  .card .admin-table-read-more-wrapper {
    padding-top: 10px;
    position: relative; }
  .card .admin-table-read-more-text {
    padding-left: 15px;
    padding-right: 15px;
    text-align: justify;
    line-height: 16px; }
    .card .admin-table-read-more-text-hidden {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      max-height: 32px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-left: 15px;
      padding-right: 15px;
      text-align: justify; }

.card .admin-table-company-api {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto; }
  .card .admin-table-company-api-active {
    background: green; }

.card .admin-table-company-owner {
  font-weight: 500; }
  .card .admin-table-company-owner td {
    padding: 10px 5px;
    font-size: 12px; }

.card .admin-table-company-member {
  font-weight: 500; }
  .card .admin-table-company-member-odd {
    background-color: #f6f7fb !important; }
  .card .admin-table-company-member-even {
    background-color: #fff !important; }

.card .admin-table .uid-column {
  font-size: 10px; }

.card .admin-table .website-column {
  position: relative;
  min-width: 100px; }
  .card .admin-table .website-column-input {
    position: absolute; }
    .card .admin-table .website-column-input-wrapper {
      position: relative; }
  .card .admin-table .website-column-save {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-top: 9px; }
  .card .admin-table .website-column-close {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-top: 9px; }
  .card .admin-table .website-column-open {
    width: 100%;
    position: absolute;
    height: 100%;
    padding: 0;
    top: 0;
    left: 0; }
  .card .admin-table .website-column-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0; }
    .card .admin-table .website-column-icon-check {
      color: #2FE31A; }
    .card .admin-table .website-column-icon-x {
      color: red; }

.card .admin-table-more-button {
  display: inline-block;
  margin-right: 15px;
  margin-left: 15px;
  min-width: 100px;
  margin-top: 20px; }

.card .content-management-text-area {
  height: 450px; }

.card .editable-column {
  position: relative;
  min-width: 150px; }
  .card .editable-column-input {
    position: absolute; }
    .card .editable-column-input-wrapper {
      position: relative; }
  .card .editable-column-save {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-top: 9px; }
  .card .editable-column-close {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-top: 9px; }
  .card .editable-column-open {
    width: 100%;
    position: absolute;
    height: 100%;
    padding: 0;
    top: 0;
    left: 0; }
  .card .editable-column-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0; }
    .card .editable-column-icon-check {
      color: #2FE31A; }
    .card .editable-column-icon-x {
      color: red; }

.content-management-video {
  padding: 0;
  top: 10vh; }
  .content-management-video .modal-content {
    padding: 0;
    background: transparent; }
  .content-management-video .modal-header {
    display: none; }
  .content-management-video .modal-body {
    padding: 0; }
  .content-management-video .modal-footer {
    display: none; }

.public-DraftStyleDefault-block {
  margin: 0;
  margin-bottom: 1em; }

.currency-input input {
  display: inline-block; }

.currency-input-sign {
  position: absolute; }

.currency-input .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell; }

.currency-input .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-left: -1px;
  position: relative;
  border-radius: 0; }

.currency-input .input-group-btn-vertical > .btn:first-child {
  border-top-right-radius: 4px; }

.currency-input .input-group-btn-vertical > .btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 4px; }

.custom-input-placeholder {
  position: absolute;
  margin-top: 7px;
  margin-left: 15px;
  pointer-events: none; }

.custom-primary-button {
  width: 100%;
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9; }

.custom-modal-button {
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9; }

.percetage-input {
  border: 1px solid #DDD;
  width: 100%;
  padding: 5px; }
  .percetage-input input {
    border: 0;
    box-shadow: none;
    display: inline-block;
    width: 90%;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 4px;
    padding-right: 4px;
    text-align: right;
    height: auto; }
  .percetage-input .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell; }
  .percetage-input .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin-left: -1px;
    position: relative;
    border-radius: 0; }
  .percetage-input .input-group-btn-vertical > .btn:first-child {
    border-top-right-radius: 4px; }
  .percetage-input .input-group-btn-vertical > .btn:last-child {
    margin-top: -2px;
    border-bottom-right-radius: 4px; }

/*
.spinner {
//  width: 100px;
}

.spinner input {
  text-align: right;
}

.input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.spinner input {
  padding-right: 13px;
}

.spinner .input-group-btn-vertical::before {
  content: "%";
  position: absolute;
  right: 0px;
  top: 7px;
}  */
.live-metrics .recharts-legend-item-text {
  font-size: 11px;
  white-space: nowrap; }

.live-metrics .recharts-bar-rectangle {
  width: 5px;
  max-width: 5px; }
  .live-metrics .recharts-bar-rectangle path {
    width: 5px;
    max-width: 5px; }

.live-metrics .recharts-label-list {
  font-size: 10px;
  margin-bottom: -5px;
  position: relative;
  top: -2px; }
  .live-metrics .recharts-label-list text {
    line-height: 20px; }

.live-metrics-export-button {
  background: #00C116;
  background-color: #00C116;
  float: right;
  color: #000;
  font-size: 9px;
  z-index: 999;
  position: relative;
  right: -25px;
  bottom: -5px;
  max-width: 20%;
  padding: 3px; }
  .live-metrics-export-button:focus {
    background: #00C116;
    background-color: #00C116;
    color: #000; }
  .live-metrics-export-button:active {
    background: #00C116;
    background-color: #00C116;
    color: #000; }
  .live-metrics-export-button:hover {
    background: #00C116;
    background-color: #00C116;
    color: #000; }

.live-metrics-card {
  padding-bottom: 0px; }

.live-metrics .recharts-surface {
  overflow: visible; }

.live-metrics-circle {
  width: 60%;
  margin-right: 20%;
  margin-left: 20%; }
  .live-metrics-circle-lenght .CircularProgressbar-text {
    font-size: 0.9vw; }

.live-metrics-filters {
  padding-bottom: 20px; }
  .live-metrics-filters .date-picker input {
    width: 100%; }

.live-metrics .live-metric {
  display: block;
  text-align: center;
  padding-right: 0; }
  .live-metrics .live-metric-circle-long .CircularProgressbar-text {
    font-size: 0.9vw; }

.campaign-details {
  max-height: calc(100vh - 245px);
  overflow-y: scroll;
  overflow-x: hidden; }
  .campaign-details-section {
    background-color: #f2f5f9;
    margin-bottom: 15px;
    padding: 30px; }
  .campaign-details-footer {
    width: 100%; }
    .campaign-details-footer .campaign-details-button {
      margin-top: 15px;
      float: right;
      margin-left: 15px;
      margin-right: 10px; }

.company-payments-disabled-button {
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
  opacity: 0.65; }

.company-payments-highlighted-table-default {
  padding: 8px;
  height: 100%; }

.new-voucher-confirmations-recommended {
  float: right;
  margin-bottom: 2px;
  margin-top: 5px; }
  .new-voucher-confirmations-recommended-text {
    padding-right: 5px;
    color: #7f8fa4; }

.new-voucher-confirmations-terms {
  float: right;
  margin-bottom: 10px;
  margin-top: 2px; }
  .new-voucher-confirmations-terms-text {
    padding-right: 5px;
    color: #7f8fa4; }

.new-voucher-left {
  margin-top: 20px;
  margin-bottom: 20px; }
  .new-voucher-left-row {
    margin-bottom: 15px; }
    .new-voucher-left-row-first {
      height: 45px; }
  .new-voucher-left-input {
    display: inline-block;
    width: calc(100% - 36px);
    box-shadow: none;
    font-size: 14px;
    border-radius: 0 4px 4px 0;
    border-left: 0;
    height: 34px;
    border-width: 1px;
    position: relative;
    top: 0px;
    align-self: flex-end; }
    .new-voucher-left-input::placeholder {
      opacity: 0.6; }
    .new-voucher-left-input-fullwidth {
      box-shadow: none;
      font-size: 14px;
      align-self: flex-end; }
      .new-voucher-left-input-fullwidth::placeholder {
        opacity: 0.6; }
    .new-voucher-left-input-col {
      display: flex; }
    .new-voucher-left-input-sign {
      display: inline-block;
      background: #6FC0F9;
      color: #FFF;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid transparent;
      border-right: 0;
      position: relative;
      top: 0px;
      align-self: flex-end; }
      .new-voucher-left-input-sign-sign {
        display: inline-block;
        color: #FFF;
        font-size: 16px;
        width: 34px;
        height: 34px;
        text-align: center;
        padding-top: 4px; }

.new-voucher-right {
  margin-top: 20px; }
  .new-voucher-right-row {
    margin-bottom: 15px; }
    .new-voucher-right-row-first {
      height: 45px;
      margin-bottom: 43px; }
    .new-voucher-right-row-second {
      height: 45px;
      margin-bottom: 47px; }
    .new-voucher-right-row > .col-sm-3 {
      padding-right: 0px;
      padding-left: 5px; }
  .new-voucher-right-input {
    display: inline-block;
    width: calc(100% - 36px);
    min-width: 64px;
    box-shadow: none;
    font-size: 14px;
    align-self: flex-end;
    border-radius: 0 4px 4px 0;
    border-left: 0;
    height: 34px;
    border-width: 1px;
    position: relative;
    top: 0px; }
    .new-voucher-right-input::placeholder {
      opacity: 0.6; }
    .new-voucher-right-input-start-date {
      box-shadow: none;
      font-size: 14px;
      align-self: flex-start;
      width: 100%;
      min-width: 100px; }
      .new-voucher-right-input-start-date::placeholder {
        opacity: 0.6; }
    .new-voucher-right-input-fullwidth {
      box-shadow: none;
      font-size: 14px;
      align-self: flex-start;
      width: 100%;
      min-width: 100px; }
      .new-voucher-right-input-fullwidth::placeholder {
        opacity: 0.6; }
    .new-voucher-right-input-col {
      font-size: 14px;
      display: flex;
      margin-bottom: 5px; }
    .new-voucher-right-input-sign {
      display: inline-block;
      background: #6FC0F9;
      color: #FFF;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 4px 0px 0px 4px;
      align-self: flex-end;
      border: 1px solid transparent;
      border-right: 0;
      position: relative;
      top: 0px; }
      .new-voucher-right-input-sign-sign {
        display: inline-block;
        color: #FFF;
        font-size: 16px;
        width: 34px;
        height: 34px;
        text-align: center;
        padding-top: 4px; }

.new-voucher-seperator {
  padding-top: 30px;
  padding-bottom: 40px; }
  .new-voucher-seperator-line {
    width: 2px;
    border: 1px solid #DDD;
    margin-left: auto;
    margin-right: auto;
    height: 100%; }

.new-voucher-label {
  font-size: 12px;
  text-align: left;
  padding: 0;
  margin: 0; }

.new-voucher-info-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
  margin-left: 3px;
  background: #6FC0F9;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 700;
  color: #FFF;
  text-align: center; }

.voucher-preview {
  background-color: #D3E3F2;
  width: 270px;
  height: 560px;
  color: #637D96;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 0px; }
  .voucher-preview-modal {
    width: 270px;
    border-radius: 4px; }
    .voucher-preview-modal .modal-content {
      padding: 0;
      width: 270px;
      box-shadow: none;
      background: transparent; }
  .voucher-preview-title {
    color: #03c2fc;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding-top: 10px; }
  .voucher-preview-app-top {
    position: relative;
    height: 50px;
    max-height: 50px;
    width: 270px;
    border-bottom: 1px solid #B3C3D2;
    margin: 0;
    margin-bottom: 8px; }
    .voucher-preview-app-top-logo {
      position: relative;
      height: 24px;
      margin-top: -7px;
      width: auto;
      top: 0;
      line-height: 50px; }
      .voucher-preview-app-top-logo-wrapper {
        position: relative;
        height: 50px;
        max-height: 50px;
        width: 35%;
        background: #03c2fc;
        color: #FFF;
        display: inline-block;
        top: 0;
        border-radius: 0 0 4px 0;
        padding: 0;
        padding-left: 15px; }
      .voucher-preview-app-top-logo-text {
        display: inline-block;
        line-height: 50px;
        font-weight: 700; }
    .voucher-preview-app-top-middle {
      position: relative;
      width: 20%;
      height: 50px;
      max-height: 50px;
      font-weight: 900;
      padding: 0;
      display: inline-block;
      padding-bottom: 8px; }
      .voucher-preview-app-top-middle-icon {
        padding-left: 12px;
        height: 17px;
        margin-top: 13px; }
        .voucher-preview-app-top-middle-icon img {
          height: 17px; }
    .voucher-preview-app-top-right {
      position: relative;
      width: 45%;
      height: 50px;
      max-height: 50px;
      display: inline-block; }
      .voucher-preview-app-top-right-icon {
        height: 18px;
        margin-top: 13px;
        display: inline-block;
        padding-left: 10px;
        float: right; }
        .voucher-preview-app-top-right-icon img {
          height: 18px;
          display: inline-block; }
  .voucher-preview-card {
    min-width: 270px;
    max-width: 270px;
    width: 270px;
    margin-right: 35px; }
  .voucher-preview-top {
    position: relative;
    height: 160px; }
    .voucher-preview-top-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 15px 15px 0px 0px; }
  .voucher-preview-header {
    position: relative;
    top: -40px;
    height: 50px;
    border-radius: 15px;
    background-color: #FFF; }
    .voucher-preview-header-logo {
      object-fit: cover;
      width: 40px;
      height: 40px;
      margin: 5px;
      display: inline-block;
      border-radius: 8px; }
    .voucher-preview-header-text {
      display: inline-block;
      height: 50px;
      position: absolute;
      width: calc(100% - 70px); }
    .voucher-preview-header-name {
      padding-top: 8px;
      font-size: 14px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .voucher-preview-header-website {
      font-size: 10px;
      color: #03c2fc;
      text-decoration: underline; }
  .voucher-preview-body {
    padding-top: 8px;
    position: relative;
    top: -40px; }
    .voucher-preview-body-description {
      padding-left: 15px;
      padding-right: 15px;
      text-align: justify;
      line-height: 16px;
      font-size: 12px; }
      .voucher-preview-body-description-hidden {
        overflow: hidden;
        font-size: 13px;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        max-height: 32px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 15px;
        padding-right: 15px;
        text-align: justify; }
    .voucher-preview-body-more {
      margin-left: 70%;
      width: 30%;
      line-height: 16px;
      font-size: 10px;
      font-weight: 700;
      position: relative;
      right: 0;
      padding-left: 15px;
      padding-right: 15px;
      color: #637D96; }
      .voucher-preview-body-more:hover {
        color: #637D96; }
      .voucher-preview-body-more:focus {
        color: #637D96; }
  .voucher-preview-steps {
    position: relative;
    padding-top: 10px; }
    .voucher-preview-steps-1 {
      display: inline-block;
      width: 25%;
      margin-left: 5%;
      margin-right: 6%;
      background-color: #D3E3F2;
      box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
      border-radius: 15px;
      z-index: 10;
      position: relative;
      cursor: pointer;
      z-index: 300; }
    .voucher-preview-steps-2 {
      display: inline-block;
      width: 25%;
      margin-right: 7%;
      background-color: #D3E3F2;
      box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
      border-radius: 15px;
      z-index: 10;
      position: relative;
      cursor: pointer;
      z-index: 500; }
    .voucher-preview-steps-3 {
      display: inline-block;
      width: 25%;
      background-color: #D3E3F2;
      box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
      border-radius: 15px;
      z-index: 10;
      position: relative;
      cursor: pointer;
      z-index: 300; }
    .voucher-preview-steps-active {
      box-shadow: 5px 5px 7px #EEF7FF, -4px -4px 4px #BBCFE2; }
    .voucher-preview-steps-percent {
      font-size: 15px;
      width: 100%;
      font-weight: 700;
      line-height: 18px;
      padding-top: 1px;
      text-align: center; }
    .voucher-preview-steps-name {
      font-size: 13px;
      width: 100%;
      font-weight: 500;
      text-align: center; }
    .voucher-preview-steps-image {
      position: absolute;
      z-index: 400;
      pointer-events: none;
      top: -5px;
      width: 88%;
      margin-left: 14px;
      margin-right: 10%; }
  .voucher-preview-loyalty-text {
    top: -40px;
    position: relative;
    font-weight: 700;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px; }
  .voucher-preview-vouchers {
    margin-top: 25px;
    position: relative;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .voucher-preview-vouchers-left {
      position: relative;
      width: 100%;
      height: 50px;
      border-radius: 15px;
      background-color: #D3E3F2;
      box-shadow: 3px 3px 7px #adbdcc, -3px -3px 7px #f2f9ff;
      padding-top: 12px;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex; }
      .voucher-preview-vouchers-left-1 {
        display: inline-block;
        width: 23%;
        font-size: 16px;
        font-weight: 700;
        align-self: center; }
      .voucher-preview-vouchers-left-2 {
        display: inline-block;
        width: 23%;
        font-size: 9px;
        font-weight: 700;
        align-self: center; }
    .voucher-preview-vouchers-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 46%;
      border-radius: 15px;
      background-color: #03c2fc;
      padding-top: 12px;
      padding-bottom: 10px;
      height: 50px;
      padding-left: 1px;
      padding-right: 1px;
      display: flex; }
      .voucher-preview-vouchers-right-1 {
        display: inline-block;
        width: 49%;
        font-size: 9px;
        font-weight: 700;
        color: white;
        text-align: center;
        align-self: center;
        position: relative;
        left: 7px; }
        .voucher-preview-vouchers-right-1-long {
          display: inline-block;
          width: 49%;
          font-size: 9px;
          font-weight: 700;
          color: white;
          text-align: center;
          align-self: center;
          position: relative;
          left: 7px; }
      .voucher-preview-vouchers-right-2 {
        display: inline-block;
        width: 51%;
        font-size: 16px;
        font-weight: 200;
        color: white;
        text-align: center;
        align-self: center; }
        .voucher-preview-vouchers-right-2-long {
          font-size: 16px;
          text-align: center;
          align-self: center; }
  .voucher-preview-voucher {
    width: 100%;
    height: 55px;
    position: relative;
    margin-top: 4px; }
  .voucher-preview-footer {
    top: -40px;
    position: relative; }

.nav-item-counter {
  float: right;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  text-align: center;
  font-size: 16px;
  padding-top: 1px;
  background-color: #ff6a00;
  color: #FFF; }

.admin-dashboard-data-fields {
  text-align: center; }
  .admin-dashboard-data-fields > .row {
    margin-bottom: 15px; }

.admin-dashboard-setup-assistance {
  /* &-indicator {
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 10px;
      display: block;
    } */ }
  .admin-dashboard-setup-assistance-line {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    position: absolute;
    height: 10px;
    top: 56%;
    background: #D4E9F7;
    z-index: 1; }
  .admin-dashboard-setup-assistance-cicle {
    background: #D4E9F7;
    width: 45%;
    min-width: 60px;
    max-width: 110px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    aspect-ratio: 1;
    z-index: 99; }
    .admin-dashboard-setup-assistance-cicle-complete {
      background: #7DB84E; }
    .admin-dashboard-setup-assistance-cicle-wrapper {
      width: 45%;
      margin-left: auto;
      margin-right: auto; }
    .admin-dashboard-setup-assistance-cicle-check {
      width: 100%;
      aspect-ratio: 1;
      z-index: 99; }
      .admin-dashboard-setup-assistance-cicle-check-icon {
        width: 80%;
        height: 85%;
        margin: 0;
        margin-left: 20%;
        margin-bottom: 15%;
        color: #FFFFFF;
        font-size: 80px;
        font-weight: 900;
        stroke-width: 5;
        stroke-linecap: square;
        stroke-linejoin: miter;
        z-index: 99; }
  .admin-dashboard-setup-assistance-info {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 50%;
    max-height: 100px;
    padding: 0; }
    .admin-dashboard-setup-assistance-info-header {
      margin-bottom: 5px;
      color: #7f8fa4;
      font-size: 28px;
      font-weight: 700;
      text-align: right; }
    .admin-dashboard-setup-assistance-info-text {
      color: #7f8fa4;
      font-size: 12px;
      font-weight: 400;
      max-width: 190px; }
      .admin-dashboard-setup-assistance-info-text-text {
        display: inline-block;
        max-width: 150px;
        text-align: right; }
      .admin-dashboard-setup-assistance-info-text-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
        position: relative;
        top: -4px;
        background: #6FC0F9;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 900;
        color: #FFF;
        text-align: center;
        margin-left: auto;
        margin-right: auto; }
    .admin-dashboard-setup-assistance-info-link {
      color: #7f8fa4;
      font-size: 16px;
      font-weight: 500;
      float: right; }
      .admin-dashboard-setup-assistance-info-link:hover {
        color: #7f8fa4; }
      .admin-dashboard-setup-assistance-info-link:focus {
        color: #7f8fa4; }
      .admin-dashboard-setup-assistance-info-link:active {
        color: #7f8fa4; }
  .admin-dashboard-setup-assistance-card {
    margin-bottom: 15px;
    padding-bottom: 0px;
    padding-top: 25px;
    border: 3px solid #00aeff; }
    .admin-dashboard-setup-assistance-card-body {
      padding: 10px;
      padding-bottom: 0;
      padding-top: 0; }
  .admin-dashboard-setup-assistance-col {
    z-index: 99;
    padding: 0; }
  .admin-dashboard-setup-assistance-label {
    text-align: center;
    width: 100%;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    cursor: pointer; }
    .admin-dashboard-setup-assistance-label-link {
      color: #7f8fa4;
      text-decoration: none; }
      .admin-dashboard-setup-assistance-label-link:hover {
        color: #7f8fa4;
        text-decoration: none; }
      .admin-dashboard-setup-assistance-label-link:focus {
        color: #7f8fa4;
        text-decoration: none; }
      .admin-dashboard-setup-assistance-label-link:active {
        color: #7f8fa4;
        text-decoration: none; }

.admin-dashboard-analytics-text {
  padding-top: 7px; }

.admin-dashboard-notifications {
  box-shadow: 0px 0px 2px 2px #e6e6e6;
  padding-left: 5px;
  padding-right: 5px; }

.admin-dashboard-data-field {
  font-size: 10px;
  height: 100%;
  min-height: 80px;
  box-shadow: 0px 0px 2px 2px #e6e6e6;
  padding: 5px 15px; }
  .admin-dashboard-data-field-h {
    font-size: 11px;
    font-weight: 700; }
  .admin-dashboard-data-field .col {
    padding-left: 1px;
    padding-right: 1px; }
  .admin-dashboard-data-field .col-sm-2 {
    padding-left: 1px;
    padding-right: 1px; }
  .admin-dashboard-data-field .col-sm-3 {
    padding-left: 1px;
    padding-right: 1px; }

.campaign-actions-modal .modal-header h5 {
  font-weight: 700; }

.campaign-actions-modal-body {
  padding-top: 0px; }

.campaign-actions-modal-header {
  padding-bottom: 10px;
  padding-top: 10px; }

.admin-campaigns-table {
  /*
  .rs-table-column-group {
    .rs-table-cell-header {
      .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    }
  } */ }
  .admin-campaigns-table .rs-table-cell-header {
    /*
    .rs-table-column-group {
      .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    } */ }
    .admin-campaigns-table .rs-table-cell-header .rs-table-cell-content {
      display: flex;
      align-items: center;
      padding: 8px; }
  .admin-campaigns-table .rs-table-column-group-header-content {
    padding: 5px; }
  .admin-campaigns-table .rs-table-cell-content {
    padding: 0; }
  .admin-campaigns-table .rs-table-cell-wrap {
    height: 100%; }
  .admin-campaigns-table-highlight {
    background-color: #E3F6FF;
    padding: 8px;
    height: 100%; }
  .admin-campaigns-table-default {
    background-color: #ffffff;
    padding: 8px;
    height: 100%; }

.admin-payments-table .rs-table-cell-header .rs-table-cell-content {
  display: flex;
  padding: 8px; }

.admin-payments-table .rs-table-cell-content {
  padding: 0; }

.admin-payments-table .rs-table-cell-wrap {
  height: 100%; }

.admin-payments-table-highlight {
  background-color: #E3F6FF;
  padding: 8px;
  height: 100%; }

.admin-payments-table-default {
  background-color: #ffffff;
  padding: 8px;
  height: 100%; }

.company-api-button {
  display: inline-block;
  min-width: 100px;
  margin-left: 10px;
  margin-right: 10px; }

.company-api-input {
  display: inline-block;
  width: 50%;
  max-width: 400px;
  min-width: 200px; }

.basic-information-info-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
  margin-left: 3px;
  background: #6FC0F9;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 700;
  color: #FFF;
  text-align: center; }

.basic-information-middle {
  display: inline-block;
  width: 25%; }
  .basic-information-middle-companyType {
    display: inline-block;
    width: 40%;
    padding-bottom: 8px; }
    .basic-information-middle-companyType-button {
      width: 100%;
      margin: 1px; }
      .basic-information-middle-companyType-button-active {
        margin: 0px;
        border: 2px solid #6FC0F9 !important;
        background: transparent !important;
        background-color: transparent !important; }

.basic-information-buttons {
  display: inline-block;
  width: 15%;
  color: #6FC0F9; }
  .basic-information-buttons-password {
    height: 70px;
    display: flex;
    align-items: flex-end; }
    .basic-information-buttons-password .btn {
      vertical-align: bottom; }

.basic-information-upload {
  display: inline-block;
  width: 15%; }
  .basic-information-upload-preview {
    padding-top: 0;
    color: var(--bs-card-color);
    padding-bottom: 0;
    font-size: 14px; }
  .basic-information-upload-buttons {
    display: inline-block;
    width: 15%; }
    .basic-information-upload-buttons .basic-information-upload-button {
      width: 100%;
      height: 30px;
      display: inline-block;
      max-height: 30px;
      margin-left: 3px;
      font-size: 12px;
      float: right;
      border-radius: 0px 4px 4px 0px; }
      .basic-information-upload-buttons .basic-information-upload-button .media {
        width: 18px;
        padding-right: 3px;
        display: inline-block; }
  .basic-information-upload-middle {
    vertical-align: top;
    font-size: 11px;
    display: inline-block;
    width: 25%;
    height: 30px;
    border: 1px solid #d2d2d2;
    border-radius: 4px 0px 0px 4px;
    border-right: 0;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden; }

.basic-information-label {
  width: 35%; }
  .basic-information-label-hasDisplayName {
    padding-left: 4px;
    width: 40%; }

.basic-information-check-button {
  padding-top: 5px;
  padding-bottom: 6px;
  max-height: 37px;
  margin-left: 3px;
  display: inline-block;
  float: right; }

.basic-information-x-button {
  padding-top: 5px;
  padding-bottom: 6px;
  max-height: 37px;
  margin-left: 3px;
  display: inline-block;
  float: right; }

.basic-information-edit-button {
  display: inline-block;
  max-height: 30px;
  padding-top: 0;
  margin-left: 3px;
  padding: 0;
  font-size: 24px;
  float: right;
  color: #6FC0F9; }

.basic-information-placeholder {
  opacity: 1;
  color: #6c757d; }

.basic-information-placeholder-for-image {
  opacity: 1;
  color: #6c757d;
  padding-left: 12px; }

.basic-information-compliance-label {
  width: 45%; }

.basic-information-compliance-right {
  width: 30%; }

.basic-information-row {
  margin-bottom: 5px;
  display: flex; }
  .basic-information-row-compliance {
    width: 50%; }
  .basic-information-row-hasDisplayName {
    display: block; }
  .basic-information-row-companyNumber {
    display: block;
    width: '100%'; }
  .basic-information-row-companyType {
    margin-bottom: 10px; }
    .basic-information-row-companyType-radio {
      padding-left: 15px; }
      .basic-information-row-companyType-radio-wrapper {
        padding-left: 15px;
        display: inline-block;
        width: 100%; }
    .basic-information-row-companyType label {
      margin-left: 5px; }

.basic-information-changes {
  color: #7f8fa4; }
  .basic-information-changes-row {
    margin-bottom: 5px; }
  .basic-information-changes-cancel {
    width: 25px;
    height: 25px;
    font-size: 16px;
    padding: 3px;
    padding-top: 0;
    background-color: red;
    border-radius: 50%;
    font-weight: 700;
    color: #FFF !important;
    cursor: pointer; }
    .basic-information-changes-cancel-button {
      padding: 0;
      text-decoration: none; }

.permissions-levels-table {
  font-size: 11px; }
  .permissions-levels-table .rs-table-cell-header .rs-table-cell-content {
    padding-top: 2px;
    padding-bottom: 2px; }

.permissions-levels-checkbox {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  border-width: 2px; }
  .permissions-levels-checkbox:checked {
    background-color: green; }

.permissions-levels-create-highlighted {
  font-weight: bold; }

.permissions-levels-create-padded {
  padding-left: 35px; }

.common-link {
  text-decoration: none; }

/*
@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

.box2 {
  margin: max(3vw);
  border: 0.35rem solid;

  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
} */
.box {
  box-sizing: border-box;
  width: 240px;
  height: 60px;
  background-color: #ff6a00;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 5px 0 #A3A3A3; }

.box::before {
  content: '';
  width: 500px;
  height: 50px;
  opacity: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 100%);
  transform-origin: center center;
  position: absolute;
  z-index: 10;
  animation-delay: 0s;
  animation-name: mask-ani;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.box::after {
  content: '';
  width: 500px;
  height: 50px;
  opacity: 0;
  background-color: #fff;
  transform-origin: center center;
  position: absolute;
  z-index: 1;
  animation-delay: 0s;
  animation-name: mask-border-ani;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.box a {
  width: calc(100% - 8px) !important;
  height: calc(100% - 8px) !important;
  vertical-align: top;
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

@keyframes mask-ani {
  0% {
    transform: rotate(-36deg) translate(-40px, -430px); }
  70%, 100% {
    transform: rotate(-36deg) translate(-40px, 378px); } }

@keyframes mask-border-ani {
  0% {
    opacity: 0.8;
    transform: rotate(-36deg) translate(-126px, -190px); }
  70%, 100% {
    opacity: 0.8;
    transform: rotate(-36deg) translate(-126px, 355px); } }

/*
$anime-time: 8s;

$box-size: 200px;
$clip-distance: .05;
$clip-size: $box-size * (1 + $clip-distance * 2);
$path-width: 2px;

$main-color: #69ca62;

// $codepen-logo-path: url('//blog.codepen.io/wp-content/uploads/2012/06/Button-White-Large.png');

%full-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinning-border {
  @extend %full-fill;
 // width: $box-size;
//  height: $box-size;
//  margin: auto;
  //  background: $codepen-logo-path no-repeat 50% / 70% rgba(#000, .1);
  color: $main-color;
  box-shadow: inset 0 0 0 1px rgba($main-color, .5);

  &::before,
  &::after {
    @extend %full-fill;
    content: '';
    z-index: -1;
    margin: -1 * $clip-distance * 100%;
    box-shadow: inset 0 0 0 $path-width;
    animation: clipMe $anime-time linear infinite;
  }

  &::before {
    animation-delay: $anime-time * -.5;
  }
  // for debug
  &:hover {
    &::after,
    &::before {
      background-color: rgba(#f00, .3);
    }
  }
}

@keyframes clipMe {
  0%, 100% {
    clip: rect(0px, $clip-size, $path-width, 0px);
  }

  25% {
    clip: rect(0px, $path-width, $clip-size, 0px);
  }

  50% {
    clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px);
  }

  75% {
    clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width);
  }
} */
.btn-link {
  text-decoration: none; }

.btn.btn-secondary {
  background: #FFF;
  box-shadow: none; }
  .btn.btn-secondary:hover {
    background: #FFF;
    box-shadow: none; }
  .btn.btn-secondary:focus {
    background: #FFF;
    box-shadow: none; }

.btn.btn-primary {
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9; }
  .btn.btn-primary:hover {
    background-color: #6FC0F9;
    border: 1px solid #6FC0F9; }
  .btn.btn-primary:focus {
    background-color: #6FC0F9;
    border: 1px solid #6FC0F9; }

body {
  padding-right: 0 !important; }

#react-tiny-popover-container {
  z-index: 999; }

.highlighted-table .rs-table-cell-header .rs-table-cell-content {
  display: flex;
  align-items: center;
  justify-content: center; }

.highlighted-table .rs-table-cell-content {
  padding: 0; }

.highlighted-table .rs-table-cell-wrap {
  height: 100%; }

.highlighted-table-highlight {
  background-color: #E3F6FF;
  padding: 8px;
  height: 100%; }

.highlighted-table-default {
  background-color: #ffffff;
  padding: 8px;
  height: 100%; }

.voucher-purchase-step-1 {
  display: inline-block;
  width: 25%;
  margin-left: 5%;
  margin-right: 6%;
  background-color: #D3E3F2;
  box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
  border-radius: 15px;
  z-index: 10;
  position: relative;
  cursor: pointer;
  z-index: 300; }

.voucher-purchase-step-2 {
  display: inline-block;
  width: 25%;
  margin-right: 7%;
  background-color: #D3E3F2;
  box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
  border-radius: 15px;
  z-index: 10;
  position: relative;
  cursor: pointer;
  z-index: 500; }

.voucher-purchase-step-3 {
  display: inline-block;
  width: 25%;
  background-color: #D3E3F2;
  box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
  border-radius: 15px;
  z-index: 10;
  position: relative;
  cursor: pointer;
  z-index: 300; }

.voucher-purchase-step-active {
  box-shadow: inset 4px 4px 8px #BBCFE2, inset -5px -5px 30px #EEF7FF; }

.product-info-logo {
  padding: 10px;
  border-radius: 2px;
  padding-left: 0; }
  .product-info-logo-col {
    display: inline-block;
    min-width: 40px;
    max-width: 20%; }

.product-info-description {
  text-align: justify;
  margin-top: 15px; }
  .product-info-description-col {
    display: inline-block;
    width: max-content;
    padding-top: 10px;
    max-width: 100%;
    text-align: justify; }
  .product-info-description-highlight {
    color: #00aeff;
    font-weight: 600;
    padding-left: 3px;
    padding-right: 4px; }

.product-info-discount-step {
  font-size: 14px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 48px; }
  .product-info-discount-step-next-button {
    margin-top: 30px;
    margin-left: 10px;
    width: 100%;
    height: 67px;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 600;
    background: #00aeff !important;
    background-color: #00aeff !important; }
    .product-info-discount-step-next-button:hover {
      border: 1px solid #00aeff !important;
      background: #FFF !important;
      color: #00aeff !important;
      transition: background 0.5s, border 0.5s, color 0.5s; }
  .product-info-discount-step-disabled {
    color: #FFF;
    border: 1px solid #FFF;
    cursor: default;
    opacity: 0.8; }
    .product-info-discount-step-disabled .product-info-discount-step-wrapper {
      background: #DDDDDD !important;
      border: 1px solid transparent; }
  .product-info-discount-step img {
    cursor: pointer; }
  .product-info-discount-step-wrapper {
    color: #00aeff;
    border: 1px solid #9de1ff;
    font-size: 14px;
    text-align: center;
    min-height: 55px;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    cursor: pointer; }
    .product-info-discount-step-wrapper:hover {
      color: #FFF;
      border: 1px solid #FFF;
      background: #00aeff;
      transition: background 0.5s, border 0.5s, color 0.5s; }
    .product-info-discount-step-wrapper-active {
      color: #FFF;
      border: 1px solid #FFF;
      background: #00aeff;
      transition: background 0.5s, border 0.5s, color 0.5s; }
  .product-info-discount-step-title {
    font-size: 22px;
    font-weight: 600; }
  .product-info-discount-step-info {
    font-size: 16px; }
    .product-info-discount-step-info svg {
      width: 24px;
      height: 24px; }
    .product-info-discount-step-info i {
      width: 24px;
      height: 24px; }
    .product-info-discount-step-info-locked {
      padding-top: 10px;
      font-size: 12px;
      color: #888;
      float: right;
      width: calc(100% - 30px);
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;
      white-space: nowrap;
      line-height: 16px; }
      .product-info-discount-step-info-locked-icon {
        width: 20px;
        height: 20px;
        filter: invert(51%) sepia(0%) saturate(84%) hue-rotate(215deg) brightness(104%) contrast(96%);
        float: left;
        margin-top: 8px;
        margin-left: 3px;
        position: absolute; }
    .product-info-discount-step-info-bespoke {
      padding-top: 10px;
      font-size: 12px;
      color: #888;
      float: right;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px; }
    .product-info-discount-step-info-bought {
      padding-top: 10px;
      font-size: 12px;
      color: #888;
      float: right;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px; }

.row-cols-7 > * {
  flex: 0 0 auto;
  width: 14.2857142857%;
  /* 100/7 */ }

.row-cols-8 > * {
  flex: 0 0 auto;
  width: 12.5%;
  /* 100/8 */ }

@media (min-width: 768px) {
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
    /* 100/7 */ }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
    /* 100/8 */ } }

@media (min-width: 992px) {
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
    /* 100/7 */ }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
    /* 100/8 */ } }

@media (max-width: 1200px) {
  .product-info-discount-step-info-locked {
    padding-top: 10px;
    width: calc(100% - 30px);
    font-size: 10px;
    padding-left: 0px;
    padding-right: 10px;
    line-height: 22px; }
    .product-info-discount-step-info-locked-icon {
      width: 20px;
      height: 20px;
      margin-top: 12px;
      margin-left: -8px; } }

@media (max-width: 992px) {
  .product-info-discount-step-wrapper {
    min-height: 85px; }
  .product-info-discount-step-info-locked {
    padding-top: 10px;
    width: calc(100% - 30px);
    font-size: 10px;
    white-space: normal;
    line-height: 22px;
    padding-left: 0; }
    .product-info-discount-step-info-locked-icon {
      width: 18px;
      height: 18px;
      margin-top: 12px;
      margin-left: -8px; } }

@media (max-width: 767px) {
  .product-info-discount-step-wrapper {
    min-height: 85px; }
  .product-info-discount-step-info-gift-text {
    line-height: 48px; }
  .product-info-discount-step-info-locked {
    padding-top: 10px;
    width: calc(100% - 30px);
    font-size: 8px;
    padding-left: 0px;
    padding-right: 5px;
    white-space: normal;
    line-height: 12px; }
    .product-info-discount-step-info-locked-icon {
      width: 18px;
      height: 18px;
      margin-top: 12px;
      margin-left: -8px; }
  .product-info-discount-step-info-bespoke {
    padding-left: 10px;
    padding-right: 0px; }
  .product-info-discount-step-info-bought {
    padding-top: 10px;
    width: 100%;
    font-size: 8px;
    padding-left: 10px;
    padding-right: 5px; } }

.stripe-pay-modal {
  margin-top: 15%; }
  .stripe-pay-modal .modal-dialog {
    border-radius: 6px; }
  .stripe-pay-modal .modal-content {
    padding: 32px; }

.stripe-pay-button {
  padding: 10px 30px;
  border-radius: 5px;
  border: solid 1px #00b2ff;
  /* background: #0064ab;
    background: -webkit-linear-gradient(#1ebdfa, #0064ab);
    background: -moz-linear-gradient(#1ebdfa, #0064ab);
    background: -o-linear-gradient(#1ebdfa, #0064ab);
    background: -ms-linear-gradient(#1ebdfa, #0064ab);
    background: linear-gradient(#1ebdfa, #0064ab);*/
  background: #00b2ff;
  margin: 0 auto;
  display: block;
  position: relative;
  color: #FFFFFF;
  height: 50px;
  margin-top: 15px;
  width: 100%; }
  .stripe-pay-button-title {
    font-size: 14px;
    color: #FFFFFF;
    vertical-align: baseline;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6); }

.aspButton.blue {
  color: #fff;
  border: solid 1px #1a528c;
  background: #0064ab;
  background: linear-gradient(top, #1ebdfa 0, #0064ab 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ebdfa',endColorstr='#0064ab',GradientType=0); }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.hinge {
  animation-duration: 2s; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

.bounce {
  animation-name: bounce; }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

.pulse {
  animation-name: pulse; }

@keyframes shake {
  0%, 100% {
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateX(10px); } }

.shake {
  animation-name: shake; }

@keyframes swing {
  20% {
    transform: rotate(15deg); }
  40% {
    transform: rotate(-10deg); }
  60% {
    transform: rotate(5deg); }
  80% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

@keyframes tada {
  0% {
    transform: scale(1); }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg); }
  100% {
    transform: scale(1) rotate(0); } }

.tada {
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes wobble {
  0% {
    transform: translateX(0%); }
  15% {
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    transform: translateX(20%) rotate(3deg); }
  45% {
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    transform: translateX(10%) rotate(2deg); }
  75% {
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    transform: translateX(0%); } }

.wobble {
  animation-name: wobble; }

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3); }
  50% {
    opacity: 1;
    transform: scale(1.05); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

.bounceIn {
  animation-name: bounceIn; }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    transform: translateY(30px); }
  80% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

.bounceInDown {
  animation-name: bounceInDown; }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    transform: translateX(30px); }
  80% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

.bounceInLeft {
  animation-name: bounceInLeft; }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

.bounceInRight {
  animation-name: bounceInRight; }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    transform: translateY(-30px); }
  80% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

.bounceInUp {
  animation-name: bounceInUp; }

@keyframes bounceOut {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(0.95); }
  50% {
    opacity: 1;
    transform: scale(1.1); }
  100% {
    opacity: 0;
    transform: scale(0.3); } }

.bounceOut {
  animation-name: bounceOut; }

@keyframes bounceOutDown {
  0% {
    transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    transform: translateY(2000px); } }

.bounceOutDown {
  animation-name: bounceOutDown; }

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0); }
  20% {
    opacity: 1;
    transform: translateX(20px); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.bounceOutLeft {
  animation-name: bounceOutLeft; }

@keyframes bounceOutRight {
  0% {
    transform: translateX(0); }
  20% {
    opacity: 1;
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.bounceOutRight {
  animation-name: bounceOutRight; }

@keyframes bounceOutUp {
  0% {
    transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(20px); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.bounceOutUp {
  animation-name: bounceOutUp; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInDown {
  animation-name: fadeInDown; }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInDownBig {
  animation-name: fadeInDownBig; }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInLeft {
  animation-name: fadeInLeft; }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInLeftBig {
  animation-name: fadeInLeftBig; }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInRightBig {
  animation-name: fadeInRightBig; }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInUpBig {
  animation-name: fadeInUpBig; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(20px); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(2000px); } }

.fadeOutDownBig {
  animation-name: fadeOutDownBig; }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-20px); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig; }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(20px); } }

.fadeOutRight {
  animation-name: fadeOutRight; }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.fadeOutRightBig {
  animation-name: fadeOutRightBig; }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-20px); } }

.fadeOutUp {
  animation-name: fadeOutUp; }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.fadeOutUpBig {
  animation-name: fadeOutUpBig; }

@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in; } }

.animated.flip {
  backface-visibility: visible;
  animation-name: flip; }

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    transform: perspective(400px) rotateX(10deg); }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX; }

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    transform: perspective(400px) rotateY(10deg); }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY; }

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important; }

@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

.flipOutY {
  backface-visibility: visible !important;
  animation-name: flipOutY; }

@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out; }

@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in; }

@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1; } }

.rotateIn {
  animation-name: rotateIn; }

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownLeft {
  animation-name: rotateInDownLeft; }

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownRight {
  animation-name: rotateInDownRight; }

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpLeft {
  animation-name: rotateInUpLeft; }

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpRight {
  animation-name: rotateInUpRight; }

@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0; } }

.rotateOut {
  animation-name: rotateOut; }

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft; }

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutDownRight {
  animation-name: rotateOutDownRight; }

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft; }

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutUpRight {
  animation-name: rotateOutUpRight; }

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px); }
  100% {
    transform: translateY(0); } }

.slideInDown {
  animation-name: slideInDown; }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px); }
  100% {
    transform: translateX(0); } }

.slideInLeft {
  animation-name: slideInLeft; }

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  100% {
    transform: translateX(0); } }

.slideInRight {
  animation-name: slideInRight; }

@keyframes slideOutLeft {
  0% {
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.slideOutLeft {
  animation-name: slideOutLeft; }

@keyframes slideOutRight {
  0% {
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.slideOutRight {
  animation-name: slideOutRight; }

@keyframes slideOutUp {
  0% {
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.slideOutUp {
  animation-name: slideOutUp; }

@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  100% {
    transform: translateY(700px);
    opacity: 0; } }

.hinge {
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg); } }

.rollIn {
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg); } }

.rollOut {
  animation-name: rollOut; }

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  animation-name: zoomIn; }

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  animation-name: zoomInDown; }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  animation-name: zoomInLeft; }

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  animation-name: zoomInRight; }

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  animation-name: zoomInUp; }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  animation-name: zoomOut; }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  animation-name: zoomOutDown; }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center; } }

.zoomOutLeft {
  animation-name: zoomOutLeft; }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center; } }

.zoomOutRight {
  animation-name: zoomOutRight; }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  animation-name: zoomOutUp; }

/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
; }

*,
::after,
::before {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25; }

hr:not([size]) {
  height: 1px; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2; }

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw); }

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + .9vw); }

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem; } }

.h3,
h3 {
  font-size: calc(1.3rem + .6vw); }

@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem; } }

.h4,
h4 {
  font-size: calc(1.275rem + .3vw); }

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem; } }

.h5,
h5 {
  font-size: 1.25rem; }

.h6,
h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-bs-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

.small,
small {
  font-size: .875em; }

.mark,
mark {
  padding: .2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: .75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }

a:hover {
  color: #0a58ca; }

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: .875em; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

code {
  font-size: .875em;
  color: #d63384;
  word-wrap: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: .2rem .4rem;
  font-size: .875em;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role=button] {
  cursor: pointer; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button; }

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit; }

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem; } }

legend + * {
  clear: left; }

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: .875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote > :last-child {
  margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014   "; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

.figure-caption {
  font-size: .875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2); }

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0
; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0
; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem
; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem
; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem
; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem
; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem
; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem
; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem
; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem
; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem
; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem
; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0
    ; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0
    ; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem
    ; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem
    ; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem
    ; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem
    ; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem
    ; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem
    ; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem
    ; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem
    ; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem
    ; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem
    ; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0
    ; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0
    ; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem
    ; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem
    ; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem
    ; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem
    ; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem
    ; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem
    ; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem
    ; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem
    ; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem
    ; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem
    ; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0
    ; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0
    ; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem
    ; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem
    ; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem
    ; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem
    ; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem
    ; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem
    ; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem
    ; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem
    ; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem
    ; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem
    ; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0
    ; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0
    ; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem
    ; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem
    ; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem
    ; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem
    ; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem
    ; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem
    ; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem
    ; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem
    ; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem
    ; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem
    ; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.3333333333%; }
  .offset-xxl-2 {
    margin-left: 16.6666666667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.3333333333%; }
  .offset-xxl-5 {
    margin-left: 41.6666666667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.3333333333%; }
  .offset-xxl-8 {
    margin-left: 66.6666666667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.3333333333%; }
  .offset-xxl-11 {
    margin-left: 91.6666666667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0
    ; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0
    ; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem
    ; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem
    ; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem
    ; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem
    ; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem
    ; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem
    ; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem
    ; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem
    ; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem
    ; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem
    ; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }

.table > :not(caption) > * > * {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }

.table > tbody {
  vertical-align: inherit; }

.table > thead {
  vertical-align: bottom; }

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: .25rem .25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6; }

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce; }

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: .5rem; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem; }

.form-text {
  margin-top: .25rem;
  font-size: .875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control[type=file] {
  overflow: hidden; }

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-control::-webkit-date-and-time-value {
  height: 1.5em; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3; }

.form-control::-webkit-file-upload-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem; }

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem; }

.form-control-sm::-webkit-file-upload-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem; }

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem; }

.form-control-lg::-webkit-file-upload-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: .375rem; }

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: .25rem; }

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: .25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  appearance: none; }

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: .75rem;
  background-image: none; }

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem; }

.form-select-lg {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem; }

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact; }

.form-check-input[type=checkbox] {
  border-radius: .25em; }

.form-check-input[type=radio] {
  border-radius: 50%; }

.form-check-input:active {
  filter: brightness(90%); }

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd; }

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5; }

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: .5; }

.form-switch {
  padding-left: 2.5em; }

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none; } }

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none; }

.form-range:focus {
  outline: 0; }

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-range::-moz-focus-outer {
  border: 0; }

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none; } }

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe; }

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none; } }

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe; }

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range:disabled {
  pointer-events: none; }

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.form-floating {
  position: relative; }

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem .75rem; }

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none; } }

.form-floating > .form-control::placeholder {
  color: transparent; }

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3; }

.input-group .btn {
  position: relative;
  z-index: 2; }

.input-group .btn:focus {
  z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem; }

.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: .25rem; }

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block; }

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #198754;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem); }

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #198754; }

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #198754; }

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: .25rem; }

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block; }

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #dc3545;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem); }

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #dc3545; }

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #dc3545; }

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529; }

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca; }

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be; }

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64; }

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e; }

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43; }

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f; }

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2; }

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2; }

.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }

.btn-info.disabled,
.btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720; }

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }

.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720; }

.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37; }

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834; }

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }

.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21; }

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }

.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f; }

.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #198754;
  background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }

.btn-link:hover {
  color: #0a58ca; }

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d; }

.btn-group-lg > .btn,
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem; }

.btn-group-sm > .btn,
.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem; }

.fade {
  transition: opacity .15s linear; }

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: .125rem; }

.dropdown-menu-start {
  --bs-position: start
; }

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0; }

.dropdown-menu-end {
  --bs-position: end
; }

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start
    ; }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end
    ; }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start
    ; }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end
    ; }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start
    ; }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end
    ; }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start
    ; }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end
    ; }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start
    ; }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end
    ; }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%; }

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: .125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%; }

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: .125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto; }

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: .125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef; }

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd; }

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: .25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd; }

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd; }

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto; }

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0; }

.dropstart .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%; }

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none; } }

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: .25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between; }

.navbar-brand {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static; }

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none; } }

.navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 .25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xxl .navbar-toggler {
    display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }

.navbar-expand .navbar-nav {
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

.navbar-expand .navbar-nav-scroll {
  overflow: visible; }

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit; }

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: .5rem; }

.card-subtitle {
  margin-top: -.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-footer {
  padding: .5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.5rem;
  margin-bottom: -.5rem;
  margin-left: -.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.5rem;
  margin-left: -.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(.25rem - 1px); }

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-group > .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none; } }

.accordion-button.collapsed {
  border-bottom-width: 0; }

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff; }

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg); }

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none; } }

.accordion-button:hover {
  z-index: 2; }

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/"); }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none; } }

.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: .375rem .75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.badge {
  display: inline-block;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem; }

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe; }

.alert-primary .alert-link {
  color: #06357a; }

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8; }

.alert-secondary .alert-link {
  color: #34383c; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }

.alert-success .alert-link {
  color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }

.alert-info .alert-link {
  color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }

.alert-warning .alert-link {
  color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }

.alert-danger .alert-link {
  color: #6a1a21; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light .alert-link {
  color: #4f5050; }

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf; }

.alert-dark .alert-link {
  color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width .6s ease; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit; }

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.list-group-item + .list-group-item {
  border-top-width: 0; }

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0; }

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0; }

.list-group-horizontal > .list-group-item.active {
  margin-top: 0; }

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0; }

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }

.list-group-flush > .list-group-item {
  border-width: 0 0 1px; }

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff; }

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #084298;
  background-color: #bacbe6; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #0f5132;
  background-color: #bcd0c7; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #055160;
  background-color: #badce3; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #664d03;
  background-color: #e6dbb9; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #141619;
  background-color: #bebebf; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5; }

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75; }

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1; }

.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: .875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.toast:not(.showing):not(.show) {
  opacity: 0; }

.toast.hide {
  display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }

.toast-container > :not(:last-child) {
  margin-bottom: .75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.toast-header .btn-close {
  margin-right: -.375rem;
  margin-left: .75rem; }

.toast-body {
  padding: .75rem;
  word-wrap: break-word; }

.modal-open {
  overflow: hidden; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  transform: none; }

.modal.modal-static .modal-dialog {
  transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden; }

.modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px); }

.modal-footer > * {
  margin: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0; }

.modal-fullscreen .modal-header {
  border-radius: 0; }

.modal-fullscreen .modal-body {
  overflow-y: auto; }

.modal-fullscreen .modal-footer {
  border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: .9; }

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
  padding: .4rem 0; }

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0; }

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
  padding: 0 .4rem; }

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
  padding: .4rem 0; }

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0; }

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
  padding: 0 .4rem; }

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem; }

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-.5rem - 1px); }

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff; }

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem; }

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff; }

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-.5rem - 1px); }

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff; }

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem; }

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff; }

.popover-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none; } }

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block; }

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%); }

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s; }

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none; } }

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none; } }

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none; } }

.carousel-indicators .active {
  opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }

.link-primary:focus,
.link-primary:hover {
  color: #0a58ca; }

.link-secondary {
  color: #6c757d; }

.link-secondary:focus,
.link-secondary:hover {
  color: #565e64; }

.link-success {
  color: #198754; }

.link-success:focus,
.link-success:hover {
  color: #146c43; }

.link-info {
  color: #0dcaf0; }

.link-info:focus,
.link-info:hover {
  color: #3dd5f3; }

.link-warning {
  color: #ffc107; }

.link-warning:focus,
.link-warning:hover {
  color: #ffcd39; }

.link-danger {
  color: #dc3545; }

.link-danger:focus,
.link-danger:hover {
  color: #b02a37; }

.link-light {
  color: #f8f9fa; }

.link-light:focus,
.link-light:hover {
  color: #f9fafb; }

.link-dark {
  color: #212529; }

.link-dark:focus,
.link-dark:hover {
  color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: ""; }

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%
; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%)
; }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%)
; }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%)
; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-0 {
  border-width: 0 !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important; }

.gap-1 {
  grid-gap: .25rem !important;
  gap: .25rem !important; }

.gap-2 {
  grid-gap: .5rem !important;
  gap: .5rem !important; }

.gap-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important; }

.gap-4 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important; }

.gap-5 {
  grid-gap: 3rem !important;
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: .25rem !important; }

.m-2 {
  margin: .5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: .25rem !important;
  margin-left: .25rem !important; }

.mx-2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important; }

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: .25rem !important; }

.mt-2 {
  margin-top: .5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: .25rem !important; }

.me-2 {
  margin-right: .5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: .25rem !important; }

.mb-2 {
  margin-bottom: .5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: .25rem !important; }

.ms-2 {
  margin-left: .5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: .25rem !important; }

.p-2 {
  padding: .5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: .25rem !important;
  padding-left: .25rem !important; }

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important; }

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: .25rem !important; }

.pt-2 {
  padding-top: .5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: .25rem !important; }

.pe-2 {
  padding-right: .5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: .25rem !important; }

.pb-2 {
  padding-bottom: .5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: .25rem !important; }

.ps-2 {
  padding-left: .5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-primary {
  color: #0d6efd !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #212529 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #0d6efd !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #212529 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: .25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: .2rem !important; }

.rounded-2 {
  border-radius: .25rem !important; }

.rounded-3 {
  border-radius: .3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important; }

.rounded-end {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important; }

.rounded-start {
  border-bottom-left-radius: .25rem !important;
  border-top-left-radius: .25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-sm-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important; }
  .gap-sm-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important; }
  .gap-sm-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-sm-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-sm-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: .25rem !important; }
  .m-sm-2 {
    margin: .5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important; }
  .mx-sm-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: .25rem !important; }
  .mt-sm-2 {
    margin-top: .5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: .25rem !important; }
  .me-sm-2 {
    margin-right: .5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: .25rem !important; }
  .mb-sm-2 {
    margin-bottom: .5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: .25rem !important; }
  .ms-sm-2 {
    margin-left: .5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: .25rem !important; }
  .p-sm-2 {
    padding: .5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important; }
  .px-sm-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: .25rem !important; }
  .pt-sm-2 {
    padding-top: .5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: .25rem !important; }
  .pe-sm-2 {
    padding-right: .5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: .25rem !important; }
  .pb-sm-2 {
    padding-bottom: .5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: .25rem !important; }
  .ps-sm-2 {
    padding-left: .5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-md-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important; }
  .gap-md-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important; }
  .gap-md-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-md-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-md-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: .25rem !important; }
  .m-md-2 {
    margin: .5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important; }
  .mx-md-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: .25rem !important; }
  .mt-md-2 {
    margin-top: .5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: .25rem !important; }
  .me-md-2 {
    margin-right: .5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: .25rem !important; }
  .mb-md-2 {
    margin-bottom: .5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: .25rem !important; }
  .ms-md-2 {
    margin-left: .5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: .25rem !important; }
  .p-md-2 {
    padding: .5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important; }
  .px-md-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: .25rem !important; }
  .pt-md-2 {
    padding-top: .5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: .25rem !important; }
  .pe-md-2 {
    padding-right: .5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: .25rem !important; }
  .pb-md-2 {
    padding-bottom: .5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: .25rem !important; }
  .ps-md-2 {
    padding-left: .5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-lg-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important; }
  .gap-lg-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important; }
  .gap-lg-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-lg-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-lg-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: .25rem !important; }
  .m-lg-2 {
    margin: .5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important; }
  .mx-lg-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: .25rem !important; }
  .mt-lg-2 {
    margin-top: .5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: .25rem !important; }
  .me-lg-2 {
    margin-right: .5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: .25rem !important; }
  .mb-lg-2 {
    margin-bottom: .5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: .25rem !important; }
  .ms-lg-2 {
    margin-left: .5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: .25rem !important; }
  .p-lg-2 {
    padding: .5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important; }
  .px-lg-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: .25rem !important; }
  .pt-lg-2 {
    padding-top: .5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: .25rem !important; }
  .pe-lg-2 {
    padding-right: .5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: .25rem !important; }
  .pb-lg-2 {
    padding-bottom: .5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: .25rem !important; }
  .ps-lg-2 {
    padding-left: .5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-xl-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important; }
  .gap-xl-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important; }
  .gap-xl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-xl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-xl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: .25rem !important; }
  .m-xl-2 {
    margin: .5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important; }
  .mx-xl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: .25rem !important; }
  .mt-xl-2 {
    margin-top: .5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: .25rem !important; }
  .me-xl-2 {
    margin-right: .5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: .25rem !important; }
  .mb-xl-2 {
    margin-bottom: .5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: .25rem !important; }
  .ms-xl-2 {
    margin-left: .5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: .25rem !important; }
  .p-xl-2 {
    padding: .5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important; }
  .px-xl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: .25rem !important; }
  .pt-xl-2 {
    padding-top: .5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: .25rem !important; }
  .pe-xl-2 {
    padding-right: .5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: .25rem !important; }
  .pb-xl-2 {
    padding-bottom: .5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: .25rem !important; }
  .ps-xl-2 {
    padding-left: .5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    grid-gap: 0 !important;
    gap: 0 !important; }
  .gap-xxl-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important; }
  .gap-xxl-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important; }
  .gap-xxl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important; }
  .gap-xxl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: .25rem !important; }
  .m-xxl-2 {
    margin: .5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important; }
  .mx-xxl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: .25rem !important; }
  .mt-xxl-2 {
    margin-top: .5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: .25rem !important; }
  .me-xxl-2 {
    margin-right: .5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: .25rem !important; }
  .mb-xxl-2 {
    margin-bottom: .5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: .25rem !important; }
  .ms-xxl-2 {
    margin-left: .5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: .25rem !important; }
  .p-xxl-2 {
    padding: .5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important; }
  .px-xxl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: .25rem !important; }
  .pt-xxl-2 {
    padding-top: .5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: .25rem !important; }
  .pe-xxl-2 {
    padding-right: .5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: .25rem !important; }
  .pb-xxl-2 {
    padding-bottom: .5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: .25rem !important; }
  .ps-xxl-2 {
    padding-left: .5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*# sourceMappingURL=bootstrap.min.css.map */
.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: 0;
  overflow: hidden; }

.glightbox-container.inactive {
  display: none; }

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden; }

.glightbox-container .gslider {
  transition: transform .4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0); }

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; }

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative; }

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999; }

.glightbox-container .gslide-inner-content {
  width: 100%; }

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh; }

.glightbox-container .ginner-container.gvideo-container {
  width: 100%; }

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  flex-direction: column; }

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important; }

.gslide iframe, .gslide video {
  outline: 0 !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  touch-action: auto; }

.gslide-image {
  align-items: center; }

.gslide-image img {
  max-height: 100vh;
  display: block;
  max-width: 100%;
  margin: 0;
  padding: 0;
  float: none;
  outline: 0;
  border: none;
  -webkit-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  object-fit: cover;
  touch-action: none;
  margin: auto;
  min-width: 200px; }

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto; }

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%; }

.gslide-image img.zoomable {
  position: relative; }

.gslide-image img.dragging {
  cursor: grabbing !important;
  transition: none; }

.gslide-video {
  width: 100%;
  max-width: 100%;
  position: relative;
  width: 100vh;
  max-width: 100vh;
  width: 100% !important; }

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto; }

.gslide-video::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none; }

.gslide-video.playing::before {
  display: none; }

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 80vh; }

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important; }

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%; }

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%; }

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1; }

.gslide-external {
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%; }

.gslide-media {
  display: block;
  display: inline-flex;
  display: flex;
  width: auto; }

.zoomed .gslide-media {
  box-shadow: none !important; }

.desc-bottom .gslide-media, .desc-top .gslide-media {
  margin: 0 auto;
  flex-direction: column; }

.gslide-description {
  position: relative; }

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%; }

.gslide-description.description-bottom, .gslide-description.description-top {
  margin: 0 auto;
  width: 100%; }

.gslide-description p {
  margin-bottom: 12px; }

.gslide-description p::last-child {
  margin-bottom: 0; }

.zoomed .gslide-description {
  display: none; }

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  background: 0 0;
  position: absolute;
  bottom: 15px;
  padding: 19px 11px;
  max-width: 100vw !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.75) 100%);
  transition: opacity .3s linear;
  padding-bottom: 50px; }

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em; }

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1; }

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700; }

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit; }

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #fff; }

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: .4; }

.gdesc-open .gslide-media {
  transition: opacity .5s ease;
  opacity: .4; }

.gdesc-open .gdesc-inner {
  padding-bottom: 30px; }

.gdesc-closed .gslide-media {
  transition: opacity .5s ease;
  opacity: 1; }

.greset {
  transition: all .3s ease; }

.gabsolute {
  position: absolute; }

.grelative {
  position: relative; }

.glightbox-desc {
  display: none !important; }

.glightbox-open {
  overflow: hidden; }

.gloader {
  height: 25px;
  width: 25px;
  animation: lightboxLoader .8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%; }

.goverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  will-change: opacity; }

.glightbox-mobile .goverlay {
  background: #000; }

.gclose, .gnext, .gprev {
  background-repeat: no-repeat;
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  display: block;
  background-position: 0 0;
  border: none; }

.gclose svg, .gnext svg, .gprev svg {
  display: block;
  width: 100%;
  height: auto; }

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: .1; }

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff; }

iframe.wait-autoplay {
  opacity: 0; }

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important; }

.glightbox-clean .gslide-description, .glightbox-modern .gslide-description {
  background: #fff; }

.glightbox-clean .gdesc-inner, .glightbox-modern .gdesc-inner {
  padding: 22px 20px; }

.glightbox-clean .gslide-title, .glightbox-modern .gslide-title {
  font-size: 1em;
  font-weight: 400;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em; }

.glightbox-clean .gslide-desc, .glightbox-modern .gslide-desc {
  font-size: .86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em; }

.glightbox-clean .gslide-video, .glightbox-modern .gslide-video {
  background: #000; }

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev, .glightbox-modern .gclose, .glightbox-modern .gnext, .glightbox-modern .gprev {
  background-color: rgba(0, 0, 0, 0.12); }

.glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover, .glightbox-modern .gclose:hover, .glightbox-modern .gnext:hover, .glightbox-modern .gprev:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path, .glightbox-modern .gclose path, .glightbox-modern .gnext path, .glightbox-modern .gprev path {
  fill: #fff; }

.glightbox-clean button:focus:not(.focused):not(.disabled), .glightbox-modern button:focus:not(.focused):not(.disabled) {
  outline: 0; }

.glightbox-clean .gprev, .glightbox-modern .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 56px; }

.glightbox-clean .gnext, .glightbox-modern .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 56px; }

.glightbox-clean .gclose, .glightbox-modern .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
  opacity: .7;
  background-position: -59px 2px; }

.glightbox-clean .gclose svg, .glightbox-modern .gclose svg {
  width: 20px; }

.glightbox-clean .gclose:hover, .glightbox-modern .gclose:hover {
  opacity: 1; }

.gfadeIn {
  animation: gfadeIn .5s ease; }

.gfadeOut {
  animation: gfadeOut .5s ease; }

.gslideOutLeft {
  animation: gslideOutLeft .3s ease; }

.gslideInLeft {
  animation: gslideInLeft .3s ease; }

.gslideOutRight {
  animation: gslideOutRight .3s ease; }

.gslideInRight {
  animation: gslideInRight .3s ease; }

.gzoomIn {
  animation: gzoomIn .5s ease; }

.gzoomOut {
  animation: gzoomOut .5s ease; }

@keyframes lightboxLoader {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes gfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes gfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes gslideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-60%, 0, 0); }
  to {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden; } }

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    transform: translate3d(60%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(60%, 0, 0);
    opacity: 0; } }

@keyframes gzoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

@keyframes gzoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    flex-direction: row; }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    order: 0; }
  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    order: 1; }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    order: 0; }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    order: 1; }
  .gslide-image img {
    max-height: 97vh;
    max-width: calc(100% - 20px);
    max-width: 100%; }
  .gslide-image img.zoomable {
    cursor: zoom-in; }
  .zoomed .gslide-image img.zoomable {
    cursor: grab; }
  .gslide-inline {
    max-height: 95vh; }
  .gslide-external {
    max-height: 100vh; }
  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px; }
  .glightbox-open {
    height: auto; }
  .goverlay {
    background: rgba(0, 0, 0, 0.92); }
  .glightbox-clean .gslide-media, .glightbox-modern .gslide-media {
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65); }
  .glightbox-clean .gprev, .glightbox-modern .gprev {
    top: 45%; }
  .glightbox-clean .gnext, .glightbox-modern .gnext {
    top: 45%; } }

@media (min-width: 992px) {
  .glightbox-clean .gclose, .glightbox-modern .gclose {
    right: 20px; } }

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000; } }

/*--------------------------------

LineIcons Free Web Font
Author: lineicons.com

-------------------------------- */
@font-face {
  font-family: 'LineIcons';
  src: url(/static/media/LineIcons.431804e5.eot);
  src: url(/static/media/LineIcons.431804e5.eot) format("embedded-opentype"), url(/static/media/LineIcons.ab4f9370.woff2) format("woff2"), url(/static/media/LineIcons.662f9b95.woff) format("woff"), url(/static/media/LineIcons.764a78c4.ttf) format("truetype"), url(/static/media/LineIcons.b15c56e4.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

/*------------------------
	base class definition
-------------------------*/
.lni {
  display: inline-block;
  font: normal normal normal 1em/1 'LineIcons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*------------------------
  change icon size
-------------------------*/
/* relative units */
.lni-sm {
  font-size: 0.8em; }

.lni-lg {
  font-size: 1.2em; }

/* absolute units */
.lni-16 {
  font-size: 16px; }

.lni-32 {
  font-size: 32px; }

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.lni-bg-square,
.lni-bg-circle {
  padding: 0.35em;
  background-color: #eee; }

.lni-bg-circle {
  border-radius: 50%; }

/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.lni-ul {
  padding-left: 0;
  list-style-type: none; }

.lni-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4; }

.lni-ul > li > .lni {
  margin-right: 0.4em;
  line-height: inherit; }

/*------------------------
  spinning icons
-------------------------*/
.lni-is-spinning {
  animation: lni-spin 2s infinite linear; }

@keyframes lni-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*------------------------
  rotated/flipped icons
-------------------------*/
.lni-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  transform: rotate(90deg); }

.lni-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(180deg); }

.lni-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(270deg); }

.lni-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  transform: scale(-1, 1); }

.lni-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: scale(1, -1); }

/*------------------------
	icons
-------------------------*/
.lni-500px::before {
  content: "\EA02"; }

.lni-add-files::before {
  content: "\EA03"; }

.lni-alarm-clock::before {
  content: "\EA04"; }

.lni-alarm::before {
  content: "\EA05"; }

.lni-airbnb::before {
  content: "\EA06"; }

.lni-adobe::before {
  content: "\EA07"; }

.lni-amazon-pay::before {
  content: "\EA08"; }

.lni-amazon::before {
  content: "\EA09"; }

.lni-amex::before {
  content: "\EA0A"; }

.lni-anchor::before {
  content: "\EA0B"; }

.lni-amazon-original::before {
  content: "\EA0C"; }

.lni-android-original::before {
  content: "\EA0D"; }

.lni-android::before {
  content: "\EA0E"; }

.lni-angellist::before {
  content: "\EA0F"; }

.lni-angle-double-down::before {
  content: "\EA10"; }

.lni-angle-double-left::before {
  content: "\EA11"; }

.lni-angle-double-right::before {
  content: "\EA12"; }

.lni-angle-double-up::before {
  content: "\EA13"; }

.lni-angular::before {
  content: "\EA14"; }

.lni-apartment::before {
  content: "\EA15"; }

.lni-app-store::before {
  content: "\EA16"; }

.lni-apple-pay::before {
  content: "\EA17"; }

.lni-apple::before {
  content: "\EA18"; }

.lni-archive::before {
  content: "\EA19"; }

.lni-arrow-down-circle::before {
  content: "\EA1A"; }

.lni-arrow-left-circle::before {
  content: "\EA1B"; }

.lni-arrow-left::before {
  content: "\EA1C"; }

.lni-arrow-right-circle::before {
  content: "\EA1D"; }

.lni-arrow-right::before {
  content: "\EA1E"; }

.lni-arrow-top-left::before {
  content: "\EA1F"; }

.lni-arrow-top-right::before {
  content: "\EA20"; }

.lni-arrow-up-circle::before {
  content: "\EA21"; }

.lni-arrow-up::before {
  content: "\EA22"; }

.lni-arrows-horizontal::before {
  content: "\EA23"; }

.lni-arrows-vertical::before {
  content: "\EA24"; }

.lni-atlassian::before {
  content: "\EA25"; }

.lni-aws::before {
  content: "\EA26"; }

.lni-arrow-down::before {
  content: "\EA27"; }

.lni-ambulance::before {
  content: "\EA28"; }

.lni-agenda::before {
  content: "\EA29"; }

.lni-backward::before {
  content: "\EA2A"; }

.lni-baloon::before {
  content: "\EA2B"; }

.lni-ban::before {
  content: "\EA2C"; }

.lni-bar-chart::before {
  content: "\EA2D"; }

.lni-behance-original::before {
  content: "\EA2E"; }

.lni-bitbucket::before {
  content: "\EA2F"; }

.lni-bitcoin::before {
  content: "\EA30"; }

.lni-blackboard::before {
  content: "\EA31"; }

.lni-blogger::before {
  content: "\EA32"; }

.lni-bluetooth::before {
  content: "\EA33"; }

.lni-bold::before {
  content: "\EA34"; }

.lni-bolt-alt::before {
  content: "\EA35"; }

.lni-bolt::before {
  content: "\EA36"; }

.lni-book::before {
  content: "\EA37"; }

.lni-bookmark-alt::before {
  content: "\EA38"; }

.lni-bookmark::before {
  content: "\EA39"; }

.lni-bootstrap::before {
  content: "\EA3A"; }

.lni-bricks::before {
  content: "\EA3B"; }

.lni-bridge::before {
  content: "\EA3C"; }

.lni-briefcase::before {
  content: "\EA3D"; }

.lni-brush-alt::before {
  content: "\EA3E"; }

.lni-brush::before {
  content: "\EA3F"; }

.lni-bubble::before {
  content: "\EA40"; }

.lni-bug::before {
  content: "\EA41"; }

.lni-bulb::before {
  content: "\EA42"; }

.lni-bullhorn::before {
  content: "\EA43"; }

.lni-burger::before {
  content: "\EA44"; }

.lni-bus::before {
  content: "\EA45"; }

.lni-cake::before {
  content: "\EA46"; }

.lni-calculator::before {
  content: "\EA47"; }

.lni-calendar::before {
  content: "\EA48"; }

.lni-camera::before {
  content: "\EA49"; }

.lni-candy-cane::before {
  content: "\EA4A"; }

.lni-candy::before {
  content: "\EA4B"; }

.lni-capsule::before {
  content: "\EA4C"; }

.lni-car-alt::before {
  content: "\EA4D"; }

.lni-car::before {
  content: "\EA4E"; }

.lni-caravan::before {
  content: "\EA4F"; }

.lni-cart-full::before {
  content: "\EA50"; }

.lni-cart::before {
  content: "\EA51"; }

.lni-certificate::before {
  content: "\EA52"; }

.lni-checkbox::before {
  content: "\EA53"; }

.lni-checkmark-circle::before {
  content: "\EA54"; }

.lni-checkmark::before {
  content: "\EA55"; }

.lni-chef-hat::before {
  content: "\EA56"; }

.lni-chevron-down-circle::before {
  content: "\EA57"; }

.lni-chevron-down::before {
  content: "\EA58"; }

.lni-chevron-left-circle::before {
  content: "\EA59"; }

.lni-chevron-left::before {
  content: "\EA5A"; }

.lni-chevron-right-circle::before {
  content: "\EA5B"; }

.lni-chevron-right::before {
  content: "\EA5C"; }

.lni-chevron-up-circle::before {
  content: "\EA5D"; }

.lni-chevron-up::before {
  content: "\EA5E"; }

.lni-chrome::before {
  content: "\EA5F"; }

.lni-circle-minus::before {
  content: "\EA60"; }

.lni-circle-plus::before {
  content: "\EA61"; }

.lni-clipboard::before {
  content: "\EA62"; }

.lni-close::before {
  content: "\EA63"; }

.lni-cloud-check::before {
  content: "\EA64"; }

.lni-cloud-download::before {
  content: "\EA65"; }

.lni-cloud-network::before {
  content: "\EA66"; }

.lni-cloud-sync::before {
  content: "\EA67"; }

.lni-cloud-upload::before {
  content: "\EA68"; }

.lni-cloud::before {
  content: "\EA69"; }

.lni-cloudy-sun::before {
  content: "\EA6A"; }

.lni-code-alt::before {
  content: "\EA6B"; }

.lni-code::before {
  content: "\EA6C"; }

.lni-codepen::before {
  content: "\EA6D"; }

.lni-coffee-cup::before {
  content: "\EA6E"; }

.lni-cog::before {
  content: "\EA6F"; }

.lni-cogs::before {
  content: "\EA70"; }

.lni-coin::before {
  content: "\EA71"; }

.lni-comments-alt::before {
  content: "\EA72"; }

.lni-comments-reply::before {
  content: "\EA73"; }

.lni-comments::before {
  content: "\EA74"; }

.lni-compass::before {
  content: "\EA75"; }

.lni-construction-hammer::before {
  content: "\EA76"; }

.lni-construction::before {
  content: "\EA77"; }

.lni-consulting::before {
  content: "\EA78"; }

.lni-control-panel::before {
  content: "\EA79"; }

.lni-cpanel::before {
  content: "\EA7A"; }

.lni-creative-commons::before {
  content: "\EA7B"; }

.lni-credit-cards::before {
  content: "\EA7C"; }

.lni-crop::before {
  content: "\EA7D"; }

.lni-cross-circle::before {
  content: "\EA7E"; }

.lni-crown::before {
  content: "\EA7F"; }

.lni-css3::before {
  content: "\EA80"; }

.lni-cup::before {
  content: "\EA81"; }

.lni-customer::before {
  content: "\EA82"; }

.lni-cut::before {
  content: "\EA83"; }

.lni-dashboard::before {
  content: "\EA84"; }

.lni-database::before {
  content: "\EA85"; }

.lni-delivery::before {
  content: "\EA86"; }

.lni-dev::before {
  content: "\EA87"; }

.lni-diamond-alt::before {
  content: "\EA88"; }

.lni-diamond::before {
  content: "\EA89"; }

.lni-diners-club::before {
  content: "\EA8A"; }

.lni-dinner::before {
  content: "\EA8B"; }

.lni-direction-alt::before {
  content: "\EA8C"; }

.lni-direction-ltr::before {
  content: "\EA8D"; }

.lni-direction-rtl::before {
  content: "\EA8E"; }

.lni-direction::before {
  content: "\EA8F"; }

.lni-discord::before {
  content: "\EA90"; }

.lni-discover::before {
  content: "\EA91"; }

.lni-display-alt::before {
  content: "\EA92"; }

.lni-display::before {
  content: "\EA93"; }

.lni-docker::before {
  content: "\EA94"; }

.lni-dollar::before {
  content: "\EA95"; }

.lni-domain::before {
  content: "\EA96"; }

.lni-download::before {
  content: "\EA97"; }

.lni-dribbble::before {
  content: "\EA98"; }

.lni-drop::before {
  content: "\EA99"; }

.lni-dropbox-original::before {
  content: "\EA9A"; }

.lni-dropbox::before {
  content: "\EA9B"; }

.lni-drupal-original::before {
  content: "\EA9C"; }

.lni-drupal::before {
  content: "\EA9D"; }

.lni-dumbbell::before {
  content: "\EA9E"; }

.lni-edge::before {
  content: "\EA9F"; }

.lni-emoji-cool::before {
  content: "\EAA0"; }

.lni-emoji-friendly::before {
  content: "\EAA1"; }

.lni-emoji-happy::before {
  content: "\EAA2"; }

.lni-emoji-sad::before {
  content: "\EAA3"; }

.lni-emoji-smile::before {
  content: "\EAA4"; }

.lni-emoji-speechless::before {
  content: "\EAA5"; }

.lni-emoji-suspect::before {
  content: "\EAA6"; }

.lni-emoji-tounge::before {
  content: "\EAA7"; }

.lni-empty-file::before {
  content: "\EAA8"; }

.lni-enter::before {
  content: "\EAA9"; }

.lni-envato::before {
  content: "\EAAA"; }

.lni-envelope::before {
  content: "\EAAB"; }

.lni-eraser::before {
  content: "\EAAC"; }

.lni-euro::before {
  content: "\EAAD"; }

.lni-exit-down::before {
  content: "\EAAE"; }

.lni-exit-up::before {
  content: "\EAAF"; }

.lni-exit::before {
  content: "\EAB0"; }

.lni-eye::before {
  content: "\EAB1"; }

.lni-facebook-filled::before {
  content: "\EAB2"; }

.lni-facebook-messenger::before {
  content: "\EAB3"; }

.lni-facebook-original::before {
  content: "\EAB4"; }

.lni-facebook-oval::before {
  content: "\EAB5"; }

.lni-facebook::before {
  content: "\EAB6"; }

.lni-figma::before {
  content: "\EAB7"; }

.lni-files::before {
  content: "\EAB8"; }

.lni-firefox-original::before {
  content: "\EAB9"; }

.lni-firefox::before {
  content: "\EABA"; }

.lni-fireworks::before {
  content: "\EABB"; }

.lni-first-aid::before {
  content: "\EABC"; }

.lni-flag-alt::before {
  content: "\EABD"; }

.lni-flag::before {
  content: "\EABE"; }

.lni-flags::before {
  content: "\EABF"; }

.lni-flickr::before {
  content: "\EAC0"; }

.lni-basketball::before {
  content: "\EAC1"; }

.lni-behance::before {
  content: "\EAC2"; }

.lni-forward::before {
  content: "\EAC3"; }

.lni-frame-expand::before {
  content: "\EAC4"; }

.lni-flower::before {
  content: "\EAC5"; }

.lni-full-screen::before {
  content: "\EAC6"; }

.lni-funnel::before {
  content: "\EAC7"; }

.lni-gallery::before {
  content: "\EAC8"; }

.lni-game::before {
  content: "\EAC9"; }

.lni-gift::before {
  content: "\EACA"; }

.lni-git::before {
  content: "\EACB"; }

.lni-github-original::before {
  content: "\EACC"; }

.lni-github::before {
  content: "\EACD"; }

.lni-goodreads::before {
  content: "\EACE"; }

.lni-google-drive::before {
  content: "\EACF"; }

.lni-google-pay::before {
  content: "\EAD0"; }

.lni-fresh-juice::before {
  content: "\EAD1"; }

.lni-folder::before {
  content: "\EAD2"; }

.lni-bi-cycle::before {
  content: "\EAD3"; }

.lni-graph::before {
  content: "\EAD4"; }

.lni-grid-alt::before {
  content: "\EAD5"; }

.lni-grid::before {
  content: "\EAD6"; }

.lni-google-wallet::before {
  content: "\EAD7"; }

.lni-grow::before {
  content: "\EAD8"; }

.lni-hammer::before {
  content: "\EAD9"; }

.lni-hand::before {
  content: "\EADA"; }

.lni-handshake::before {
  content: "\EADB"; }

.lni-harddrive::before {
  content: "\EADC"; }

.lni-headphone-alt::before {
  content: "\EADD"; }

.lni-headphone::before {
  content: "\EADE"; }

.lni-heart-filled::before {
  content: "\EADF"; }

.lni-heart-monitor::before {
  content: "\EAE0"; }

.lni-heart::before {
  content: "\EAE1"; }

.lni-helicopter::before {
  content: "\EAE2"; }

.lni-helmet::before {
  content: "\EAE3"; }

.lni-help::before {
  content: "\EAE4"; }

.lni-highlight-alt::before {
  content: "\EAE5"; }

.lni-highlight::before {
  content: "\EAE6"; }

.lni-home::before {
  content: "\EAE7"; }

.lni-hospital::before {
  content: "\EAE8"; }

.lni-hourglass::before {
  content: "\EAE9"; }

.lni-html5::before {
  content: "\EAEA"; }

.lni-image::before {
  content: "\EAEB"; }

.lni-inbox::before {
  content: "\EAEC"; }

.lni-indent-decrease::before {
  content: "\EAED"; }

.lni-indent-increase::before {
  content: "\EAEE"; }

.lni-infinite::before {
  content: "\EAEF"; }

.lni-information::before {
  content: "\EAF0"; }

.lni-instagram-filled::before {
  content: "\EAF1"; }

.lni-instagram-original::before {
  content: "\EAF2"; }

.lni-instagram::before {
  content: "\EAF3"; }

.lni-invention::before {
  content: "\EAF4"; }

.lni-graduation::before {
  content: "\EAF5"; }

.lni-invest-monitor::before {
  content: "\EAF6"; }

.lni-island::before {
  content: "\EAF7"; }

.lni-italic::before {
  content: "\EAF8"; }

.lni-java::before {
  content: "\EAF9"; }

.lni-javascript::before {
  content: "\EAFA"; }

.lni-jcb::before {
  content: "\EAFB"; }

.lni-joomla-original::before {
  content: "\EAFC"; }

.lni-joomla::before {
  content: "\EAFD"; }

.lni-jsfiddle::before {
  content: "\EAFE"; }

.lni-juice::before {
  content: "\EAFF"; }

.lni-key::before {
  content: "\EB00"; }

.lni-keyboard::before {
  content: "\EB01"; }

.lni-keyword-research::before {
  content: "\EB02"; }

.lni-hacker-news::before {
  content: "\EB03"; }

.lni-google::before {
  content: "\EB04"; }

.lni-laravel::before {
  content: "\EB05"; }

.lni-layers::before {
  content: "\EB06"; }

.lni-layout::before {
  content: "\EB07"; }

.lni-leaf::before {
  content: "\EB08"; }

.lni-library::before {
  content: "\EB09"; }

.lni-licencse::before {
  content: "\EB0A"; }

.lni-life-ring::before {
  content: "\EB0B"; }

.lni-line-dashed::before {
  content: "\EB0C"; }

.lni-line-dotted::before {
  content: "\EB0D"; }

.lni-line-double::before {
  content: "\EB0E"; }

.lni-line-spacing::before {
  content: "\EB0F"; }

.lni-line::before {
  content: "\EB10"; }

.lni-lineicons-alt::before {
  content: "\EB11"; }

.lni-lineicons::before {
  content: "\EB12"; }

.lni-link::before {
  content: "\EB13"; }

.lni-linkedin-original::before {
  content: "\EB14"; }

.lni-linkedin::before {
  content: "\EB15"; }

.lni-list::before {
  content: "\EB16"; }

.lni-lock-alt::before {
  content: "\EB17"; }

.lni-lock::before {
  content: "\EB18"; }

.lni-magnet::before {
  content: "\EB19"; }

.lni-magnifier::before {
  content: "\EB1A"; }

.lni-mailchimp::before {
  content: "\EB1B"; }

.lni-map-marker::before {
  content: "\EB1C"; }

.lni-map::before {
  content: "\EB1D"; }

.lni-mashroom::before {
  content: "\EB1E"; }

.lni-mastercard::before {
  content: "\EB1F"; }

.lni-medall-alt::before {
  content: "\EB20"; }

.lni-medall::before {
  content: "\EB21"; }

.lni-medium::before {
  content: "\EB22"; }

.lni-laptop::before {
  content: "\EB23"; }

.lni-investment::before {
  content: "\EB24"; }

.lni-laptop-phone::before {
  content: "\EB25"; }

.lni-megento::before {
  content: "\EB26"; }

.lni-mic::before {
  content: "\EB27"; }

.lni-microphone::before {
  content: "\EB28"; }

.lni-menu::before {
  content: "\EB29"; }

.lni-microscope::before {
  content: "\EB2A"; }

.lni-money-location::before {
  content: "\EB2B"; }

.lni-minus::before {
  content: "\EB2C"; }

.lni-mobile::before {
  content: "\EB2D"; }

.lni-more-alt::before {
  content: "\EB2E"; }

.lni-mouse::before {
  content: "\EB2F"; }

.lni-move::before {
  content: "\EB30"; }

.lni-music::before {
  content: "\EB31"; }

.lni-network::before {
  content: "\EB32"; }

.lni-night::before {
  content: "\EB33"; }

.lni-nodejs-alt::before {
  content: "\EB34"; }

.lni-nodejs::before {
  content: "\EB35"; }

.lni-notepad::before {
  content: "\EB36"; }

.lni-npm::before {
  content: "\EB37"; }

.lni-offer::before {
  content: "\EB38"; }

.lni-opera::before {
  content: "\EB39"; }

.lni-package::before {
  content: "\EB3A"; }

.lni-page-break::before {
  content: "\EB3B"; }

.lni-pagination::before {
  content: "\EB3C"; }

.lni-paint-bucket::before {
  content: "\EB3D"; }

.lni-paint-roller::before {
  content: "\EB3E"; }

.lni-pallet::before {
  content: "\EB3F"; }

.lni-paperclip::before {
  content: "\EB40"; }

.lni-more::before {
  content: "\EB41"; }

.lni-pause::before {
  content: "\EB42"; }

.lni-paypal-original::before {
  content: "\EB43"; }

.lni-microsoft::before {
  content: "\EB44"; }

.lni-money-protection::before {
  content: "\EB45"; }

.lni-pencil::before {
  content: "\EB46"; }

.lni-paypal::before {
  content: "\EB47"; }

.lni-pencil-alt::before {
  content: "\EB48"; }

.lni-patreon::before {
  content: "\EB49"; }

.lni-phone-set::before {
  content: "\EB4A"; }

.lni-phone::before {
  content: "\EB4B"; }

.lni-pin::before {
  content: "\EB4C"; }

.lni-pinterest::before {
  content: "\EB4D"; }

.lni-pie-chart::before {
  content: "\EB4E"; }

.lni-pilcrow::before {
  content: "\EB4F"; }

.lni-plane::before {
  content: "\EB50"; }

.lni-play::before {
  content: "\EB51"; }

.lni-plug::before {
  content: "\EB52"; }

.lni-plus::before {
  content: "\EB53"; }

.lni-pointer-down::before {
  content: "\EB54"; }

.lni-pointer-left::before {
  content: "\EB55"; }

.lni-pointer-right::before {
  content: "\EB56"; }

.lni-pointer-up::before {
  content: "\EB57"; }

.lni-play-store::before {
  content: "\EB58"; }

.lni-pizza::before {
  content: "\EB59"; }

.lni-postcard::before {
  content: "\EB5A"; }

.lni-pound::before {
  content: "\EB5B"; }

.lni-power-switch::before {
  content: "\EB5C"; }

.lni-printer::before {
  content: "\EB5D"; }

.lni-producthunt::before {
  content: "\EB5E"; }

.lni-protection::before {
  content: "\EB5F"; }

.lni-pulse::before {
  content: "\EB60"; }

.lni-pyramids::before {
  content: "\EB61"; }

.lni-python::before {
  content: "\EB62"; }

.lni-pointer::before {
  content: "\EB63"; }

.lni-popup::before {
  content: "\EB64"; }

.lni-quotation::before {
  content: "\EB65"; }

.lni-radio-button::before {
  content: "\EB66"; }

.lni-rain::before {
  content: "\EB67"; }

.lni-quora::before {
  content: "\EB68"; }

.lni-react::before {
  content: "\EB69"; }

.lni-question-circle::before {
  content: "\EB6A"; }

.lni-php::before {
  content: "\EB6B"; }

.lni-reddit::before {
  content: "\EB6C"; }

.lni-reload::before {
  content: "\EB6D"; }

.lni-restaurant::before {
  content: "\EB6E"; }

.lni-road::before {
  content: "\EB6F"; }

.lni-rocket::before {
  content: "\EB70"; }

.lni-rss-feed::before {
  content: "\EB71"; }

.lni-ruler-alt::before {
  content: "\EB72"; }

.lni-ruler-pencil::before {
  content: "\EB73"; }

.lni-ruler::before {
  content: "\EB74"; }

.lni-rupee::before {
  content: "\EB75"; }

.lni-save::before {
  content: "\EB76"; }

.lni-school-bench-alt::before {
  content: "\EB77"; }

.lni-school-bench::before {
  content: "\EB78"; }

.lni-scooter::before {
  content: "\EB79"; }

.lni-scroll-down::before {
  content: "\EB7A"; }

.lni-search-alt::before {
  content: "\EB7B"; }

.lni-search::before {
  content: "\EB7C"; }

.lni-select::before {
  content: "\EB7D"; }

.lni-seo::before {
  content: "\EB7E"; }

.lni-service::before {
  content: "\EB7F"; }

.lni-share-alt::before {
  content: "\EB80"; }

.lni-share::before {
  content: "\EB81"; }

.lni-shield::before {
  content: "\EB82"; }

.lni-shift-left::before {
  content: "\EB83"; }

.lni-shift-right::before {
  content: "\EB84"; }

.lni-ship::before {
  content: "\EB85"; }

.lni-shopify::before {
  content: "\EB86"; }

.lni-shopping-basket::before {
  content: "\EB87"; }

.lni-shortcode::before {
  content: "\EB88"; }

.lni-shovel::before {
  content: "\EB89"; }

.lni-shuffle::before {
  content: "\EB8A"; }

.lni-signal::before {
  content: "\EB8B"; }

.lni-sketch::before {
  content: "\EB8C"; }

.lni-skipping-rope::before {
  content: "\EB8D"; }

.lni-skype::before {
  content: "\EB8E"; }

.lni-slack::before {
  content: "\EB8F"; }

.lni-slice::before {
  content: "\EB90"; }

.lni-slideshare::before {
  content: "\EB91"; }

.lni-slim::before {
  content: "\EB92"; }

.lni-reply::before {
  content: "\EB93"; }

.lni-sort-alpha-asc::before {
  content: "\EB94"; }

.lni-remove-file::before {
  content: "\EB95"; }

.lni-sort-amount-dsc::before {
  content: "\EB96"; }

.lni-sort-amount-asc::before {
  content: "\EB97"; }

.lni-soundcloud::before {
  content: "\EB98"; }

.lni-souncloud-original::before {
  content: "\EB99"; }

.lni-spiner-solid::before {
  content: "\EB9A"; }

.lni-revenue::before {
  content: "\EB9B"; }

.lni-spinner::before {
  content: "\EB9C"; }

.lni-spellcheck::before {
  content: "\EB9D"; }

.lni-spotify::before {
  content: "\EB9E"; }

.lni-spray::before {
  content: "\EB9F"; }

.lni-sprout::before {
  content: "\EBA0"; }

.lni-snapchat::before {
  content: "\EBA1"; }

.lni-stamp::before {
  content: "\EBA2"; }

.lni-star-empty::before {
  content: "\EBA3"; }

.lni-star-filled::before {
  content: "\EBA4"; }

.lni-star-half::before {
  content: "\EBA5"; }

.lni-star::before {
  content: "\EBA6"; }

.lni-stats-down::before {
  content: "\EBA7"; }

.lni-spinner-arrow::before {
  content: "\EBA8"; }

.lni-steam::before {
  content: "\EBA9"; }

.lni-stackoverflow::before {
  content: "\EBAA"; }

.lni-stop::before {
  content: "\EBAB"; }

.lni-strikethrough::before {
  content: "\EBAC"; }

.lni-sthethoscope::before {
  content: "\EBAD"; }

.lni-stumbleupon::before {
  content: "\EBAE"; }

.lni-sun::before {
  content: "\EBAF"; }

.lni-support::before {
  content: "\EBB0"; }

.lni-surf-board::before {
  content: "\EBB1"; }

.lni-swift::before {
  content: "\EBB2"; }

.lni-syringe::before {
  content: "\EBB3"; }

.lni-tab::before {
  content: "\EBB4"; }

.lni-tag::before {
  content: "\EBB5"; }

.lni-target-customer::before {
  content: "\EBB6"; }

.lni-target-revenue::before {
  content: "\EBB7"; }

.lni-target::before {
  content: "\EBB8"; }

.lni-taxi::before {
  content: "\EBB9"; }

.lni-stats-up::before {
  content: "\EBBA"; }

.lni-telegram-original::before {
  content: "\EBBB"; }

.lni-telegram::before {
  content: "\EBBC"; }

.lni-text-align-center::before {
  content: "\EBBD"; }

.lni-text-align-justify::before {
  content: "\EBBE"; }

.lni-text-align-left::before {
  content: "\EBBF"; }

.lni-text-format-remove::before {
  content: "\EBC0"; }

.lni-text-align-right::before {
  content: "\EBC1"; }

.lni-text-format::before {
  content: "\EBC2"; }

.lni-thought::before {
  content: "\EBC3"; }

.lni-thumbs-down::before {
  content: "\EBC4"; }

.lni-thumbs-up::before {
  content: "\EBC5"; }

.lni-thunder-alt::before {
  content: "\EBC6"; }

.lni-thunder::before {
  content: "\EBC7"; }

.lni-ticket-alt::before {
  content: "\EBC8"; }

.lni-ticket::before {
  content: "\EBC9"; }

.lni-timer::before {
  content: "\EBCA"; }

.lni-train-alt::before {
  content: "\EBCB"; }

.lni-train::before {
  content: "\EBCC"; }

.lni-trash::before {
  content: "\EBCD"; }

.lni-travel::before {
  content: "\EBCE"; }

.lni-tree::before {
  content: "\EBCF"; }

.lni-trees::before {
  content: "\EBD0"; }

.lni-trello::before {
  content: "\EBD1"; }

.lni-trowel::before {
  content: "\EBD2"; }

.lni-tshirt::before {
  content: "\EBD3"; }

.lni-tumblr::before {
  content: "\EBD4"; }

.lni-twitch::before {
  content: "\EBD5"; }

.lni-twitter-filled::before {
  content: "\EBD6"; }

.lni-twitter-original::before {
  content: "\EBD7"; }

.lni-twitter::before {
  content: "\EBD8"; }

.lni-ubuntu::before {
  content: "\EBD9"; }

.lni-underline::before {
  content: "\EBDA"; }

.lni-unlink::before {
  content: "\EBDB"; }

.lni-unlock::before {
  content: "\EBDC"; }

.lni-upload::before {
  content: "\EBDD"; }

.lni-user::before {
  content: "\EBDE"; }

.lni-users::before {
  content: "\EBDF"; }

.lni-ux::before {
  content: "\EBE0"; }

.lni-vector::before {
  content: "\EBE1"; }

.lni-video::before {
  content: "\EBE2"; }

.lni-vimeo::before {
  content: "\EBE3"; }

.lni-visa::before {
  content: "\EBE4"; }

.lni-vk::before {
  content: "\EBE5"; }

.lni-volume-high::before {
  content: "\EBE6"; }

.lni-volume-low::before {
  content: "\EBE7"; }

.lni-volume-medium::before {
  content: "\EBE8"; }

.lni-volume-mute::before {
  content: "\EBE9"; }

.lni-volume::before {
  content: "\EBEA"; }

.lni-wallet::before {
  content: "\EBEB"; }

.lni-warning::before {
  content: "\EBEC"; }

.lni-website-alt::before {
  content: "\EBED"; }

.lni-website::before {
  content: "\EBEE"; }

.lni-wechat::before {
  content: "\EBEF"; }

.lni-weight::before {
  content: "\EBF0"; }

.lni-whatsapp::before {
  content: "\EBF1"; }

.lni-wheelbarrow::before {
  content: "\EBF2"; }

.lni-wheelchair::before {
  content: "\EBF3"; }

.lni-windows::before {
  content: "\EBF4"; }

.lni-wordpress-filled::before {
  content: "\EBF5"; }

.lni-wordpress::before {
  content: "\EBF6"; }

.lni-world-alt::before {
  content: "\EBF7"; }

.lni-world::before {
  content: "\EBF8"; }

.lni-write::before {
  content: "\EBF9"; }

.lni-yahoo::before {
  content: "\EBFA"; }

.lni-ycombinator::before {
  content: "\EBFB"; }

.lni-yen::before {
  content: "\EBFC"; }

.lni-youtube::before {
  content: "\EBFD"; }

.lni-zip::before {
  content: "\EBFE"; }

.lni-zoom-in::before {
  content: "\EBFF"; }

.lni-zoom-out::before {
  content: "\EC00"; }

.lni-teabag::before {
  content: "\EC01"; }

.lni-stripe::before {
  content: "\EC02"; }

.lni-spotify-original::before {
  content: "\EC03"; }

.tns-outer {
  padding: 0 !important; }

.tns-outer [hidden] {
  display: none !important; }

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer; }

.tns-slider {
  transition: all 0s; }

.tns-slider > .tns-item {
  box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  transition: transform 0s, opacity 0s; }

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important; }

.tns-gallery > .tns-moving {
  transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6; }

.tns-lazy-img.tns-complete {
  opacity: 1; }

.tns-ah {
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: 2333.3333333%;
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both; }

.tns-t-ct > div {
  width: 1.4285714%;
  width: calc(100% / 70);
  height: 10px;
  float: left; }

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */
/* ===========================
Index Of css

01. Veriables CSS
02. Normalize CSS
03. Header CSS
04. Hero CSS
05. About CSS
06. Achievement CSS
07. Testimonial CSS
08. Pricing CSS
09. Faq CSS
10. Blog CSS
11. Clients CSS
12. Categories CSS
13. Items Grid CSS
14. Browse Chities CSS
15. Items Tab CSS
16. Newsletter CSS
17. Dashboard CSS
18. Login CSS
19. Why Choose CSS
20. Item Details CSS
21. How Works CSS
22. Call To Action CSS
23. Bookmarked CSS
24. Responsive CSS
25. Contact CSS
26. Error CSS
27. Mail Success CSS
28. Coming Soon CSS
29. Footer CSS
========================== */
/*======================================
    Variables
========================================*/
/*======================================
    Normalize CSS
========================================*/
@keyframes color {
  0% {
    background-color: #ECECEC; }
  50% {
    background-color: #CFCFCF; }
  100% {
    background-color: #ECECEC; } }

html {
  scroll-behavior: smooth; }

body {
  font-family: "Jost", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #888;
  overflow-x: hidden;
  font-size: 15px; }

p {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 15px; }

* {
  margin: 0;
  padding: 0; }

.filter_blue {
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

.filter_white {
  filter: invert(99%) sepia(0%) saturate(7496%) hue-rotate(251deg) brightness(104%) contrast(104%); }

.filter_grey {
  filter: invert(51%) sepia(0%) saturate(84%) hue-rotate(215deg) brightness(104%) contrast(96%); }

.navbar-toggler:focus,
a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  box-shadow: none !important; }

.form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff; }

select {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: #081828;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: -internal-light-dark(white, #3b3b3b);
  cursor: default;
  margin: 0em;
  font: 400 14px;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(#767676, #858585);
  border-image: initial; }

span,
a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0px;
  color: #081828; }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit; }

h1 {
  font-size: 50px; }

h2 {
  font-size: 40px; }

h3 {
  font-size: 30px; }

h4 {
  font-size: 25px; }

h5 {
  font-size: 20px; }

h6 {
  font-size: 16px; }

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-25 {
  margin-top: 25px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-40 {
  margin-top: 40px; }

.mt-45 {
  margin-top: 45px; }

.mt-50 {
  margin-top: 50px; }

.mt-55 {
  margin-top: 55px; }

.mt-60 {
  margin-top: 60px; }

.mt-65 {
  margin-top: 65px; }

.mt-70 {
  margin-top: 70px; }

.mt-75 {
  margin-top: 75px; }

.mt-80 {
  margin-top: 80px; }

.mt-85 {
  margin-top: 85px; }

.mt-90 {
  margin-top: 90px; }

.mt-95 {
  margin-top: 95px; }

.mt-100 {
  margin-top: 100px; }

.mt-105 {
  margin-top: 105px; }

.mt-110 {
  margin-top: 110px; }

.mt-115 {
  margin-top: 115px; }

.mt-120 {
  margin-top: 120px; }

.mt-125 {
  margin-top: 125px; }

.mt-130 {
  margin-top: 130px; }

.mt-135 {
  margin-top: 135px; }

.mt-140 {
  margin-top: 140px; }

.mt-145 {
  margin-top: 145px; }

.mt-150 {
  margin-top: 150px; }

.mt-155 {
  margin-top: 155px; }

.mt-160 {
  margin-top: 160px; }

.mt-165 {
  margin-top: 165px; }

.mt-170 {
  margin-top: 170px; }

.mt-175 {
  margin-top: 175px; }

.mt-180 {
  margin-top: 180px; }

.mt-185 {
  margin-top: 185px; }

.mt-190 {
  margin-top: 190px; }

.mt-195 {
  margin-top: 195px; }

.mt-200 {
  margin-top: 200px; }

.mt-205 {
  margin-top: 205px; }

.mt-210 {
  margin-top: 210px; }

.mt-215 {
  margin-top: 215px; }

.mt-220 {
  margin-top: 220px; }

.mt-225 {
  margin-top: 225px; }

.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-35 {
  margin-bottom: 35px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-45 {
  margin-bottom: 45px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-55 {
  margin-bottom: 55px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-65 {
  margin-bottom: 65px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-75 {
  margin-bottom: 75px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-85 {
  margin-bottom: 85px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-95 {
  margin-bottom: 95px; }

.mb-100 {
  margin-bottom: 100px; }

.mb-105 {
  margin-bottom: 105px; }

.mb-110 {
  margin-bottom: 110px; }

.mb-115 {
  margin-bottom: 115px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-125 {
  margin-bottom: 125px; }

.mb-130 {
  margin-bottom: 130px; }

.mb-135 {
  margin-bottom: 135px; }

.mb-140 {
  margin-bottom: 140px; }

.mb-145 {
  margin-bottom: 145px; }

.mb-150 {
  margin-bottom: 150px; }

.mb-155 {
  margin-bottom: 155px; }

.mb-160 {
  margin-bottom: 160px; }

.mb-165 {
  margin-bottom: 165px; }

.mb-170 {
  margin-bottom: 170px; }

.mb-175 {
  margin-bottom: 175px; }

.mb-180 {
  margin-bottom: 180px; }

.mb-185 {
  margin-bottom: 185px; }

.mb-190 {
  margin-bottom: 190px; }

.mb-195 {
  margin-bottom: 195px; }

.mb-200 {
  margin-bottom: 200px; }

.mb-205 {
  margin-bottom: 205px; }

.mb-210 {
  margin-bottom: 210px; }

.mb-215 {
  margin-bottom: 215px; }

.mb-220 {
  margin-bottom: 220px; }

.mb-225 {
  margin-bottom: 225px; }

.pt-5 {
  padding-top: 5px; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-25 {
  padding-top: 25px; }

.pt-30 {
  padding-top: 30px; }

.pt-35 {
  padding-top: 35px; }

.pt-40 {
  padding-top: 40px; }

.pt-45 {
  padding-top: 45px; }

.pt-50 {
  padding-top: 50px; }

.pt-55 {
  padding-top: 55px; }

.pt-60 {
  padding-top: 60px; }

.pt-65 {
  padding-top: 65px; }

.pt-70 {
  padding-top: 70px; }

.pt-75 {
  padding-top: 75px; }

.pt-80 {
  padding-top: 80px; }

.pt-85 {
  padding-top: 85px; }

.pt-90 {
  padding-top: 90px; }

.pt-95 {
  padding-top: 95px; }

.pt-100 {
  padding-top: 100px; }

.pt-105 {
  padding-top: 105px; }

.pt-110 {
  padding-top: 110px; }

.pt-115 {
  padding-top: 115px; }

.pt-120 {
  padding-top: 120px; }

.pt-125 {
  padding-top: 125px; }

.pt-130 {
  padding-top: 130px; }

.pt-135 {
  padding-top: 135px; }

.pt-140 {
  padding-top: 140px; }

.pt-145 {
  padding-top: 145px; }

.pt-150 {
  padding-top: 150px; }

.pt-155 {
  padding-top: 155px; }

.pt-160 {
  padding-top: 160px; }

.pt-165 {
  padding-top: 165px; }

.pt-170 {
  padding-top: 170px; }

.pt-175 {
  padding-top: 175px; }

.pt-180 {
  padding-top: 180px; }

.pt-185 {
  padding-top: 185px; }

.pt-190 {
  padding-top: 190px; }

.pt-195 {
  padding-top: 195px; }

.pt-200 {
  padding-top: 200px; }

.pt-205 {
  padding-top: 205px; }

.pt-210 {
  padding-top: 210px; }

.pt-215 {
  padding-top: 215px; }

.pt-220 {
  padding-top: 220px; }

.pt-225 {
  padding-top: 225px; }

.pb-5 {
  padding-bottom: 5px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-25 {
  padding-bottom: 25px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-35 {
  padding-bottom: 35px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-45 {
  padding-bottom: 45px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-55 {
  padding-bottom: 55px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-65 {
  padding-bottom: 65px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-75 {
  padding-bottom: 75px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-85 {
  padding-bottom: 85px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-95 {
  padding-bottom: 95px; }

.pb-100 {
  padding-bottom: 100px; }

.pb-105 {
  padding-bottom: 105px; }

.pb-110 {
  padding-bottom: 110px; }

.pb-115 {
  padding-bottom: 115px; }

.pb-120 {
  padding-bottom: 120px; }

.pb-125 {
  padding-bottom: 125px; }

.pb-130 {
  padding-bottom: 130px; }

.pb-135 {
  padding-bottom: 135px; }

.pb-140 {
  padding-bottom: 140px; }

.pb-145 {
  padding-bottom: 145px; }

.pb-150 {
  padding-bottom: 150px; }

.pb-155 {
  padding-bottom: 155px; }

.pb-160 {
  padding-bottom: 160px; }

.pb-165 {
  padding-bottom: 165px; }

.pb-170 {
  padding-bottom: 170px; }

.pb-175 {
  padding-bottom: 175px; }

.pb-180 {
  padding-bottom: 180px; }

.pb-185 {
  padding-bottom: 185px; }

.pb-190 {
  padding-bottom: 190px; }

.pb-195 {
  padding-bottom: 195px; }

.pb-200 {
  padding-bottom: 200px; }

.pb-205 {
  padding-bottom: 205px; }

.pb-210 {
  padding-bottom: 210px; }

.pb-215 {
  padding-bottom: 215px; }

.pb-220 {
  padding-bottom: 220px; }

.pb-225 {
  padding-bottom: 225px; }

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px; } }

/* Bread Crumbs */
.breadcrumbs {
  position: relative;
  padding-bottom: 40px;
  padding-top: 50px;
  background: #00aeff;
  z-index: 2;
  text-align: left; }

.breadcrumbs .breadcrumbs-content {
  position: relative;
  text-align: left; }

.breadcrumbs .breadcrumbs-content .page-title {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  position: relative;
  line-height: 50px; }

.breadcrumbs .breadcrumbs-content .breadcrumb-nav {
  background: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block; }

.breadcrumbs .breadcrumb-nav {
  text-align: right; }

.breadcrumbs .breadcrumb-nav li {
  display: inline-block;
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  text-transform: capitalize; }

.breadcrumbs .breadcrumb-nav li:after {
  content: "\EA5C";
  font-family: lineIcons;
  font-size: 10px;
  position: absolute;
  top: 4px;
  right: -7px; }

.breadcrumbs .breadcrumb-nav li:last-child {
  margin: 0;
  padding: 0; }

.breadcrumbs .breadcrumb-nav li:last-child::after {
  display: none; }

.breadcrumbs .breadcrumb-nav li,
.breadcrumbs .breadcrumb-nav li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500; }

.breadcrumbs .breadcrumb-nav li a {
  position: relative; }

.breadcrumbs .breadcrumb-nav li a:hover {
  color: #00aeff; }

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative; }

/* Section Title */
.section-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 300px;
  position: relative;
  z-index: 5; }

.section-title h2 {
  font-size: 35px;
  margin-bottom: 20px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  padding-bottom: 20px; }

.section-title h2:before {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  bottom: -2px;
  height: 4px;
  width: 50px;
  border-radius: 5px;
  background: #00aeff;
  content: ""; }

.section-title p {
  font-size: 16px;
  line-height: 26px; }

.section-title.align-right {
  padding: 0;
  padding-left: 600px; }

.section-title.align-right h2:before {
  display: none; }

.section-title.align-right h2:after {
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: #00aeff;
  content: ""; }

.section-title.align-left {
  padding: 0;
  padding-right: 600px; }

.section-title.align-left h2:before {
  left: 0;
  margin-left: 0; }

/* One Click Scrool Top Button*/
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #00aeff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  border-radius: 0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  transition: all .3s ease-out 0s;
  border-radius: 4px; }

.scroll-top:hover {
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  transform: translate3d(0, -5px, 0);
  background-color: #081828; }

/* Overlay */
.overlay {
  position: relative; }

.overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  content: "";
  transition: all 0.4s ease;
  z-index: 5; }

/* Pagination CSS */
.pagination {
  text-align: left;
  margin: 30px 0 0 0;
  display: block; }

.pagination.center {
  text-align: center; }

.pagination.right {
  text-align: right; }

.pagination.left {
  text-align: left; }

.pagination .pagination-list {
  display: inline-block;
  overflow: hidden; }

.pagination .pagination-list li {
  margin-right: 5px;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer; }

.pagination .pagination-list li:last-child {
  margin-right: 0px; }

.pagination .pagination-list li a {
  background: #fff;
  color: #081828;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0;
  line-height: 38px;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #eee; }

.pagination .pagination-list li.active a,
.pagination .pagination-list li:hover a {
  background: #00aeff;
  color: #fff;
  border-color: transparent; }

.pagination .pagination-list li a i {
  font-size: 13px; }

.blog-grids.pagination {
  margin-top: 50px;
  text-align: center; }

.button .btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 30px;
  background-color: #00aeff;
  color: #fff;
  border: none;
  transition: 0.5s;
  border-radius: 4px; }

.button .btn:hover {
  background-color: #081828;
  color: #fff;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  border: 1px solid #081828; }

.button .btn-alt {
  background-color: #081828;
  color: #fff; }

.button .btn-alt:hover {
  background-color: #00aeff;
  color: #fff;
  border: 1px solid #081828; }

.button .btn-white {
  background-color: #FFF;
  color: #888; }

.button .btn-white:hover {
  background-color: #081828;
  color: #fff;
  border: 1px solid #081828; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.align-center {
  text-align: center; }

/* Preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden; }

.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.preloader-icon {
  width: 100px;
  height: 100px;
  display: inline-block;
  padding: 0px; }

.preloader-icon span {
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #00aeff;
  animation: preloader-fx 1.6s linear infinite; }

.preloader-icon span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s; }

@keyframes preloader-fx {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    transform: scale(1, 1);
    opacity: 0; } }

/*======================================
	01. Start Header CSS
========================================*/
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff; }

.header .login-button {
  margin-left: 40px; }

.header .login-button ul {
  padding: 14px 20px;
  border-radius: 30px; }

.header .login-button ul li {
  display: inline-block;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #eee;
  height: 100%; }

.header .login-button ul li:last-child {
  margin: 0;
  padding: 0;
  border: none; }

.header .login-button ul li a {
  color: #081828;
  font-size: 14px;
  font-weight: 500; }

.header .login-button ul li a:hover {
  color: #00aeff; }

.header .login-button ul li a i {
  display: inline-block;
  color: #00aeff;
  margin-right: 4px;
  font-size: 15px; }

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-out 0s;
  top: 0; }

.navbar-expand-lg .navbar-nav {
  margin-left: auto; }

.sticky .navbar .navbar-nav .nav-item a {
  color: #333; }

.header .navbar .navbar-nav .nav-item a.active {
  color: #00aeff; }

.sticky .navbar .navbar-nav .nav-item a.active {
  color: #00aeff; }

.header .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff; }

.sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff; }

.sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333; }

/* Topbar */
.header .toolbar-area {
  padding: 18px 0;
  background: #00aeff;
  overflow: hidden; }

.header .toolbar-area .toolbar-social {
  float: right; }

.header .toolbar-area .toolbar-social ul li {
  display: inline-block;
  margin-right: 15px; }

.header .toolbar-area .toolbar-social ul li .title {
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  color: #fff; }

.header .toolbar-area .toolbar-social ul li:last-child {
  margin: 0; }

.header .toolbar-area .toolbar-social ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease; }

.header .toolbar-area .toolbar-social ul li a:hover {
  color: #081828; }

.header .toolbar-login {
  float: left; }

.header .toolbar-login span {
  display: inline-block;
  color: #fff;
  margin: 0px 12px;
  font-size: 13px;
  font-weight: 600; }

.header .toolbar-login a {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-size: 13px; }

.header .toolbar-login a i {
  display: inline-block;
  margin-right: 3px;
  color: #fff;
  font-size: 11px;
  height: 22px;
  width: 22px;
  text-align: center;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  color: #00aeff;
  line-height: 22px; }

.header .toolbar-login a:hover {
  color: #081828; }

.header .toolbar-login a:last-child {
  margin: 0; }

/*===== NAVBAR =====*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-out 0s;
  padding: 0; }

.navbar-area.sticky {
  position: fixed;
  z-index: 99;
  background: #00aeff;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-out 0s;
  background: #fff;
  padding: 0px 0;
  min-height: 98px;
  max-height: 98px; }

.navbar-area.sticky .toolbar-area {
  display: none; }

.navbar {
  padding: 0;
  position: relative;
  transition: all 0.3s ease-out 0s; }

.navbar-brand {
  padding: 0; }

.navbar-brand img {
  width: 190px; }

.mobile-menu-btn {
  padding: 0px; }

.mobile-menu-btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none; }

.mobile-menu-btn .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #222;
  display: block;
  margin: 5px 0;
  position: relative;
  transition: all 0.3s ease-out 0s; }

.mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
  transform: rotate(45deg);
  top: 7px; }

.mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
  opacity: 0; }

.mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
  transform: rotate(135deg);
  top: -7px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 66px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee; } }

@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 165% !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll; } }

.mobile-navbar {
  display: block; }

@media (min-width: 992px) {
  .navbar-expand-lg .mobile-navbar {
    display: none !important; } }

.navbar-expand-lg .browser-navbar {
  display: none !important; }

@media (min-width: 992px) {
  .navbar-expand-lg .browser-navbar {
    display: flex !important; } }

.navbar-expand-lg .navbar-nav {
  margin: 0;
  margin-right: auto !important; }

.navbar-nav .nav-item {
  z-index: 1;
  position: relative;
  margin-right: 40px; }

.navbar-nav .nav-item:last-child {
  margin-right: 0 !important; }

.navbar-nav .nav-item:hover a {
  color: #00aeff; }

.navbar-nav .nav-item a {
  font-size: 15px;
  color: #081828;
  transition: all 0.3s ease-out 0s;
  position: relative;
  padding: 18px 0;
  padding-top: 38px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
  position: relative;
  text-transform: capitalize; }

.navbar-nav .nav-item a::after {
  opacity: 0;
  visibility: hidden; }

.navbar-nav .nav-item a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 0%;
  background: #00aeff;
  transition: all 0.3s ease-in-out; }

.navbar-nav .nav-item a.active:before {
  width: 100%; }

.navbar-nav .nav-item:hover a:before {
  width: 100%; }

.navbar-nav .nav-item a.active {
  color: #00aeff; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu {
    padding-right: 30px; } }

.navbar-nav .nav-item a.dd-menu::after {
  content: "\EA58";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 17px;
  font-size: 10px;
  top: 50%;
  margin-left: 5px;
  margin-top: 0px;
  transition: all 0.3s ease-out 0s;
  height: 10px;
  margin-top: -5px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu::after {
    right: 13px; } }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a.dd-menu::after {
    top: 16px;
    right: 0;
    transform: rotate(180deg); } }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a.collapsed::after {
    transform: rotate(0deg); } }

.navbar-nav .nav-item:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible; }

.navbar-nav .nav-item:hover > .sub-menu .sub-menu {
  left: 100%;
  top: 0; }

.navbar-nav .nav-item .sub-menu {
  border-top: 1px solid #eee;
  padding: 10px;
  min-width: 220px;
  background: #fff;
  box-shadow: 0px 10px 20px #1414140e;
  position: absolute;
  top: 100% !important;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s; }

.navbar-nav .nav-item .sub-menu.mega-menu {
  width: 500px;
  white-space: none !important;
  padding-right: 5px; }

.navbar-nav .nav-item .sub-menu.mega-menu .mega-menu-title {
  font-size: 16px;
  display: block;
  font-weight: 500;
  color: #081828;
  margin-bottom: 10px;
  padding-left: 12px;
  padding-top: 12px; }

.navbar-nav .nav-item .sub-menu.mega-menu .single-block {
  width: 49.5% !important;
  display: inline-block !important;
  white-space: none !important;
  border-right: 1px solid #eee;
  height: 100%;
  float: left; }

.navbar-nav .nav-item .sub-menu.mega-menu .single-block:first-child {
  padding-right: 10px; }

.navbar-nav .nav-item .sub-menu.mega-menu .single-block:last-child {
  border: none;
  padding-left: 10px; }

.navbar-nav .nav-item .sub-menu.mega-menu .single-block li a:hover {
  background-color: #00aeff;
  color: #fff; }

.navbar-nav .nav-item .sub-menu.mega-menu .single-block li.active a {
  background-color: #00aeff;
  color: #fff; }

.navbar-nav .nav-item .sub-menu .nav-item {
  width: 100%; }

.navbar-nav .nav-item .sub-menu .nav-item a {
  padding: 12px 15px;
  color: #888;
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  position: relative; }

.navbar-nav .nav-item .sub-menu .nav-item a:before {
  display: none; }

.navbar-nav .nav-item .sub-menu.left-menu {
  left: -100%; }

.navbar-nav .nav-item .sub-menu.collapse:not(.show) {
  display: block; }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item {
    margin: 0; }
  .navbar-nav .nav-item a {
    padding: 12px 16px !important; }
  .navbar-nav .nav-item a::before {
    display: none; }
  .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px; }
  .navbar-nav .nav-item .sub-menu.mega-menu {
    width: 100% !important;
    white-space: none !important;
    padding-right: 0; }
  .navbar-nav .nav-item .sub-menu.mega-menu .single-block {
    width: 100% !important;
    display: block !important;
    white-space: none !important;
    border: none;
    height: 100%;
    float: left; }
  .navbar-nav .nav-item .sub-menu.mega-menu .single-block:first-child {
    padding-right: 0; }
  .navbar-nav .nav-item .sub-menu.mega-menu .single-block:last-child {
    border: none;
    padding-left: 0; }
  .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px; }
  .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    background: #00aeff !important;
    color: #fff !important; }
  .navbar-nav .nav-item .sub-menu::after {
    display: none; } }

.navbar-nav .nav-item .sub-menu > li {
  display: block;
  margin-left: 0; }

.navbar-nav .nav-item .sub-menu > li:last-child {
  border: none; }

.navbar-nav .nav-item .sub-menu > li.active > a,
.navbar-nav .nav-item .sub-menu > li:hover > a {
  color: #fff !important;
  background-color: #00aeff !important; }

.navbar-nav .nav-item .sub-menu > li > a {
  font-weight: 400;
  display: block;
  padding: 12px 15px;
  font-size: 14px;
  color: #222;
  font-weight: 400; }

.navbar-nav .nav-item .sub-menu > li:first-child a {
  border: none; }

.navbar-nav .nav-item .sub-menu > li > a:hover {
  background: #00aeff;
  color: #fff; }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a {
    color: #051441;
    display: flex;
    justify-content: space-between;
    padding: 10px 0; }
  .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible; }
  .navbar-nav .nav-item .sub-menu > li.active {
    background: #f9f9f9 !important;
    color: #00aeff !important; }
  .header.style3.navbar-area .header-social {
    display: none; }
  .navbar-nav .nav-item .sub-menu > li.active > a,
  .navbar-nav .nav-item .sub-menu > li:hover > a {
    color: #fff !important;
    background-color: #00aeff !important; } }

.header-button {
  display: inline-block;
  margin-left: 10px; }

.header-button .btn i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px; }

/*======================================
     End Header CSS
  ========================================*/
/*======================================
    Hero Area CSS
========================================*/
.hero-area {
  background: #00aeff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 2; }

/* Shimmer Effect */
.hero-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(120deg, transparent 30%, rgba(255, 255, 255, 0.5) 50%, transparent 70%);
  animation: shimmer 0.75s linear forwards;
  /* Runs once */ }

@keyframes shimmer {
  0% {
    left: -100%;
    width: 200%; }
  100% {
    left: 100%;
    width: 0%; } }

.hero-area.overlay::before {
  z-index: -1;
  opacity: 0.9; }

.hero-area.style2 {
  padding-top: 100px;
  padding-bottom: 100px;
  animation: none; }

.hero-area.style2::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: #00aeff;
  animation: none; }

.hero-area.style2 .business-homepage-start-selling-btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 30px;
  background-color: #FFF;
  color: #00aeff;
  border: none;
  transition: 0.5s;
  border-radius: 4px; }

.hero-area.style2 .business-homepage-start-selling-btn:hover {
  background-color: #eee; }

.hero-area.style2 .business-homepage-start-selling-image {
  max-width: 125%;
  width: 125%;
  margin-left: -15px; }

@media only screen and (max-width: 1200px) {
  .hero-area.style2 .business-homepage-start-selling-image {
    max-width: 115%;
    width: 115%;
    margin-left: -30px; } }

@media only screen and (max-width: 767px) {
  .hero-area.style2 .business-homepage-start-selling-image {
    max-width: 115%;
    width: 115%;
    margin-left: -20px; } }

.hero-area.style2 .hero-text {
  text-align: left; }

.hero-area.style2 .hero-text h2 {
  font-size: 50px;
  margin-bottom: 30px;
  padding-bottom: 20px; }

.hero-area.style2 .hero-text h2::before {
  left: 0;
  margin-left: 0; }

.hero-area.style3 {
  padding-top: 100px;
  padding-bottom: 100px; }

.hero-area.style3 .search-form {
  margin-top: 100px;
  background: #fff;
  border-radius: 6px;
  padding: 35px 40px;
  padding-right: 55px;
  width: 100%;
  border: 1px solid #eee;
  position: relative;
  transform: translateY(50%); }

.hero-area.style3 .search-form .btn:hover {
  background-color: #081828;
  color: #fff; }

.hero-area.style3 .categories {
  padding: 0;
  margin-top: 30px; }

.hero-area.style3 .categories .single-cat {
  border: none;
  background-color: #fff;
  padding: 20px 10px; }

.hero-area .hero-text h2 {
  color: #fff;
  font-size: 45px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative; }

.hero-area .hero-text h2::before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -2px;
  height: 4px;
  width: 60px;
  margin-left: -30px;
  background: #00aeff;
  border-radius: 10px; }

.hero-area .hero-text p {
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  display: block; }

.hero-area .hero-text .button .btn:hover {
  background-color: #081828;
  color: #fff;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important; }

/* Hero Search Form */
.search-form {
  margin-top: 60px;
  background: #fff3;
  border-radius: 6px;
  padding: 30px;
  padding-right: 45px; }

.search-form.style2 {
  padding: 0;
  padding: 50px;
  background-color: #fff; }

.search-form.style2 .heading-title {
  font-size: 24px;
  margin-bottom: 8px; }

.search-form.style2 .sub-heding-text {
  margin-bottom: 40px;
  font-size: 16px; }

.search-form.style2 .search-input {
  padding: 0;
  margin-bottom: 18px; }

.search-form.style2 .btn {
  margin: 0; }

.search-form.style2 .btn:hover {
  background-color: #081828;
  color: #fff; }

.search-form .search-input {
  position: relative;
  padding: 0px 8px; }

.search-form .search-input input {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  border: none;
  padding: 0 25px;
  padding-right: 45px;
  height: 55px;
  font-size: 15px;
  border: 1px solid #eee; }

.search-form .search-input input:focus {
  outline: none !important; }

.search-form .search-input label {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%); }

.search-form .search-input select {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 0 25px;
  height: 55px;
  -webkit-appearance: none;
  appearance: none;
  font-size: 15px; }

.search-form .search-input select:focus {
  outline: none !important; }

.search-form button {
  width: 100%;
  border-radius: 6px;
  border: none;
  padding: 0 25px;
  height: 55px;
  margin-left: 5px; }

.search-form button.btn:hover {
  background: #fff;
  color: #081828; }

.search-form button i {
  display: inline-block;
  margin-right: 5px; }

/*======================================
    About Us CSS
========================================*/
.about-us .content-left {
  position: relative; }

.about-us .content-left img {
  width: 100%;
  border-radius: 6px; }

.about-us .content-left .video {
  position: absolute;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  color: #00aeff;
  background-color: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font-size: 16px;
  padding-left: 3px;
  transition: all 0.4s ease-in-out; }

.about-us .content-left .video:hover {
  color: #fff;
  background-color: #00aeff;
  transform: scale(1.05); }

.about-us .content-right {
  padding-right: 70px;
  padding-left: 40px; }

.about-us .content-right .sub-heading {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #00aeff;
  background-color: #f9f9f9;
  padding: 5px 20px;
  border-radius: 4px; }

.about-us .content-right h2 {
  display: block;
  font-size: 32px;
  margin-bottom: 25px;
  line-height: 40px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700; }

.about-us .content-right h3 {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 8px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600; }

.about-us .content-right p {
  font-size: 16px;
  line-height: 26px; }

.about-us .image-placeholder {
  width: 100%;
  aspect-ratio: 1.2884 / 1;
  position: absolute;
  top: 0;
  border-radius: 4px;
  background-color: #ECECEC;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

/*======================================
    Our Achievement CSS
========================================*/
.our-achievement {
  background-color: #081828;
  padding-top: 70px;
  padding: 90px 0 120px 0; }

.our-achievement .single-achievement {
  margin-top: 30px;
  text-align: center;
  padding: 0px 50px; }

.our-achievement .single-achievement h3 {
  font-size: 40px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  color: #fff; }

.our-achievement .single-achievement p {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  font-family: 'Jost', sans-serif; }

/*======================================
    Testimonial CSS
========================================*/
/*.testimonials {
    background-color: #081828;
    padding-bottom: 180px;
  }

    .testimonials .section-title h2 {
      color: #fff;
    }

    .testimonials .section-title p {
      color: #fff;
    }

    .testimonials .testimonial-slider {
      margin: 0;
    }

    .testimonials .tns-nav {
      text-align: center;
      position: absolute;
      bottom: 90px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 100%;
      left: 50%;
      z-index: 9;
    }

      .testimonials .tns-nav button {
        height: 5px;
        width: 30px;
        background-color: #fff;
        border-radius: 5px;
        display: inline-block;
        border: none;
        margin: 0px 5px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

        .testimonials .tns-nav button:hover {
          background: #00aeff;
        }

        .testimonials .tns-nav button.tns-nav-active {
          background: #00aeff;
        }

    .testimonials .single-testimonial {
      background: #fff;
      border: 1px solid #ececec;
      padding: 50px 40px;
      margin: 20px 0;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      position: relative;
    }

      .testimonials .single-testimonial .quote-icon {
        position: absolute;
        right: 30px;
        top: 10px;
      }

        .testimonials .single-testimonial .quote-icon i {
          font-size: 70px;
          color: #cccccc3b;
        }

      .testimonials .single-testimonial:hover {
        -webkit-box-shadow: 0px 0px 13px #0000001a;
        box-shadow: 0px 0px 13px #0000001a;
      }

        .testimonials .single-testimonial:hover .author img {
          border-color: #00aeff;
        }

      .testimonials .single-testimonial .author {
        position: relative;
        padding-left: 90px;
        min-height: 70px;
        margin-bottom: 25px;
        padding-top: 10px;
      }

        .testimonials .single-testimonial .author img {
          height: 70px;
          width: 70px;
          border-radius: 50%;
          border: 5px solid #ececec;
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }

        .testimonials .single-testimonial .author .name {
          font-size: 18px;
          font-weight: 600;
          color: #081828;
        }

          .testimonials .single-testimonial .author .name .deg {
            display: block;
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #888;
          }

      .testimonials .single-testimonial .text p {
        font-size: 16px;
      } */
/*=============================
	Pricing Table CSS
===============================*/
.pricing-table {
  background: #f9f9f9;
  position: relative; }

.pricing-table .section-title {
  margin-bottom: 50px; }

.pricing-table .single-table {
  margin-top: 30px;
  background: #fff;
  text-align: left;
  position: relative;
  transition: all 0.4s ease;
  border: 1px solid #ececec;
  overflow: hidden;
  border-radius: 6px;
  padding: 40px 40px;
  z-index: 2; }

.pricing-table .single-table::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 300px;
  width: 300px;
  background: #00aeff;
  border-radius: 50%;
  left: -50px;
  top: -110px; }

.pricing-table .single-table::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 300px;
  width: 300px;
  background: #00aeff;
  border-radius: 50%;
  left: -40px;
  top: -110px;
  opacity: 0.8;
  z-index: -1; }

.pricing-table .single-table .table-head {
  position: relative; }

.pricing-table .single-table .table-head .amount {
  font-size: 35px;
  line-height: 56px;
  font-weight: 700;
  color: #fff; }

.pricing-table .single-table .table-head .duration {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: -2px; }

.pricing-table .single-table .table-head .title {
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 19px; }

.pricing-table .single-table .table-list {
  position: relative;
  display: block;
  margin-top: 90px; }

.pricing-table .single-table .table-list li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #444;
  font-weight: 400;
  margin-bottom: 10px; }

.pricing-table .single-table .table-list li:last-child {
  margin-bottom: 0; }

.pricing-table .single-table .button {
  margin-top: 40px; }

/*======================================
    Faq CSS
========================================*/
.faq {
  padding-bottom: 80px; }

.accordion-item .accordion-button {
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  display: block;
  overflow: hidden;
  border: none;
  border: 1px solid #eee; }

.accordion-item .accordion-button span {
  float: left; }

.accordion-item .accordion-button i {
  float: right;
  font-size: 13px;
  transition: all 0.3s ease; }

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #00aeff;
  border-color: transparent;
  border-radius: 5px 5px 0 0; }

.accordion-button:not(.collapsed) i::before {
  content: "\EB2C";
  font-family: lineIcons; }

.accordion-button::after {
  display: none; }

.accordion-collapse {
  border: none; }

.accordion-body {
  border: 1px solid #eee;
  border-radius: 0 0 5px 5px;
  padding: 30px 20px; }

.accordion-body p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px; }

.accordion-body p:last-child {
  margin: 0; }

.accordion-item {
  margin-bottom: 20px; }

/*======================================
    Blog CSS
========================================*/
.latest-news-area .single-news {
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff; }

.latest-news-area .single-news .image {
  overflow: hidden;
  position: relative;
  z-index: 0;
  width: 100%; }

.latest-news-area .single-news .image a {
  width: 100%; }

.latest-news-area .single-news .image:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 0%;
  height: 50px;
  width: 2px;
  background: #fff;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden; }

.latest-news-area .single-news .image:after {
  position: absolute;
  content: "";
  left: 0%;
  top: 50%;
  height: 2px;
  width: 50px;
  background: #fff;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden; }

.latest-news-area .single-news .image img {
  width: 100%;
  transition: all 0.4s ease;
  position: relative; }

.latest-news-area .single-news:hover .image::before {
  top: 50%;
  opacity: 1;
  visibility: visible; }

.latest-news-area .single-news:hover .image::after {
  left: 50%;
  opacity: 1;
  visibility: visible; }

.latest-news-area .single-news:hover .image img {
  transform: scale(1.1); }

.latest-news-area .single-news .content-body {
  padding: 40px 30px 30px 30px;
  border-radius: 6px;
  border-radius: 0 0 6px 6px;
  border: 1px solid #ececec; }

.latest-news-area .single-news .content-body .title {
  line-height: 30px; }

.latest-news-area .single-news .content-body .title a {
  font-size: 20px;
  font-weight: 700;
  color: #081828;
  margin-bottom: 20px; }

.latest-news-area .single-news .content-body .title a:hover {
  color: #00aeff; }

.latest-news-area .single-news .content-body p {
  font-size: 16px; }

.latest-news-area .single-news .content-body .meta-details {
  padding-top: 15px;
  margin-top: 30px;
  border-top: 1px solid #eee; }

.latest-news-area .single-news .content-body .meta-details ul li {
  display: inline-block;
  margin-right: 17px;
  padding-right: 17px;
  position: relative; }

.latest-news-area .single-news .content-body .meta-details ul li::before {
  position: absolute;
  content: "";
  right: -4px;
  top: 9px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #00aeff; }

.latest-news-area .single-news .content-body .meta-details ul li:last-child {
  margin: 0;
  padding: 0; }

.latest-news-area .single-news .content-body .meta-details ul li:last-child::before {
  display: none; }

.latest-news-area .single-news .content-body .meta-details ul li a {
  font-size: 14px;
  color: #081828;
  font-weight: 500; }

.latest-news-area .single-news .content-body .meta-details ul li a:hover {
  color: #00aeff; }

.blog-list {
  background: #f9f9f9; }

.blog-list .single-news {
  margin: 0; }

.blog-list .single-news {
  margin-bottom: 40px; }

/* News Details */
.blog-single {
  background: #f9f9f9; }

.blog-single .post-thumbnils {
  position: relative;
  overflow: hidden;
  border-radius: 6px 6px 0 0; }

.blog-single .post-thumbnils img {
  width: 100%; }

.post-details .detail-inner {
  background-color: #fff;
  padding: 40px;
  margin-bottom: 40px;
  border: 1px solid #eee;
  border-radius: 0 0 6px 6px;
  border-top-color: transparent; }

.post-details .post-thumbnils {
  margin-bottom: 30px; }

.post-details .post-title {
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 32px; }

.post-details .post-title a {
  font-size: 25px;
  font-weight: 600; }

.post-details .post-title a:hover {
  color: #00aeff; }

ul.custom-flex {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.post-details .post-meta li {
  font-weight: 500;
  font-size: 15px;
  margin-right: 25px; }

.post-details .post-meta li a i {
  font-weight: 400;
  margin-right: 3px; }

.post-details .post-meta li a:hover {
  color: #00aeff; }

.post-details p {
  font-size: 15px;
  margin: 30px 0; }

.post-details h3 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 28px; }

.post-details p:last-child {
  margin-bottom: 0; }

blockquote {
  position: relative;
  color: #fff;
  font-weight: 400;
  clear: both;
  z-index: 1;
  margin: 40px 0;
  text-align: left;
  padding: 40px;
  background-color: #00aeff;
  border-radius: 5px;
  overflow: hidden; }

blockquote .icon i {
  font-size: 40px;
  color: #fff;
  display: block;
  margin-bottom: 20px; }

blockquote h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff; }

blockquote span {
  font-size: 13px;
  display: block;
  margin-top: 20px; }

blockquote .shape {
  position: absolute;
  right: -90px;
  top: -91px;
  z-index: -1;
  transform: rotate(45deg); }

.post-details .list {
  margin: 40px; }

.post-details .list li {
  color: #888;
  font-weight: 400;
  position: relative;
  padding-left: 40px;
  display: block;
  margin-bottom: 20px;
  position: relative;
  font-size: 16px;
  font-family: 'Lato', sans-serif; }

.post-details .list li i {
  display: block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 10px;
  color: #fff;
  background-color: #00aeff;
  position: absolute;
  left: 0;
  top: -1px;
  text-align: center;
  border-radius: 3px; }

.post-details .list li:last-child {
  margin: 0; }

.post-social-media {
  margin-top: 40px; }

.post-social-media .share-title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  display: inline-block; }

.post-details .post-social-media ul {
  display: inline-block;
  margin-left: 30px; }

.post-details .post-social-media ul li {
  display: inline-block;
  margin-right: 13px; }

.post-details .post-social-media ul li a {
  font-size: 15px;
  color: #999;
  position: relative; }

.post-details .post-social-media ul li a:hover {
  color: #00aeff; }

.post-details .post-social-media ul li a span {
  background-color: #00aeff;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease; }

.post-details .post-social-media ul li a span::before {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -5px;
  bottom: -10px;
  border: 5px solid #00aeff;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent; }

.post-details .post-social-media ul li a:hover span {
  opacity: 1;
  visibility: visible;
  top: -35px; }

.post-details .post-meta li {
  font-weight: 500;
  margin-right: 25px; }

.post-details .post-meta li a {
  font-size: 13px;
  font-weight: 400;
  font-weight: 500;
  color: #081828; }

.post-details .post-meta li a i {
  font-weight: 400;
  margin-right: 5px;
  color: #00aeff; }

.post-details p {
  font-size: 16px;
  line-height: 28px; }

.post-details .post-image {
  margin: 40px 0 20px 0;
  width: 100%; }

.post-details .post-image img {
  width: 100%;
  transition: all 0.4s ease;
  border-radius: 5px; }

.post-details > ul > li {
  font-weight: 500; }

.detail-post-navigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 30px;
  border: 1px solid #eee; }

/*comments*/
.post-comments {
  background-color: #fff;
  padding: 40px;
  margin-top: 40px;
  border: 1px solid #eee;
  border-radius: 5px; }

.comment-title {
  font-size: 22px !important;
  font-weight: 500 !important;
  margin-bottom: 40px !important;
  position: relative;
  z-index: 1;
  text-transform: capitalize; }

.comment-title span {
  background-color: white;
  padding-right: 15px; }

.comment-title::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #eee;
  content: '';
  z-index: -1; }

.comment-reply-title {
  font-size: 22px !important;
  font-weight: 500 !important;
  margin-bottom: 40px !important;
  position: relative;
  z-index: 1;
  text-transform: capitalize; }

.comment-reply-title span {
  background-color: white;
  padding-right: 15px; }

.comment-reply-title::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #eee;
  content: '';
  z-index: -1; }

.post-comments .comments-list li {
  padding-left: 130px;
  position: relative;
  font-size: 14px; }

.post-comments .comments-list li .comment-img {
  position: absolute;
  left: 0;
  width: 100px;
  height: 100px; }

.post-comments .comments-list li .comment-img img {
  max-width: 100px;
  max-height: 100px; }

.post-comments .comments-list li .comment-desc .desc-top {
  margin-bottom: 20px;
  position: relative;
  display: block; }

.post-comments .comments-list li .comment-desc .desc-top h6 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 500; }

.post-comments .comments-list li .comment-desc .desc-top h6 .saved {
  color: #00aeff;
  font-size: 14px;
  margin-left: 10px; }

.post-comments .comments-list li .comment-desc .desc-top span.date {
  font-size: 14px;
  font-weight: 400; }

.post-comments .comments-list li .comment-desc .desc-top .reply-link {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 22px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #00aeff; }

.post-comments .comments-list li .comment-desc .desc-top .reply-link:hover {
  background-color: #00aeff;
  color: #fff;
  border-color: transparent; }

.post-comments .comments-list li .comment-desc .desc-top .reply-link i {
  margin-right: 5px; }

.post-comments .comments-list li .comment-desc p {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px; }

.post-comments .comments-list li.children {
  margin-left: 130px; }

.post-comments .comments-list li:not(:first-child) {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #eee; }

/*Comment form*/
.comment-form {
  background-color: #fff;
  padding: 40px;
  margin-top: 40px;
  border: 1px solid #eee;
  border-radius: 5px; }

.comment-form form .form-box {
  position: relative; }

.comment-form form .form-box .icon {
  position: absolute;
  top: 17px;
  right: 25px;
  font-size: 16px; }

.comment-form form .form-box .form-control-custom {
  border: none;
  background: #fff;
  font-size: 14px;
  color: #081828;
  padding: 0 25px;
  font-weight: 500;
  height: 55px;
  border: 1px solid #eee;
  margin-bottom: 25px;
  border-radius: 0;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px; }

.comment-form form .form-box textarea.form-control-custom {
  height: 200px;
  padding: 25px; }

.comment-form form .form-box .form-control-custom::placeholder {
  font-size: 15px;
  color: #081828;
  font-weight: 400; }

/* News sidebar */
.sidebar .widget {
  padding: 40px;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #eee; }

.sidebar .widget:last-child {
  margin-bottom: 0; }

.sidebar .widget .widget-title {
  font-size: 20px;
  margin-bottom: 40px;
  line-height: 1;
  position: relative;
  font-weight: 600;
  line-height: 28px;
  z-index: 1; }

.sidebar .widget .widget-title span {
  background-color: white;
  padding-right: 18px; }

.sidebar .widget .widget-title::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #eee;
  content: '';
  z-index: -1; }

.sidebar .widget.search-widget form {
  position: relative; }

.sidebar .widget.search-widget form input {
  width: 100%;
  background-color: transparent;
  height: 55px;
  border: none;
  padding: 0 80px 0 30px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 0;
  border: 1px solid #eee;
  border-radius: 5px; }

.sidebar .widget.search-widget form input::placeholder {
  color: #333; }

.sidebar .widget.search-widget form button {
  border: none;
  position: absolute;
  right: 7px;
  top: 6px;
  width: 55px;
  height: 42px;
  z-index: 1;
  color: #fff !important;
  font-size: 13px;
  transition: all 0.3s ease-out 0s;
  color: #fff;
  border-radius: 0;
  border-radius: 3px;
  padding: 0 !important;
  border: none;
  background: #00aeff; }

.sidebar .widget.search-widget form button:hover {
  background-color: #081828;
  color: #fff; }

.sidebar .widget.popular-feeds .single-popular-feed {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid #eee; }

.sidebar .widget.popular-feeds .single-popular-feed:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0; }

.sidebar .widget.popular-feeds .single-popular-feed .feed-img {
  width: 80px;
  margin-right: 20px;
  border-radius: 50%;
  height: 80px;
  border-radius: 50% !important;
  overflow: hidden; }

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1.5; }

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a {
  font-size: 16px;
  font-weight: 500; }

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a:hover {
  color: #00aeff; }

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
  font-weight: 400;
  font-size: 14px; }

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time > i {
  margin-right: 4px; }

.sidebar .widget.categories-widget ul li {
  margin-bottom: 10px; }

.sidebar .widget.categories-widget ul li:last-child {
  margin-bottom: 0; }

.sidebar .widget.categories-widget ul li a {
  font-size: 15px;
  height: 35px;
  background-color: transparent;
  display: block;
  position: relative;
  line-height: 35px;
  color: #081828;
  transition: all 0.3s ease-out 0s;
  font-weight: 500;
  padding: 0;
  padding-right: 40px !important;
  display: inline-block;
  line-height: 22px;
  width: 100%; }

.sidebar .widget.categories-widget ul li a span {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: transparent;
  color: #333;
  transition: all 0.3s ease-out 0s;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 12px; }

.sidebar .widget.categories-widget ul li a:hover {
  color: #00aeff; }

.sidebar .widget.categories-widget ul li a:hover span {
  background: #00aeff;
  color: #fff;
  border-color: transparent; }

.sidebar .widget.popular-tag-widget {
  padding-bottom: 30px; }

.popular-tag-widget .tags > a {
  display: inline-flex;
  justify-content: center;
  padding: 8px 20px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  background: #00aeff;
  margin-right: 7px;
  margin-bottom: 10px;
  color: #fff;
  background: transparent;
  color: #888;
  border: 1px solid #eee;
  border-radius: 4px; }

.popular-tag-widget .tags > a:hover {
  background-color: #00aeff;
  color: #fff;
  border-color: transparent; }

.sidebar-as img {
  width: 100%; }

/*======================================
   Clients CSS
========================================*/
.client-logo-section {
  background: #fff;
  padding: 50px 0; }

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  padding: 10px 20px;
  text-align: center;
  margin: 0; }

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img {
  max-width: 220px;
  width: 100%;
  opacity: .3;
  transition: all .3s ease-out 0s; }

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img:hover {
  opacity: 1; }

/*======================================
    Dashboard Info Box CSS
========================================*/
.info-box {
  background-color: transparent;
  transform: translateY(-50%);
  position: relative;
  z-index: 10; }

.info-box .tns-controls button {
  width: 46px;
  height: 46px;
  cursor: pointer;
  z-index: 2;
  color: #fff;
  font-size: 17px;
  background: #00aeff;
  border: none;
  padding: 2px 5px;
  border-radius: 50%;
  transition: all .3s ease-out 0s;
  position: absolute;
  z-index: 99;
  top: 50%;
  font-size: 14px;
  color: #fff;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease; }

.info-box .tns-controls button:hover {
  background-color: #081828;
  color: #fff; }

.info-box:hover .tns-controls button {
  opacity: 1;
  visibility: visible; }

.info-box .tns-controls button:first-child {
  left: -23px; }

.info-box .tns-controls button:last-child {
  right: -23px; }

.info-box .info-box-inner {
  padding: 30px 40px;
  border: 0px;
  border-radius: 6px;
  position: relative;
  z-index: 9;
  background: #fff; }

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .info-box {
    transform: translateY(-33%); } }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .info-box {
    transform: translateY(-35%); } }

@media only screen and (max-width: 768px) {
  .info-box {
    transform: translateY(-20%); } }

.info-box.style2 {
  height: auto;
  padding: 100px 0; }

.info-box.style2 .section-title {
  margin-bottom: 50px; }

.info-box.style2 .cat-inner {
  transform: none;
  padding: 0;
  border: none; }

.info-box.style2 .single-cat {
  margin-top: 30px;
  border: 1px solid #eee;
  padding: 25px 10px; }

.info-box.style2 .single-cat:hover {
  box-shadow: 0px 10px 15px #00000012;
  background-color: #fff;
  transform: translateY(-5px); }

.info-box .single-info-box-item {
  text-align: center;
  padding: 15px 10px;
  transition: all 0.3s ease;
  display: block;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 2;
  border: none;
  border-radius: 6px; }

.info-box .single-info-box-item .icon img {
  width: 40px; }

.info-box .single-info-box-item h3 {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #081828;
  transition: all 0.4s ease; }

.info-box .single-info-box-item:hover h3 {
  color: #00aeff; }

.info-box .single-info-box-item .total {
  font-size: 13px;
  padding: 5px 10px;
  font-weight: 500;
  text-align: center;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 5px;
  color: #081828;
  display: inline-block;
  margin-top: 15px;
  transition: all 0.4s ease; }

.info-box .single-info-box-item .total {
  background-color: #00aeff;
  color: #fff;
  border-color: transparent; }

.info-box .single-info-box-item-main-text {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #00aeff;
  display: flex;
  align-items: center;
  justify-content: center; }

.info-box .single-info-box-item-text {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  cursor: default;
  margin-top: 25px; }

.info-box .single-info-box-item-icon {
  height: 80px;
  width: 80px;
  background-color: #00aeff;
  color: #fff;
  border-radius: 50%;
  border: 6px solid #00aeff;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.5s; }

.info-box .single-info-box-item-icon svg {
  color: #FFF;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 36px;
  margin-top: 16px;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.info-box .single-info-box-item:hover .single-info-box-item-icon svg {
  color: #00aeff;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 36px;
  margin-top: 16px;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.info-box .single-info-box-item-icon i {
  color: #FFF;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 36px;
  margin-top: 16px;
  transition: color 0.5s, border 0.5s; }

.info-box .single-info-box-item:hover .single-info-box-item-icon {
  background-color: #FFFFFF;
  border: 6px solid #00aeff; }

.info-box .single-info-box-item:hover .single-info-box-item-icon i {
  color: #00aeff; }

.info-box .single-info-box-item-icon img {
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-top: 12px;
  filter: invert(99%) sepia(0%) saturate(7496%) hue-rotate(251deg) brightness(104%) contrast(104%); }

.info-box .single-info-box-item:hover .single-info-box-item-icon img {
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

/*
    .info-box .single-info-box-item-icon:hover {
      background-color: #FFFFFF;
      border: 6px solid #00aeff;
    } */
/*
    .info-box .single-info-box-item-icon:hover img {
      filter: invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%);
    } */
/*======================================
    Items Category CSS
========================================*/
.categories {
  background-color: transparent; }

.categories .tns-controls button {
  width: 46px;
  height: 46px;
  cursor: pointer;
  z-index: 2;
  color: #fff;
  font-size: 17px;
  background: #00aeff;
  border: none;
  padding: 2px 5px;
  border-radius: 50%;
  transition: all .3s ease-out 0s;
  position: absolute;
  z-index: 99;
  top: 50%;
  font-size: 14px;
  color: #fff;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease; }

.categories .tns-controls button:hover {
  background-color: #081828;
  color: #fff; }

.categories:hover .tns-controls button {
  opacity: 1;
  visibility: visible; }

.categories .tns-controls button:first-child {
  left: -23px; }

.categories .tns-controls button:last-child {
  right: -23px; }

.categories .cat-inner {
  padding: 30px 40px;
  background: #f4faff;
  border-radius: 6px;
  position: relative;
  z-index: 9; }

.categories.style2 {
  height: auto;
  padding: 100px 0; }

.categories.style2 .section-title {
  margin-bottom: 50px; }

.categories.style2 .cat-inner {
  transform: none;
  padding: 0;
  border: none; }

.categories.style2 .single-cat {
  margin-top: 30px;
  border: 1px solid #eee;
  padding: 25px 10px; }

.categories.style2 .single-cat:hover {
  box-shadow: 0px 10px 15px #00000012;
  background-color: #fff;
  transform: translateY(-5px); }

.categories .single-cat {
  text-align: center;
  padding: 15px 10px;
  transition: all 0.3s ease;
  display: block;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 2;
  border: none;
  border-radius: 6px;
  min-width: 80px;
  min-height: 80px; }

.categories .single-cat .icon-wrapper-active {
  background-color: #00aeff;
  border-radius: 50%; }

.categories .single-cat .icon-wrapper-active img {
  filter: saturate(0%) invert(99%) hue-rotate(251deg) brightness(0%) invert(100%) contrast(104%); }

.categories .single-cat .icon-wrapper {
  width: 60px;
  padding: 10px; }

.categories .single-cat .icon {
  justify-content: center;
  display: flex; }

.categories .single-cat .icon img {
  width: 40px; }

.categories .single-cat .icon-wrapper:hover {
  background-color: #00aeff;
  border-radius: 50%; }

.categories .single-cat .icon-wrapper:hover img {
  filter: saturate(0%) invert(99%) hue-rotate(251deg) brightness(0%) invert(100%) contrast(104%);
  transition: background 0.5s, border 0.5s, color 0.5s; }

.categories .single-cat h3 {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #081828;
  transition: all 0.4s ease; }

.categories .single-cat:hover h3 {
  color: #00aeff; }

.categories .single-cat .total {
  font-size: 13px;
  padding: 5px 10px;
  font-weight: 500;
  text-align: center;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 5px;
  color: #081828;
  display: inline-block;
  margin-top: 15px;
  transition: all 0.4s ease; }

.categories .single-cat:hover .total {
  background-color: #00aeff;
  color: #fff;
  border-color: transparent; }

.all-categories {
  border-bottom: 1px solid #eee;
  padding-bottom: 70px; }

.all-categories .categories-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px; }

.all-categories .cat-title {
  font-size: 16px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  background: #00aeff;
  color: #fff; }

.all-categories .cat-title span {
  font-weight: 400;
  display: inline-block;
  margin-left: 5px;
  font-size: 14px; }

.all-categories ul {
  margin-bottom: 30px; }

.all-categories ul li {
  display: block;
  margin-bottom: 7px; }

.all-categories ul li:last-child {
  margin: 0; }

.all-categories ul li a {
  color: #888;
  padding: 10px 15px;
  border: 1px solid #eee;
  display: block;
  border-radius: 4px; }

.all-categories ul li a:hover {
  color: #fff;
  background-color: #00aeff;
  border-color: transparent; }

/* Category Page*/
.category-page {
  background-color: #f9f9f9; }

.category-page .single-ad-grid {
  background-color: #fff; }

.category-page .category-grid-topbar {
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  padding-right: 15px; }

.category-page .category-grid-topbar .title {
  font-size: 15px;
  font-weight: 500;
  color: #888;
  float: left; }

.category-page .category-grid-topbar .nav {
  border: none;
  float: right; }

.category-page .category-grid-topbar .nav button {
  border: none;
  padding: 5px 10px 3px 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-right: 6px; }

.category-page .category-grid-topbar .nav button.active {
  background-color: #00aeff;
  border-color: transparent;
  color: #fff; }

.category-page .category-grid-topbar .nav button:last-child {
  margin: 0; }

.category-sidebar .single-widget {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 30px; }

.category-sidebar .single-widget:last-child {
  margin-bottom: 0; }

.category-sidebar .single-widget h3 {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 25px; }

.category-sidebar .single-widget.search form {
  position: relative; }

.category-sidebar .single-widget.search form input {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #081828;
  padding: 0px 15px;
  padding-right: 50px; }

.category-sidebar .single-widget.search form button {
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  color: #081828;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s ease; }

.category-sidebar .single-widget.search form button:hover {
  color: #00aeff; }

.category-sidebar .single-widget.range input {
  color: #081828;
  cursor: pointer; }

.category-sidebar .single-widget.range ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #081828;
  opacity: 1;
  /* Firefox */ }

.category-sidebar .single-widget.range :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #081828; }

.category-sidebar .single-widget.range ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #081828; }

.category-sidebar .single-widget.range .range-inner {
  position: relative;
  padding-left: 15px; }

.category-sidebar .single-widget.range label {
  color: #081828;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px; }

.category-sidebar .single-widget.range #rangePrimary {
  display: inline-block;
  border: none; }

.category-sidebar .single-widget.range input[type="range"]::-webkit-slider-thumb {
  background: #00aeff !important; }

.category-sidebar .single-widget.range input[type="range"]::-moz-range-thumb {
  background: #00aeff !important; }

.category-sidebar .single-widget.range input[type="range"]::-ms-thumb {
  background: #00aeff !important; }

.category-sidebar .single-widget.condition .form-check {
  display: block;
  margin-bottom: 10px; }

.category-sidebar .single-widget.condition .form-check:last-child {
  margin: 0; }

.category-sidebar .single-widget.condition .form-check-label {
  cursor: pointer; }

.category-sidebar .single-widget.condition .form-check-input {
  cursor: pointer; }

.category-sidebar .single-widget.condition .form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff; }

.category-sidebar .single-widget .list li {
  margin-bottom: 25px; }

.category-sidebar .single-widget .list li:last-child {
  margin: 0; }

.category-sidebar .single-widget .list li a {
  color: #888;
  display: block;
  position: relative;
  padding-right: 35px; }

.category-sidebar .single-widget .list li a i {
  display: inline-block;
  margin-right: 5px; }

.category-sidebar .single-widget .list li a span {
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  display: block;
  border: 1px solid #eee;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }

.category-sidebar .single-widget .list li a:hover {
  color: #00aeff; }

.category-sidebar .single-widget .list li a:hover span {
  color: #fff;
  background-color: #00aeff;
  border-color: transparent; }

.category-sidebar .single-widget.banner a img {
  width: 100%; }

/*======================================
    Items Grid CSS
========================================*/
.items-grid {
  background: #f9f9f9; }

.items-grid.custom-padding {
  padding-top: 220px; }

.items-grid .section-title {
  margin-bottom: 50px; }

.items-grid .single-grid {
  box-shadow: 0 10px 30px rgba(35, 38, 45, 0.14) !important;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 30px; }

.items-grid .single-grid .image {
  position: relative; }

.items-grid .single-grid .image .author {
  background: transparent;
  padding: 15px 20px;
  border-radius: 0;
  position: absolute;
  left: 0;
  bottom: 5px;
  overflow: visible;
  width: 100%;
  z-index: 2 !important; }

.items-grid .single-grid .image .author::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 110px;
  width: 100%;
  background: linear-gradient(transparent, #000000e3);
  z-index: -1;
  overflow: visible; }

.items-grid .single-grid .image .author .author-image {
  float: left; }

.items-grid .single-grid .image .author .author-image a {
  color: #081828; }

.items-grid .single-grid .image .author .author-image a:hover {
  color: #00aeff; }

.items-grid .single-grid .image .author .author-image a:hover span {
  color: #00aeff; }

.items-grid .single-grid .image .author .author-image a span {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
  color: #fff; }

.items-grid .single-grid .image .author .author-image img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block; }

.items-grid .single-grid .image .author .author-image p {
  display: inline-block;
  margin-left: 5px; }

.items-grid .single-grid .image .author .sale {
  display: inline-block;
  background: #00aeff;
  color: #fff;
  font-size: 14px;
  float: right;
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 6px;
  font-family: 'Jost', sans-serif; }

.items-grid .single-grid .image .item-position {
  display: inline-block;
  background: #00aeff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 6px;
  position: absolute;
  right: 15px;
  top: 15px;
  box-shadow: 0 5px 10px rgba(35, 38, 45, 0.14) !important;
  font-family: 'Jost', sans-serif; }

.items-grid .single-grid .image .item-position i {
  display: inline-block;
  margin-right: 3px; }

.items-grid .single-grid .image img {
  width: 100%;
  transition: all 0.4s ease; }

.items-grid .single-grid .image .thumbnail {
  overflow: hidden; }

.items-grid .single-grid:hover .image .thumbnail img {
  transform: scale(1.1); }

.items-grid .single-grid .content .top-content {
  padding: 30px 30px 25px 30px; }

.items-grid .single-grid .content .top-content .tag {
  display: inline-block;
  margin-bottom: 5px;
  color: #888;
  font-size: 14px;
  font-weight: 500; }

.items-grid .single-grid .content .top-content .tag i {
  display: inline-block;
  margin-right: 5px;
  color: #00aeff; }

.items-grid .single-grid .content .top-content .tag:hover {
  color: #00aeff; }

.items-grid .single-grid .content .top-content .title {
  display: block;
  margin-bottom: 10px;
  line-height: 30px; }

.items-grid .single-grid .content .top-content .title a {
  color: #081828;
  font-size: 20px;
  font-weight: 600; }

.items-grid .single-grid .content .top-content .title a:hover {
  color: #00aeff; }

.items-grid .single-grid .content .top-content .update-time {
  font-size: 14px; }

.items-grid .single-grid .content .top-content .rating {
  margin-top: 10px; }

.items-grid .single-grid .content .top-content .rating li {
  display: inline-block;
  color: #FFAB02; }

.items-grid .single-grid .content .top-content .rating li a {
  color: #888;
  font-weight: 400;
  font-size: 13px; }

.items-grid .single-grid .content .top-content .rating li a:hover {
  color: #00aeff; }

.items-grid .single-grid .content .top-content .info-list {
  margin-top: 10px;
  overflow: hidden; }

.items-grid .single-grid .content .top-content .info-list li {
  display: block;
  float: left;
  width: 50%;
  line-height: 30px;
  font-size: 14px;
  font-weight: 500; }

.items-grid .single-grid .content .top-content .info-list li a {
  color: #888;
  font-weight: 500; }

.items-grid .single-grid .content .top-content .info-list li a:hover {
  color: #00aeff; }

.items-grid .single-grid .content .top-content .info-list li a i {
  color: #00aeff;
  display: inline-block;
  margin-right: 3px; }

.items-grid .single-grid .content .top-content .info-list li:last-child {
  margin: 0; }

.items-grid .single-grid .content .bottom-content {
  padding: 15px 30px;
  border-top: 1px solid #eee;
  overflow: hidden; }

.items-grid .single-grid .content .bottom-content .price {
  float: left;
  font-size: 14px;
  position: relative;
  font-weight: 500;
  top: 8px;
  font-family: 'Jost', sans-serif; }

.items-grid .single-grid .content .bottom-content .price span {
  color: #00aeff;
  font-weight: 700;
  font-size: 18px;
  display: inline-block;
  margin-left: 5px; }

.items-grid .single-grid .content .bottom-content .like {
  float: right;
  color: #00aeff;
  font-size: 18px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 50%;
  border: 1px solid #eee; }

.items-grid .single-grid .content .bottom-content .like:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

/*======================================
    Browse Cities CSS
========================================*/
/* .browse-cities {
    background-color: #f9f9f9;
  }

    .browse-cities .section-title {
      margin-bottom: 50px;
    }

    .browse-cities .single-city {
      margin-top: 30px;
      position: relative;
      z-index: 3;
      border-radius: 6px;
      overflow: hidden;
    }

      .browse-cities .single-city .info-box {
        position: relative;
      }

        .browse-cities .single-city .info-box::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          height: 130px;
          width: 100%;
          background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000000f0));
          background: linear-gradient(transparent, #000000f0);
          z-index: 0;
        }

      .browse-cities .single-city .more-btn {
        font-size: 30px;
        color: #fff;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: scale(0);
        transform: scale(0);
        position: absolute;
        right: 25px;
        bottom: 25px;
      }

        .browse-cities .single-city .more-btn:hover {
          color: #00aeff;
        }

      .browse-cities .single-city:hover .more-btn {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      .browse-cities .single-city .image {
        z-index: -1;
        position: relative;
      }

        .browse-cities .single-city .image img {
          height: 100%;
          width: 100%;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }

      .browse-cities .single-city:hover .image img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      .browse-cities .single-city .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 25px;
        padding-right: 70px;
      }

        .browse-cities .single-city .content .name {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          display: block;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
          display: inline-block;
        }

          .browse-cities .single-city .content .name:hover {
            color: #00aeff;
          }

          .browse-cities .single-city .content .name span {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            display: block;
            margin-top: 4px;
          } */
/*======================================
    Ads Grid CSS
========================================*/
.items-tab {
  background: #fff; }

.popular-vouchers-all-button {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 30px;
  margin-top: 25px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: #00aeff !important;
  color: #fff;
  border: none;
  transition: 0.5s;
  border-radius: 4px; }

.popular-vouchers-all-button:hover {
  background-color: #081828 !important;
  color: #fff;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important; }

.popular-vouchers-all-button-wrapper {
  width: 100%;
  display: block;
  position: relative; }

.popular-vouchers-tab {
  background: #FFF;
  background-color: #FFF;
  padding-top: 30px;
  padding-bottom: 60px; }

.items-tab.custom-padding {
  padding-top: 150px; }

.items-tab .nav-tabs {
  border-bottom: 2px solid #eee;
  width: 100%;
  text-align: center;
  margin-bottom: 30px !important; }

.items-tab .nav {
  display: inline-block !important;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: transparent; }

.items-tab .nav-tabs .nav-link {
  display: inline-block !important;
  background: transparent !important;
  color: #081828;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 40px;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  transition: all 0.4s ease;
  position: relative;
  bottom: -1px; }

.items-tab .nav-tabs .nav-link:hover {
  color: #00aeff; }

.items-tab .nav-tabs .nav-link.active {
  color: #00aeff;
  border: none;
  border-bottom: 2px solid #00aeff; }

.single-item-grid {
  border-radius: 6px;
  overflow: hidden;
  margin-top: 30px;
  border: 1px solid #3333331a;
  transition: all 0.4s ease;
  cursor: pointer;
  box-shadow: 0px 10px 30px #00000010;
  background: #fff; }

.single-item-grid-wrapper {
  display: flex; }

.single-item-grid:hover {
  box-shadow: 0px 10px 30px #00000010;
  background: #fff; }

.single-item-grid .image {
  overflow: hidden;
  position: relative; }

.single-item-grid .image a {
  width: 100%; }

.single-item-grid .image img {
  width: 100%;
  transition: all 0.3s ease;
  aspect-ratio: 18 / 9;
  position: relative; }

.single-item-grid .text-placeholder {
  width: 100%;
  min-height: 20px;
  border-radius: 4px;
  max-height: 100%;
  position: relative;
  top: 0;
  background-color: #ECECEC;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.single-item-grid .cat-placeholder {
  width: 50%;
  min-height: 20px;
  max-height: 20px; }

.single-item-grid .desc-placeholder {
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  margin-bottom: 25px; }

.single-item-grid .voucher-val-placeholder {
  width: 75%;
  min-height: 25px;
  max-height: 25px; }

.single-item-grid .voucher-val2-placeholder {
  width: 95%;
  min-height: 25px;
  max-height: 25px; }

.single-item-grid .primary-image-placeholder {
  width: 100%;
  aspect-ratio: 18 / 9;
  position: absolute;
  top: 0;
  background-color: #ECECEC;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.single-item-grid .primary-image-placeholder .spinner-border {
  width: 1.8rem;
  height: 1.8rem;
  margin-top: 3px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%; }

.single-item-grid .image .cross-badge {
  position: absolute;
  bottom: 5px;
  left: 15px; }

.single-item-grid .image .cross-badge-text {
  position: absolute;
  bottom: 10px;
  left: 70px;
  font-size: 13px;
  color: #FFF;
  font-weight: 600; }

.single-item-grid .image .cross-badge {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  display: inline-block;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: 10px; }

.single-item-grid .image .cross-badge img {
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
  aspect-ratio: 1 / 1;
  border-radius: 4px; }

.single-item-grid .image .cross-badge .logo-placeholder {
  width: 40px;
  height: 40px;
  aspect-ratio: 1 / 1;
  position: absolute;
  top: 0;
  border-radius: 4px;
  background-color: #ECECEC;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.single-item-grid .image .flat-badge {
  position: absolute;
  bottom: 15px;
  right: 15px; }

.single-item-grid .image .flat-badge {
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-transform: capitalize; }

.single-item-grid .image .sale {
  background: #dc3545; }

.single-item-grid .image .rent {
  background: #03a70c; }

.single-item-grid:hover .image img {
  transform: scale(1.1); }

.single-item-grid .content {
  padding: 20px 25px 20px 20px; }

.single-item-grid .content .tag {
  font-size: 14px;
  color: #888;
  font-weight: 500; }

.single-item-grid .content .tag:hover {
  color: #00aeff; }

.single-item-grid .content .campaign-card-category {
  font-size: 12px;
  color: #888;
  font-weight: 500;
  text-align: right;
  width: 100%;
  margin-top: 25px; }

.single-item-grid .content .campaign-card-category:hover {
  color: #00aeff; }

.single-item-grid .content .campaign-card-company-name {
  font-size: 15px;
  color: #081828;
  font-weight: 600; }

.single-item-grid .content .title {
  border-top: 1px solid #eee;
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 10px; }

.single-item-grid .content .title a {
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  color: #081828; }

.single-item-grid .content .title a div {
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  color: #081828;
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  height: 6.5em;
  /* Fallback for non-webkit, line-height * 5 */
  line-height: 1.3em;
  -webkit-line-clamp: 5;
  /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }

.single-item-grid .content .title a:hover {
  color: #00aeff; }

.single-item-grid .content .location {
  margin-top: 10px; }

.single-item-grid .content .location a {
  color: #888; }

.single-item-grid .content .location a i {
  color: #00aeff;
  display: inline-block;
  margin-right: 5px; }

.single-item-grid .content .location a:hover {
  color: #00aeff; }

.single-item-grid .content .info {
  overflow: hidden;
  margin-bottom: 15px; }

.single-item-grid .content .info .priceDesc {
  font-size: 12px;
  font-weight: 600;
  float: left;
  position: relative;
  top: 1px;
  min-height: 24px; }

.single-item-grid .content .info .priceDesc p {
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  color: #888; }

.single-item-grid .content .info .price {
  font-size: 18px;
  font-weight: 600;
  color: #00aeff;
  float: left;
  position: relative;
  top: 1px;
  margin-left: 4px; }

.single-item-grid .content .info .like {
  float: right; }

.single-item-grid .content .info .like a {
  color: #888;
  font-size: 14px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
  border: 1px solid #eee;
  border-radius: 50%; }

.single-item-grid .content .info .like a:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

/*======================================
    Newsletter CSS
========================================*/
/* .newsletter {
    padding: 80px;
    background-color: #00aeff;
    background-image: url(./images/newsletter/newsletter-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

    .newsletter .inner-content {
      position: relative;
    }

    .newsletter .title {
      padding-left: 80px;
    }

      .newsletter .title i {
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        display: inline-block;
        background: #fff;
        color: #00aeff;
        border-radius: 50%;
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      .newsletter .title h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 50px;
        color: #fff;
        line-height: 40px;
      }

      .newsletter .title p {
        color: #fff;
        display: block;
        margin-top: 0px;
        font-size: 16px;
        font-family: 'Jost', sans-serif;
      }

    .newsletter .newsletter-form {
      padding-left: 80px;
      position: relative;
    }

      .newsletter .newsletter-form input {
        height: 55px;
        width: 100%;
        display: inline-block;
        background: transparent;
        border: none;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 6px;
        padding: 0 142px 0 20px;
        color: #fff !important;
        font-size: 16px;
        border-radius: 6px;
      }

      .newsletter .newsletter-form ::-webkit-input-placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form :-ms-input-placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form ::-ms-input-placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form ::placeholder {
        color: #fff;
        opacity: 1;
      }

      .newsletter .newsletter-form :-ms-input-placeholder {
        color: #fff;
      }

      .newsletter .newsletter-form ::-ms-input-placeholder {
        color: #fff;
      }

      .newsletter .newsletter-form .button {
        display: inline-block;
        position: absolute;
        right: 0;
      }

      .newsletter .newsletter-form .button .btn {
        display: inline-block;
        background: #fff;
        color: #00aeff;
        height: 55px;
        border-radius: 0px 6px 6px 0;
      }

      .newsletter .newsletter-form .button .btn:hover {
        color: #081828;
      } */
/*======================================
    Dashbord CSS
========================================*/
.dashboard {
  background: #f9f9f9; }

.dashboard .main-content .details-lists .single-list {
  position: relative;
  background: transparent;
  border-radius: 6px;
  border: 0px;
  padding: 50px 30px;
  padding-left: 115px; }

.dashboard .main-content .details-lists .single-list .list-icon {
  height: 65px;
  width: 65px;
  line-height: 69px;
  border: 2px solid #15c0b03d;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center; }

.dashboard .main-content .details-lists .single-list .list-icon i {
  font-size: 25px;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(45deg, #15c0b0, #3fdd90); }

.dashboard .main-content .details-lists .single-list.two .list-icon {
  border-color: #ddd7fb; }

.dashboard .main-content .details-lists .single-list.two .list-icon i {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(45deg, #5d45fd, #6197ff); }

.dashboard .main-content .details-lists .single-list.three .list-icon {
  border-color: #f5e3f3; }

.dashboard .main-content .details-lists .single-list.three .list-icon i {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(45deg, #de6dd5, #ff5eb3); }

.dashboard .main-content .details-lists .single-list h3 {
  font-size: 23px;
  font-weight: 600;
  position: relative;
  top: -2px; }

.dashboard .main-content .details-lists .single-list h3 span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px; }

.dashboard .main-content .activity-log {
  margin-top: 30px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  padding-bottom: 20px; }

.dashboard .main-content .activity-log .block-title {
  margin-bottom: 20px; }

.dashboard .main-content .activity-log ul li {
  display: block;
  position: relative;
  padding-right: 50px;
  padding-left: 65px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  transition: all 0.4s ease; }

.dashboard .main-content .activity-log ul li:hover {
  background: #f9f9f9; }

.dashboard .main-content .activity-log ul li .log-icon {
  position: absolute;
  left: 30px;
  top: 25px; }

.dashboard .main-content .activity-log ul li .log-icon i {
  color: #00aeff;
  font-size: 16px; }

.dashboard .main-content .activity-log ul li .title {
  font-size: 14px;
  color: #666;
  font-weight: 500; }

.dashboard .main-content .activity-log ul li .title:hover {
  color: #00aeff; }

.dashboard .main-content .activity-log ul li .time {
  display: block;
  font-size: 14px;
  color: #888;
  margin-top: 2px; }

.dashboard .main-content .activity-log ul li .remove {
  display: block; }

.dashboard .main-content .activity-log ul li .remove a {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  line-height: 15px;
  background: #f4193f;
  color: #fff;
  font-size: 10px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  border-radius: 3px; }

.dashboard .main-content .activity-log ul li .remove a:hover {
  color: #fff;
  background: #081828; }

.dashboard .main-content .activity-log ul li:hover .remove a {
  opacity: 1;
  visibility: visible; }

.dashboard .main-content .recent-items {
  padding-bottom: 20px; }

.dashboard .main-content .recent-items .block-title {
  margin-bottom: 20px; }

.dashboard .main-content .recent-items ul li {
  display: block;
  position: relative;
  padding-right: 50px;
  padding-left: 110px;
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  transition: all 0.4s ease; }

.dashboard .main-content .recent-items ul li .image a {
  position: absolute;
  left: 30px;
  top: 15px; }

.dashboard .main-content .recent-items ul li .image img {
  height: 60px;
  width: 60px;
  border-radius: 3px; }

.dashboard .main-content .recent-items ul li:hover {
  background: #f9f9f9; }

.dashboard .main-content .recent-items ul li .title {
  font-size: 14px;
  color: #666;
  font-weight: 500; }

.dashboard .main-content .recent-items ul li .title:hover {
  color: #00aeff; }

.dashboard .main-content .recent-items ul li .time {
  display: block;
  font-size: 14px;
  color: #888; }

.dashboard .main-content .recent-items ul li .remove {
  display: block; }

.dashboard .main-content .recent-items ul li .remove a {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  line-height: 15px;
  background: #f4193f;
  color: #fff;
  font-size: 10px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  border-radius: 3px; }

.dashboard .main-content .recent-items ul li .remove a:hover {
  color: #fff;
  background: #081828; }

.dashboard .main-content .recent-items ul li:hover .remove a {
  opacity: 1;
  visibility: visible; }

/* Dashboard Block */
.dashboard-block {
  border: 1px solid #eee;
  border-radius: 6px;
  background: #fff;
  padding-bottom: 30px;
  position: relative; }

.dashboard-block .block-title {
  padding: 25px 30px;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px; }

/* Dasshboard Sidebar */
.dashboard-sidebar {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px; }

.dashboard-sidebar .user-image {
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #eee;
  padding-left: 100px;
  padding-bottom: 40px; }

.dashboard-sidebar .user-image img {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  position: absolute;
  left: 30px;
  top: 30px; }

.dashboard-sidebar .user-image h4 {
  font-size: 15px;
  font-weight: 400;
  color: #888;
  margin-top: 5px; }

.dashboard-sidebar .user-image h3 {
  font-size: 18px;
  font-weight: 600;
  color: #081828;
  margin-top: 5px; }

.dashboard-sidebar .user-image h3 span {
  display: block;
  margin-top: 3px; }

.dashboard-sidebar .user-image h3 span a {
  font-weight: 400;
  color: #888;
  font-size: 15px; }

.dashboard-sidebar .user-image h3 span a:hover {
  color: #00aeff; }

.dashboard-sidebar .dashboard-menu {
  padding: 30px 0; }

.dashboard-sidebar .dashboard-menu ul li {
  display: block;
  margin-bottom: 15px; }

.dashboard-sidebar .dashboard-menu ul li:last-child {
  margin: 0; }

.dashboard-sidebar .dashboard-menu ul li a {
  color: #888;
  font-weight: 500;
  font-size: 15px;
  display: block;
  position: relative;
  padding: 6px 30px;
  cursor: pointer; }

.dashboard-sidebar .dashboard-menu ul li a i {
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
  position: relative;
  top: 1px; }

.dashboard-sidebar .dashboard-menu ul li a img {
  display: inline-block;
  margin-right: 4px;
  font-size: 15px;
  position: relative;
  top: -4px;
  width: 18px;
  height: 18px;
  filter: invert(51%) sepia(0%) saturate(84%) hue-rotate(215deg) brightness(104%) contrast(96%); }

.dashboard-sidebar .dashboard-menu ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  border-radius: 0 3px 3px 0;
  background: #00aeff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease; }

.dashboard-sidebar .dashboard-menu ul li a.active {
  color: #00aeff; }

.dashboard-sidebar .dashboard-menu ul li a.active img {
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

.dashboard-sidebar .dashboard-menu ul li a:hover img {
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

.dashboard-sidebar .dashboard-menu ul li a.active::before {
  opacity: 1;
  visibility: visible; }

.dashboard-sidebar .dashboard-menu ul li a:hover {
  color: #00aeff; }

.dashboard-sidebar .dashboard-menu ul li a:hover::before {
  opacity: 1;
  visibility: visible; }

.dashboard-sidebar .dashboard-menu .button {
  margin-top: 60px;
  padding: 0px 30px; }

.dashboard-sidebar .dashboard-menu .button .btn {
  background: #fee8eb;
  color: #f73357;
  padding: 13px 35px;
  font-size: 14px;
  font-weight: 600; }

.dashboard-sidebar .dashboard-menu .button .btn:hover {
  color: #fff;
  background: #f73357; }

.profile-settings-block .inner-block {
  padding: 0px 30px; }

.profile-settings-block .image img {
  height: 150px;
  width: 150px;
  border-radius: 6px; }

.profile-settings-block .profile-setting-form {
  margin-top: 45px; }

.profile-settings-block .profile-setting-form .form-group {
  margin-bottom: 30px; }

.profile-settings-block .profile-setting-form .form-group label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: #081828; }

.profile-settings-block .profile-setting-form .form-group input {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #081828;
  background: #fff;
  padding: 0px 20px; }

.profile-settings-block .profile-setting-form .form-group textarea {
  height: 130px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #081828;
  width: 100%; }

.profile-settings-block .profile-setting-form .form-group.upload-image input {
  padding: 10px;
  font-size: 14px;
  color: #888; }

.password-change-block .inner-block {
  padding: 0px 30px; }

.close-content {
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: 10px; }

/* FOR ME PAGE */
.for-me-graph-block {
  padding: 0px;
  border: 0 !important;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
  height: 160px;
  background: transparent !important; }

.for-me-graph-block-first {
  padding-left: 10px; }

.for-me-graph-block-last {
  padding-right: 10px; }

.for-me-graph-block .row {
  padding: 20px;
  border: 1px solid #eee;
  padding-bottom: 0px;
  padding-top: 40px;
  vertical-align: middle;
  height: 160px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 6px; }

.for-me-wallet-block {
  min-height: 450px;
  margin-left: -15px;
  margin-right: 10px; }

.for-me-graph-icon {
  width: 100%;
  min-width: 60px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 1px solid green;
  padding: 20px; }

.for-me-graph-icon-1 {
  border: 1px solid #15c0b0;
  opacity: 0.8;
  color: #15c0b0;
  font-size: 24px;
  filter: invert(60%) sepia(91%) saturate(1890%) hue-rotate(130deg) brightness(96%) contrast(84%); }

.for-me-graph-icon-2 {
  border: 1px solid #5830E0;
  opacity: 0.8;
  color: #5830E0;
  filter: invert(15%) sepia(82%) saturate(4760%) hue-rotate(254deg) brightness(91%) contrast(93%); }

.for-me-graph-icon-3 {
  border: 1px solid #00aeff;
  opacity: 0.8;
  filter: invert(58%) sepia(82%) saturate(3929%) hue-rotate(171deg) brightness(105%) contrast(103%); }

.for-me-wallet-row {
  border-bottom: 1px solid #eee;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 0px;
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px; }

.for-me-wallet-row-header {
  border-bottom: 2px solid #eee; }

.for-me-wallet-row > div {
  padding-left: 0px;
  padding-right: 0px; }

.for-me-wallet-header {
  text-align: center;
  font-size: 15px;
  color: #888;
  font-weight: 500;
  font-family: 'Jost', sans-serif; }

.for-me-wallet-logo-wrapper {
  float: left;
  width: 60px;
  margin-right: 10px; }

.for-me-wallet-logo {
  height: 60px;
  width: 60px;
  border-radius: 3px; }

.for-me-wallet-text-wrapper {
  float: right;
  width: calc(100% - 80px);
  padding-top: 0px;
  margin-top: -5px; }

.for-me-wallet-text {
  text-align: center;
  font-size: 12px;
  font-family: 'Jost', sans-serif; }

.for-me-wallet-text-code {
  text-align: center;
  font-size: 12px;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  word-break: break-all; }

.for-me-wallet-text-code i {
  cursor: pointer;
  font-size: 18px; }

.for-me-wallet-text-code i:hover {
  cursor: pointer;
  font-size: 18px;
  color: #00aeff; }

.for-me-wallet-text-company {
  text-align: left;
  font-size: 16px;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  white-space: nowrap; }

.for-me-wallet-text-link {
  text-align: left;
  font-size: 12px;
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  cursor: pointer;
  color: #00aeff;
  white-space: nowrap; }

.for-me-wallet-text-large {
  text-align: center;
  font-size: 16px;
  font-family: 'Jost', sans-serif; }

.for-me-wallet-info-text {
  position: absolute;
  bottom: 5px;
  right: 15px;
  width: -moz-fit-content;
  width: fit-content; }

.for-me-wallet-text-cancelled {
  color: #888; }

.for-me-wallet-text-gifted {
  color: #00aeff; }

.for-me-wallet-text-used {
  color: #e8aa3d; }

.for-me-wallet-text-unused {
  color: #00D13F; }

.for-me-list-nav {
  padding-top: 28px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee; }

.for-me-list-nav ul {
  padding: 0px 30px; }

.for-me-list-nav ul li {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 8px; }

.for-me-list-nav ul li:last-child {
  margin: 0; }

.for-me-list-nav ul li a {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 8px 12px 8px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #888; }

.for-me-list-nav ul li a span {
  display: inline-block;
  border: 1px solid #eee;
  font-size: 11px;
  margin-left: 3px;
  padding: 1px 6px;
  border-radius: 4px;
  font-weight: 600; }

.for-me-list-nav ul li a:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.for-me-list-nav ul li a:hover span {
  color: #00aeff;
  background: #fff;
  border-color: transparent; }

.for-me-list-nav ul li .active {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.for-me-list-nav ul li .active span {
  color: #00aeff;
  background: #fff;
  border-color: transparent; }

.for-me-list-nav ul li.active a {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.for-me-list-nav ul li.active a span {
  color: #00aeff;
  background: #fff;
  border-color: transparent; }

/* Default Form Style */
.default-form-style {
  margin-top: 30px; }

.default-form-style .form-group {
  margin-bottom: 30px; }

.default-form-style .form-group.button .btn {
  padding: 13px 30px;
  margin-left: 10px; }

.default-form-style .form-group.button .btn.alt-btn {
  background: #081828;
  color: #fff; }

.default-form-style .form-group.button .btn.alt-btn:hover {
  color: #fff;
  background: #00aeff; }

.default-form-style .form-group.button .btn:first-child {
  margin: 0; }

.default-form-style .form-group label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: #081828; }

.default-form-style .form-group input {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #081828;
  background: #fff;
  padding: 0px 20px; }

.default-form-style .form-group select {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #081828;
  background: #fff;
  padding: 0px 20px;
  -webkit-appearance: none;
  appearance: none;
  position: relative; }

.default-form-style .form-group select:focus {
  outline: none !important; }

.default-form-style .form-group .selector-head {
  position: relative; }

.default-form-style .form-group .arrow {
  position: absolute;
  color: #888;
  right: 20px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  font-size: 12px; }

.default-form-style .form-group textarea {
  height: 130px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #081828;
  width: 100%; }

/* Post Ad Tab */
/* .post-ad-tab {
    padding: 0px 30px;
  }

    .post-ad-tab nav .nav-tabs {
      border-bottom: 2px solid #eee;
    }

    .post-ad-tab nav button {
      padding: 20px 40px;
      border: none !important;
      text-align: left;
      position: relative;
      padding-left: 100px;
      border-radius: 0 !important;
      border-radius: 6px 6px 0 0 !important;
      margin: 0 !important;
      width: 33.33333333333333%;
      position: relative;
      bottom: -1px;
      font-size: 17px;
      font-weight: 600;
      color: #081828;
    }

      .post-ad-tab nav button .serial {
        height: 50px;
        width: 50px;
        line-height: 50px;
        border: 1px solid #eee;
        border-radius: 50%;
        text-align: center;
        color: #888;
        font-size: 19px;
        font-weight: 600;
        display: block;
        position: absolute;
        left: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      .post-ad-tab nav button.active {
        color: #fff !important;
      }

        .post-ad-tab nav button.active .serial {
          border-color: transparent;
          background: #fff;
          color: #00aeff;
        }

        .post-ad-tab nav button.active .sub-title {
          color: #fff;
        }

      .post-ad-tab nav button .sub-title {
        display: block;
        color: #888;
        font-size: 14px;
        font-weight: 400;
      }

      .post-ad-tab nav button:hover {
        border: none !important;
      }

      .post-ad-tab nav button.active {
        background: #00aeff !important;
        border: none !important;
      }

    .post-ad-tab .step-two-content .upload-input {
      position: relative;
      height: 240px;
    }

      .post-ad-tab .step-two-content .upload-input input {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px dashed rgba(124, 134, 154, 0.25);
        text-indent: -9999px;
        z-index: 2;
        border-radius: 6px;
      }

      .post-ad-tab .step-two-content .upload-input .plus-icon {
        height: 80px;
        width: 80px;
        color: #fff;
        background: #88888859;
        display: inline-block;
        line-height: 80px;
        font-size: 18px;
        border-radius: 5px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
      }

      .post-ad-tab .step-two-content .upload-input:hover .plus-icon {
        background-color: #00aeff;
      }

      .post-ad-tab .step-two-content .upload-input .content {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 30px;
        top: 0;
        left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 2;
      }

    .post-ad-tab .step-two-content .video-label {
      display: inline-block;
    }

      .post-ad-tab .step-two-content .video-label span {
        color: #888;
        display: inline-block;
        margin-left: 12px;
        font-weight: 400;
        font-size: 14px;
      }

    .post-ad-tab .step-two-content .add-video {
      text-transform: capitalize;
      font-weight: 600;
      color: #888;
      font-size: 14px;
      margin-top: 20px;
    }

      .post-ad-tab .step-two-content .add-video:hover {
        color: #00aeff;
      }

      .post-ad-tab .step-two-content .add-video i {
        display: inline-block;
        margin-right: 5px;
      }

    .post-ad-tab .step-two-content .tag-label {
      display: inline-block;
    }

      .post-ad-tab .step-two-content .tag-label span {
        color: #888;
        display: inline-block;
        margin-left: 12px;
        font-weight: 400;
        font-size: 14px;
      }

    .post-ad-tab .step-three-content .google-map {
      margin-bottom: 30px;
    }

    .post-ad-tab .step-three-content .form-check {
      display: block;
      margin-bottom: 20px;
    } */
/* List Nav */
.list-nav {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee; }

.list-nav ul {
  padding: 0px 30px; }

.list-nav ul li {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 8px; }

.list-nav ul li:last-child {
  margin: 0; }

.list-nav ul li a {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 8px 12px 8px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #888; }

.list-nav ul li a span {
  display: inline-block;
  border: 1px solid #eee;
  font-size: 11px;
  margin-left: 3px;
  padding: 1px 6px;
  border-radius: 4px;
  font-weight: 600; }

.list-nav ul li a:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.list-nav ul li a:hover span {
  color: #00aeff;
  background: #fff;
  border-color: transparent; }

.list-nav ul li.active a {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.list-nav ul li.active a span {
  color: #00aeff;
  background: #fff;
  border-color: transparent; }

/* My Items */
.my-items {
  padding: 0 30px; }

.my-items .item-list-title {
  padding-bottom: 15px;
  border-bottom: 2px solid #eee; }

.my-items .item-list-title p {
  color: #888;
  font-weight: 500;
  font-family: 'Jost', sans-serif; }

.my-items .single-item-list {
  padding: 20px 0;
  border-bottom: 1px solid #eee; }

.my-items .single-item-list .content {
  position: relative;
  top: -6px; }

.my-items .single-item-list .item-image {
  position: relative;
  padding-left: 80px; }

.my-items .single-item-list .item-image img {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 60px;
  border-radius: 3px; }

.my-items .single-item-list .item-image .title a {
  font-size: 15px;
  font-weight: 400;
  color: #888; }

.my-items .single-item-list .item-image .title a:hover {
  color: #00aeff; }

.my-items .single-item-list .item-image .price {
  font-size: 14px;
  color: #888;
  display: block;
  margin-top: 3px; }

.my-items .single-item-list p {
  color: #888;
  font-family: 'Jost', sans-serif; }

.my-items .single-item-list .action-btn li {
  display: inline-block;
  margin-right: 8px; }

.my-items .single-item-list .action-btn li:last-child {
  margin: 0; }

.my-items .single-item-list .action-btn li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  border: 1px solid #eee;
  border-radius: 50%;
  color: #888;
  text-align: center;
  display: block; }

.my-items .single-item-list .action-btn li a:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

/* Messages Body */
.messages-body .form-head {
  border-bottom: 1px solid #eee; }

.messages-body .form-head .username-title {
  font-size: 15px;
  font-weight: 500;
  padding-right: 30px;
  color: #888; }

.messages-body .chat-search-form {
  position: relative;
  border-right: 1px solid #eee;
  height: 100%;
  padding: 30px; }

.messages-body .chat-search-form input {
  height: 100%;
  width: 100%;
  border: none;
  color: #081828;
  font-size: 15px;
  font-weight: 400;
  background: #fff;
  padding-right: 80px; }

.messages-body .chat-search-form button {
  font-size: 14px;
  color: #081828;
  background: transparent;
  border: none;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 50%;
  transition: all 0.4s ease; }

.messages-body .chat-search-form button:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.messages-body .user-list {
  border-right: 1px solid #eee;
  height: 100%;
  padding-bottom: 30px;
  overflow-y: auto;
  height: 480px; }

.messages-body .user-list ul li {
  display: block; }

.messages-body .user-list ul li a {
  position: relative;
  padding-left: 70px;
  width: 100%;
  padding: 30px 65px 30px 100px; }

.messages-body .user-list ul li a:hover {
  background: #f9f9f9; }

.messages-body .user-list ul li a .image {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%); }

.messages-body .user-list ul li a .image::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 2px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #00d13f;
  border: 2px solid #fff; }

.messages-body .user-list ul li a .image.busy::before {
  background: #f73d64; }

.messages-body .user-list ul li a .image img {
  height: 50px;
  width: 50px;
  display: block;
  border-radius: 50%; }

.messages-body .user-list ul li a .username {
  font-size: 14px;
  font-weight: 400;
  display: block;
  color: #081828; }

.messages-body .user-list ul li a .short-message {
  font-size: 14px;
  font-weight: 400;
  display: block;
  color: #888;
  margin-top: 3px; }

.messages-body .user-list ul li a .unseen-message {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: #f73c67;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 30px;
  color: #fff;
  font-weight: 500; }

.messages-body .user-list ul li a .time {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: 400;
  color: #888;
  font-weight: 500; }

.messages-body .chat-list {
  padding-right: 30px;
  padding-top: 30px; }

.messages-body .chat-list .single-chat-head {
  height: 309px;
  overflow-y: auto; }

.messages-body .chat-list .single-chat-head li {
  position: relative;
  padding-left: 75px;
  margin-bottom: 20px; }

.messages-body .chat-list .single-chat-head li:last-child {
  margin: 0; }

.messages-body .chat-list .single-chat-head li.left img {
  height: 50px;
  width: 50px;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 50%; }

.messages-body .chat-list .single-chat-head li.left .text {
  background: #f9f9f9;
  padding: 30px;
  font-size: 16px;
  line-height: 25px;
  color: #666;
  border-radius: 6px;
  position: relative;
  font-weight: 400;
  font-family: 'Jost', sans-serif; }

.messages-body .chat-list .single-chat-head li.left .text::before {
  position: absolute;
  left: -16px;
  bottom: 16px;
  content: "";
  border: 8px solid #f9f9f9;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.messages-body .chat-list .single-chat-head li.left .text .time {
  display: block;
  color: #00aeff;
  margin-top: 10px;
  font-size: 14px; }

.messages-body .chat-list .single-chat-head li.right {
  padding: 0;
  padding-right: 75px; }

.messages-body .chat-list .single-chat-head li.right img {
  height: 50px;
  width: 50px;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%; }

.messages-body .chat-list .single-chat-head li.right .text {
  background: #f9f9f9;
  padding: 30px;
  font-size: 16px;
  line-height: 25px;
  color: #666;
  border-radius: 6px;
  position: relative;
  font-weight: 400;
  font-family: 'Jost', sans-serif; }

.messages-body .chat-list .single-chat-head li.right .text::before {
  position: absolute;
  right: -16px;
  bottom: 16px;
  content: "";
  border: 8px solid #f9f9f9;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.messages-body .chat-list .single-chat-head li.right .text .time {
  display: block;
  color: #00aeff;
  margin-top: 10px;
  font-size: 14px; }

.messages-body .chat-list .reply-block {
  padding: 30px 0;
  margin-top: 40px;
  border-top: 1px solid #eee; }

.messages-body .chat-list .reply-block .add-media-list {
  display: inline-block; }

.messages-body .chat-list .reply-block .add-media-list li {
  display: inline-block;
  margin-right: 5px; }

.messages-body .chat-list .reply-block .add-media-list li:last-child {
  margin: 0; }

.messages-body .chat-list .reply-block .add-media-list li a {
  height: 40px;
  width: 40px;
  text-align: center;
  color: #888;
  font-size: 14px;
  display: block;
  border: 1px solid #eee;
  border-radius: 50%;
  line-height: 40px; }

.messages-body .chat-list .reply-block .add-media-list li a:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

.messages-body .chat-list .reply-block input {
  height: 100%;
  width: 280px;
  color: #081828;
  border: none;
  padding: 0px 20px;
  font-size: 15px; }

.messages-body .chat-list .reply-block button {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background: #00aeff;
  border-radius: 50%;
  float: right;
  border: none;
  font-size: 18px;
  position: relative;
  top: -4px;
  transition: all 0.4s ease;
  padding-left: 3px;
  padding-bottom: 3px; }

.messages-body .chat-list .reply-block button img {
  width: 17px; }

.messages-body .chat-list .reply-block button:hover {
  background: #081828;
  color: #fff; }

/* Default List Style  */
.default-list-style .default-list-title {
  padding: 0px 30px 15px 30px;
  border-bottom: 2px solid #eee; }

.default-list-style .default-list-title p {
  color: #888;
  font-weight: 500; }

.default-list-style .action-btn li {
  display: inline-block;
  margin-right: 8px; }

.default-list-style .action-btn li:last-child {
  margin-right: 0; }

.default-list-style .action-btn li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  border: 1px solid #eee;
  border-radius: 50%;
  color: #888;
  text-align: center;
  display: block; }

.default-list-style .action-btn li a:hover {
  background: #00aeff;
  color: #fff;
  border-color: transparent; }

.default-list-style .single-list {
  padding: 25px 30px;
  border-bottom: 1px solid #eee; }

.default-list-style .single-list p span {
  display: block;
  font-size: 14px;
  margin-top: 4px;
  font-style: italic;
  font-weight: 400; }

.default-list-style .single-list p.paid {
  color: #00D13F; }

.default-list-style .single-list p.unpaid {
  color: #F53C66; }

.default-list-style .single-list p.pending {
  color: #e8aa3d; }

.default-list-style .pagination {
  padding: 0px 30px; }

/* Close Account */
.close-account-button {
  color: #AAA;
  cursor: pointer; }

.close-content {
  padding: 80px 50px;
  text-align: center; }

.close-content h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px; }

.close-content h4 {
  font-size: 17px;
  font-weight: 400;
  color: #888; }

.close-content .button {
  margin-top: 40px; }

.close-content .button .btn {
  padding: 12px 30px;
  margin-right: 6px; }

.close-content .button .btn:last-child {
  margin: 0; }

/*======================================
    Login CSS
========================================*/
.login {
  background-color: #f9f9f9; }

.login .form-head {
  padding: 50px;
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #fff; }

.login .form-head .title {
  font-size: 30px;
  line-height: 1.42;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center; }

.login .form-head form .form-group {
  margin-bottom: 25px; }

.login .form-head form .form-group label {
  display: block;
  margin-bottom: .5rem;
  color: #888;
  font-size: 15px;
  font-weight: 400; }

.login .form-head form .form-group input {
  width: 100%;
  min-height: 56px;
  padding: 3px 20px;
  color: #081828;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: 0;
  background-color: #fff;
  font-size: 15px; }

.login .form-head form .check-and-pass .form-check {
  float: left; }

.login .form-head form .check-and-pass .form-check a {
  color: #00aeff; }

.login .form-head form .check-and-pass .form-check .form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff; }

.login .form-head form .check-and-pass .form-check-label a:hover {
  text-decoration: underline; }

.login .form-head form .check-and-pass .lost-pass {
  float: right;
  color: #777;
  position: relative;
  top: -2px; }

.login .form-head form .check-and-pass .lost-pass:hover {
  color: #00aeff;
  text-decoration: underline; }

.login .form-head form .button {
  margin-top: 25px; }

.login .form-head form .button .btn {
  width: 100%;
  box-shadow: none !important; }

.login .form-head form .button .btn:hover {
  box-shadow: none !important; }

.login .form-head form .outer-link {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #888;
  margin-top: 30px;
  text-align: center;
  font-family: 'Jost', sans-serif; }

.login .form-head form .outer-link a {
  color: #00aeff; }

.login .form-head form .outer-link a:hover {
  text-decoration: underline; }

.login .form-head .alt-option {
  margin: 30px 0;
  text-align: center;
  display: inline-block;
  position: relative;
  width: 100%;
  z-index: 1; }

.login .form-head .alt-option span {
  font-size: 15px;
  background: #fff;
  color: #888;
  padding: 5px 15px; }

.login .form-head .alt-option::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background: #eee;
  content: "";
  z-index: -1; }

.login .form-head .socila-login ul li {
  display: block;
  margin-bottom: 12px; }

.login .form-head .socila-login ul li:last-child {
  margin: 0; }

.login .form-head .socila-login ul li a {
  padding: 15px 20px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 6px;
  display: block;
  color: #888;
  font-size: 15px; }

.login .form-head .socila-login ul li a.facebook:hover {
  color: #fff;
  background: #3b5999;
  border-color: transparent; }

.login .form-head .socila-login ul li a.facebook:hover i {
  color: #fff; }

.login .form-head .socila-login ul li a.facebook i {
  color: #3b5999; }

.login .form-head .socila-login ul li a.google:hover {
  color: #fff;
  background: #dd4b39;
  border-color: transparent; }

.login .form-head .socila-login ul li a.google:hover i {
  color: #fff; }

.login .form-head .socila-login ul li a.google i {
  color: #dd4b39; }

.login .form-head .socila-login ul li a i {
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
  transition: all 0.4s ease; }

/*======================================
    Why Choose CSS
========================================*/
.why-choose .choose-image img {
  height: 100%;
  width: 100%; }

.why-choose .choose-content .title {
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  padding-bottom: 20px; }

.why-choose .choose-content .single-list {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #00aeff;
  border-radius: 6px;
  border: 0px;
  text-align: center;
  min-height: 360px; }

.why-choose .choose-content .single-list-transparent {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: transparent !important;
  border-radius: 6px;
  border: 0px;
  text-align: center; }

.why-choose .choose-content .single-list-last {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #ff801b !important;
  border-radius: 6px;
  border: 0px;
  text-align: center; }

.why-choose .choose-content .single-list .single-list-number {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
  width: 110px;
  line-height: 105px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #F4FAFF;
  color: #00aeff; }

.why-choose .choose-content .single-list .single-list-number:hover {
  background-color: #00aeff;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.why-choose .choose-content .single-list .single-list-number-last {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
  width: 110px;
  line-height: 105px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #E2CEBE;
  color: #ff801b; }

.why-choose .choose-content .single-list .single-list-number-last:hover {
  background-color: #E2CEBE;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.why-choose .choose-content .single-list:hover i {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.why-choose .choose-content .single-list h4 {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
  color: #081828;
  margin-top: 20px;
  min-height: 40px; }

.why-choose .choose-content .single-list-last h4 {
  color: #FFFFFF;
  min-height: 60px; }

.why-choose .choose-content .single-list-transparent h4 {
  color: #FFFFFF;
  min-height: 60px; }

.why-choose .choose-content .single-list p {
  font-size: 16px;
  color: #FFFFFF; }

.why-choose .choose-content .single-list-last p {
  font-size: 16px;
  color: #FFFFFF; }

.why-choose .choose-content .single-list-transparent p {
  font-size: 16px;
  color: #888; }

.why-choose .choose-content .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  position: relative;
  min-height: 340px; }

.why-choose .choose-content .single-work .single-list-logo-wrapper {
  width: 100%; }

.why-choose .choose-content .single-work .single-list-logo-wrapper2 {
  width: 80px;
  margin-left: calc(50% - 63px);
  margin-right: calc(50% - 63px);
  top: 44px;
  position: absolute;
  display: block; }

.why-choose .choose-content .single-work .single-list-logo-img {
  text-align: center;
  position: absolute;
  background: url(/static/media/logo_blue.d6bb8ac3.webp);
  border: 0px;
  top: 0px;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  width: 25px;
  aspect-ratio: 1 / 1;
  background-size: cover;
  z-index: 999;
  top: 50%;
  left: 38%; }

.why-choose .choose-content .single-work img {
  text-align: center;
  padding: 5px;
  width: 80px;
  height: 80px;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff; }

.why-choose .choose-content .single-work svg {
  text-align: center;
  padding: 5px;
  width: 80px;
  height: 80px;
  color: #FFF;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
  transition: color 0.5s, border 0.5s; }

.why-choose .choose-content .single-work i {
  text-align: center;
  padding: 5px;
  padding-top: 13px;
  font-size: 60px;
  width: 80px;
  height: 80px;
  color: #FFF;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
  transition: color 0.5s, border 0.5s; }

.why-choose .choose-content .single-work:hover img {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

.why-choose .choose-content .single-work:hover svg {
  background-color: transparent;
  border: 4px solid #00aeff;
  color: #00aeff;
  transition: color 0.5s, border 0.5s; }

.why-choose .choose-content .single-work:hover i {
  background-color: transparent;
  border: 4px solid #00aeff;
  color: #00aeff;
  transition: color 0.5s, border 0.5s; }

.why-choose .choose-content .single-work:hover .single-list-logo-img {
  filter: saturate(0%) brightness(0%) invert(100%) contrast(100%); }

.why-choose .choose-content .single-list-last img {
  text-align: center;
  padding: 20px;
  background-color: #ff801b;
  border-radius: 50%;
  border: 4px solid #ff801b; }

.why-choose .choose-content .single-list-last img:hover {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: none !important; }

.why-choose .choose-content .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  transition: all 0.4s ease; }

.why-choose .choose-content .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.why-choose .choose-content .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px; }

.why-choose .choose-content .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px; }

/*======================================
    vouchers are important CSS
========================================*/
.vouchers-are-important .choose-image img {
  height: 100%;
  width: 100%; }

.vouchers-are-important .choose-content .title {
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  padding-bottom: 20px; }

.vouchers-are-important .choose-content .single-list {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #00aeff;
  border-radius: 6px;
  border: 0px;
  text-align: center;
  min-height: 320px; }

.vouchers-are-important .choose-content .single-list-transparent {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: transparent !important;
  border-radius: 6px;
  border: 0px;
  text-align: center; }

.vouchers-are-important .choose-content .single-list-last {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #ff801b !important;
  border-radius: 6px;
  border: 0px;
  text-align: center; }

.vouchers-are-important .choose-content .single-list .single-list-number {
  font-size: 26px;
  display: inline-block;
  margin-bottom: 10px;
  height: 90px;
  width: 90px;
  line-height: 85px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #F4FAFF;
  color: #00aeff; }

.vouchers-are-important .choose-content .single-list:hover .single-list-number {
  background-color: #00aeff;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.vouchers-are-important .choose-content .single-list .single-list-number-last {
  font-size: 26px;
  display: inline-block;
  margin-bottom: 10px;
  height: 90px;
  width: 90px;
  line-height: 85px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #FFFFFF;
  color: #ff801b; }

.vouchers-are-important .choose-content .single-list:hover .single-list-number-last {
  background-color: #ff801b;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.vouchers-are-important .choose-content .single-list:hover i {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.vouchers-are-important .choose-content .single-list h4 {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
  color: #FFFFFF;
  margin-top: 20px;
  min-height: 40px; }

.vouchers-are-important .choose-content .single-list-last h4 {
  color: #FFFFFF;
  min-height: 40px; }

.vouchers-are-important .choose-content .single-list-transparent h4 {
  color: #FFFFFF;
  min-height: 40px; }

.vouchers-are-important .choose-content .single-list p {
  font-size: 16px;
  color: #FFFFFF; }

.vouchers-are-important .choose-content .single-list-last p {
  font-size: 16px;
  color: #FFFFFF; }

.vouchers-are-important .choose-content .single-list-transparent p {
  font-size: 16px;
  color: #888; }

.vouchers-are-important .choose-content .single-work {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px; }

.vouchers-are-important .choose-content .single-work img {
  text-align: center;
  padding: 20px;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff; }

.vouchers-are-important .choose-content .single-work img:hover {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

.vouchers-are-important .choose-content .single-list-last img {
  text-align: center;
  padding: 20px;
  background-color: #ff801b;
  border-radius: 50%;
  border: 4px solid #ff801b; }

.vouchers-are-important .choose-content .single-list-last img:hover {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: none !important; }

.vouchers-are-important .choose-content .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  transition: all 0.4s ease; }

.vouchers-are-important .choose-content .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.vouchers-are-important .choose-content .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px; }

.vouchers-are-important .choose-content .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px; }

/*======================================
    Item Details CSS
========================================*/
.item-details {
  background-color: #f9f9f9; }

.item-details .top-area {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px; }

.item-details .product-images img {
  width: 100%;
  aspect-ratio: 18 / 9; }

.item-details .product-images .primary-placeholder {
  width: 100%;
  aspect-ratio: 18 / 9;
  background-color: #ECECEC;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.item-details .product-images .images {
  display: grid;
  Grid-template-columns: repeat(5, 1fr);
  Grid-gap: 1em 1em;
  Margin-top: 1em; }

@keyframes fadeIn {
  to {
    Opacity: 1; } }

.item-details .product-images .fade-in {
  animation: fadeIn 0.5s ease-in 1 forwards; }

.item-details .product-info {
  padding-left: 40px; }

.item-details .product-info-edit-button {
  color: #00aeff;
  font-size: 14px;
  float: right;
  font-weight: 400;
  cursor: pointer; }

.item-details .recipient-next-button {
  margin-top: 30px;
  margin-left: 10px;
  width: 100%;
  height: 67px;
  color: white;
  font-size: 1rem;
  background: #00aeff;
  right: 6px;
  position: relative; }

.item-details .recipient-next-button:hover {
  border: 1px solid #00B2FF !important;
  background: #FFF !important;
  color: #00B2FF !important;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.item-details .recipient-next-button-info {
  padding-left: 15px;
  text-align: center;
  white-space: nowrap; }

.item-details .recipient-when-to-send-button {
  margin-top: 30px;
  margin-left: 10px;
  width: 100%;
  height: 90%;
  color: white;
  font-size: 1rem;
  background: #00aeff;
  right: -6px;
  position: relative; }

.item-details .recipient-when-to-send-button div {
  margin-top: 5px; }

.item-details .recipient-when-to-send-button-info {
  padding-left: 5px;
  text-align: center;
  margin-right: -25px;
  white-space: nowrap; }

.item-details .recipient-when-to-send-button-icon {
  margin-right: auto;
  margin-left: auto;
  width: 24px;
  height: 24px;
  color: white;
  position: relative; }

.item-details .product-info h4 {
  font-size: 22px; }

.item-details .product-info .title {
  font-size: 30px;
  margin-bottom: 10px;
  min-height: 36px; }

.item-details .product-info .website {
  display: block;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
  color: #00B2FF;
  font-weight: 600;
  min-height: 23px; }

.item-details .product-info .location {
  display: block;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
  color: #888; }

.item-details .product-info .location i {
  display: inline-block;
  margin-right: 5px;
  color: #00aeff; }

.item-details .product-info .location a {
  color: #888; }

.item-details .product-info .location a:hover {
  color: #00aeff; }

.item-details .product-info .price {
  color: #00aeff;
  display: block; }

.item-details .product-info .list-info {
  margin-top: 5px;
  padding-top: 10px;
  position: relative;
  margin-left: 15px; }

.item-details .product-info .list-info-input-label {
  margin-bottom: 7px;
  font-weight: 400; }

.item-details .product-info .list-info .form-control {
  font-size: 15px;
  border: 1px solid #BAE9FF;
  box-shadow: none; }

.item-details .product-info .list-info .list-info-date {
  border: 1px solid #bae9ff;
  box-shadow: none; }

.item-details .product-info .list-info .list-info-date .rw-widget-input {
  border: 0;
  box-shadow: none; }

.item-details .product-info .list-info .list-info-date .rw-select-bordered {
  border-left: 1px solid #bae9ff; }

.item-details .product-info .list-info .list-info-date .rw-widget-container {
  border: 0;
  box-shadow: none; }

.item-details .product-info .list-info .list-info-char-count {
  position: absolute;
  right: 20px;
  bottom: 0px; }

.item-details .product-info .list-info .list-info-textarea {
  min-height: 110px; }

.item-details .product-info .list-info h4 {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 15px; }

.item-details .product-info .list-info ul li {
  display: block;
  margin-bottom: 6px; }

.item-details .product-info .list-info ul li:last-child {
  margin: 0; }

.item-details .product-info .list-info ul li span {
  display: inline-block;
  font-weight: 500;
  min-width: 100px; }

.item-details .main-img {
  position: relative; }

.item-details .product-info-logo {
  padding: 10px;
  border-radius: 2px;
  padding-left: 0;
  width: 13% !important;
  max-width: 20%;
  position: absolute;
  left: 10px;
  bottom: 0px;
  border-radius: 18px;
  min-width: 70px !important;
  aspect-ratio: 1/1 !important; }

.item-details .product-info-logo .logo-placeholder {
  padding: 10px;
  border-radius: 2px;
  padding-left: 0;
  width: 13% !important;
  max-width: 20%;
  position: absolute;
  left: 10px;
  bottom: 0px;
  border-radius: 18px;
  min-width: 70px !important;
  aspect-ratio: 1 / 1 !important;
  background-color: #ECECEC;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

.item-details .product-info .contact-info {
  margin-top: 30px; }

.item-details .product-info .contact-info ul li {
  display: inline-block;
  margin-right: 10px; }

.item-details .product-info .contact-info ul li:last-child {
  margin: 0; }

.item-details .product-info .contact-info ul li .call {
  position: relative;
  padding: 15px 40px;
  padding-left: 80px;
  color: #fff;
  background-color: #00aeff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  float: left; }

.item-details .product-info .contact-info ul li .call:hover {
  color: #fff;
  background-color: #081828; }

.item-details .product-info .contact-info ul li .call span {
  font-size: 14px;
  display: block;
  margin-top: 2px;
  font-weight: 400; }

.item-details .product-info .contact-info ul li .call i {
  color: #fff;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px; }

.item-details .product-info .contact-info ul li .mail {
  padding: 14px 30px;
  color: #00aeff;
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 4px;
  font-size: 30px;
  float: left; }

.item-details .product-info .contact-info ul li .mail:hover {
  background-color: #00aeff;
  color: #fff;
  border-color: transparent; }

.item-details .product-info .social-share {
  display: block;
  margin-top: 35px; }

.item-details .product-info .social-share h4 {
  font-size: 16px;
  font-weight: 500;
  color: #888;
  display: inline-block;
  margin-right: 15px; }

.item-details .product-info .social-share ul {
  display: inline-block; }

.item-details .product-info .social-share ul li {
  display: inline-block;
  margin-right: 5px; }

.item-details .product-info .social-share ul li:last-child {
  margin-right: 0; }

.item-details .product-info .social-share ul li a {
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  border-radius: 50%;
  border: 1px solid #eee;
  background-color: #fff;
  color: #00aeff;
  line-height: 40px;
  font-size: 14px; }

.item-details .product-info .social-share ul li a.facebook {
  color: #3b5999; }

.item-details .product-info .social-share ul li a.facebook:hover {
  border-color: transparent;
  background-color: #3b5999;
  color: #fff; }

.item-details .product-info .social-share ul li a.twitter {
  color: #55acee; }

.item-details .product-info .social-share ul li a.twitter:hover {
  border-color: transparent;
  background-color: #55acee;
  color: #fff; }

.item-details .product-info .social-share ul li a.google {
  color: #dd4b39; }

.item-details .product-info .social-share ul li a.google:hover {
  border-color: transparent;
  background-color: #dd4b39;
  color: #fff; }

.item-details .product-info .social-share ul li a.linkedin {
  color: #0077B5; }

.item-details .product-info .social-share ul li a.linkedin:hover {
  border-color: transparent;
  background-color: #0077B5;
  color: #fff; }

.item-details .product-info .social-share ul li a.pinterest {
  color: #bd081c; }

.item-details .product-info .social-share ul li a.pinterest:hover {
  border-color: transparent;
  background-color: #bd081c;
  color: #fff; }

/* Item Details Blocks */
.item-details-blocks .single-block {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-top: 30px; }

.item-details-blocks .single-block h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px; }

.item-details-blocks .single-block.description p {
  margin: 15px 0;
  font-size: 16px; }

.item-details-blocks .single-block.description ul {
  display: block;
  margin: 30px 0; }

.item-details-blocks .single-block.description ul li {
  display: block;
  margin-bottom: 5px;
  font-size: 15px; }

.item-details-blocks .single-block.description ul li:last-child {
  margin-bottom: 0; }

.item-details-blocks .single-block.tags ul li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 8px; }

.item-details-blocks .single-block.tags ul li a {
  padding: 8px 15px;
  border: 1px solid #eee;
  color: #888;
  border-radius: 4px; }

.item-details-blocks .single-block.tags ul li a:hover {
  color: #00aeff;
  border-color: #00aeff; }

.item-details-blocks .single-block.comments .single-comment {
  position: relative;
  padding-left: 110px;
  margin-top: 40px; }

.item-details-blocks .single-block.comments .single-comment img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0; }

.item-details-blocks .single-block.comments .single-comment h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 2px; }

.item-details-blocks .single-block.comments .single-comment span {
  display: block;
  margin-bottom: 25px; }

.item-details-blocks .single-block.comments .single-comment p {
  font-size: 16px; }

.item-details-blocks .single-block.comments .single-comment .reply {
  padding: 8px 15px;
  border: 1px solid #eee;
  color: #888;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500; }

.item-details-blocks .single-block.comments .single-comment .reply i {
  display: inline-block;
  margin-right: 2px; }

.item-details-blocks .single-block.comments .single-comment .reply:hover {
  color: #00aeff;
  border-color: #00aeff; }

/* Item Details Sidebar */
.item-details-sidebar .single-block.author {
  position: relative; }

.item-details-sidebar .single-block.author img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0; }

.item-details-sidebar .single-block.author .content {
  position: relative;
  padding-left: 70px; }

.item-details-sidebar .single-block.author .content h4 {
  font-size: 16px;
  font-weight: 500; }

.item-details-sidebar .single-block.author .content span {
  font-size: 14px;
  color: #888;
  margin-top: 4px;
  display: block; }

.item-details-sidebar .single-block.author .content .see-all {
  font-size: 15px;
  margin-top: 20px;
  color: #00aeff; }

.item-details-sidebar .single-block.author .content .see-all:hover {
  color: #081828; }

.item-details-sidebar .single-block.contant-seller .form-control-custom {
  margin-bottom: 15px;
  height: 50px; }

.item-details-sidebar .single-block.contant-seller .form-control-custom::placeholder {
  color: #081828;
  padding: 0 !important; }

.item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom {
  height: 150px; }

.item-details-sidebar .single-block.contant-seller .form-box textarea.form-control-custom::placeholder {
  color: #081828;
  padding: 0 !important; }

/* What steppr offers */
.what-steppr-offers {
  background-color: #F4FAFF; }

.what-steppr-offers-card-text {
  height: 50%;
  position: relative; }

.what-steppr-offers-card-text-top {
  margin-top: 30%; }

.what-steppr-offers-card-text > h3 {
  bottom: 0;
  margin-top: 60%; }

.what-steppr-offers-card-text > p {
  position: absolute;
  top: 0; }

.what-steppr-offers-row {
  display: flex; }

.what-steppr-offers-row .col-12 {
  margin-bottom: 10px; }

.what-steppr-offers .single-work {
  text-align: center;
  padding: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  min-height: 260px; }

.what-steppr-offers .single-work:hover {
  color: #FFF;
  background-color: #00aeff;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.what-steppr-offers .single-work:hover h3 {
  color: #FFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.what-steppr-offers .single-work-odd {
  color: #FFF;
  background-color: #00aeff; }

.what-steppr-offers .single-work-odd h3 {
  color: #FFF; }

.what-steppr-offers .single-work-odd:hover {
  background-color: #fff; }

.what-steppr-offers .single-work-odd:hover h3 {
  color: #081828; }

.what-steppr-offers .single-work-odd:hover p {
  color: #888; }

.what-steppr-offers .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  transition: all 0.4s ease; }

.what-steppr-offers .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.what-steppr-offers .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px; }

.what-steppr-offers .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px; }

/* How It Works */
.how-works {
  background-color: #00aeff; }

.connect-easily .choose-image img {
  height: 100%;
  width: 100%; }

.connect-easily .choose-content .title {
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  padding-bottom: 20px; }

.connect-easily .choose-content .single-list {
  position: relative;
  margin-bottom: 30px;
  padding: 40px;
  background: #00aeff;
  border-radius: 6px;
  border: 0px;
  text-align: center;
  min-height: 180px; }

.connect-easily .choose-content .single-list-transparent {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: transparent !important;
  border-radius: 6px;
  border: 0px;
  text-align: center; }

.connect-easily .choose-content .single-list-last {
  position: relative;
  margin-top: 30px;
  padding: 40px;
  background: #ff801b !important;
  border-radius: 6px;
  border: 0px;
  text-align: center; }

.connect-easily .choose-content .single-list .single-list-number {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
  width: 110px;
  line-height: 105px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #F4FAFF;
  color: #00aeff; }

.connect-easily .choose-content .single-list .single-list-number:hover {
  background-color: #00aeff;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.connect-easily .choose-content .single-list .single-list-number-last {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
  width: 110px;
  line-height: 105px;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  transition: all 0.4s ease;
  background-color: #FFFFFF;
  border: 4px solid #E2CEBE;
  color: #ff801b; }

.connect-easily .choose-content .single-list .single-list-number-last:hover {
  background-color: #E2CEBE;
  border: 4px solid #FFFFFF;
  color: #FFFFFF;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.connect-easily .choose-content .single-list:hover i {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.connect-easily .choose-content .single-list h4 {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
  color: #081828;
  margin-top: 20px;
  min-height: 40px; }

.connect-easily .choose-content .single-list-last h4 {
  color: #FFFFFF;
  min-height: 60px; }

.connect-easily .choose-content .single-list-transparent h4 {
  color: #FFFFFF;
  min-height: 60px; }

.connect-easily .choose-content .single-list p {
  font-size: 16px; }

.connect-easily .choose-content .single-list-last p {
  font-size: 16px;
  color: #FFFFFF; }

.connect-easily .choose-content .single-list-transparent p {
  font-size: 16px;
  color: #888; }

.connect-easily .choose-content .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  position: relative;
  min-height: 320px; }

.connect-easily .choose-content .single-work .single-list-logo-wrapper {
  width: 100%; }

.connect-easily .choose-content .single-work .single-list-logo-wrapper2 {
  width: 80px;
  margin-left: calc(50% - 63px);
  margin-right: calc(50% - 63px);
  top: 44px;
  position: absolute;
  display: block; }

.connect-easily .choose-content .single-work .single-list-logo-img {
  text-align: center;
  position: absolute;
  background: url(/static/media/logo_blue.d6bb8ac3.webp);
  border: 0px;
  top: 0px;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  width: 25px;
  aspect-ratio: 1 / 1;
  background-size: cover;
  z-index: 999;
  top: 50%;
  left: 38%; }

.connect-easily .choose-content .single-work img {
  text-align: center;
  padding: 5px;
  width: 80px;
  height: 80px;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff; }

.connect-easily .choose-content .single-work svg {
  text-align: center;
  padding: 5px;
  width: 80px;
  height: 80px;
  color: #FFF;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
  transition: color 0.5s, border 0.5s; }

.connect-easily .choose-content .single-work i {
  text-align: center;
  padding: 5px;
  padding-top: 13px;
  font-size: 60px;
  width: 80px;
  height: 80px;
  color: #FFF;
  background-color: #00AEFF;
  border-radius: 50%;
  border: 4px solid #00aeff;
  transition: color 0.5s, border 0.5s; }

.connect-easily .choose-content .single-work:hover img {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: brightness(0) saturate(100%) invert(55%) sepia(73%) saturate(3007%) hue-rotate(169deg) brightness(101%) contrast(104%); }

.connect-easily .choose-content .single-work:hover svg {
  background-color: transparent;
  border: 4px solid #00aeff;
  color: #00aeff;
  transition: color 0.5s, border 0.5s; }

.connect-easily .choose-content .single-work:hover i {
  background-color: transparent;
  border: 4px solid #00aeff;
  color: #00aeff;
  transition: color 0.5s, border 0.5s; }

.connect-easily .choose-content .single-work:hover .single-list-logo-img {
  filter: saturate(0%) brightness(0%) invert(100%) contrast(100%); }

.connect-easily .choose-content .single-list-last img {
  text-align: center;
  padding: 20px;
  background-color: #ff801b;
  border-radius: 50%;
  border: 4px solid #ff801b; }

.connect-easily .choose-content .single-list-last img:hover {
  background-color: transparent;
  border: 4px solid #FFF;
  filter: none !important; }

.connect-easily .choose-content .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  transition: all 0.4s ease; }

.connect-easily .choose-content .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.connect-easily .choose-content .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px; }

.connect-easily .choose-content .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px; }

.connect-easily .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  min-height: 250px;
  margin-bottom: 0px; }

.connect-easily .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #FFF;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  transition: all 0.4s ease; }

.connect-easily .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff; }

.connect-easily .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px; }

.connect-easily .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px; }

.connect-easily-row {
  width: 100%; }

@media (min-width: 992px) {
  .connect-easily-row .col-md-2 {
    width: 20%; }
  .how-works-row {
    display: flex; }
  .how-works-row .col {
    display: flex; }
  .how-works-row .col-12 {
    display: flex; }
  .how-works-row .col-md-3 {
    display: flex; }
  .how-works-row .col-lg-3 {
    display: flex; } }

.how-works .single-work {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  min-height: 320px; }

.how-works .single-work .serial {
  height: 80px;
  width: 80px;
  line-height: 68px;
  background-color: #00aeff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #00aeff;
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 25px;
  transition: all 0.4s ease; }

.how-works .single-work:hover .serial {
  color: #00aeff;
  background-color: transparent;
  border-color: #00aeff;
  transition: background 0.5s, border 0.5s, color 0.5s; }

.how-works .single-work h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 40px; }

.how-works .single-work p {
  font-size: 16px;
  line-height: 24px;
  min-height: 72px; }

/*======================================
    Call Action CSS
========================================*/
/* .call-action {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 2;
    padding: 150px 0;
    background-attachment: fixed;
  }

    .call-action.overlay::before {
      background-color: #081828;
      opacity: 0.9;
      z-index: -1;
    }

    .call-action .content {
      text-align: center;
    }

      .call-action .content h2 {
        font-size: 35px;
        font-weight: 600;
        color: #fff;
        display: block;
        margin-bottom: 15px;
      }

      .call-action .content p {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }

      .call-action .content .button {
        margin-top: 30px;
      }

        .call-action .content .button .btn:hover {
          color: #081828;
          background: #fff;
        } */
/*======================================
    Bookmarked CSS
========================================*/
/* .bookmarked {
    background-color: #f9f9f9;
  }

    .bookmarked .job-items {
      padding: 30px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
    }

      .bookmarked .job-items .manage-content {
        padding: 25px 0;
        border-bottom: 1px solid #f1f1f1;
      }

        .bookmarked .job-items .manage-content:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        .bookmarked .job-items .manage-content .title-img .can-img {
          float: left;
          margin-right: 8px;
        }

        .bookmarked .job-items .manage-content .title-img h3 {
          font-weight: 600;
          position: relative;
          top: 8px;
        }

          .bookmarked .job-items .manage-content .title-img h3 span {
            font-size: 13px;
            font-weight: 400;
            display: block;
            margin-top: 5px;
          }

        .bookmarked .job-items .manage-content .time {
          background: #2042e314;
          color: #00aeff;
          padding: 5px 14px;
          border-radius: 3px;
          font-size: 13px;
        }

        .bookmarked .job-items .manage-content .can-img img {
          width: 50px;
          margin: 5px 15px;
        }

        .bookmarked .job-items .manage-content .location {
          font-size: 13px;
          position: relative;
          padding-left: 20px;
        }

          .bookmarked .job-items .manage-content .location i {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 3px;
          }

        .bookmarked .job-items .manage-content p i {
          font-size: 14px;
        }

        .bookmarked .job-items .manage-content h3 {
          font-size: 15px;
          font-weight: 500;
        }

        .bookmarked .job-items .manage-content .button .btn {
          padding: 8px 12px;
          font-size: 13px;
        } */
/*======================================
    Responsive CSS
========================================*/
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .section-title {
    margin-bottom: 50px;
    padding: 0 220px; }
  .header .header .login-button {
    margin-left: 20px; }
  .header .login-button ul {
    padding: 0; }
  .header .nav-item {
    margin-right: 30px; }
  .header .nav-item a {
    padding: 18px 0 !important;
    padding-top: 38px !important; }
  .header .nav-item .sub-menu li a {
    padding: 12px 15px !important; }
  .header .nav-item .sub-menu.mega-menu {
    width: 460px; }
  .hero-area {
    padding-top: 60px;
    padding-bottom: 60px; }
  .hero-area .hero-text h2 {
    font-size: 38px; }
  .hero-area .hero-text .button .btn {
    padding: 12px 10px;
    margin: 12px 0px; }
  .hero-area.style2 .hero-text h2 {
    font-size: 42px; } }

/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    padding: 60px 0px; }
  .section-title {
    margin-bottom: 30px;
    padding: 0px 90px; }
  .section-title h2 {
    font-size: 30px;
    line-height: 38px; }
  .section-title p {
    font-size: 16px; }
  .section-title.align-left {
    padding: 0;
    padding-right: 200px; }
  .breadcrumbs {
    padding-top: 50px;
    padding-bottom: 20px; }
  .breadcrumbs .page-title {
    font-size: 28px; }
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500; }
  #scrollUp {
    bottom: 55px; }
  .header {
    padding: 15px 0; }
  .navbar-area.sticky {
    padding: 15px 0;
    min-height: 80px;
    max-height: 80px; }
  .navbar-brand img {
    width: 170px; }
  .sticky .navbar .navbar-nav .nav-item .sub-menu .nav-item a {
    color: #081828; }
  .sticky .navbar .navbar-nav .nav-item:hover a {
    color: #00aeff; }
  .breadcrumbs .bread-list {
    margin-top: 8px; }
  .why-choose .choose-content .single-work {
    padding: 10px; }
  .why-choose .choose-content .single-work img {
    padding: 5px; }
  .why-choose .choose-content .single-work .single-list-logo-wrapper2 {
    top: 38px; }
  .why-choose .choose-content .single-work .single-list-logo-img {
    top: 20%;
    left: 50%; }
  .hero-area {
    padding-top: 60px;
    padding-bottom: 60px; }
  .hero-area .hero-text h2 {
    font-size: 38px; }
  .hero-area .hero-text .button .btn {
    padding: 12px 10px;
    margin: 12px 0px; }
  .hero-area.style2 .hero-text {
    text-align: center; }
  .hero-area.style2 .hero-text h2 {
    font-size: 38px; }
  .hero-area.style2 .hero-text h2::before {
    left: 50%;
    transform: translateX(-50%); }
  .hero-area.style3 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .hero-area.style3 .hero-text {
    text-align: center; }
  .hero-area.style3 .hero-text h2 {
    font-size: 38px; }
  .hero-area.style3 .hero-text h2::before {
    left: 50%;
    transform: translateX(-50%); }
  .hero-area.style3 .search-form {
    margin-top: 30px; }
  .items-tab.custom-padding {
    padding-top: 120px; }
  .categories.style2 {
    height: auto;
    padding: 60px 0; }
  .categories .single-cat .icon img {
    width: 35px; }
  .our-achievement {
    padding-top: 30px; }
  .our-achievement .single-achievement {
    padding: 0; }
  .our-achievement .single-achievement h3 {
    font-size: 30px; }
  .our-achievement .single-achievement p {
    font-size: 16px; }
  .single-ad-grid .align-items-center {
    align-items: inherit !important; }
  .single-ad-grid .image {
    height: 100%; }
  .single-ad-grid .image a {
    height: 100%; }
  .single-ad-grid .image img {
    height: 100%; }
  .items-tab.custom-padding {
    padding-top: 150px; }
  .items-tab .section-title {
    margin-bottom: 30px; }
  .items-tab .single-grid .content .top-content .info-list li a {
    line-height: 20px; }
  /* .browse-cities .section-title {
      margin-bottom: 30px;
    }

    .pricing-table .section-title {
      margin-bottom: 30px;
    }

    .testimonials {
      padding-bottom: 100px !important;
    }

      .testimonials .tns-nav {
        bottom: 50px;
      } */
  .connect-easily .single-work {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 6px; }
  .how-works .single-work {
    padding: 25px 15px;
    margin-top: 30px;
    min-height: 240px; }
  .how-works .single-work .serial {
    height: 70px;
    width: 70px;
    line-height: 60px;
    border: 4px solid #FFF;
    margin-bottom: 25px;
    font-size: 22px; }
  .how-works .single-work h3 {
    font-size: 20px;
    margin-bottom: 8px; }
  .how-works .single-work p {
    font-size: 15px; }
  .item-details .product-info {
    padding-left: 0;
    margin-top: 40px; }
  .latest-news-area .single-news {
    margin-top: 30px; }
  .newsletter .newsletter-form {
    padding-left: 0; }
  .newsletter .newsletter-form input {
    padding-right: 135px; }
  .about-us .content-right {
    margin-top: 30px;
    padding-right: 0;
    padding-left: 0; }
  /* .post-ad-tab nav .nav-tabs {
      border: none;
      padding: 20px;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .post-ad-tab .step-two-content .upload-input {
      margin-bottom: 20px;
    }

    .post-ad-tab nav button {
      border-radius: 6px !important;
      width: 100%;
    } */
  .contact-us .form-main {
    margin-top: 30px; }
  /* .map-section .mapouter {
      height: 400px;
    }

    .map-section .gmap_canvas {
      height: 400px;
    }

    .map-section iframe {
      height: 400px;
    } */
  .faq {
    padding-bottom: 40px; }
  /* .coming-soon .soon-content .text h2 {
      font-size: 35px !important;
    } */
  .dashboard .main-content .details-lists .single-list {
    padding: 0;
    padding: 20px;
    text-align: center; }
  .dashboard .main-content .details-lists .single-list .list-icon {
    height: 55px;
    width: 55px;
    line-height: 55px;
    transform: translateY(0%);
    text-align: center;
    position: relative;
    text-align: center;
    left: 0;
    top: 0;
    display: inline-block;
    margin-bottom: 15px; }
  .dashboard .main-content .details-lists .single-list .list-icon i {
    font-size: 20px; }
  .dashboard-sidebar {
    margin-bottom: 30px; }
  .messages-body .chat-search-form {
    border-right: none; }
  .messages-body .form-head .username-title {
    text-align: left;
    padding-left: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #eee;
    padding-top: 30px; }
  .messages-body .user-list {
    border-right: none; }
  .messages-body .chat-list {
    padding-left: 30px;
    border-top: 1px solid #eee; }
  .messages-body .chat-list .reply-block input {
    width: 240px; }
  .close-content h2 {
    font-size: 26px; }
  .latest-news-area.blog-list {
    padding-top: 30px !important; }
  .blog-grid-page {
    margin-top: 30px; }
  .blog-grid-page.pagination {
    margin-top: 10px !important; }
  .footer .footer-top {
    padding: 50px 0 !important;
    padding-top: 45px !important; }
  .footer .single-footer h3 {
    margin-bottom: 25px !important; }
  .footer .single-footer {
    margin-top: 40px; }
  .footer .footer-bottom .footer-social {
    margin-top: 30px !important; } }

/* Mobile Screen */
@media only screen and (max-width: 767px) {
  .section {
    padding: 60px 0px; }
  .section-title {
    margin-bottom: 25px;
    padding: 0px 10px; }
  .section-title h2 {
    font-size: 25px;
    margin-top: 3px;
    line-height: 35px; }
  .section-title p {
    font-size: 16px; }
  .section-title.align-left {
    padding: 0;
    padding-right: 0; }
  .breadcrumbs {
    padding-top: 50px;
    padding-bottom: 20px; }
  .breadcrumbs .breadcrumbs-content {
    text-align: center; }
  .breadcrumbs .page-title {
    font-size: 22px;
    line-height: 30px !important; }
  .breadcrumbs .breadcrumb-nav {
    float: none !important;
    margin-top: 15px;
    text-align: center; }
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500; }
  .stripe-pay-modal {
    margin-top: 35%; }
  #scrollUp {
    bottom: 55px; }
  .navbar-brand img {
    width: 170px; }
  .header {
    padding: 20px 0; }
  .header .add-list-button {
    display: none; }
  .header .login-button {
    margin-left: 0px; }
  .header .navbar-collapse {
    top: 59px !important;
    border-top: 1px solid #eee; }
  .sticky .navbar .navbar-nav .nav-item .sub-menu .nav-item a {
    color: #081828; }
  .sticky .navbar .navbar-nav .nav-item:hover a {
    color: #00aeff; }
  .breadcrumbs .bread-list {
    margin-top: 8px; }
  .navbar-area.sticky {
    padding: 20px 0;
    min-height: 80px;
    max-height: 80px; }
  .navbar-brand img {
    width: 170px; }
  .breadcrumbs .bread-list {
    margin-top: 8px; }
  .for-me-graph-block {
    padding-left: 25px;
    padding-right: 0px; }
  .for-me-graph-block-first {
    padding-left: 25px;
    padding-right: 0px; }
  .for-me-graph-block-last {
    padding-right: 0px; }
  .for-me-graph-block .row {
    padding: 20px;
    border: 1px solid #eee;
    padding-bottom: 0px;
    padding-top: 40px;
    vertical-align: middle;
    height: 160px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px; }
  .for-me-wallet-block {
    min-height: 450px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px !important;
    padding-bottom: 80px; }
  .for-me-wallet-text {
    text-align: center; }
  .for-me-wallet-text-large {
    text-align: center; }
  .for-me-wallet-text-company {
    font-size: 16px;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .for-me-wallet-text-wrapper {
    padding-top: 0;
    margin-top: 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; }
  .for-me-wallet-text-code {
    font-size: 11px; }
  .why-choose .choose-content .single-work .single-list-logo-wrapper2 {
    top: 46px; }
  .why-choose .choose-content .single-work .single-list-logo-img {
    left: 38%; }
  .why-choose .choose-content .single-list {
    min-height: 300px; }
  .vouchers-are-important .choose-content .single-list {
    min-height: 300px; }
  .hero-area {
    padding-top: 60px;
    padding-bottom: 60px; }
  .hero-area .hero-text h2 {
    font-size: 32px;
    line-height: 38px; }
  .hero-area .hero-text .button .btn {
    padding: 12px 10px;
    margin: 12px 0px; }
  .hero-area .search-form {
    margin-top: 30px;
    background: #fff3;
    border-radius: 6px;
    padding: 40px; }
  .hero-area .search-form .search-input {
    margin: 0;
    padding: 0;
    margin-bottom: 10px; }
  .hero-area .search-form .btn {
    margin: 0; }
  .hero-area.style2 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .hero-area.style2 .hero-text {
    text-align: center; }
  .hero-area.style2 .hero-text h2 {
    font-size: 32px;
    line-height: 38px; }
  .hero-area.style2 .hero-text h2::before {
    left: 50%;
    transform: translateX(-50%); }
  .hero-area.style2 .search-form {
    background-color: #fff;
    padding: 25px; }
  .hero-area.style3 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .hero-area.style3 .hero-text {
    text-align: center; }
  .hero-area.style3 .hero-text h2 {
    font-size: 32px;
    line-height: 38px; }
  .hero-area.style3 .hero-text h2::before {
    left: 50%;
    transform: translateX(-50%); }
  .hero-area.style3 .categories {
    margin-top: 20px; }
  .hero-area.style3 .categories .single-cat {
    margin-top: 20px; }
  .hero-area.style3 .search-form {
    margin-top: 30px;
    transform: translateY(0);
    padding-right: 40px; }
  .items-tab.custom-padding {
    padding-top: 50px; }
  .categories.style2 {
    height: auto;
    padding: 60px 0; }
  .category-page .category-grid-topbar {
    margin-top: 30px; }
  .category-page .category-grid-topbar .nav {
    float: none;
    margin-top: 15px; }
  .our-achievement {
    padding-top: 60px; }
  .our-achievement .single-achievement {
    padding: 0; }
  .our-achievement .single-achievement h3 {
    font-size: 30px; }
  .our-achievement .single-achievement p {
    font-size: 16px; }
  .categories .tns-controls button {
    width: 30px;
    height: 46px;
    opacity: 1;
    visibility: visible;
    border-radius: 0; }
  .categories .tns-controls button:first-child {
    left: 0;
    border-radius: 0 6px 6px 0; }
  .categories .tns-controls button:last-child {
    right: 0;
    border-radius: 6px 0 0 6px; }
  .categories .single-cat .icon img {
    width: 35px; }
  .categories .cat-inner {
    top: 60px; }
  .items-grid.custom-padding {
    padding-top: 150px; }
  .items-grid .section-title {
    margin-bottom: 30px; }
  .items-grid .single-grid .content .top-content .info-list li a {
    line-height: 20px; }
  /* .browse-cities .section-title {
      margin-bottom: 30px;
    } */
  .call-action .content h2 {
    font-size: 28px; }
  .items-tab .nav {
    border: none;
    margin: 0 !important; }
  .items-tab .nav-tabs .nav-link {
    padding: 12px 40px;
    display: block !important;
    text-align: center !important;
    width: 100%;
    margin-bottom: 7px;
    border-radius: 6px 6px 0 0; }
  /* .pricing-table .section-title {
      margin-bottom: 30px;
    }

    .testimonials {
      padding-bottom: 80px !important;
    }

      .testimonials .single-testimonial {
        padding: 35px 30px;
      }

        .testimonials .single-testimonial .quote-icon i {
          font-size: 35px;
        }

      .testimonials .tns-nav {
        bottom: 45px;
      } */
  .connect-easily .single-work {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 6px; }
  .how-works .single-work {
    padding: 25px 15px;
    margin-top: 30px;
    min-height: 180px; }
  .how-works .single-work .serial {
    height: 70px;
    width: 70px;
    line-height: 60px;
    border: 4px solid #FFF;
    margin-bottom: 25px;
    font-size: 22px; }
  .how-works .single-work h3 {
    font-size: 20px;
    margin-bottom: 8px; }
  .how-works .single-work p {
    font-size: 15px; }
  /* .newsletter .title {
      text-align: center;
      padding: 0;
    }

      .newsletter .title i {
        position: relative;
        top: 0 !important;
        display: inline-block;
        text-align: center;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 15px;
      }

    .newsletter .newsletter-form {
      margin-top: 20px;
    }

      .newsletter .newsletter-form .button {
        position: relative;
        margin-top: 15px;
        width: 100%;
      }

        .newsletter .newsletter-form .button .btn {
          width: 100%;
          border-radius: 6px;
        } */
  /* .faq-area {
      padding-bottom: 20px !important;
    }

      .faq-area .single-faq {
        margin-bottom: 45px;
      }

      .faq-area .heading {
        font-size: 15px;
        line-height: 25px;
      } */
  .brand-area .section-title.align-left {
    padding-right: 0;
    margin-bottom: 40px !important; }
  .client-logo-section {
    padding: 0 !important; }
  .client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo img {
    max-width: 170px; }
  .about-us .content-right {
    margin-top: 30px;
    padding-right: 0;
    padding-left: 0; }
  .about-us .content-right h2 {
    font-size: 25px;
    line-height: 35px; }
  .about-us .content-right h3 {
    margin-top: 30px; }
  .latest-news-area .section-title {
    margin-bottom: 10px; }
  .latest-news-area .single-news {
    margin-top: 30px; }
  .latest-news-area .single-news .content-body .title {
    line-height: 24px !important; }
  .latest-news-area .single-news .content-body .title a {
    font-size: 17px !important; }
  .post-details .post-meta li {
    margin-bottom: 8px; }
  .post-details p {
    margin: 20px 0; }
  .post-image .blog-inner-big-img {
    margin-bottom: 1.5rem !important; }
  .post-details .list {
    margin-left: 0 !important; }
  .post-details .list li i {
    top: 6px !important; }
  .post-comments {
    padding: 30px; }
  .post-comments .comments-list {
    padding: 0 !important; }
  .post-comments .comments-list li {
    padding: 0 !important; }
  .post-comments .comments-list li .comment-img {
    position: relative !important; }
  .comment-form {
    padding: 30px; }
  .comment-form form .form-box .form-control-custom {
    height: 50px;
    margin-bottom: 15px; }
  .post-comments .comments-list li.children {
    margin-left: 0 !important; }
  .post-comments .comments-list li {
    padding-top: 30px !important; }
  .post-comments .comments-list li .comment-desc {
    margin-top: -15px; }
  .post-comments .comments-list li .comment-img img {
    max-width: 70px;
    max-height: 70px;
    display: inline-block !important;
    margin-bottom: -15px !important; }
  .post-comments .comments-list li .comment-desc .desc-top .reply-link {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(0);
    padding: 8px 25px;
    position: relative;
    margin-top: 15px; }
  .post-comments .comments-list li {
    padding-left: 100px; }
  .latest-news-area.blog-list {
    padding-top: 20px; }
  .blog-list .single-news {
    margin-bottom: 0 !important; }
  .sidebar {
    margin-top: 30px; }
  .sidebar .widget.search-widget form input {
    padding: 0 80px 0 20px; }
  .sidebar .widget.social-widget ul li {
    margin-bottom: 10px; }
  .post-details .post-title {
    line-height: 30px; }
  .post-details .post-title a {
    font-size: 24px;
    font-weight: 600; }
  .error-area .error-content h1 {
    font-size: 60px !important;
    line-height: 80px !important; }
  .error-area .error-content h2 {
    font-size: 18px !important; }
  /*
    .maill-success .success-content h1 {
      font-size: 28px !important;
    }

    .maill-success .success-content h2 {
      font-size: 18px !important;
    } */
  .item-details .product-info {
    padding-left: 0;
    margin-top: 30px; }
  .item-details .product-info .contact-info ul li {
    margin: 0;
    width: 100%; }
  .item-details .product-info .contact-info ul li:last-child {
    width: 100%;
    text-align: center;
    margin-top: 8px; }
  .item-details .product-info .contact-info ul li:last-child a {
    width: 100%; }
  .item-details .product-info .social-share h4 {
    display: block;
    margin-bottom: 15px; }
  .item-details-blocks .single-block.comments .single-comment {
    position: relative;
    padding-left: 0;
    margin-top: 30px; }
  .item-details-blocks .single-block.comments .single-comment img {
    position: relative;
    margin-bottom: 15px; }
  .item-details .product-images .images {
    display: grid;
    Grid-template-columns: repeat(4, 1fr);
    Grid-gap: 1em 1em;
    Margin-top: 1em; }
  .item-details .product-info .social-share ul li {
    margin-bottom: 8px; }
  .item-details .product-info .contact-info ul li .call {
    width: 100%; }
  .item-details .product-info .title {
    font-size: 25px;
    margin-bottom: 10px; }
  .item-details .product-info .contact-info ul li .call {
    width: 100%; }
  .item-details .recipient-next-button-info {
    padding-left: 10px;
    white-space: normal;
    margin-right: -3px;
    text-align: right; }
  /*  .item-details .recipient-when-to-send-button {
      margin-left: 0px;
      right: 0px;
    } */
  .item-details .recipient-when-to-send-button-info {
    padding-left: 5px;
    text-align: center;
    margin-right: -15px;
    margin-left: 15px;
    white-space: normal;
    width: 100%; }
  .latest-news-area .single-news {
    margin-top: 30px; }
  /* .newsletter .newsletter-form {
      padding-left: 0;
    }

    .newsletter .newsletter-form input {
      padding-right: 135px;
    } */
  /* .post-ad-tab nav .nav-tabs {
      border: none;
      padding: 20px;
      border: 1px solid #eee;
      border-radius: 6px;
    }

    .post-ad-tab .step-two-content .upload-input {
      margin-bottom: 20px;
    }

    .post-ad-tab nav button {
      border-radius: 6px !important;
      width: 100%;
    } */
  .login .form-head {
    padding: 30px; }
  .login .form-head .title {
    font-size: 25px; }
  .login .form-head .alt-option {
    margin: 20px 0; }
  .login .form-head form .form-group {
    margin-bottom: 18px; }
  .contact-us .form-main {
    margin-top: 30px; }
  .contact-us .single-head {
    padding: 30px !important; }
  .contact-us .form-main {
    padding: 30px !important; }
  .contact-us .contant-inner-title h2 {
    font-size: 22px; }
  /*.map-section .mapouter {
      height: 300px;
    }

    .map-section .gmap_canvas {
      height: 300px;
    }

    .map-section iframe {
      height: 300px;
    } */
  .contact-us .form-main .form-title h2 {
    font-size: 25px; }
  .faq {
    padding-bottom: 30px; }
  .login .form-head form .check-and-pass .form-check {
    float: none;
    text-align: center;
    display: block;
    margin-top: 10px; }
  .login .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
    float: none;
    text-align: center; }
  .login .form-head form .check-and-pass .lost-pass {
    float: right;
    color: #777;
    position: relative;
    top: -2px;
    float: none;
    margin-top: 10px;
    text-align: center;
    display: block; }
  /* .coming-soon .soon-content .text h2 {
      font-size: 28px !important;
      margin-bottom: 10px !important;
      line-height: 32px !important;
    }

    .coming-soon .soon-content .text {
      margin-bottom: 10px !important;
    }

    .coming-soon .soon-content .box {
      margin-top: 18px !important;
    } */
  .dashboard .main-content .details-lists .single-list {
    margin-top: 30px; }
  .dashboard-sidebar {
    margin-bottom: 30px; }
  .profile-settings-block .profile-setting-form .form-group {
    margin-bottom: 20px !important; }
  .close-account-button {
    position: absolute;
    bottom: 20px; }
  .close-content {
    margin-bottom: 5px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0; }
  .my-items .item-list-title {
    text-align: left; }
  .my-items .item-list-title p {
    padding: 5px 0; }
  .my-items .item-list-title .align-right {
    float: none;
    text-align: left !important; }
  .my-items .single-item-list p {
    padding: 8px 0; }
  .my-items .single-item-list .item-image {
    margin-bottom: 10px; }
  .my-items .single-item-list .action-btn {
    margin-top: 10px; }
  .my-items .single-item-list .align-right {
    text-align: left !important; }
  .messages-body .chat-search-form {
    border-right: none; }
  .messages-body .form-head .username-title {
    text-align: left;
    padding-left: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #eee;
    padding-top: 30px; }
  .messages-body .user-list {
    border-right: none; }
  .messages-body .chat-list {
    padding-left: 30px;
    border-top: 1px solid #eee; }
  .messages-body .chat-list .single-chat-head li.left img {
    height: 35px;
    width: 35px;
    bottom: 6px; }
  .messages-body .chat-list .single-chat-head li.right img {
    height: 35px;
    width: 35px;
    bottom: 6px; }
  .messages-body .chat-list .single-chat-head li.left {
    padding: 0;
    padding-left: 50px; }
  .messages-body .chat-list .single-chat-head li.right {
    padding: 0;
    padding-right: 50px; }
  .messages-body .chat-list .single-chat-head li.left .text {
    padding: 17px;
    font-size: 15px;
    line-height: 21px; }
  .messages-body .chat-list .single-chat-head li.left .text .time {
    font-size: 13px; }
  .messages-body .chat-list .single-chat-head li.right .text {
    padding: 17px;
    font-size: 15px;
    line-height: 21px; }
  .messages-body .chat-list .single-chat-head li.right .text .time {
    font-size: 13px; }
  .messages-body .chat-list .reply-block input {
    width: 100%;
    padding: 20px;
    border: 1px solid #eee;
    margin-top: 10px;
    padding-right: 75px;
    border-radius: 6px; }
  .messages-body .chat-list .reply-block {
    position: relative; }
  .messages-body .chat-list .reply-block button {
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 48px;
    color: #fff;
    background: #00aeff;
    border-radius: 6px;
    border: none;
    font-size: 18px;
    position: absolute;
    top: -4px;
    transition: all 0.4s ease;
    padding-left: 3px;
    padding-bottom: 3px;
    right: 8px;
    top: 87px; }
  .messages-body .chat-list .single-chat-head li.right .text {
    padding: 20px;
    font-size: 15px; }
  .messages-body .chat-list .single-chat-head li.left .text {
    padding: 20px;
    font-size: 15px; }
  .close-content h2 {
    font-size: 22px; }
  .close-content {
    padding: 50px 30px; }
  .close-content .button .btn {
    width: 75%;
    margin: 0;
    margin-bottom: 10px; }
  .close-content .button .btn:last-child {
    margin: 0; }
  .default-list-style .default-list-title p {
    padding: 3px 0; }
  .default-list-title .align-right {
    text-align: left; }
  .default-list-style .single-list p {
    padding: 3px 0; }
  .default-list-style .single-list .action-btn {
    margin-top: 5px; }
  .default-list-style .single-list .align-right {
    text-align: left; }
  .post-details .detail-inner {
    padding: 20px;
    margin-bottom: 30px; }
  .post-details .post-title a {
    font-size: 22px;
    font-weight: 600; }
  blockquote {
    margin: 30px 0;
    padding: 20px; }
  blockquote .icon i {
    font-size: 30px; }
  .post-social-media .share-title {
    margin-bottom: 25px; }
  .comment-title {
    margin-bottom: 0px !important; }
  .sidebar .widget {
    padding: 30px; }
  .post-details .post-image img {
    margin-top: 20px; }
  .post-details .post-social-media ul {
    margin-left: 0; }
  .footer .footer-top {
    padding: 30px 0 !important;
    padding-top: 25px !important; }
  .footer .footer-bottom .inner {
    padding: 30px 0 !important; }
  .footer .single-footer h3 {
    margin-bottom: 25px !important; }
  .footer .single-footer {
    margin-top: 40px;
    text-align: center; }
  .footer .footer-bottom .footer-social {
    margin-top: 30px !important; } }

/*======================================
	Contact CSS
========================================*/
.contact-us {
  position: relative;
  background-color: #f9f9f9; }

.contact-us .contact-head {
  overflow: hidden;
  position: relative;
  z-index: 5; }

.contact-us .title {
  margin-bottom: 30px; }

.contact-us .contant-inner-title {
  margin-bottom: 45px; }

.contact-us .contant-inner-title h2 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #081828;
  text-transform: capitalize; }

.contact-us .contant-inner-title p {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 10px;
  display: block; }

.contact-us .single-head {
  padding: 50px;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px; }

.contact-us .single-info {
  text-align: left;
  margin-bottom: 35px;
  position: relative; }

.contact-us .single-info:last-child {
  margin: 0; }

.contact-us .single-info h3 {
  font-size: 20px;
  margin-bottom: 15px; }

.contact-us .single-info ul {
  display: inline-block; }

.contact-us .single-info ul li {
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #888; }

.contact-us .single-info ul li:last-child {
  margin: 0; }

.contact-us .single-info ul li a {
  font-weight: 400;
  color: #7E8890;
  display: inline-block;
  font-size: 15px; }

.contact-us .single-info ul li a:hover {
  color: #00aeff; }

.contact-us .contact-social {
  display: block;
  margin-top: 65px; }

.contact-us .contact-social h5 {
  color: #081828;
  font-weight: 600;
  font-size: 18px; }

.contact-us .contact-social ul li {
  display: inline-block;
  margin-right: 6px; }

.contact-us .contact-social ul li:last-child {
  margin-right: 0; }

.contact-us .contact-social ul li a {
  font-size: 14px;
  color: #555;
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  line-height: 40px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 50%;
  position: relative;
  z-index: 3; }

.contact-us .contact-social ul li a:hover {
  color: #fff;
  background-color: #00aeff;
  border-color: transparent; }

.contact-us .single-info .title {
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff;
  font-size: 18px; }

.contact-us .form-main {
  padding: 45px 50px 50px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  height: 100%; }

.contact-us .form-main .form-title {
  margin-bottom: 30px; }

.contact-us .form-main .form-title h2 {
  margin-bottom: 20px;
  font-size: 30px; }

.contact-us .form-main .form-title p {
  font-size: 16px; }

.contact-us .form .form-group {
  margin-bottom: 15px;
  display: block; }

.contact-us .form .form-group input {
  height: 55px;
  line-height: 55px;
  width: 100%;
  border: 1px solid #e6e2f5;
  padding: 0px 20px;
  color: #333;
  border-radius: 0px;
  font-weight: 400;
  border-radius: 5px; }

.contact-us .form .form-group textarea {
  height: 180px;
  width: 100%;
  border: 1px solid #e6e2f5;
  padding: 15px 20px;
  color: #333;
  border-radius: 0px;
  resize: none;
  font-weight: 400;
  border-radius: 5px;
  resize: vertical; }

.contact-us .form .button {
  margin: 0; }

.contact-us .form .button .btn {
  height: 50px;
  border: none; }

/* .map-section {
    background-color: #f9f9f9;
  } */
/*======================================
	Error 404 CSS
========================================*/
.error-area {
  height: 100vh;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

.d-table {
  width: 100%;
  height: 100%; }

.d-table {
  display: table !important; }

.d-table-cell {
  vertical-align: middle; }

.d-table-cell {
  display: table-cell !important; }

.error-area .error-content h1 {
  font-size: 100px;
  color: #00aeff;
  margin-bottom: 8px;
  font-weight: 800;
  line-height: 100px; }

.error-area .error-content h2 {
  font-size: 25px;
  margin-bottom: 8px;
  color: #081828; }

.error-area .error-content p {
  font-weight: 500;
  margin-bottom: 40px; }

/*======================================
	Mail Success CSS
========================================*/
.maill-success {
  height: 100vh;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

.d-table {
  width: 100%;
  height: 100%; }

.d-table {
  display: table !important; }

.d-table-cell {
  vertical-align: middle; }

.d-table-cell {
  display: table-cell !important; }

/*
  .maill-success .success-content h1 {
    font-size: 40px;
    color: #00aeff;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .maill-success .success-content h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #081828;
  }

  .maill-success .success-content p {
    font-weight: 500;
    margin-bottom: 20px;
  } */
/*======================================
	Coming Soon CSS
========================================*/
/* .coming-soon {
    height: 100vh;
    text-align: center;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: #f9f9f9;
    z-index: 2;
  }

    .coming-soon .verticle-lines .vlines {
      width: 3px;
      height: 100%;
      background: #9b9b9b;
      position: absolute;
      top: 0;
      bottom: 0;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-name: lineanim;
      animation-name: lineanim;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      opacity: 0.05;
      z-index: -1;
    }

      .coming-soon .verticle-lines .vlines.one {
        left: 20%;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }

      .coming-soon .verticle-lines .vlines.two {
        left: 40%;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }

      .coming-soon .verticle-lines .vlines.three {
        left: 60%;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
      }

      .coming-soon .verticle-lines .vlines.four {
        left: 80%;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
      } */

@keyframes lineanim {
  50% {
    transform: scaleY(1);
    transform-origin: top left; }
  50.1% {
    transform-origin: bottom left; }
  100% {
    transform: scaleY(0);
    transform-origin: bottom left; } }

.d-table {
  width: 100%;
  height: 100%; }

.d-table {
  display: table !important; }

.d-table-cell {
  vertical-align: middle; }

.d-table-cell {
  display: table-cell !important; }

/* .coming-soon .soon-content {
    text-align: center;
  }

    .coming-soon .soon-content .text {
      margin-bottom: 60px;
    }

      .coming-soon .soon-content .text h2 {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        color: #081828;
        display: block;
        margin-bottom: 20px;
        text-transform: capitalize;
      }

      .coming-soon .soon-content .text p {
        color: #888;
        font-size: 16px;
      }

    .coming-soon .soon-content .box {
      background: #fff;
      border: 1px solid #eee;
      border-radius: 6px;
      width: 100px;
      height: 100px;
      margin: 0 5px;
      display: inline-block;
      padding-top: 25px;
    }

    .coming-soon .soon-content h2 {
      text-align: center;
      padding-top: 2px;
      color: #081828;
      font-size: 15px;
      font-weight: 400;
    }

    .coming-soon .soon-content .box h1 {
      font-size: 26px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 5px;
      color: #00aeff;
    }

    .coming-soon .soon-content .alert {
      display: none;
    } */
/*======================================
	Footer CSS
========================================*/
.footer {
  background: #081828; }

.footer .footer-top {
  padding: 100px 0;
  padding-top: 70px;
  border-bottom: 1px solid #ebebeb s; }

.footer .single-footer {
  margin-top: 30px; }

.footer .single-footer.mobile-app .app-button .btn {
  border: 1px solid #eeeeee4a;
  border-radius: 6px;
  padding: 12px 35px;
  position: relative;
  text-align: left;
  padding-left: 70px;
  margin-bottom: 12px;
  min-width: 192px;
  color: #e1e1e1; }

.footer .single-footer.mobile-app .app-button .btn:hover {
  border-color: transparent;
  background: #00aeff;
  color: #fff; }

.footer .single-footer.mobile-app .app-button .btn:last-child {
  margin: 0; }

.footer .single-footer.mobile-app .app-button .btn i {
  font-size: 28px;
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%); }

.footer .single-footer.mobile-app .app-button .btn .text {
  font-size: 14px;
  font-weight: 500; }

.footer .single-footer.mobile-app .app-button .btn .text .small-text {
  font-weight: 400;
  font-size: 13px;
  display: block;
  margin-bottom: -1px; }

.footer .single-footer.f-link li {
  display: block;
  margin-bottom: 10px; }

.footer .single-footer.f-link li:last-child {
  margin: 0; }

.footer .single-footer.f-link li a {
  font-size: 15px;
  font-weight: 400;
  color: #e1e1e1; }

.footer .single-footer.f-link li a:hover {
  color: #00aeff; }

.footer .single-footer.f-contact ul li {
  display: block;
  margin-bottom: 25px;
  line-height: 25px;
  color: #e1e1e1; }

.footer .single-footer.f-contact ul li:last-child {
  margin-bottom: 0; }

.footer .single-footer h3 {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 35px;
  color: #fff; }

.footer .footer-bottom {
  text-align: center;
  position: relative;
  z-index: 1; }

.footer .footer-bottom .inner {
  border-top: 1px solid #ebebeb4f;
  padding: 50px 0; }

.footer .footer-bottom .footer-bottom-links li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-right: 15px; }

.footer .footer-bottom .footer-bottom-links li::before {
  position: absolute;
  content: "";
  right: -3px;
  top: 50%;
  height: 1px;
  width: 5px;
  background: #888; }

.footer .footer-bottom .footer-bottom-links li:last-child {
  margin: 0;
  padding: 0; }

.footer .footer-bottom .footer-bottom-links li:last-child::before {
  display: none; }

.footer .footer-bottom .footer-bottom-links li a {
  color: #e1e1e1;
  font-weight: 400; }

.footer .footer-bottom .footer-bottom-links li a:hover {
  color: #00aeff; }

.footer .footer-bottom .copyright-text {
  margin-top: 15px;
  color: #e1e1e1;
  font-family: 'Jost', sans-serif; }

.footer .footer-bottom .copyright-text a {
  color: #e1e1e1; }

.footer .footer-bottom .copyright-text a:hover {
  color: #00aeff; }

.footer .footer-bottom .footer-social {
  margin-top: 40px; }

.footer .footer-bottom .footer-social li {
  display: inline-block;
  margin-right: 10px; }

.footer .footer-bottom .footer-social li:last-child {
  margin: 0; }

.footer .footer-bottom .footer-social li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 50%;
  color: #fff;
  background: transparent;
  border: 1px solid #eee3; }

.footer .footer-bottom .footer-social li a:hover {
  color: #fff;
  background: #00aeff;
  border-color: transparent; }

