/* TAB STYLES
   ----------------------------- */

.tabs-container {
  @include cardShadow;
  border-radius: $cardRoundedCorner $cardRoundedCorner 0 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.nav-tabs {
  background-color: $tabHeadingBgColor;
  border-bottom: 0;
  border-bottom: 1px solid #ebeef0;

  > li {
    border-right: 1px solid #ebeef0;

    > a {
      border-radius: 0;
      color: $tabLinkColor;
      padding: 15px 20px;
      font-weight: normal;
      margin-right: 0;
      cursor: pointer;
      border: 1px solid transparent !important;

      &:hover {
        background-color: #fafbfc;
        color: #0078bd;
        border-bottom: 1px solid #ebeef0 !important;
      }
    }

    &.active {
      > a {
        color: #1a1a1a;
        background-color: #ffffff;
        border-bottom: 1px solid white !important;

        &:hover {
          border-bottom: 1px solid transparent !important;
        }
      }
    }
  }
  &.tabs-justified {
    width: 100%;

    > li {
      display: table-cell;
      width: 1%;
      float: none;

      > a {
        text-align: center;
      }
    }
  }
  & .nav-item {
    margin-bottom: 0;
  }
}
.tab-content {
  // background: white;
 // padding: 20px;
}

.tabs-left {
  > .nav-tabs {
    float: left;
    margin-right: 0px;

    li {
      float: none;
      border-right: 0;

      a {
        margin-right: 0;
        min-width: 74px;
        border-radius: 4px 0 0 4px;
        margin-right: -1px;
        -webkit-border-top-left-radius: 3px;
        -webkit-border-bottom-left-radius: 3px;
        -moz-border-radius-topleft: 3px;
        -moz-border-radius-bottomleft: 3px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.active {
        a {
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  > .tab-content {
    overflow: auto;
    position: relative;
    z-index: 10;
    background-color: #ffffff;
    overflow: hidden;
  //  margin-bottom: 18px;
  }
}

/*custom tab*/
.nav.customtab {
  background-color: transparent;

  > li {
    border-color: transparent;

    a,
    a:hover,
    a:focus {
      border: 0px;
      background: transparent;
    }

    &.active a,
    &.active a:hover,
    &.active a:focus {
      background: transparent !important;
      border: 0px !important;
      border-bottom: 2px solid #458ab6 !important;
      margin-bottom: -1px;
      color: #458ab6;
    }
  }
}

.tab-content.customtab {
  background-color: transparent;
}
